import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useEventData, useEventResult } from '../../../api/event';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import { useEventId } from '../../../redux/selectors/gameSelectors';
import { EventStatus } from '../../../types';
import Button from '../../uiElements/button/MainButton';
import TextWindow from '../../uiElements/TextWindow';

interface AllStagesFinishedProps {}
const AllStagesFinished: React.FC<AllStagesFinishedProps> = () => {
  const user = useCurrentUser();
  const eventId = useEventId();
  const history = useHistory();
  const [eventResult] = useEventResult(user?.uid ?? null, eventId);
  const [eventData] = useEventData(eventId);
  const resultReady =
    eventResult !== undefined && eventData?.status === EventStatus.Ended;

  const toResult = React.useCallback(() => {
    if (!eventId) return;
    history.push(`/events/${eventId}/result`);
  }, [eventId, history]);

  const resultDate = React.useMemo(() => {
    if (!eventData) {
      return undefined;
    }
    const d = new Date(eventData?.scheduledEndTime);
    d.setTime(d.getTime() + 86400000);
    return d;
  }, [eventData]);
  const text = resultReady
    ? '最終結果の算出が終わりました。'
    : `すべてのステージが終了しました。\n結果は${
        resultDate &&
        format(resultDate, 'yyyy年M月d日(E)', {
          locale: ja,
        })
      }頃に発表されます`;

  return (
    <AllStagesFinishedWrapper>
      <Caption bracket>{text}</Caption>
      <Button
        size='large'
        color='positive'
        onClick={toResult}
        disabled={!resultReady}
      >
        最終結果を見る
      </Button>
    </AllStagesFinishedWrapper>
  );
};

const AllStagesFinishedWrapper = styled.div``;

const Caption = styled(TextWindow)`
  margin: 5rem auto;
  display: block;
  width: 58rem;
`;

export default AllStagesFinished;
