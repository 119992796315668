import format from 'date-fns/format';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import {
  EventDocument,
  useEventRecords,
  useEventStats,
} from '../../../api/event';
import {
  DataArray,
  calcAvg,
  calcRank,
  getRankMessage,
} from '../../../lib/stageResults';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors from '../../../styles/colors';
import { AdminRole } from '../AdminAdministratorDetail';

interface ResultDetailProps {
  tests: any[];
  event: EventDocument;
  eventId: string;
  role: AdminRole;
}
const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;
const ResultDetail: React.FC<ResultDetailProps> = props => {
  const [eventHistory] = useEventRecords(props.eventId);
  const [totalStats] = useEventStats('total');
  const userAvg = React.useMemo(() => {
    if (eventHistory === undefined) {
      return undefined;
    }

    const scoreList = eventHistory.docs.map(
      ev =>
        stages.map(
          stage => ev.data().calculatedScores?.[stage]?.score ?? -1
        ) as DataArray
    );
    return calcAvg(scoreList);
  }, [eventHistory]);

  const userAvgRank = React.useMemo(() => {
    if (userAvg === undefined) {
      return undefined;
    }
    return userAvg.map(calcRank) as DataArray;
  }, [userAvg]);

  const allUserDeviations = React.useMemo(() => {
    if (userAvg === undefined) {
      return undefined;
    }
    if (userAvg.every(e => e < 0)) {
      return undefined;
    }
    return stages.map((stage, index) => {
      const score = userAvg[index];
      const eventAvg = totalStats?.[stage].avg ?? score;
      const eventStd = totalStats?.[stage].std ?? 1;
      return 50 + 10 * ((score - eventAvg) / eventStd);
    }) as DataArray;
  }, [userAvg, totalStats]);

  return (
    <PresetWrapper>
      <Head>
        <Item>
          <SVG src={Icon_Switch} />
          <p>Switch</p>
        </Item>
        <Item>
          <SVG src={Icon_Program} />
          <p>Program</p>
        </Item>
        <Item>
          <SVG src={Icon_Create} />
          <p>Create</p>
        </Item>
        <Item>
          <SVG src={Icon_Energy} />
          <p>Energy</p>
        </Item>
        <Item>
          <SVG src={Icon_Communicate} />
          <p>Communicate</p>
        </Item>
      </Head>
      {props.role !== 'organizationAdmin' && (
        <>
          <SubHeader>受験者平均ランク</SubHeader>
          <Body>
            <BodyItem>
              <Rank>{getRankMessage(userAvgRank?.[0] ?? -1)}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{getRankMessage(userAvgRank?.[1] ?? -1)}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{getRankMessage(userAvgRank?.[2] ?? -1)}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{getRankMessage(userAvgRank?.[3] ?? -1)}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{getRankMessage(userAvgRank?.[4] ?? -1)}</Rank>
            </BodyItem>
          </Body>
          <SubHeader>同じテストの全受験者における偏差値</SubHeader>
          <Body>
            <BodyItem>
              <Rank>{allUserDeviations?.[0].toFixed(1) ?? '-'}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{allUserDeviations?.[1].toFixed(1) ?? '-'}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{allUserDeviations?.[2].toFixed(1) ?? '-'}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{allUserDeviations?.[3].toFixed(1) ?? '-'}</Rank>
            </BodyItem>
            <BodyItem>
              <Rank>{allUserDeviations?.[4].toFixed(1) ?? '-'}</Rank>
            </BodyItem>
          </Body>
        </>
      )}
    </PresetWrapper>
  );
};

const PresetWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:last-child {
    margin-right: 0;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
`;
const BodyItem = styled.div`
  flex: 1;
  margin-right: 1rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  border-right: 1px solid ${Colors.gray4};

  p {
    font-size: 1.6rem;
    margin: 0.6rem 0;
  }
  span {
    font-size: 1rem;
    color: ${Colors.gray6};
  }

  &:last-child {
    margin-right: 0;
  }
`;
const Rank = styled.div`
  font-weight: bold;
  color: ${Colors.accent};
  font-size: 2.8rem;
  text-align: center;
`;

const SubHeader = styled.div`
  background: ${Colors.gray2};
  font-size: 1.4rem;
  padding: 0.2rem 1rem;
  margin: 0.8rem 0;
`;

export default ResultDetail;
