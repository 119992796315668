import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemoveAdminRoleMutationVariables = Types.Exact<{
  input: Types.AdminRoleInput;
}>;


export type RemoveAdminRoleMutation = (
  { __typename?: 'Mutation' }
  & { removeAdminRole: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type DeleteUserMutationVariables = Types.Exact<{
  input: Types.UserDeleteInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type AdminUpdateUserInfoMutationVariables = Types.Exact<{
  input: Types.AdminUpdateUserInfoInput;
}>;


export type AdminUpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateUserInfo?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);


export const RemoveAdminRoleDocument = gql`
    mutation removeAdminRole($input: AdminRoleInput!) {
  removeAdminRole(input: $input) {
    success
  }
}
    `;
export type RemoveAdminRoleMutationFn = Apollo.MutationFunction<RemoveAdminRoleMutation, RemoveAdminRoleMutationVariables>;

/**
 * __useRemoveAdminRoleMutation__
 *
 * To run a mutation, you first call `useRemoveAdminRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdminRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdminRoleMutation, { data, loading, error }] = useRemoveAdminRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdminRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdminRoleMutation, RemoveAdminRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdminRoleMutation, RemoveAdminRoleMutationVariables>(RemoveAdminRoleDocument, options);
      }
export type RemoveAdminRoleMutationHookResult = ReturnType<typeof useRemoveAdminRoleMutation>;
export type RemoveAdminRoleMutationResult = Apollo.MutationResult<RemoveAdminRoleMutation>;
export type RemoveAdminRoleMutationOptions = Apollo.BaseMutationOptions<RemoveAdminRoleMutation, RemoveAdminRoleMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: UserDeleteInput!) {
  deleteUser(input: $input) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AdminUpdateUserInfoDocument = gql`
    mutation adminUpdateUserInfo($input: AdminUpdateUserInfoInput!) {
  adminUpdateUserInfo(input: $input) {
    success
  }
}
    `;
export type AdminUpdateUserInfoMutationFn = Apollo.MutationFunction<AdminUpdateUserInfoMutation, AdminUpdateUserInfoMutationVariables>;

/**
 * __useAdminUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserInfoMutation, { data, loading, error }] = useAdminUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserInfoMutation, AdminUpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserInfoMutation, AdminUpdateUserInfoMutationVariables>(AdminUpdateUserInfoDocument, options);
      }
export type AdminUpdateUserInfoMutationHookResult = ReturnType<typeof useAdminUpdateUserInfoMutation>;
export type AdminUpdateUserInfoMutationResult = Apollo.MutationResult<AdminUpdateUserInfoMutation>;
export type AdminUpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserInfoMutation, AdminUpdateUserInfoMutationVariables>;