import * as React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import Colors from '../../../styles/colors';
import {
  ExtendedInputProps,
  InputBaseProps,
  TextInputType,
} from './inputConfigs';

interface TextInputProps extends InputBaseProps {
  invalid: boolean;
}

type TextInputFullProps = ExtendedInputProps<TextInputProps>;

const TextInput: React.FC<TextInputFullProps> = props => {
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);
  const inputType: TextInputType =
    props.type === 'password' && isPasswordHidden
      ? 'password'
      : props.type === 'number'
      ? 'number'
      : 'text';
  return (
    <TextInputWrapper fullWidth={props.fullWidth}>
      <TextInputBody
        {...props}
        type={inputType}
        parentType={props.type ?? 'text'}
        invalid={props.invalid}
        disabled={props.disabled}
        width={props.width}
        value={props.value}
        onChange={e => props.onChange?.(e.target.value)}
        textAlign={props.inputAlign}
      />
      {props.type === 'password' && (
        <Button onClick={() => setIsPasswordHidden(p => !p)}>
          {isPasswordHidden ? '表示する' : '非表示'}
        </Button>
      )}
    </TextInputWrapper>
  );
};

const TextInputWrapper = styled.div`
  input[type="number"]::-webkit-outer-spin-button, 
  input[type="number"]::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }} 
  input[type="number"] { 
    -moz-appearance:textfield; 
  } 

  ${(p: Pick<InputBaseProps, 'fullWidth'>) =>
    p.fullWidth ? 'width: 100%;' : 'width: 32rem;'}
  display: inline-flex;
`;

const colorStyles = {
  default: css`
    border-color: ${Colors.gray6};
  `,
  focus: css`
    border-color: ${Colors.primary};
  `,
  readonly: css`
    background-color: ${Colors.gray2};
    border-color: ${Colors.gray4};
  `,
  error: css`
    background-color: ${Colors.highlightError};
    border-color: ${Colors.error};
  `,
};

const textTypeStyle: Partial<
  Record<TextInputType, FlattenSimpleInterpolation>
> = {
  text: css`
    border-width: 0.2rem;
    border-radius: 0.5rem;
  `,
  number: css`
    border-width: 0.2rem;
    border-radius: 0.5rem;
  `,
  password: css`
    border-width: 0.2rem 0 0.2rem 0.2rem;
    border-radius: 0.5rem 0 0 0.5rem;
  `,
};

interface TextInputBodyProps {
  invalid: boolean;
  parentType: TextInputType;
  textAlign?: TextInputFullProps['inputAlign'];
  width?: string;
}

const TextInputBody = styled.input`
  height: 4rem;
  line-height: 4rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-style: solid;
  outline: 0;

  ${(p: TextInputBodyProps) => `width: ${p.width ?? '100%'};`}

  text-align: ${(p: TextInputBodyProps) => p.textAlign ?? 'left'};

  ${(p: TextInputBodyProps) => textTypeStyle[p.parentType]};

  ${() => colorStyles.default};

  &:focus {
    ${() => colorStyles.focus};
  }

  &:disabled {
    ${() => colorStyles.readonly};
  }
  ${(p: TextInputBodyProps) => p.invalid && colorStyles.error};
`;

const Button = styled.button`
  width: 8rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.3rem;
  background-color: ${Colors.gray8};
  border-color: ${Colors.gray8};
  color: ${Colors.white};
  border-radius: 0 0.5rem 0.5rem 0;
  box-sizing: border-box;
  border-width: 0.2rem;
  border-style: solid;
  outline: 0;
  padding: 0 1rem;
  white-space: nowrap;
`;

export default TextInput;
