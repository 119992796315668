import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateGroupMutationVariables = Types.Exact<{
  input: Types.EventInput;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup: (
    { __typename?: 'GroupInfo' }
    & Pick<Types.GroupInfo, 'eventId' | 'searchId' | 'groupDocId'>
  ) }
);

export type JoinGroupMutationVariables = Types.Exact<{
  input: Types.JoinGroupInput;
}>;


export type JoinGroupMutation = (
  { __typename?: 'Mutation' }
  & { joinGroup: (
    { __typename?: 'GroupInfo' }
    & Pick<Types.GroupInfo, 'eventId' | 'searchId' | 'groupDocId'>
  ) }
);

export type DissolveGroupMutationVariables = Types.Exact<{
  input: Types.JoinGroupInput;
}>;


export type DissolveGroupMutation = (
  { __typename?: 'Mutation' }
  & { dissolveGroup: (
    { __typename?: 'GroupInfo' }
    & Pick<Types.GroupInfo, 'eventId' | 'searchId' | 'groupDocId'>
  ) }
);

export type StartGroupMutationVariables = Types.Exact<{
  input: Types.GroupInput;
}>;


export type StartGroupMutation = (
  { __typename?: 'Mutation' }
  & { startGroup: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type StartProblemMutationVariables = Types.Exact<{
  input: Types.GroupInput;
}>;


export type StartProblemMutation = (
  { __typename?: 'Mutation' }
  & { startProblem: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type AcceptStartRequestMutationVariables = Types.Exact<{
  input: Types.GroupInput;
}>;


export type AcceptStartRequestMutation = (
  { __typename?: 'Mutation' }
  & { acceptStartRequest: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type SubmitAnswer5MutationVariables = Types.Exact<{
  input: Types.Answer5;
}>;


export type SubmitAnswer5Mutation = (
  { __typename?: 'Mutation' }
  & { submitAnswer5: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type GetGroupMembersQueryVariables = Types.Exact<{
  input: Types.GroupInput;
}>;


export type GetGroupMembersQuery = (
  { __typename?: 'Query' }
  & { getGroupMembers: Array<(
    { __typename?: 'GroupMember' }
    & Pick<Types.GroupMember, 'uid' | 'name'>
  )> }
);

export type GetFinishedStage5GroupsQueryVariables = Types.Exact<{
  input: Types.EventInput;
}>;


export type GetFinishedStage5GroupsQuery = (
  { __typename?: 'Query' }
  & { getFinishedStage5Groups: Array<(
    { __typename?: 'GroupInfo' }
    & Pick<Types.GroupInfo, 'eventId' | 'searchId' | 'groupDocId'>
  )> }
);

export type GetAdminAllFinishedStage5GroupsQueryVariables = Types.Exact<{
  input: Types.EventInput;
}>;


export type GetAdminAllFinishedStage5GroupsQuery = (
  { __typename?: 'Query' }
  & { getAdminAllFinishedStage5Groups: Array<(
    { __typename?: 'GroupMembersInfo' }
    & Pick<Types.GroupMembersInfo, 'eventId' | 'searchId' | 'groupDocId' | 'members'>
  )> }
);

export type GetAdminAllStage5GroupsQueryVariables = Types.Exact<{
  input: Types.EventInput;
}>;


export type GetAdminAllStage5GroupsQuery = (
  { __typename?: 'Query' }
  & { getAdminAllStage5Groups: Array<(
    { __typename?: 'GroupMembersInfo' }
    & Pick<Types.GroupMembersInfo, 'eventId' | 'searchId' | 'groupDocId' | 'members' | 'state'>
  )> }
);


export const CreateGroupDocument = gql`
    mutation createGroup($input: EventInput!) {
  createGroup(input: $input) {
    eventId
    searchId
    groupDocId
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const JoinGroupDocument = gql`
    mutation joinGroup($input: JoinGroupInput!) {
  joinGroup(input: $input) {
    eventId
    searchId
    groupDocId
  }
}
    `;
export type JoinGroupMutationFn = Apollo.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const DissolveGroupDocument = gql`
    mutation dissolveGroup($input: JoinGroupInput!) {
  dissolveGroup(input: $input) {
    eventId
    searchId
    groupDocId
  }
}
    `;
export type DissolveGroupMutationFn = Apollo.MutationFunction<DissolveGroupMutation, DissolveGroupMutationVariables>;

/**
 * __useDissolveGroupMutation__
 *
 * To run a mutation, you first call `useDissolveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissolveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissolveGroupMutation, { data, loading, error }] = useDissolveGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDissolveGroupMutation(baseOptions?: Apollo.MutationHookOptions<DissolveGroupMutation, DissolveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DissolveGroupMutation, DissolveGroupMutationVariables>(DissolveGroupDocument, options);
      }
export type DissolveGroupMutationHookResult = ReturnType<typeof useDissolveGroupMutation>;
export type DissolveGroupMutationResult = Apollo.MutationResult<DissolveGroupMutation>;
export type DissolveGroupMutationOptions = Apollo.BaseMutationOptions<DissolveGroupMutation, DissolveGroupMutationVariables>;
export const StartGroupDocument = gql`
    mutation startGroup($input: GroupInput!) {
  startGroup(input: $input) {
    success
  }
}
    `;
export type StartGroupMutationFn = Apollo.MutationFunction<StartGroupMutation, StartGroupMutationVariables>;

/**
 * __useStartGroupMutation__
 *
 * To run a mutation, you first call `useStartGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGroupMutation, { data, loading, error }] = useStartGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartGroupMutation(baseOptions?: Apollo.MutationHookOptions<StartGroupMutation, StartGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartGroupMutation, StartGroupMutationVariables>(StartGroupDocument, options);
      }
export type StartGroupMutationHookResult = ReturnType<typeof useStartGroupMutation>;
export type StartGroupMutationResult = Apollo.MutationResult<StartGroupMutation>;
export type StartGroupMutationOptions = Apollo.BaseMutationOptions<StartGroupMutation, StartGroupMutationVariables>;
export const StartProblemDocument = gql`
    mutation startProblem($input: GroupInput!) {
  startProblem(input: $input) {
    success
  }
}
    `;
export type StartProblemMutationFn = Apollo.MutationFunction<StartProblemMutation, StartProblemMutationVariables>;

/**
 * __useStartProblemMutation__
 *
 * To run a mutation, you first call `useStartProblemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartProblemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startProblemMutation, { data, loading, error }] = useStartProblemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartProblemMutation(baseOptions?: Apollo.MutationHookOptions<StartProblemMutation, StartProblemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartProblemMutation, StartProblemMutationVariables>(StartProblemDocument, options);
      }
export type StartProblemMutationHookResult = ReturnType<typeof useStartProblemMutation>;
export type StartProblemMutationResult = Apollo.MutationResult<StartProblemMutation>;
export type StartProblemMutationOptions = Apollo.BaseMutationOptions<StartProblemMutation, StartProblemMutationVariables>;
export const AcceptStartRequestDocument = gql`
    mutation acceptStartRequest($input: GroupInput!) {
  acceptStartRequest(input: $input) {
    success
  }
}
    `;
export type AcceptStartRequestMutationFn = Apollo.MutationFunction<AcceptStartRequestMutation, AcceptStartRequestMutationVariables>;

/**
 * __useAcceptStartRequestMutation__
 *
 * To run a mutation, you first call `useAcceptStartRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptStartRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptStartRequestMutation, { data, loading, error }] = useAcceptStartRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptStartRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptStartRequestMutation, AcceptStartRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptStartRequestMutation, AcceptStartRequestMutationVariables>(AcceptStartRequestDocument, options);
      }
export type AcceptStartRequestMutationHookResult = ReturnType<typeof useAcceptStartRequestMutation>;
export type AcceptStartRequestMutationResult = Apollo.MutationResult<AcceptStartRequestMutation>;
export type AcceptStartRequestMutationOptions = Apollo.BaseMutationOptions<AcceptStartRequestMutation, AcceptStartRequestMutationVariables>;
export const SubmitAnswer5Document = gql`
    mutation submitAnswer5($input: Answer5!) {
  submitAnswer5(input: $input) {
    success
  }
}
    `;
export type SubmitAnswer5MutationFn = Apollo.MutationFunction<SubmitAnswer5Mutation, SubmitAnswer5MutationVariables>;

/**
 * __useSubmitAnswer5Mutation__
 *
 * To run a mutation, you first call `useSubmitAnswer5Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswer5Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswer5Mutation, { data, loading, error }] = useSubmitAnswer5Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAnswer5Mutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswer5Mutation, SubmitAnswer5MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswer5Mutation, SubmitAnswer5MutationVariables>(SubmitAnswer5Document, options);
      }
export type SubmitAnswer5MutationHookResult = ReturnType<typeof useSubmitAnswer5Mutation>;
export type SubmitAnswer5MutationResult = Apollo.MutationResult<SubmitAnswer5Mutation>;
export type SubmitAnswer5MutationOptions = Apollo.BaseMutationOptions<SubmitAnswer5Mutation, SubmitAnswer5MutationVariables>;
export const GetGroupMembersDocument = gql`
    query getGroupMembers($input: GroupInput!) {
  getGroupMembers(input: $input) {
    uid
    name
  }
}
    `;

/**
 * __useGetGroupMembersQuery__
 *
 * To run a query within a React component, call `useGetGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, options);
      }
export function useGetGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, options);
        }
export type GetGroupMembersQueryHookResult = ReturnType<typeof useGetGroupMembersQuery>;
export type GetGroupMembersLazyQueryHookResult = ReturnType<typeof useGetGroupMembersLazyQuery>;
export type GetGroupMembersQueryResult = Apollo.QueryResult<GetGroupMembersQuery, GetGroupMembersQueryVariables>;
export const GetFinishedStage5GroupsDocument = gql`
    query getFinishedStage5Groups($input: EventInput!) {
  getFinishedStage5Groups(input: $input) {
    eventId
    searchId
    groupDocId
  }
}
    `;

/**
 * __useGetFinishedStage5GroupsQuery__
 *
 * To run a query within a React component, call `useGetFinishedStage5GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinishedStage5GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinishedStage5GroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFinishedStage5GroupsQuery(baseOptions: Apollo.QueryHookOptions<GetFinishedStage5GroupsQuery, GetFinishedStage5GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinishedStage5GroupsQuery, GetFinishedStage5GroupsQueryVariables>(GetFinishedStage5GroupsDocument, options);
      }
export function useGetFinishedStage5GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinishedStage5GroupsQuery, GetFinishedStage5GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinishedStage5GroupsQuery, GetFinishedStage5GroupsQueryVariables>(GetFinishedStage5GroupsDocument, options);
        }
export type GetFinishedStage5GroupsQueryHookResult = ReturnType<typeof useGetFinishedStage5GroupsQuery>;
export type GetFinishedStage5GroupsLazyQueryHookResult = ReturnType<typeof useGetFinishedStage5GroupsLazyQuery>;
export type GetFinishedStage5GroupsQueryResult = Apollo.QueryResult<GetFinishedStage5GroupsQuery, GetFinishedStage5GroupsQueryVariables>;
export const GetAdminAllFinishedStage5GroupsDocument = gql`
    query getAdminAllFinishedStage5Groups($input: EventInput!) {
  getAdminAllFinishedStage5Groups(input: $input) {
    eventId
    searchId
    groupDocId
    members
  }
}
    `;

/**
 * __useGetAdminAllFinishedStage5GroupsQuery__
 *
 * To run a query within a React component, call `useGetAdminAllFinishedStage5GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAllFinishedStage5GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAllFinishedStage5GroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminAllFinishedStage5GroupsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminAllFinishedStage5GroupsQuery, GetAdminAllFinishedStage5GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminAllFinishedStage5GroupsQuery, GetAdminAllFinishedStage5GroupsQueryVariables>(GetAdminAllFinishedStage5GroupsDocument, options);
      }
export function useGetAdminAllFinishedStage5GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminAllFinishedStage5GroupsQuery, GetAdminAllFinishedStage5GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminAllFinishedStage5GroupsQuery, GetAdminAllFinishedStage5GroupsQueryVariables>(GetAdminAllFinishedStage5GroupsDocument, options);
        }
export type GetAdminAllFinishedStage5GroupsQueryHookResult = ReturnType<typeof useGetAdminAllFinishedStage5GroupsQuery>;
export type GetAdminAllFinishedStage5GroupsLazyQueryHookResult = ReturnType<typeof useGetAdminAllFinishedStage5GroupsLazyQuery>;
export type GetAdminAllFinishedStage5GroupsQueryResult = Apollo.QueryResult<GetAdminAllFinishedStage5GroupsQuery, GetAdminAllFinishedStage5GroupsQueryVariables>;
export const GetAdminAllStage5GroupsDocument = gql`
    query getAdminAllStage5Groups($input: EventInput!) {
  getAdminAllStage5Groups(input: $input) {
    eventId
    searchId
    groupDocId
    members
    state
  }
}
    `;

/**
 * __useGetAdminAllStage5GroupsQuery__
 *
 * To run a query within a React component, call `useGetAdminAllStage5GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAllStage5GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAllStage5GroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminAllStage5GroupsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminAllStage5GroupsQuery, GetAdminAllStage5GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminAllStage5GroupsQuery, GetAdminAllStage5GroupsQueryVariables>(GetAdminAllStage5GroupsDocument, options);
      }
export function useGetAdminAllStage5GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminAllStage5GroupsQuery, GetAdminAllStage5GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminAllStage5GroupsQuery, GetAdminAllStage5GroupsQueryVariables>(GetAdminAllStage5GroupsDocument, options);
        }
export type GetAdminAllStage5GroupsQueryHookResult = ReturnType<typeof useGetAdminAllStage5GroupsQuery>;
export type GetAdminAllStage5GroupsLazyQueryHookResult = ReturnType<typeof useGetAdminAllStage5GroupsLazyQuery>;
export type GetAdminAllStage5GroupsQueryResult = Apollo.QueryResult<GetAdminAllStage5GroupsQuery, GetAdminAllStage5GroupsQueryVariables>;