import firebase from 'firebase/app';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useChangeToVerifiedEmailMutation } from '../../../../api/__generated__/user.generated';
import {
  useSetErrorOverlayState,
  useSetLoadingState,
} from '../../../../redux/actions/appActions';
import CommonBG from '../../../uiElements/CommonBG';
import UpdateEmailView from './UpdateEmailView';

interface UpdateEmailProps {}
interface ParamTypes {
  email: string;
}
const UpdateEmail: React.FC<UpdateEmailProps> = () => {
  const history = useHistory();

  const setErrorOverlayState = useSetErrorOverlayState();
  const setLoadingState = useSetLoadingState();
  const [changeEmail] = useChangeToVerifiedEmailMutation();

  const [visible, setVisible] = React.useState(false);

  const params = useParams<ParamTypes>();
  const email = Buffer.from(params.email, 'base64').toString();
  const onClick = React.useCallback(() => {
    window.localStorage.removeItem('selectedOrgId');
    firebase
      .auth()
      .signOut()
      .finally(() => {
        history.push('/');
      });
  }, [history]);

  React.useEffect(() => {
    setLoadingState({
      visible: true,
      text: '通信中...',
    });
    changeEmail({
      variables: {
        input: {
          email: email,
        },
      },
    })
      .then(() => {
        setVisible(true);
        // Password reset has been confirmed and new password updated.
      })
      .catch(() => {
        setErrorOverlayState({ errorType: 'CommonError' });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      })
      .finally(() => {
        setLoadingState({ visible: false });
      });
  }, [setErrorOverlayState, setLoadingState]);
  return visible ? <UpdateEmailView onClick={onClick} /> : <CommonBG />;
};

export default UpdateEmail;
