import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ValidateRegistrationTokenQueryVariables = Types.Exact<{
  input: Types.ValidateRegistrationTokenInput;
}>;


export type ValidateRegistrationTokenQuery = (
  { __typename?: 'Query' }
  & { validateRegistrationToken: (
    { __typename?: 'orgName' }
    & Pick<Types.OrgName, 'organizationName'>
  ) }
);

export type RegisterMutationVariables = Types.Exact<{
  input: Types.RegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UserId' }
    & Pick<Types.UserId, 'uid'>
  ) }
);

export type RegisterUserInfoMutationVariables = Types.Exact<{
  input: Types.RegisterUserInfoInput;
}>;


export type RegisterUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { registerUserInfo?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type UpdateUserInfoMutationVariables = Types.Exact<{
  input: Types.UpdateUserInfoInput;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type UpdateEmailMutationVariables = Types.Exact<{
  input: Types.EmailInput;
}>;


export type UpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateEmail?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type ConfirmUpdateEmailMutationVariables = Types.Exact<{
  input: Types.EmailInput;
}>;


export type ConfirmUpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { confirmUpdateEmail?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type ChangeToVerifiedEmailMutationVariables = Types.Exact<{
  input: Types.EmailInput;
}>;


export type ChangeToVerifiedEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeToVerifiedEmail?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type CancelUpdateEmailMutationVariables = Types.Exact<{
  input: Types.EmailInput;
}>;


export type CancelUpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { cancelUpdateEmail?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);


export const ValidateRegistrationTokenDocument = gql`
    query validateRegistrationToken($input: ValidateRegistrationTokenInput!) {
  validateRegistrationToken(input: $input) {
    organizationName
  }
}
    `;

/**
 * __useValidateRegistrationTokenQuery__
 *
 * To run a query within a React component, call `useValidateRegistrationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRegistrationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRegistrationTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateRegistrationTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateRegistrationTokenQuery, ValidateRegistrationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateRegistrationTokenQuery, ValidateRegistrationTokenQueryVariables>(ValidateRegistrationTokenDocument, options);
      }
export function useValidateRegistrationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateRegistrationTokenQuery, ValidateRegistrationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateRegistrationTokenQuery, ValidateRegistrationTokenQueryVariables>(ValidateRegistrationTokenDocument, options);
        }
export type ValidateRegistrationTokenQueryHookResult = ReturnType<typeof useValidateRegistrationTokenQuery>;
export type ValidateRegistrationTokenLazyQueryHookResult = ReturnType<typeof useValidateRegistrationTokenLazyQuery>;
export type ValidateRegistrationTokenQueryResult = Apollo.QueryResult<ValidateRegistrationTokenQuery, ValidateRegistrationTokenQueryVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    uid
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RegisterUserInfoDocument = gql`
    mutation registerUserInfo($input: RegisterUserInfoInput!) {
  registerUserInfo(input: $input) {
    success
  }
}
    `;
export type RegisterUserInfoMutationFn = Apollo.MutationFunction<RegisterUserInfoMutation, RegisterUserInfoMutationVariables>;

/**
 * __useRegisterUserInfoMutation__
 *
 * To run a mutation, you first call `useRegisterUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserInfoMutation, { data, loading, error }] = useRegisterUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserInfoMutation, RegisterUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserInfoMutation, RegisterUserInfoMutationVariables>(RegisterUserInfoDocument, options);
      }
export type RegisterUserInfoMutationHookResult = ReturnType<typeof useRegisterUserInfoMutation>;
export type RegisterUserInfoMutationResult = Apollo.MutationResult<RegisterUserInfoMutation>;
export type RegisterUserInfoMutationOptions = Apollo.BaseMutationOptions<RegisterUserInfoMutation, RegisterUserInfoMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation updateUserInfo($input: UpdateUserInfoInput!) {
  updateUserInfo(input: $input) {
    success
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation updateEmail($input: EmailInput!) {
  updateEmail(input: $input) {
    success
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const ConfirmUpdateEmailDocument = gql`
    mutation confirmUpdateEmail($input: EmailInput!) {
  confirmUpdateEmail(input: $input) {
    success
  }
}
    `;
export type ConfirmUpdateEmailMutationFn = Apollo.MutationFunction<ConfirmUpdateEmailMutation, ConfirmUpdateEmailMutationVariables>;

/**
 * __useConfirmUpdateEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUpdateEmailMutation, { data, loading, error }] = useConfirmUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUpdateEmailMutation, ConfirmUpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUpdateEmailMutation, ConfirmUpdateEmailMutationVariables>(ConfirmUpdateEmailDocument, options);
      }
export type ConfirmUpdateEmailMutationHookResult = ReturnType<typeof useConfirmUpdateEmailMutation>;
export type ConfirmUpdateEmailMutationResult = Apollo.MutationResult<ConfirmUpdateEmailMutation>;
export type ConfirmUpdateEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmUpdateEmailMutation, ConfirmUpdateEmailMutationVariables>;
export const ChangeToVerifiedEmailDocument = gql`
    mutation changeToVerifiedEmail($input: EmailInput!) {
  changeToVerifiedEmail(input: $input) {
    success
  }
}
    `;
export type ChangeToVerifiedEmailMutationFn = Apollo.MutationFunction<ChangeToVerifiedEmailMutation, ChangeToVerifiedEmailMutationVariables>;

/**
 * __useChangeToVerifiedEmailMutation__
 *
 * To run a mutation, you first call `useChangeToVerifiedEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeToVerifiedEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeToVerifiedEmailMutation, { data, loading, error }] = useChangeToVerifiedEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeToVerifiedEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeToVerifiedEmailMutation, ChangeToVerifiedEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeToVerifiedEmailMutation, ChangeToVerifiedEmailMutationVariables>(ChangeToVerifiedEmailDocument, options);
      }
export type ChangeToVerifiedEmailMutationHookResult = ReturnType<typeof useChangeToVerifiedEmailMutation>;
export type ChangeToVerifiedEmailMutationResult = Apollo.MutationResult<ChangeToVerifiedEmailMutation>;
export type ChangeToVerifiedEmailMutationOptions = Apollo.BaseMutationOptions<ChangeToVerifiedEmailMutation, ChangeToVerifiedEmailMutationVariables>;
export const CancelUpdateEmailDocument = gql`
    mutation cancelUpdateEmail($input: EmailInput!) {
  cancelUpdateEmail(input: $input) {
    success
  }
}
    `;
export type CancelUpdateEmailMutationFn = Apollo.MutationFunction<CancelUpdateEmailMutation, CancelUpdateEmailMutationVariables>;

/**
 * __useCancelUpdateEmailMutation__
 *
 * To run a mutation, you first call `useCancelUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUpdateEmailMutation, { data, loading, error }] = useCancelUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<CancelUpdateEmailMutation, CancelUpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUpdateEmailMutation, CancelUpdateEmailMutationVariables>(CancelUpdateEmailDocument, options);
      }
export type CancelUpdateEmailMutationHookResult = ReturnType<typeof useCancelUpdateEmailMutation>;
export type CancelUpdateEmailMutationResult = Apollo.MutationResult<CancelUpdateEmailMutation>;
export type CancelUpdateEmailMutationOptions = Apollo.BaseMutationOptions<CancelUpdateEmailMutation, CancelUpdateEmailMutationVariables>;