import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link as _Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useEventResult } from '../../../api/event';
import { stageIds } from '../../../api/stages';
import { isStageDebug } from '../../../lib/env';
import useLocalStorage from '../../../lib/useLocalStorage';
import { gameActions } from '../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStagesFinished,
} from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import StageNumber from '../../uiElements/StageNumber';
import TextWindow from '../../uiElements/TextWindow';

interface StageSelectorProps {}
const StageSelector: React.FC<StageSelectorProps> = () => {
  const stagesFinished = useStagesFinished();
  const match = useRouteMatch();

  const dispatch = useDispatch();
  const user = useCurrentUser();

  const eventId = useEventId();

  const [stage2Status, setStage2Status] = useLocalStorage<string | null>(
    'stage2:' + eventId + ':' + user?.uid,
    null
  );
  const [stage3Status, setStage3Status] = useLocalStorage<string | null>(
    'stage3:' + eventId + ':' + user?.uid,
    null
  );
  const [stage4Status, setStage4Status] = useLocalStorage<string | null>(
    'stage4:' + eventId + ':' + user?.uid,
    null
  );

  const [eventResult] = useEventResult(user?.uid ?? null, eventId);
  React.useEffect(() => {
    if (!eventResult?.startTimes2 && stage2Status) {
      setStage2Status(null);
      dispatch(gameActions.setHasStageFinished({ stage: 2, finished: false }));
    }
    if (!eventResult?.startTimes3 && stage3Status) {
      setStage3Status(null);
      dispatch(gameActions.setHasStageFinished({ stage: 3, finished: false }));
    }
    if (!eventResult?.startTimes4 && stage4Status) {
      setStage4Status(null);
      dispatch(gameActions.setHasStageFinished({ stage: 4, finished: false }));
    }
  }, [
    dispatch,
    eventResult,
    setStage2Status,
    setStage3Status,
    setStage4Status,
    stage2Status,
    stage3Status,
    stage4Status,
  ]);

  React.useEffect(() => {
    if (stage2Status == 'finished') {
      dispatch(gameActions.setHasStageFinished({ stage: 2, finished: true }));
    }
    if (stage3Status == 'finished') {
      dispatch(gameActions.setHasStageFinished({ stage: 3, finished: true }));
    }
    if (stage4Status == 'finished') {
      dispatch(gameActions.setHasStageFinished({ stage: 4, finished: true }));
    }
  }, [user, eventId, dispatch, stage4Status, stage2Status, stage3Status]);

  return (
    <>
      <StageCaption>ステージを選んでください。</StageCaption>
      <StageList>
        {stageIds.map(i => (
          <StageListItem key={i} disabled={!isStageDebug && stagesFinished[i]}>
            <Link key={i} to={`${match.url}/${i}`}>
              <StageNumber stageNum={i} />
            </Link>
          </StageListItem>
        ))}
      </StageList>
    </>
  );
};

const StageCaption = styled(TextWindow)`
  margin: 5rem 0;
  margin-bottom: 2.8rem;
  display: inline-block;
  width: 58rem;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 3px solid ${Colors.gray8};
    width: 10px;
    height: calc(100% - 6px);
    position: absolute;
    top: 0;
  }
  &:before {
    border-right: none;
    left: 0;
  }
  &:after {
    border-left: none;
    right: 0;
  }
`;

const StageList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 55rem;
  margin: 1rem auto;

  a {
    display: block;
    width: 84px;
    height: 80px;
  }
`;

interface StageItemProps {
  disabled: boolean;
}

const StageListItem = styled.li`
  pointer-events: ${(p: StageItemProps) => (p.disabled ? 'none' : 'initial')};

  svg {
    .cls-1 {
      transition: 0.2s;
      fill: ${(p: StageItemProps) =>
        p.disabled ? Colors.gray4 : Colors.primary};
    }
    .cls-2 {
      transition: 0.2s;
      fill: white;
    }
  }

  &:hover {
    svg {
      .cls-1 {
        fill: white;
        stroke: ${Colors.primary};
        stroke-width: 5px;
      }
      .cls-2 {
        fill: ${Colors.primary};
      }
    }
  }
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

export default StageSelector;
