import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { appActions } from '../actions/appActions';

export interface AppState {
  loading: LoadingOverlayState;
  errorOverlayState: ErrorOverlayState | null;
}

export interface LoadingOverlayState {
  visible: boolean;
  text: string;
}

type CommonErrorOverlayState = {
  errorType: 'CommonError';
  message?: string;
  onRetry?: () => void;
  retryButtonText?: string;
  onBack?: () => void;
  backButtonText?: string;
};

type CommonErrorOverlayStateNoRetry = Omit<CommonErrorOverlayState, 'onRetry'>;

type CriticalErrorOverlayState = {
  errorType: 'CriticalError';
};

type ManualErrorOverlayState = {
  errorType: 'ManualError';
};
export type ErrorOverlayState =
  | CommonErrorOverlayState
  | CriticalErrorOverlayState
  | ManualErrorOverlayState;

export type ErrorOverlayStateNoRetry =
  | CommonErrorOverlayStateNoRetry
  | CriticalErrorOverlayState
  | ManualErrorOverlayState;

export const initialAppState: AppState = {
  loading: {
    visible: false,
    text: '',
  },
  errorOverlayState: null,
};

const AppReducer = reducerWithInitialState<AppState>(initialAppState)
  .case(appActions.setLoadingState, (state, { visible, text }): AppState => {
    return {
      ...state,
      loading: {
        visible,
        text: text ?? '',
      },
    };
  })
  .case(appActions.setErrorOverlayState, (state, payload): AppState => {
    return {
      ...state,
      errorOverlayState: payload,
    };
  });

export default AppReducer;
