import loadImage from 'blueimp-load-image';

const toBlob = (base64: string, reject: () => void) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try {
    const blob = new Blob([buffer.buffer], {
      type: 'image/jpg',
    });
    return blob;
  } catch (e) {
    reject();
    return false;
  }
};

export const resizeImage = (
  event: any,
  maxWidth = 1024
): Promise<{
  imageFile: boolean | Blob;
  imageUri: string;
}> => {
  return new Promise((resolve, reject) => {
    const file = event?.target.files[0];
    loadImage.parseMetaData(file, data => {
      const options: loadImage.LoadImageOptions = {
        maxWidth,
        canvas: true,
      };
      if (data.exif && options) {
        options!.orientation = data.exif.get(
          'Orientation'
        ) as loadImage.Orientation;
      }
      loadImage(
        file,
        canvas => {
          const imageUri = (canvas as HTMLCanvasElement).toDataURL('image/jpg');
          const imageFile = toBlob(imageUri, reject);
          resolve({
            imageFile,
            imageUri,
          });
        },
        options
      );
    });
  });
};
