import * as React from 'react';
import styled from 'styled-components';

import { useSubmitAnswer5Mutation } from '../../api/__generated__/stage5.generated';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import APITest from './APITest';
import AuthManager from './AuthManager';

interface TestPageProps {}
const TestPage: React.FC<TestPageProps> = () => {
  const onCompleted = React.useCallback(
    (t: unknown) => console.log('c: ' + t),
    []
  );
  const [submitAnswer] = useRetryableMutationWithUI(useSubmitAnswer5Mutation, {
    hookOptions: {
      onCompleted,
    },
    loading: {
      options: {
        text: '送信中...',
      },
    },
  });

  const h = React.useCallback(() => {
    submitAnswer({
      variables: {
        input: {
          answer: '-',
          eventId: 'yTMZSo31x2saKhSMt0iF',
          groupId: 'tMKp6CpoAlVoBMVat20P',
          problemId: 5,
        },
      },
    });
  }, [submitAnswer]);

  return (
    <TestPageWrapper>
      <button onClick={h}>hoge</button>

      <AuthManager />
      <APITest />
    </TestPageWrapper>
  );
};

const TestPageWrapper = styled.div``;

export default TestPage;
