import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { presets } from '../../lib/preset';
import Icon_Communicate from '../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../static/svg/admin/category_switch.svg';
import Colors from '../../styles/colors';
import _Button from '../uiElements/button/SubButton';
import AdminCommonBG from './AdminCommonBG';
import { HeaderButton } from './UIelements/AdminNavigation';

interface AdminPresetProps {}
const AdminPreset: React.FC<AdminPresetProps> = props => {
  const history = useHistory();

  return (
    <AdminCommonBG title='プリセット'>
      <Row>
        <Item>
          <ItemBody>
            <RankWrapper>
              <PresetHead>
                <PresetItem>
                  <p>プリセット名</p>
                </PresetItem>
                <PresetItem>
                  <SVG src={Icon_Switch} />
                  <p>Switch</p>
                </PresetItem>
                <PresetItem>
                  <SVG src={Icon_Program} />
                  <p>Program</p>
                </PresetItem>
                <PresetItem>
                  <SVG src={Icon_Create} />
                  <p>Create</p>
                </PresetItem>
                <PresetItem>
                  <SVG src={Icon_Energy} />
                  <p>Energy</p>
                </PresetItem>
                <PresetItem>
                  <SVG src={Icon_Communicate} />
                  <p>Communicate</p>
                </PresetItem>
              </PresetHead>

              {presets.map((single, i) => {
                return (
                  <Single
                    key={'preset_' + i}
                    onClick={() => {
                      history.push(`/admin/preset/${single.preset_id}`);
                    }}
                  >
                    <DetailBody>
                      <DetailItem>{single.preset_name}</DetailItem>
                      {single.tests.map((singleTest, n) => {
                        return (
                          <DetailItem key={'detail_' + n}>
                            {singleTest.name}
                          </DetailItem>
                        );
                      })}
                    </DetailBody>
                    <RankBody>
                      <RankItem>平均ランクと平均点</RankItem>
                      <RankItem>
                        <p>C+</p>
                        <p>123</p>
                      </RankItem>
                      <RankItem>
                        <p>C+</p>
                        <p>123</p>
                      </RankItem>
                      <RankItem>
                        <p>C+</p>
                        <p>123</p>
                      </RankItem>
                      <RankItem>
                        <p>C+</p>
                        <p>123</p>
                      </RankItem>
                      <RankItem>
                        <p>C+</p>
                        <p>123</p>
                      </RankItem>
                    </RankBody>
                  </Single>
                );
              })}
            </RankWrapper>
          </ItemBody>
        </Item>
      </Row>
    </AdminCommonBG>
  );
};

const Row = styled.div`
  margin-top: 2rem;

  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const Single = styled.div`
  padding-bottom: 0.6rem;
  border-bottom: 1px solid ${Colors.gray4};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${Colors.gray2};
  }
`;

const ItemBody = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const RankWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  min-width: 800px;
`;
const PresetHead = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const PresetItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;
const RankBody = styled.div`
  width: 100%;
  display: flex;
  background: rgba(134, 142, 150, 0.2);
  border-radius: 5px;
`;
const DetailBody = styled.div`
  width: 100%;
  display: flex;
`;
const DetailItem = styled.div`
  flex: 1;
  font-size: 1.4rem;
  margin-right: 1rem;
  font-weight: bold;
  margin: 1.4rem 0;
  margin-right: 1rem;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;
const RankItem = styled.div`
  flex: 1;
  font-size: 2.4rem;
  margin-right: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${Colors.accent};
  margin: 0.4rem 0;
  margin-right: 1rem;
  display: flex;

  p:first-child {
    margin-right: 1rem;
  }

  &:first-child {
    color: ${Colors.gray6};
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-top: 0.6rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;
export default AdminPreset;
