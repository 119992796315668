import { useCallback, useEffect, useState } from 'react';

import { HandleFirebaseErrorResult } from './firebaseErrorHandlers';

const useFirebaseError = (
  error: HandleFirebaseErrorResult | null
): {
  emailError: string | null;
  pwError: string | null;
  otherError: string | null;
  resetError(type: 'email' | 'pw' | 'other'): void;
} => {
  const [emailError, setEmailError] = useState<string | null>(null);
  const [pwError, setPwError] = useState<string | null>(null);
  const [otherError, setOtherError] = useState<string | null>(null);
  const resetError = useCallback((type: 'email' | 'pw' | 'other') => {
    switch (type) {
      case 'email':
        setEmailError(null);
        break;
      case 'pw':
        setPwError(null);
        break;
      case 'other':
        setOtherError(null);
        break;
    }
  }, []);

  useEffect(() => {
    if (!error) return;
    console.log(error);
    switch (error.type) {
      case 'auth/email':
        setEmailError(error.message);
        return;
      case 'auth/pw':
        setPwError(error.message);
        return;
      default:
        setOtherError(error.message);
        return;
    }
  }, [error]);

  return {
    emailError,
    pwError,
    otherError,
    resetError,
  };
};

export default useFirebaseError;
