import { ApolloError, gql } from '@apollo/client';
import * as React from 'react';

import { ErrorReport } from '../redux/reducers/errorReducer';
import { useEventId } from '../redux/selectors/gameSelectors';
import {
  RecordErrorMutation,
  useRecordErrorMutation,
} from './__generated__/error.generated';

export const eventSchema = gql`
  mutation recordError($input: ErrorPayload!) {
    recordError(input: $input) {
      reportId
    }
  }
`;

export function useSendError(
  onCompleted: (data: RecordErrorMutation) => void,
  onError: (error: ApolloError) => void
): (errorReport: ErrorReport) => void {
  const eventId = useEventId();
  const _onCompleted = React.useCallback(
    (data: RecordErrorMutation) => {
      console.log('Send Error: ' + data.recordError.reportId);
      onCompleted(data);
    },
    [onCompleted]
  );

  const [recordErrorMutation] = useRecordErrorMutation({
    onCompleted: _onCompleted,
    onError,
  });

  const sendError = React.useCallback(
    (errorReport: ErrorReport) => {
      recordErrorMutation({
        variables: {
          input: {
            ...errorReport,
            userAgent: navigator.userAgent,
            eventId: eventId ?? undefined,
          },
        },
      });
    },
    [eventId, recordErrorMutation]
  );
  return sendError;
}
