import React from 'react';

import Q1_0 from '../static/jpg/stage5/Q1_0_resized.jpg';
import Q1_1 from '../static/jpg/stage5/Q1_1_resized.jpg';
import Q1_2 from '../static/jpg/stage5/Q1_2_resized.jpg';
import Q1_3 from '../static/jpg/stage5/Q1_3_resized.jpg';
import Q1_4 from '../static/jpg/stage5/Q1_4_resized.jpg';
import Q10_0 from '../static/jpg/stage5/Q10_0_resized.jpg';
import Q10_1 from '../static/jpg/stage5/Q10_1_resized.jpg';
import Q10_2 from '../static/jpg/stage5/Q10_2_resized.jpg';
import Q10_3 from '../static/jpg/stage5/Q10_3_resized.jpg';
import Q10_4 from '../static/jpg/stage5/Q10_4_resized.jpg';
import Q11_0 from '../static/jpg/stage5/Q11_0_resized.jpg';
import Q11_1 from '../static/jpg/stage5/Q11_1_resized.jpg';
import Q11_2 from '../static/jpg/stage5/Q11_2_resized.jpg';
import Q11_3 from '../static/jpg/stage5/Q11_3_resized.jpg';
import Q11_4 from '../static/jpg/stage5/Q11_4_resized.jpg';
import Q12_0 from '../static/jpg/stage5/Q12_0_resized.jpg';
import Q12_1 from '../static/jpg/stage5/Q12_1_resized.jpg';
import Q12_2 from '../static/jpg/stage5/Q12_2_resized.jpg';
import Q12_3 from '../static/jpg/stage5/Q12_3_resized.jpg';
import Q12_4 from '../static/jpg/stage5/Q12_4_resized.jpg';
import Q13_0 from '../static/jpg/stage5/Q13_0_resized.jpg';
import Q13_1 from '../static/jpg/stage5/Q13_1_resized.jpg';
import Q13_2 from '../static/jpg/stage5/Q13_2_resized.jpg';
import Q13_3 from '../static/jpg/stage5/Q13_3_resized.jpg';
import Q13_4 from '../static/jpg/stage5/Q13_4_resized.jpg';
import Q14_0 from '../static/jpg/stage5/Q14_0_resized.jpg';
import Q14_1 from '../static/jpg/stage5/Q14_1_resized.jpg';
import Q14_2 from '../static/jpg/stage5/Q14_2_resized.jpg';
import Q14_3 from '../static/jpg/stage5/Q14_3_resized.jpg';
import Q14_4 from '../static/jpg/stage5/Q14_4_resized.jpg';
import Q15_0 from '../static/jpg/stage5/Q15_0_resized.jpg';
import Q15_1 from '../static/jpg/stage5/Q15_1_resized.jpg';
import Q15_2 from '../static/jpg/stage5/Q15_2_resized.jpg';
import Q15_3 from '../static/jpg/stage5/Q15_3_resized.jpg';
import Q15_4 from '../static/jpg/stage5/Q15_4_resized.jpg';
import Q16_0 from '../static/jpg/stage5/Q16_0_resized.jpg';
import Q16_1 from '../static/jpg/stage5/Q16_1_resized.jpg';
import Q16_2 from '../static/jpg/stage5/Q16_2_resized.jpg';
import Q16_3 from '../static/jpg/stage5/Q16_3_resized.jpg';
import Q16_4 from '../static/jpg/stage5/Q16_4_resized.jpg';
import Q17_0 from '../static/jpg/stage5/Q17_0_resized.jpg';
import Q17_1 from '../static/jpg/stage5/Q17_1_resized.jpg';
import Q17_2 from '../static/jpg/stage5/Q17_2_resized.jpg';
import Q17_3 from '../static/jpg/stage5/Q17_3_resized.jpg';
import Q17_4 from '../static/jpg/stage5/Q17_4_resized.jpg';
import Q18_0 from '../static/jpg/stage5/Q18_0_resized.jpg';
import Q18_1 from '../static/jpg/stage5/Q18_1_resized.jpg';
import Q18_2 from '../static/jpg/stage5/Q18_2_resized.jpg';
import Q18_3 from '../static/jpg/stage5/Q18_3_resized.jpg';
import Q18_4 from '../static/jpg/stage5/Q18_4_resized.jpg';
import Q19_0 from '../static/jpg/stage5/Q19_0_resized.jpg';
import Q19_1 from '../static/jpg/stage5/Q19_1_resized.jpg';
import Q19_2 from '../static/jpg/stage5/Q19_2_resized.jpg';
import Q19_3 from '../static/jpg/stage5/Q19_3_resized.jpg';
import Q19_4 from '../static/jpg/stage5/Q19_4_resized.jpg';
import Q2_0 from '../static/jpg/stage5/Q2_0_resized.jpg';
import Q2_1 from '../static/jpg/stage5/Q2_1_resized.jpg';
import Q2_2 from '../static/jpg/stage5/Q2_2_resized.jpg';
import Q2_3 from '../static/jpg/stage5/Q2_3_resized.jpg';
import Q2_4 from '../static/jpg/stage5/Q2_4_resized.jpg';
import Q20_0 from '../static/jpg/stage5/Q20_0_resized.jpg';
import Q20_1 from '../static/jpg/stage5/Q20_1_resized.jpg';
import Q20_2 from '../static/jpg/stage5/Q20_2_resized.jpg';
import Q20_3 from '../static/jpg/stage5/Q20_3_resized.jpg';
import Q20_4 from '../static/jpg/stage5/Q20_4_resized.jpg';
import Q3_0 from '../static/jpg/stage5/Q3_0_resized.jpg';
import Q3_1 from '../static/jpg/stage5/Q3_1_resized.jpg';
import Q3_2 from '../static/jpg/stage5/Q3_2_resized.jpg';
import Q3_3 from '../static/jpg/stage5/Q3_3_resized.jpg';
import Q3_4 from '../static/jpg/stage5/Q3_4_resized.jpg';
import Q4_0 from '../static/jpg/stage5/Q4_0_resized.jpg';
import Q4_1 from '../static/jpg/stage5/Q4_1_resized.jpg';
import Q4_2 from '../static/jpg/stage5/Q4_2_resized.jpg';
import Q4_3 from '../static/jpg/stage5/Q4_3_resized.jpg';
import Q4_4 from '../static/jpg/stage5/Q4_4_resized.jpg';
import Q5_0 from '../static/jpg/stage5/Q5_0_resized.jpg';
import Q5_1 from '../static/jpg/stage5/Q5_1_resized.jpg';
import Q5_2 from '../static/jpg/stage5/Q5_2_resized.jpg';
import Q5_3 from '../static/jpg/stage5/Q5_3_resized.jpg';
import Q5_4 from '../static/jpg/stage5/Q5_4_resized.jpg';
import Q6_0 from '../static/jpg/stage5/Q6_0_resized.jpg';
import Q6_1 from '../static/jpg/stage5/Q6_1_resized.jpg';
import Q6_2 from '../static/jpg/stage5/Q6_2_resized.jpg';
import Q6_3 from '../static/jpg/stage5/Q6_3_resized.jpg';
import Q6_4 from '../static/jpg/stage5/Q6_4_resized.jpg';
import Q7_0 from '../static/jpg/stage5/Q7_0_resized.jpg';
import Q7_1 from '../static/jpg/stage5/Q7_1_resized.jpg';
import Q7_2 from '../static/jpg/stage5/Q7_2_resized.jpg';
import Q7_3 from '../static/jpg/stage5/Q7_3_resized.jpg';
import Q7_4 from '../static/jpg/stage5/Q7_4_resized.jpg';
import Q8_0 from '../static/jpg/stage5/Q8_0_resized.jpg';
import Q8_1 from '../static/jpg/stage5/Q8_1_resized.jpg';
import Q8_2 from '../static/jpg/stage5/Q8_2_resized.jpg';
import Q8_3 from '../static/jpg/stage5/Q8_3_resized.jpg';
import Q8_4 from '../static/jpg/stage5/Q8_4_resized.jpg';
import Q9_0 from '../static/jpg/stage5/Q9_0_resized.jpg';
import Q9_1 from '../static/jpg/stage5/Q9_1_resized.jpg';
import Q9_2 from '../static/jpg/stage5/Q9_2_resized.jpg';
import Q9_3 from '../static/jpg/stage5/Q9_3_resized.jpg';
import Q9_4 from '../static/jpg/stage5/Q9_4_resized.jpg';

export const stage5problems: [string, string, string, string, string][] = [
  [Q1_0, Q1_1, Q1_2, Q1_3, Q1_4],
  [Q2_0, Q2_1, Q2_2, Q2_3, Q2_4],
  [Q3_0, Q3_1, Q3_2, Q3_3, Q3_4],
  [Q4_0, Q4_1, Q4_2, Q4_3, Q4_4],
  [Q5_0, Q5_1, Q5_2, Q5_3, Q5_4],
  [Q6_0, Q6_1, Q6_2, Q6_3, Q6_4],
  [Q7_0, Q7_1, Q7_2, Q7_3, Q7_4],
  [Q8_0, Q8_1, Q8_2, Q8_3, Q8_4],
  [Q9_0, Q9_1, Q9_2, Q9_3, Q9_4],
  [Q10_0, Q10_1, Q10_2, Q10_3, Q10_4],
  [Q11_0, Q11_1, Q11_2, Q11_3, Q11_4],
  [Q12_0, Q12_1, Q12_2, Q12_3, Q12_4],
  [Q13_0, Q13_1, Q13_2, Q13_3, Q13_4],
  [Q14_0, Q14_1, Q14_2, Q14_3, Q14_4],
  [Q15_0, Q15_1, Q15_2, Q15_3, Q15_4],
  [Q16_0, Q16_1, Q16_2, Q16_3, Q16_4],
  [Q17_0, Q17_1, Q17_2, Q17_3, Q17_4],
  [Q18_0, Q18_1, Q18_2, Q18_3, Q18_4],
  [Q19_0, Q19_1, Q19_2, Q19_3, Q19_4],
  [Q20_0, Q20_1, Q20_2, Q20_3, Q20_4],
];

export const stage5Answers = [
  2, // 1
  2, // 2
  1, // 3
  3, // 4
  1, // 5
  4, // 6
  2, // 7
  2, // 8
  1, // 9
  4, // 10
  2, // 11
  2, // 12
  1, // 13
  3, // 14
  2, // 15
  1, // 16
  4, // 17
  3, // 18
  1, // 19
  1, // 20
];

interface PresenterText {
  main: string;
  sub?: string;
}
interface Stage5TextItem {
  presenter: PresenterText;
  answerer: string;
}

export const stage5Texts: Stage5TextItem[] = [
  {
    presenter: { main: '言葉を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 1
  {
    presenter: { main: '単語を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 2
  {
    presenter: { main: '単語を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 3
  {
    presenter: { main: '風景を説明してください。' },
    answerer: '相手が見ている景色は？',
  }, // 4
  {
    presenter: { main: 'グラフを伝えてください。' },
    answerer: '相手が説明しているグラフは？',
  }, // 5
  {
    presenter: {
      main: '下の図形を説明してください。',
      sub: '※ 相手の選択肢は、点の繋ぎ方のみ異なります。',
    },
    answerer: '相手が説明している図形は？',
  }, // 6
  {
    presenter: { main: '単語の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 7
  {
    presenter: { main: '単語を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 8
  {
    presenter: { main: '単語を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 9
  {
    presenter: { main: 'どんな人か説明してください。' },
    answerer: '相手が見ている人は？',
  }, // 10
  {
    presenter: { main: '絵を伝えてください。' },
    answerer: '相手が見ている絵は？',
  }, // 11
  {
    presenter: { main: 'グラフを伝えてください。' },
    answerer: '相手が説明しているグラフは？',
  }, // 12
  {
    presenter: { main: '単語の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 13
  {
    presenter: { main: '単語を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 14
  { presenter: { main: 'どんな人？' }, answerer: '相手が伝えているのは？' }, // 15
  {
    presenter: { main: '道順を説明してください。' },
    answerer: '相手が説明している道順は？',
  }, // 16
  {
    presenter: { main: '文字を伝えてください。' },
    answerer: '相手が説明している文字は？',
  }, // 17
  {
    presenter: { main: '写真を伝えてください。' },
    answerer: '相手が説明しているのは？',
  }, // 18
  {
    presenter: { main: '文字を伝えてください。' },
    answerer: '相手が説明している文字は？',
  }, // 19
  {
    presenter: { main: '立体を伝えてください。' },
    answerer: '相手が説明している立体は？',
  }, // 20
];

export interface Stage5Problem {
  index: number;
  count: number;
  text: Stage5TextItem;
  imageSrc: [string, string, string, string, string];
  answer: number;
}

export const useStage5Problem = (
  problemId: number,
  problemCount: number
): Stage5Problem => {
  const problemIndex = problemId - 1;
  return React.useMemo<Stage5Problem>(
    () => ({
      index: problemIndex,
      count: problemCount,
      imageSrc: stage5problems[problemIndex],
      text: stage5Texts[problemIndex],
      answer: stage5Answers[problemIndex],
    }),
    [problemIndex, problemCount]
  );
};
