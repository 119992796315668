import * as React from 'react';
import styled from 'styled-components';

import { useEventMembersData } from '../../api/event';
import { useOrganizationMembers } from '../../api/organization';
import { useSelectedOrgId } from '../../redux/selectors/authSelectors';
import { useAdminEventId } from '../../redux/selectors/gameSelectors';
import CommonBG from '../uiElements/CommonBG';
import OverviewRow from './overview/OverviewRow';

interface AdminOverviewProps {}
const AdminOverview: React.FC<AdminOverviewProps> = () => {
  const orgId = useSelectedOrgId();
  const eventId = useAdminEventId();
  const [initEventMembers] = useEventMembersData(eventId);
  const [participantUids, setParticipantUids] = React.useState<string[]>([]);
  const { orgMemberDoc } = useOrganizationMembers(orgId);

  React.useEffect(() => {
    if (initEventMembers !== undefined) {
      setParticipantUids(initEventMembers.participants ?? []);
    }
  }, [initEventMembers]);

  return (
    <CommonBG>
      <AdminOverviewWrapper>
        {participantUids.map(p => (
          <OverviewRow
            key={p}
            uid={p}
            email={orgMemberDoc[p]?.email}
            displayName={orgMemberDoc[p]?.displayName}
          />
        ))}
      </AdminOverviewWrapper>
    </CommonBG>
  );
};

const AdminOverviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-between;
`;

export default AdminOverview;
