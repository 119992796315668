import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';
import RightPane from './RightPane';
import MailChange from './settingsItems/MailChange';
import Privacy from './settingsItems/PrivacyView';
import ProfileSettings from './settingsItems/ProfileSettings';
import PWSettings from './settingsItems/PWSettings';
import Term from './settingsItems/TermView';
import SettingsMenu from './SettingsMenu';
import { settingsPaths } from './settingsPaths';

const headerOptions: HeaderOptions = {
  buttonType: 'logout',
  showEmail: false,
};

interface SettingsMainProps {}
const SettingsMain: React.FC<SettingsMainProps> = () => {
  return (
    <CommonBG withHeader withFooter headerOptions={headerOptions}>
      <Content>
        <SettingsMenu />

        <RightPane>
          <Switch>
            <Route path={settingsPaths.profile}>
              <ProfileSettings />
            </Route>
            <Route path={settingsPaths.password}>
              <PWSettings />
            </Route>
            <Route path={settingsPaths.mail}>
              <MailChange />
            </Route>
            <Route path={settingsPaths.term}>
              <Term />
            </Route>
            <Route path={settingsPaths.privacy}>
              <Privacy />
            </Route>
          </Switch>
        </RightPane>
      </Content>
    </CommonBG>
  );
};

// NOTE: min-height = 100vh - headerHeight - footerHeight;
const Content = styled.div`
  display: flex;
  margin: 0 4rem;
  min-height: calc(100vh - 8rem - 6rem);
`;

export default SettingsMain;
