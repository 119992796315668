import * as React from 'react';
import styled from 'styled-components';

import Stage4Problem from '../../../../lib/Stage4Problem';
import Colors from '../../../../styles/colors';
import Stage4ProblemContainer from './Stage4ProblemContainer';

interface Stage4MainProps {
  problem: Stage4Problem;
  fadingOutProblem: boolean;
  onClear: () => void;
  onEndFadingOut: () => void;
  incrementPenalty: () => void;
}

const Stage4Main: React.FC<Stage4MainProps> = props => {
  const [practicing, setPracticing] = React.useState(true);
  const [practiceProblem, setPracticeProblem] = React.useState<Stage4Problem>(
    props.problem
  );
  const [trialProblem, setTrialProblem] = React.useState<Stage4Problem>(
    props.problem
  );

  React.useEffect(() => {
    setPracticing(true);
  }, [props.problem]);

  const onPractice = React.useCallback(() => {
    setPracticing(true);
  }, []);
  const onTrial = React.useCallback(() => {
    setPracticing(false);
  }, []);

  const onClear = React.useCallback(() => {
    props.onClear();
  }, [props]);

  React.useEffect(() => {
    setPracticing(true);
    setPracticeProblem(props.problem);
    setTrialProblem(props.problem);
  }, [props.problem]);

  return (
    <Stage4MainWrapper>
      <Tabs>
        <Tab isActive={practicing} onClick={onPractice}>
          練習用
        </Tab>
        <Tab isActive={!practicing} onClick={onTrial}>
          本番用
        </Tab>
      </Tabs>
      <ProblemArea>
        <Stage4ProblemContainer
          incrementPenalty={props.incrementPenalty}
          practicing={practicing}
          problem={practicing ? practiceProblem : trialProblem}
          setProblem={practicing ? setPracticeProblem : setTrialProblem}
          onClear={onClear}
          onEndFadingOut={props.onEndFadingOut}
          fadingOutProblem={props.fadingOutProblem}
        />
      </ProblemArea>
    </Stage4MainWrapper>
  );
};

const Stage4MainWrapper = styled.div``;

const Tabs = styled.div`
  height: 10rem;
  text-align: center;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

interface TabProps {
  isActive: boolean;
}

const Tab = styled.div`
  height: 4.4rem;
  width: 12rem;
  padding-top: 4rem;
  margin: 0 2.5rem;
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.3rem;
  display: block;
  color: ${(p: TabProps) => (p.isActive ? Colors.primary : Colors.gray6)};
  border-width: 0 0 0.5rem 0;
  border-color: ${(p: TabProps) =>
    p.isActive ? Colors.primary : Colors.white};
  border-style: solid;
  cursor: ${(p: TabProps) => (p.isActive ? 'default' : 'pointer')};
  &:hover {
    color: ${Colors.primary};
  }
`;

const ProblemArea = styled.div`
  height: calc(100% - 11rem);
`;

export default Stage4Main;
