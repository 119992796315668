import { useCallback, useState } from 'react';

import {
  InputStates,
  InputValidator,
} from '../components/uiElements/input/inputConfigs';
import { validEmailRegEx, validPasswordLength } from './regex';

export type LocalValidator = (value: string, states: InputStates) => boolean;
type Validator = [LocalValidator, string] | [LocalValidator];
export type Validators = Validator[];

export const useValidator = (
  validators: Validators,
  OKMessage?: string
): [InputValidator, boolean] => {
  const [valid, setValid] = useState(false);

  const validator = useCallback<InputValidator>(
    (val, state) => {
      const invalidated = validators.find(validator =>
        validator[0](val, state)
      );
      setValid(!invalidated);
      return {
        type: invalidated ? (invalidated[1] ? 'error' : 'none') : 'ok',
        message: invalidated ? invalidated[1] : OKMessage,
      };
    },
    [OKMessage, validators]
  );

  return [validator, valid];
};

export const emailFormatValidator: Validator = [
  val => !validEmailRegEx.test(val),
  'メールアドレスの形式が間違っています',
];

export const pwFormatValidator: Validator = [
  val => !validPasswordLength.test(val),
  '英数字8文字以上で入力してください',
];
