export type FirebaseErrorType =
  | 'auth/email'
  | 'auth/pw'
  | 'auth/general'
  | 'unknown';

export interface HandleFirebaseErrorResult {
  type: FirebaseErrorType;
  message: string;
}

export const handleSignInWithEmailAndPasswordErrorCode = (
  code: string | undefined
): HandleFirebaseErrorResult => {
  if (code === 'auth/invalid-email') {
    return {
      type: 'auth/email',
      message: '不正なメールアドレスです',
    };
  }
  if (code === 'auth/user-disabled') {
    return {
      type: 'auth/general',
      message: 'このユーザではログインできません',
    };
  }
  if (code === 'auth/user-not-found') {
    return {
      type: 'auth/email',
      message: 'メールアドレスが間違っています',
    };
  }
  if (code === 'auth/wrong-password') {
    return {
      type: 'auth/pw',
      message: 'パスワードが間違っています',
    };
  }

  return {
    type: 'unknown',
    message: `エラーが発生しました(${code})`,
  };
};

export const handleCreateUserWithEmailAndPasswordErrorCode = (
  code: string | undefined
): HandleFirebaseErrorResult => {
  if (code === 'auth/invalid-email') {
    return {
      type: 'auth/email',
      message: '不正なメールアドレスです',
    };
  }
  if (code === 'auth/email-already-in-use') {
    return {
      type: 'auth/email',
      message: 'このメールアドレスは既に使用されています',
    };
  }
  if (code === 'auth/operation-not-allowed') {
    return {
      type: 'auth/general',
      message: '権限エラーです。管理者に連絡してください(Operation Not Found)',
    };
  }
  if (code === 'auth/weak-password') {
    return {
      type: 'auth/pw',
      message: '強いパスワードを指定してください',
    };
  }

  return {
    type: 'unknown',
    message: `エラーが発生しました(${code})`,
  };
};

export const handleSendPasswordResetEmailErrorCode = (
  code: string | undefined
): HandleFirebaseErrorResult => {
  switch (code) {
    case 'auth/invalid-email':
      return {
        type: 'auth/email',
        message: '不正なメールアドレスです',
      };
    case 'auth/user-not-found':
      return {
        type: 'auth/email',
        message: 'メールアドレスが間違っています',
      };
    case 'auth/wrong-password':
      return {
        type: 'auth/pw',
        message: '現在のパスワードが間違っています。',
      };
    default:
      return {
        type: 'unknown',
        message: `エラーが発生しました(${code})`,
      };
  }
};

export const handleReauthenticateWithCredentialErrorCode = (
  code: string | undefined
): HandleFirebaseErrorResult => {
  switch (code) {
    case 'auth/wrong-password':
      return {
        type: 'auth/pw',
        message: '現在のパスワードが間違っています。',
      };
    case 'auth/too-many-requests':
      return {
        type: 'auth/general',
        message:
          '変更リクエストが一時的に無効になっています。時間を置いて再度トライしてください。',
      };
    default:
      return {
        type: 'unknown',
        message: `エラーが発生しました(${code})`,
      };
  }
};
