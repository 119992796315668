import actionCreatorFactory from 'typescript-fsa';

import { StageId } from '../../api/stages';
import { StageManagerState } from '../../components/eventProvider/stageProviders/StageManager';
import { Stage5GroupInfo } from '../reducers/gameReducer';

const actionCreator = actionCreatorFactory();

export const gameActions = {
  setHasStageFinished: actionCreator<{
    stage: number;
    finished: boolean;
  }>('GAME_SET_HAS_STAGE_FINISHED'),
  setStage4Penalty: actionCreator<number>('GAME_SET_STAGE4_PENALTY'),
  incrementStage4Penalty: actionCreator('GAME_INCREMENT_STAGE4_PENALTY'),
  setStage5GroupInfo: actionCreator<Stage5GroupInfo | null>(
    'GAME_SET_STAGE5_GROUP_INFO'
  ),
  setStage5CurrentAnswer: actionCreator<string | null>(
    'GAME_SET_STAGE5_CURRENT_ANSWER'
  ),
  setRecordedStageStartTimes: actionCreator<Partial<Record<StageId, number>>>(
    'GAME_SET_RECORDED_STAGE_START_TIMES'
  ),
  setStageManagerState: actionCreator<Partial<StageManagerState>>(
    'GAME_SET_STAGE_MANAGER_STATE'
  ),
};
