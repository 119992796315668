import * as React from 'react';
import Select, { Styles, components } from 'react-select';
import styled from 'styled-components';

import { useAdminOrganizations } from '../../../api/organization';
import { useUserInfo } from '../../../api/user';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import dummyIcon from '../../../static/png/admin/dummy_org.png';
import Colors from '../../../styles/colors';
import OrganizationIcon from '../UIelements/OrganizationIcon';

interface OrganizationSelectorProps {
  currentOrganization?: string;
  onChange: (v: any) => void;
  initialSelectedOrgId: string | null;
  isAdmin: boolean;
}

type Option = {
  value: string;
  label: string;
  icon: string;
  id: string;
};
const OrganizationSelector: React.FC<OrganizationSelectorProps> = props => {
  const [selectedOrgId, setSelectedOrgId] = React.useState<string | null>(null);
  const user = useCurrentUser();
  const [userInfo] = useUserInfo(user?.uid);
  const [organizations] = useAdminOrganizations(user?.uid);

  React.useEffect(() => {
    setSelectedOrgId(props.initialSelectedOrgId);
  }, [props.initialSelectedOrgId]);

  const optionsState = React.useMemo(() => {
    console.log(organizations);
    if (organizations !== undefined) {
      const _options: Option[] = [];

      organizations
        .filter(
          doc =>
            !props.isAdmin ||
            !['adminRiddler', 'adminDentsu'].includes(doc.orgId)
        )
        .forEach(doc => {
          if (userInfo?.orgAdmin?.includes(doc.orgId)) {
            if (
              doc.orgId == 'adminRiddler' &&
              !userInfo?.commonRoles?.includes('riddlerAdmin')
            ) {
              return;
            }
            _options.push({
              value: doc.orgId,
              id: doc.orgId,
              label: doc.name,
              icon: doc.imgSrc ?? dummyIcon,
            } as Option);
          }
        });

      _options.sort((a, b) => (a.id < b.id ? -1 : 1));
      return _options;
    }
    return [];
  }, [organizations, props.isAdmin, userInfo?.commonRoles, userInfo?.orgAdmin]);

  console.log(user?.uid, organizations, optionsState);
  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <OptionStyle>
        <OrganizationIcon size={24} src={props.data.icon} />
        <p>{props.data.label}</p>
      </OptionStyle>
    </Option>
  );

  const selectedOrg = React.useMemo(() => {
    return optionsState.find(e => e.id === selectedOrgId);
  }, [selectedOrgId, optionsState]);

  return (
    <SelectorWrapper>
      <Icon>
        <OrganizationIcon size={24} src={selectedOrg?.icon ?? ''} />
      </Icon>
      {optionsState.length > 0 && (
        <Select
          isSearchable={false}
          defaultValue={selectedOrg}
          value={selectedOrg}
          options={optionsState}
          onChange={v => {
            props.onChange(v);
            setSelectedOrgId(v.id);
          }}
          components={{ Option: IconOption }}
          styles={selectStyles}
        />
      )}
    </SelectorWrapper>
  );
};

const Icon = styled.div`
  position: absolute;
  left: 14px;
  margin-top: 18px;
  z-index: 1;
`;

const containerStyle = {
  width: '100%',
  height: '100%',
  fontSize: '1.6rem',
  display: 'inline-block',
  margin: '0',
  verticalAlign: 'middle',
};

const controlStyle = {
  boxShadow: 'none',
  borderRadius: '0.5rem',
};

const selectStyles: Styles<any, false> = {
  container: provided => ({
    ...provided,
    ...containerStyle,
  }),
  control: (provided, { menuIsOpen }) => ({
    ...provided,
    ...controlStyle,
    background: 'initial',
    border: 'none',
    borderRadius: 0,
    paddingLeft: 36,
    paddingBottom: 2,
    height: '100%',
    '&:hover': {
      background: Colors.gray4,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: Colors.gray8,
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'none',
    border: `0.2rem solid ${Colors.gray6}`,
    borderRadius: '0.5rem',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '400px',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected
      ? Colors.primary
      : isFocused
      ? Colors.highlight
      : 'transparent',
    textAlign: 'left',
    fontSize: '1.6rem',
    fontWeight: 500,
  }),
  singleValue: provided => {
    return {
      ...provided,
      fontSize: '1.6rem',
      fontWeight: 500,
    };
  },
};

const SelectorWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${Colors.gray4};
  height: 6rem;
`;

const Selected = styled.div`
  padding: 0 2rem;
  height: 100%;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  > :first-child {
    margin-right: 0.6rem;
  }

  p {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

const OptionStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;

  p {
    margin-left: 1rem;
    margin-bottom: 0.3rem;
    font-size: 1.6rem;
  }
`;

export default OrganizationSelector;
