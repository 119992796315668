import firebase from 'firebase/app';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { timeActions } from '../redux/actions/timeActions';
import { useCurrentUser } from '../redux/selectors/authSelectors';

type SnapshotData =
  | {
      timestamp: firebase.firestore.Timestamp;
    }
  | undefined;

export const useServerTimeOffsetEffect = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  useEffect(() => {
    if (user == null) return;
    const ref = firebase
      .firestore()
      .collection('version')
      .doc('1')
      .collection('dummy_for_time_get')
      .doc(user.uid);
    ref
      .set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        ref.onSnapshot(snapshot => {
          const timestamp = (snapshot.data() as SnapshotData)?.timestamp;
          const offset =
            timestamp != null
              ? (timestamp.toMillis() - Date.now()) / 1000
              : undefined;
          dispatch(timeActions.setServerTimeOffset(offset));
        });
      });
  }, [dispatch, user]);
};
