import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useOwnerOrganizations } from '../../api/organization';
import { useSetLoadingState } from '../../redux/actions/appActions';
import {
  useCurrentUser,
  useCustomClaim,
} from '../../redux/selectors/authSelectors';
import AdminAdministratorDetail from './AdminAdministratorDetail';
import AdminAdministrators from './AdminAdministrators';
import AdminAnalyze from './AdminAnalyze';
import AdminEventControlView from './adminEvents/AdminEventControlView';
import AdminEventEditView from './adminEvents/AdminEventEditView';
import AdminEventResult from './adminEvents/AdminEventResult';
import AdminEvents from './adminEvents/AdminEvents';
import AdminEventUserStatusView from './adminEvents/AdminEventUserStatusView';
import AdminOrganizationDetail from './adminOrganizations/AdminOrganizationDetail';
import AdminOrganizationView from './adminOrganizations/AdminOrganizationView';
import AdminOverview from './AdminOverview';
import AdminPreset from './AdminPreset';
import AdminPresetDetail from './AdminPresetDetail';
import AdminStage5Overview from './AdminStage5Overview';
import AdminUserAccounts from './adminUsers/AdminUserAccounts';
import AdminUserDetail from './adminUsers/AdminUserDetail';
import AdminUserEdit from './adminUsers/AdminUserEdit';
import AdminUserHistory from './adminUsers/AdminUserHistory';

const AdminRouter: React.FC = () => {
  const user = useCurrentUser();
  const [ownerOrgs, loading] = useOwnerOrganizations(user);
  const setLoadingState = useSetLoadingState();
  const customClaim = useCustomClaim();

  React.useEffect(() => {
    setLoadingState({ visible: loading });
  }, [loading, setLoadingState]);

  console.log(loading, ownerOrgs);
  if (loading) return null;
  if (ownerOrgs === undefined || ownerOrgs.length === 0) {
    if (customClaim?.commonRoles.length === 0) {
      return <Redirect to='/' />;
    }
  }

  return (
    <Switch>
      <Route exact path='/admin/organization'>
        <AdminOrganizationView />
      </Route>
      <Route exact path='/admin/organization/create'>
        <AdminOrganizationDetail create />
      </Route>
      <Route exact path='/admin/organization/:orgId'>
        <AdminOrganizationDetail create={false} />
      </Route>
      <Route exact path='/admin/events/create'>
        <AdminEventEditView create />
      </Route>
      <Route exact path='/admin/events/'>
        <AdminEvents />
      </Route>
      <Route exact path='/admin/accounts/'>
        <AdminUserAccounts />
      </Route>
      <Route exact path='/admin/accounts/:userId'>
        <AdminUserDetail />
      </Route>
      <Route exact path='/admin/accounts/:userId/edit'>
        <AdminUserEdit />
      </Route>
      <Route exact path='/admin/accounts/:userId/history/:eventId'>
        <AdminUserHistory />
      </Route>
      <Route exact path='/admin/administrators'>
        <AdminAdministrators />
      </Route>
      <Route exact path='/admin/administrators/:userId'>
        <AdminAdministratorDetail />
      </Route>
      <Route path='/admin/events/:eventId/overview'>
        <AdminOverview />
      </Route>
      <Route path='/admin/events/:eventId/overview5'>
        <AdminStage5Overview />
      </Route>
      <Route path='/admin/events/:eventId/edit'>
        <AdminEventEditView />
      </Route>
      <Route exact path='/admin/events/:eventId/control'>
        <AdminEventControlView />
      </Route>
      <Route exact path='/admin/events/:eventId/control/:userId/status'>
        <AdminEventUserStatusView />
      </Route>
      <Route path='/admin/events/:eventId/result/:userId'>
        <AdminEventResult />
      </Route>
      <Route path='/admin/analyze'>
        <AdminAnalyze />
      </Route>
      <Route exact path='/admin/preset/:presetId'>
        <AdminPresetDetail />
      </Route>
      <Route path='/admin/preset'>
        <AdminPreset />
      </Route>
      <Route exact path='/admin'>
        <Redirect to='/admin/events' />
      </Route>
      <Redirect to='/admin' />
    </Switch>
  );
};

export default AdminRouter;
