import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../../../styles/colors';

interface Stage4DotsProps {
  dotCount: number;
  activeDotCount: number;
}
const Stage4Dots: React.FC<Stage4DotsProps> = props => {
  const range = (n: number) => Array.from({ length: n }, (_, k) => k);

  return (
    <Stage4DotsWrapper>
      {range(props.dotCount).map(i => (
        <Dot
          key={i}
          active={i >= props.dotCount - props.activeDotCount}
          last={props.activeDotCount === 1}
        />
      ))}
    </Stage4DotsWrapper>
  );
};

const Stage4DotsWrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

interface DotProps {
  active: boolean;
  last: boolean;
}
const Dot = styled.span`
  margin: 2rem;
  height: 1.7rem;
  width: 1.7rem;
  background-color: ${(p: DotProps) =>
    p.active ? (p.last ? Colors.error : Colors.primary) : Colors.gray4};
  border-radius: 50%;
  transition: 0.2s;
`;

export default Stage4Dots;
