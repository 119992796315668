import { combineReducers } from 'redux';

import appReducer from './appReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import gameReducer from './gameReducer';
import timeReducer from './timeReducer';

export default combineReducers({
  game: gameReducer,
  auth: authReducer,
  app: appReducer,
  time: timeReducer,
  error: errorReducer,
});
