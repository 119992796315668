import * as React from 'react';
import { useDispatch } from 'react-redux';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import styled from 'styled-components';

import { useSubmitAnswer2InfoMutation } from '../../../../api/__generated__/stage2.generated';
import { useStage2InfoSubmission } from '../../../../api/stage2';
import {
  Stage2Clue,
  allSections,
  lastClueItems,
} from '../../../../lib/Stage2Options';
import { appActions } from '../../../../redux/actions/appActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import ClueIconCorrectImg from '../../../../static/svg/Stage2_clueicon_correct.svg';
import ClueIconIncorrectImg from '../../../../static/svg/Stage2_clueicon_incorrect.svg';
import ClueIconNormalImg from '../../../../static/svg/Stage2_clueicon_normal.svg';
import JuuyouImg from '../../../../static/svg/Stage2_juuyou.svg';
import SaisyuuImg from '../../../../static/svg/Stage2_saisyuu.svg';
import Colors from '../../../../styles/colors';
import Button from '../../../uiElements/button/MainButton';
import Stage2LastConfirm from './Stage2LastConfirm';
import {
  selectIncorrectStyles,
  selectLastStyles,
  selectStyles,
} from './stage2SelectStyles';

const allClues = allSections.map(sec => sec.clues).flat();
const allClueIds = allClues.map(cl => cl.id);
const findClueById = (id: string) => allClues.find(cl => cl.id === id);
const findClueByServerId = (id: number) =>
  allClues.find(cl => cl.serverId === id);
const getAnswerableItemsFromClue = (clue: Stage2Clue) =>
  clue.items.filter(it => it.type === 'select' || it.type === 'input');

interface Stage2AnswerProps {
  onSubmitLastAnswer: (answer: string) => void;
}

type ClueState = 'normal' | 'correct' | 'incorrect';

type Answers = { [v: string]: string };
type ClueStates = { [v: string]: ClueState };
type Timers = { [v: string]: NodeJS.Timeout };

const Stage2Answer: React.FC<Stage2AnswerProps> = props => {
  const dispatch = useDispatch();
  const eventId = useEventId();
  const user = useCurrentUser();
  const [answers, setAnswers] = React.useState<Answers>({});
  const [lastAnswer, setLastAnswer] = React.useState<string>('');
  const [showingLastConfirm, setShowingLastConfirm] =
    React.useState<boolean>(false);
  const [clueStates, setClueStates] = React.useState<ClueStates>({});
  const [timers, setTimers] = React.useState<Timers>({});
  const [submitAnswer2Info] = useSubmitAnswer2InfoMutation();

  const [previousInfoSubmissions] = useStage2InfoSubmission(user, eventId);

  const mergeAnswer = React.useCallback(
    (clueId: string, id: string, ans: string) => {
      setAnswers(oldAnswers => ({
        ...oldAnswers,
        [id]: ans,
      }));
    },
    []
  );

  const mergeClueState = React.useCallback(
    (clueId: string, state: ClueState) => {
      setClueStates(oldStates => ({
        ...oldStates,
        [clueId]: state,
      }));
    },
    []
  );

  React.useEffect(() => {
    previousInfoSubmissions?.forEach(doc => {
      const clueServerId = doc.infoId;
      const clue = findClueByServerId(clueServerId);
      if (!clue) return;
      const answerableItems = getAnswerableItemsFromClue(clue);

      const correctAns = doc.answers.find(ans => ans.correct === true);
      if (!correctAns) return;
      if (correctAns.answer.length !== answerableItems.length) return;

      mergeClueState(clue.id, 'correct');

      answerableItems.forEach((item, i) => {
        mergeAnswer(clue.id, item.id, correctAns.answer[i]);
      });
    });
  }, [mergeAnswer, mergeClueState, previousInfoSubmissions]);

  const mapClueToAnswers = React.useCallback(
    (clueId: string) => {
      const clue = allClues.find(cl => cl.id === clueId);
      if (!clue) return null;

      const ansObj: Answers = {};

      getAnswerableItemsFromClue(clue).forEach(it => {
        ansObj[it.id] = answers[it.id];
      });

      return ansObj;
    },
    [answers]
  );

  const submitInfo = React.useCallback(
    (infoId: number, answer: string[]) => {
      if (!eventId) {
        return;
      }

      dispatch(
        appActions.setLoadingState({ visible: true, text: '判定中...' })
      );
      submitAnswer2Info({
        variables: {
          input: {
            eventId,
            infoId,
            answer,
          },
        },
      })
        .then(res => {
          dispatch(appActions.setLoadingState({ visible: false }));

          const clue = findClueByServerId(infoId);
          if (!clue) return;

          mergeClueState(
            clue.id,
            res.data?.submitAnswer2Info ? 'correct' : 'incorrect'
          );
        })
        .catch(() => {
          dispatch(appActions.setLoadingState({ visible: false }));
        });
    },
    [dispatch, eventId, mergeClueState, submitAnswer2Info]
  );

  const onSelectChange = React.useCallback(
    (clueId: string, id: string, v: ValueType<OptionTypeBase, false>) => {
      if (v === undefined || v === null) return;
      const label = (v as OptionTypeBase).label;
      mergeAnswer(clueId, id, label);

      const ansObj = mapClueToAnswers(clueId);
      if (!ansObj) {
        return;
      }
      ansObj[id] = label;

      // clueに対するanswerはすべて出揃ったか？
      if (
        (Object.values(ansObj) as (string | undefined)[]).includes(undefined)
      ) {
        return;
      }

      const answersInClue = Object.values(ansObj);

      const clue = findClueById(clueId);
      if (!clue) {
        return;
      }

      mergeClueState(clue.id, 'normal');

      submitInfo(clue.serverId, answersInClue);
    },
    [mapClueToAnswers, mergeAnswer, mergeClueState, submitInfo]
  );

  const onInputChange = React.useCallback(
    (clueId: string, id: string, v: string) => {
      const clue = findClueById(clueId);
      if (!clue) {
        return;
      }

      if (timers[clue.id] !== undefined) {
        clearTimeout(timers[clue.id]);
      }

      const timer = setTimeout(() => {
        submitInfo(clue.serverId, [v]);
      }, 1000);

      setTimers({
        ...timers,
        [clue.id]: timer,
      });
    },
    [submitInfo, timers]
  );
  const allCluesSatisfied =
    allClueIds.find(id => clueStates[id] !== 'correct') === undefined;
  return (
    <>
      <Stage2AnswerWrapper>
        <Juuyou />
        {allSections.map((sec, i) => (
          <Section key={sec.title}>
            <SectionTitle>
              <MainTitle>{sec.title}</MainTitle>
              <SubTitle>{sec.subTitle}</SubTitle>
            </SectionTitle>
            <ClueList>
              {sec.clues.map(cl => (
                <ClueLine key={cl.id}>
                  <ClueIcon state={clueStates[cl.id] ?? 'normal'} />

                  {cl.items.map(item => {
                    if (item.type === 'text') {
                      return (
                        <ClueItemText key={item.id} sectionNum={i}>
                          {item.content}
                        </ClueItemText>
                      );
                    }

                    if (clueStates[cl.id] === 'correct') {
                      return (
                        <ClueItemAnswerText key={item.id}>
                          {answers[item.id]}
                        </ClueItemAnswerText>
                      );
                    }

                    if (item.type === 'select') {
                      return (
                        <Select
                          key={item.id}
                          styles={
                            clueStates[cl.id] === 'incorrect'
                              ? selectIncorrectStyles
                              : selectStyles
                          }
                          options={item.content}
                          placeholder={''}
                          onChange={e => onSelectChange(cl.id, item.id, e)}
                        />
                      );
                    }

                    if (item.type === 'input') {
                      return (
                        <Input
                          key={item.id}
                          value={answers[item.id] ?? ''}
                          onChange={e => {
                            mergeAnswer(cl.id, item.id, e.target.value);
                            onInputChange(cl.id, item.id, e.target.value);
                          }}
                        />
                      );
                    }

                    return null;
                  })}
                </ClueLine>
              ))}
            </ClueList>
          </Section>
        ))}
        <SaisyuuWrapper>
          <InlineWrapper>
            <Saisyuu />
            <AnnotationText>
              重要情報を全て埋めた後で解答できます。
            </AnnotationText>
          </InlineWrapper>
          <LastClue>
            <LastClueItemText>{lastClueItems[0].content}</LastClueItemText>
            <Select
              styles={selectLastStyles}
              isDisabled={!allCluesSatisfied}
              options={lastClueItems[1].content}
              placeholder={''}
              onChange={e => setLastAnswer((e as OptionTypeBase).label)}
              menuPlacement='top'
            />
            <SubmitButton
              onClick={() => setShowingLastConfirm(true)}
              disabled={lastAnswer === ''}
            >
              解答
            </SubmitButton>
          </LastClue>
        </SaisyuuWrapper>
      </Stage2AnswerWrapper>

      {showingLastConfirm && (
        <Stage2LastConfirm
          answer={lastAnswer}
          onSubmit={() => {
            props.onSubmitLastAnswer(lastAnswer);
            setShowingLastConfirm(false);
          }}
          onCancel={() => {
            setShowingLastConfirm(false);
          }}
        />
      )}
    </>
  );
};

const Stage2AnswerWrapper = styled.div`
  padding: 2rem 0 2rem;
  overflow-y: scroll;
  flex: 6;
`;

const Section = styled.div`
  margin: 1.5rem 3rem;
`;
const SectionTitle = styled.div`
  font-weight: 600;
  margin: 0 0 1.5rem;
`;
const MainTitle = styled.span`
  font-size: 2.6rem;
`;
const SubTitle = styled.span`
  margin-left: 2rem;
  font-size: 2.5rem;
`;

const ClueList = styled.ul`
  list-style: none;
  width: 100%;
`;
const ClueLine = styled.li`
  margin: 0.5rem 0;
  display: inline-block;
  min-width: 33%;
`;

interface ClueItemTextProps {
  sectionNum?: number;
}
const ClueItemText = styled.span`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3.7rem;
  margin: 0 0.5rem;
  display: inline-block;
  vertical-align: middle;

  ${(p: ClueItemTextProps) =>
    p.sectionNum === 0 &&
    `
    min-width: 7rem;
  `}
`;

const ClueItemAnswerText = styled.span`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3.7rem;
  margin: 0 1rem;
  min-width: 9.5rem;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid ${Colors.gray8};
  text-align: center;

  ${(p: ClueItemTextProps) =>
    p.sectionNum === 0 &&
    `
    min-width: 7rem;
  `}
`;

const Input = styled.input`
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 0.3rem;
  margin: 0 0.6rem;
`;

const Juuyou = styled.img.attrs({
  src: JuuyouImg,
})`
  top: 5rem;
  height: 5rem;
`;

const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin-bottom: 2rem;
  }
`;
const AnnotationText = styled.div`
  margin-left: 3rem;
  font-size: 1.8rem;
  display: inline-block;
  font-weight: 600;
`;

const Saisyuu = styled.img.attrs({
  src: SaisyuuImg,
})`
  top: 0;
  left: 0;
  height: 5rem;
`;

const LastClue = styled.div`
  margin: 1.5rem 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;
const LastClueItemText = styled.span`
  font-size: 3rem;
  font-weight: 600;
  margin-right: 0.8rem;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2rem;
`;

const SubmitButton = styled(Button).attrs({
  variant: 'primary',
  size: 'medium',
  color: 'positive',
})`
  /*height: 4.5rem;
  min-width: 12rem;
  padding: 0;
  font-size: 2.8rem;
  line-height: 4.7rem;
  display: inline-block;
  vertical-align: middle;
  padding: 0 3rem;*/
`;

interface ClueIconProps {
  state: ClueState;
}

const clueIconImages = {
  normal: ClueIconNormalImg,
  correct: ClueIconCorrectImg,
  incorrect: ClueIconIncorrectImg,
};

const ClueIcon = styled.span`
  width: 2.1rem;
  height: 2.1rem;
  background-image: url(${(p: ClueIconProps) => clueIconImages[p.state]});
  background-size: contain;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
`;

const SaisyuuWrapper = styled.div`
  margin-top: 1px;
  padding: 0 0 2rem;
`;

export default Stage2Answer;
