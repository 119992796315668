import { faEllipsisV, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useDeleteUserMutation } from '../../api/__generated__/adminRole.generated';
import { UserOrgInfo } from '../../api/firestoreTypes';
import { UserDocument } from '../../api/user';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { options_ind, options_occ } from '../../lib/userProfiles';
import { appActions } from '../../redux/actions/appActions';
import { useSelectedOrgId } from '../../redux/selectors/authSelectors';
import Colors from '../../styles/colors';
import { AccountParams } from '../admin/adminUsers/AdminUserAccounts';
import AdminPopup from '../admin/UIelements/AdminPopup';
import MenuPopup from '../admin/UIelements/MenuPopup';
import SortTH from '../admin/UIelements/sorter/SortTH';

type UserAccountsTableProps = {
  accountParams: AccountParams[];
} & {
  onJoin?: undefined;
  onAdminEdit: (eventId: string) => void;
  onAdminControl: (eventId: string) => void;
  showResult?: undefined;
  activeEventId?: undefined;
  sortObj: any;
  onChangeSort: (sortObj: any) => void;
};

const AdminUserAccountsTable: React.FC<UserAccountsTableProps> = props => {
  const { accountParams } = props;
  const csvData = React.useMemo(() => {
    return accountParams.map(e => {
      const industryName = e.userOrg.industryId
        ? options_ind.find(
            o => o.value.toString() === e.userOrg.industryId?.toString()
          )?.label ?? ''
        : '';
      const list = options_occ.map(o => o.options).flat();
      const occupationName = e.userOrg.occupationId
        ? list.find(
            o => o.value.toString() === e.userOrg.occupationId?.toString()
          )?.label ?? ''
        : '';

      return {
        uid: e.userId,
        fullName: e.userData.fullName,
        ruby: e.userData.ruby,
        displayName: e.userData.displayName,
        birthday: e.userData.birthday,
        email: e.userData.email,
        startYear: e.userOrg.startYear,
        employeeId: e.userOrg.employeeId,
        industry: industryName,
        occupation: occupationName,
        department: e.userOrg.department,
        createdOn: e.userData.createdOn,
      };
    });
  }, [accountParams]);

  const listHeader = [
    { label: '氏名', key: 'fullName' },
    { label: '管理者', key: 'admin' },
    { label: '入社年', key: 'startYear' },
    { label: '社員ID', key: 'employeeId' },
    { label: '業種', key: 'industry' },
    { label: '職種', key: 'occupation' },
    { label: '部署', key: 'department' },
  ];

  return (
    <UserAccountsTableWrapper>
      <Table>
        <THead>
          <TR>
            {listHeader.map(single => {
              return (
                <SortTH
                  key={single.key}
                  sortKey={single.key}
                  currentSortObj={props.sortObj}
                  onClick={props.onChangeSort}
                >
                  {single.label}
                </SortTH>
              );
            })}
            <TH>
              {csvData && (
                <CSVLink
                  data={csvData}
                  filename={'accounts.csv'}
                  headers={[
                    { label: 'ユーザーID', key: 'uid' },
                    { label: '氏名', key: 'fullName' },
                    { label: 'よみがな', key: 'ruby' },
                    { label: '表示名', key: 'displayName' },
                    { label: '生年月日', key: 'birthday' },
                    { label: 'メールアドレス', key: 'email' },
                    { label: '入社年', key: 'startYear' },
                    { label: '社員ID', key: 'employeeId' },
                    { label: '業種', key: 'industry' },
                    { label: '職種', key: 'occupation' },
                    { label: '部署', key: 'department' },
                    { label: 'アカウント作成日時', key: 'createdOn' },
                  ]}
                  style={{
                    color: Colors.accent,
                    fontSize: '1.3rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  <CsvLabel>
                    <p>CSVのダウンロード</p>
                  </CsvLabel>
                </CSVLink>
              )}
            </TH>
          </TR>
        </THead>

        <TBody>
          {accountParams.map(data => (
            <UserRow
              key={data.userId}
              userId={data.userId}
              userData={data.userData}
              userOrg={data.userOrg}
              {...props}
            />
          ))}
        </TBody>
      </Table>
    </UserAccountsTableWrapper>
  );
};

type UserRowProps = UserAccountsTableProps & {
  userData: UserDocument;
  userOrg: UserOrgInfo;
  userId: string;
};
const UserRow: React.FC<UserRowProps> = props => {
  const history = useHistory();
  const onUserDetail = (userId?: string) => {
    history.push(`/admin/accounts/${userId}/`);
  };
  const user = props.userData;
  const userOrg = props.userOrg;
  const [deleteUser] = useRetryableMutationWithUI(useDeleteUserMutation, {
    hookOptions: {},
    error: {
      options: {
        errorType: 'CommonError',
        message: `現在開催中のテストイベントに参加しているアカウントは、削除することができません。`,
      },
    },
  });
  const orgId = useSelectedOrgId();
  const dispatch = useDispatch();

  const getIndustryName = (industryId: string) => {
    if (industryId) {
      return (
        options_ind.find(e => e.value.toString() === industryId.toString())
          ?.label ?? ''
      );
    }
    return '';
  };

  const getOccupationName = (occupationId: string) => {
    const list = options_occ.map(e => e.options).flat();
    if (occupationId) {
      return (
        list?.find(e => e.value.toString() === occupationId.toString())
          ?.label ?? ''
      );
    }
    return '';
  };

  return (
    <TR onClick={() => onUserDetail(props.userId)}>
      <TD>
        <Name>{user.fullName}</Name>
        <Mail>{user.email ?? ''}</Mail>
      </TD>
      <TD>
        <p>{(user.orgAdmin?.length ?? 0) > 0 ? '★' : ''}</p>
      </TD>
      <TD>
        <p>{userOrg.startYear}</p>
      </TD>
      <TD>
        <p>{userOrg.employeeId}</p>
      </TD>
      <TD>
        <p>{getIndustryName(userOrg.industryId ?? '')}</p>
      </TD>
      <TD>
        <p>{getOccupationName(userOrg.occupationId ?? '')}</p>
      </TD>
      <TD>
        <p>{userOrg.department ?? ''}</p>
      </TD>
      <TD>
        <AdminPopup
          width={100}
          key='popup'
          content={
            <MenuPopup
              contents={[
                {
                  title: '詳細',
                  onClick: () => {
                    history.push(`/admin/accounts/${props.userId}/`);
                  },
                },
                {
                  title: '編集',
                  onClick: () => {
                    history.push(`/admin/accounts/${props.userId}/edit`);
                  },
                },
                {
                  title: '削除する',
                  onClick: () => {
                    dispatch(
                      appActions.setErrorOverlayState({
                        errorType: 'CommonError',
                        message: '本当にアカウントを削除しますか。',
                        backButtonText: 'いいえ',
                        retryButtonText: 'はい',
                        onRetry: () => {
                          if (orgId === null) {
                            return;
                          }
                          deleteUser({
                            variables: {
                              input: {
                                uid: props.userId,
                                orgId,
                              },
                            },
                          });
                        },
                        onBack: () => {
                          console.log('close');
                        },
                      })
                    );
                  },
                  red: true,
                },
              ]}
            />
          }
        >
          <InfoButton>
            <FontAwesomeIcon icon={faEllipsisV} />
          </InfoButton>
        </AdminPopup>
      </TD>
    </TR>
  );
};

const Name = styled.div`
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InfoButton = styled.div`
  border-radius: 99px;
  width: 36px;
  height: 36px;
  transition: 0.2s;
  color: ${Colors.gray6};
  opacity: 0;
  margin-left: auto;

  svg {
    margin: 9px;
    width: 18px !important;
    height: 18px;
  }

  &:hover {
    background: white;
  }
`;

const UserAccountsTableWrapper = styled.div`
  th:last-child {
    width: 13rem;
    a {
      text-decoration: none;
    }
    svg {
      margin-right: 0.4rem;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
`;

const THead = styled.thead`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const TBody = styled.tbody`
  width: 100%;

  tr {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: ${Colors.gray2};

      & ${InfoButton} {
        opacity: 1;
      }
    }
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  position: relative;
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 30%;
  }
  td:nth-child(2) {
    width: 7rem;
  }
  td:nth-child(3) {
    width: 7rem;
  }
  td:nth-child(4) {
  }

  td:nth-child(5) {
  }

  td:last-child {
    width: 130px;
  }
`;

const CsvButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: ${Colors.accent};
  cursor: pointer;

  position: absolute;
  right: 1px;
  top: 15px;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const CsvLabel = styled.div`
  p:first-child {
    display: none;
  }
  p:last-child {
    display: initial;
  }

  /*@media screen and (min-width: 1040px) {
    p:first-child {
      display: initial;
    }
    p:last-child {
      display: none;
    }
  }*/
`;

export default AdminUserAccountsTable;
