import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSubmitAnswer1Mutation } from '../../../../api/__generated__/stage1.generated';
import { appActions } from '../../../../redux/actions/appActions';
import PanelEqualImg from '../../../../static/svg/Panel_=_Stage1.svg';
import RotateLeftImg from '../../../../static/svg/Rotate_Left_Stage1.svg';
import RotateRightImg from '../../../../static/svg/Rotate_Right_Stage1.svg';
import Colors from '../../../../styles/colors';
import Keyframes from '../../../../styles/keyframes';
import _Button from '../../../uiElements/deprecated/Button';
import {
  InputName,
  PanelKey,
  PanelStates,
  PanelsInInput,
  initialPanelStates,
  initialPanelsInInput,
  inputHeight,
  inputLeftPos,
  inputNames,
  inputTopPos,
  inputWidth,
  panelAreaStepSize,
  panels,
} from './DraggableArea';
import {
  Panel,
  Position,
  RotationControl,
  panelAreaOffset,
  panelSize,
} from './DraggablePanel';
import { Target } from './Stage1';

interface Stage1TesterProps {}

const Stage1Tester: React.FC<Stage1TesterProps> = () => {
  const dispatch = useDispatch();
  const [panelStates, setPanelStates] =
    React.useState<PanelStates>(initialPanelStates);
  const [panelsInInput, setPanelsInInput] = React.useState<PanelsInInput>({
    ...initialPanelsInInput,
  });
  const [newlyAchievedTargets, setNewlyAchievedTargets] = React.useState<
    Target[]
  >([]);
  const [dragDelta, setDragDelta] = React.useState<Position>({ x: 0, y: 0 });
  const [ansFormat, setAnsFormat] = React.useState<string>('');
  const [ansFailed, setAnsFailed] = React.useState<boolean>(false);
  const [submitAnswer1, submitAnswer1Result] = useSubmitAnswer1Mutation();

  React.useEffect(() => {
    const { called, loading } = submitAnswer1Result;
    if (!called || loading) {
      return;
    }

    if (!loading) {
      dispatch(appActions.setLoadingState({ visible: false }));
    }

    const { data } = submitAnswer1Result;

    const achieved = data?.submitAnswer1?.achieved ?? [];

    if (achieved.length > 0) {
      setNewlyAchievedTargets(achieved);

      setTimeout(() => {
        setNewlyAchievedTargets([]);
      }, 1000);
    } else {
      setAnsFailed(true);
    }
  }, [submitAnswer1Result]);

  // 入力欄に重なっているかどうか
  const findOverlappingInput = (key: string, dragging: boolean) => {
    const state = panelStates[key];
    if (!state) return null;

    const pos = dragging
      ? {
          x: state.position.x + dragDelta.x,
          y: state.position.y + dragDelta.y,
        }
      : state.position;

    const centerPos: Position = {
      x: pos.x + panelSize / 2,
      y: pos.y + panelSize / 2,
    };

    for (const name of inputNames) {
      const targetInputTopPos = inputTopPos[name];
      if (
        centerPos.x >= inputLeftPos &&
        centerPos.x <= inputLeftPos + inputWidth &&
        centerPos.y >= targetInputTopPos &&
        centerPos.y <= targetInputTopPos + inputHeight
      ) {
        return name;
      }
    }

    return null;
  };

  // ドラッグ中
  const onDrag = (key: PanelKey, e: DraggableEvent, data: DraggableData) => {
    setAnsFailed(false);

    setDragDelta({
      x: dragDelta.x + data.deltaX,
      y: dragDelta.y + data.deltaY,
    });
  };

  // ドラッグ終わり
  const onStop = (key: PanelKey) => {
    const overlappingInput = findOverlappingInput(key, true);
    const newPanelsInInput = { ...panelsInInput };

    if (overlappingInput && !panelsInInput[overlappingInput].includes(key)) {
      newPanelsInInput[overlappingInput].push(key);
    }

    inputNames.forEach(name => {
      if (overlappingInput !== name) {
        newPanelsInInput[name] = newPanelsInInput[name].filter(v => v !== key);
      }
    });

    setPanelsInInput(newPanelsInInput);
    setDragDelta({ x: 0, y: 0 });
  };

  React.useEffect(() => {
    const newPanelStates = { ...panelStates };

    // 位置リセット
    for (const panel of panels) {
      newPanelStates[panel] = {
        ...newPanelStates[panel],
        rotation: 0,
        position: initialPanelStates[panel].position,
      };
    }

    // 入力欄に配置
    for (const name of inputNames) {
      const panels = panelsInInput[name];

      panels.forEach((key, i) => {
        newPanelStates[key] = {
          ...newPanelStates[key],
          rotation: panelStates[key].rotation,
          position: {
            x: inputLeftPos + panelAreaStepSize * (i + 1) - panelSize,
            y: inputTopPos[name] + (inputHeight - panelSize) / 2,
          },
        };
      });
    }

    setPanelStates(newPanelStates);
  }, [panelsInInput]);

  // パネルが入力欄にあるか？
  const isInInput = (key: PanelKey) => {
    for (const name of inputNames) {
      const panels = panelsInInput[name];
      if (panels.includes(key)) {
        return true;
      }
    }

    return false;
  };

  // まわす
  const rotatePanel = (key: PanelKey, diff: number) => {
    const newPanelStates = { ...panelStates };

    newPanelStates[key] = {
      ...newPanelStates[key],
      rotation: newPanelStates[key].rotation + diff,
    };

    setPanelStates(newPanelStates);
  };

  const inputName2AnsFormat = (name: InputName) => {
    const panels = panelsInInput[name];
    const mod = (n: number, m: number) => ((n % m) + m) % m;

    return panels
      .map(p => {
        const s = panelStates[p];
        return `${p}${mod(s.rotation, 8)}`;
      })
      .join(',');
  };

  // 送信
  const onSend = () => {
    setAnsFailed(false);

    const ansStr = inputNames.map(inputName2AnsFormat).join('|');

    dispatch(appActions.setLoadingState({ visible: true, text: '送信中...' }));

    submitAnswer1({
      variables: {
        input: {
          eventId: 'ABCDE',
          answer: ansStr,
        },
      },
    });
  };

  React.useEffect(() => {
    setAnsFormat(inputNames.map(inputName2AnsFormat).join('|'));
  }, [panelStates]);

  const loadAnsFormat2InputStates = () => {
    const [top, bottom] = ansFormat.split('|');
    const allPanels: Record<InputName, string[]> = {
      top: top.split(','),
      bottom: bottom.split(','),
    };

    const newPanelStates = { ...panelStates };
    const newPanelsInInput: PanelsInInput = {
      top: [],
      bottom: [],
    };

    inputNames.forEach(name => {
      allPanels[name].forEach(p => {
        const [a, b] = p.split('');
        const panelKey = panels.find(k => k === a)?.[0];

        if (panelKey) {
          newPanelStates[panelKey] = {
            rotation: Number(b),
            position: { x: 0, y: 0 },
          };
          newPanelsInInput[name].push(panelKey as PanelKey);
        }
      });
    });

    setPanelsInInput(newPanelsInInput);
    setPanelStates(newPanelStates);
  };

  // リセット
  const onReset = () => {
    // TODO:
    // setPanelsInInput(initialPanelsInInput);
    // だと動かないの何????

    setPanelsInInput({ top: [], bottom: [] });
    setDragDelta({ x: 0, y: 0 });
  };

  return (
    <Wrapper>
      <DraggableArea>
        {panels.map(src => (
          <Draggable
            key={src}
            bounds={'parent'}
            onDrag={(e, data) => onDrag(src, e, data)}
            onStop={() => onStop(src)}
            position={
              (panelStates[src] && panelStates[src].position) || panelAreaOffset
            }
          >
            <Panel src={src} rotation={panelStates[src].rotation}>
              {isInInput(src) && (
                <RotationControl>
                  <RotationControlButton
                    src={RotateLeftImg}
                    onClick={() => rotatePanel(src, -1)}
                  />
                  <RotationControlButton
                    src={RotateRightImg}
                    onClick={() => rotatePanel(src, 1)}
                  />
                </RotationControl>
              )}
            </Panel>
          </Draggable>
        ))}

        <InputTop failed={ansFailed} />
        <Equal />
        <InputBottom failed={ansFailed} />
      </DraggableArea>

      <Buttons>
        <Button variant={'light'} onClick={() => onReset()}>
          リセット
        </Button>
        <Button onClick={() => onSend()}>送信</Button>
      </Buttons>

      <TextInputArea>
        <TextInput
          onChange={e => setAnsFormat(e.target.value)}
          value={ansFormat}
        />
        <button
          onClick={() => {
            loadAnsFormat2InputStates();
          }}
        >
          反映
        </button>
      </TextInputArea>

      {newlyAchievedTargets.length > 0 && (
        <NewlyAchievedWindowWrapper>
          <NewlyAchievedWindow>
            <NewlyAchievedText>ターゲットを開放しました</NewlyAchievedText>
            <NewlyAchievedList>
              {newlyAchievedTargets.map(t => (
                <NewlyAchievedItem key={t.targetId}>{t.name}</NewlyAchievedItem>
              ))}
            </NewlyAchievedList>
          </NewlyAchievedWindow>
        </NewlyAchievedWindowWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const DraggableArea = styled.div`
  width: 90%;
  height: 100%;
  position: absolute;
  top: 8%;
  left: 5%;
`;

interface RotationControlButtonProps {
  src: string;
}
const RotationControlButton = styled.div`
  width: ${panelSize * 0.3}px;
  height: ${panelSize * 0.3}px;
  background-image: url(${(p: RotationControlButtonProps) => p.src});
  background-size: contain;

  &:hover {
    filter: brightness(0.7);
  }
`;

const Equal = styled.img.attrs({
  src: PanelEqualImg,
  rotation: 0,
})`
  width: 4rem;
  height: 6rem;

  position: absolute;
  top: calc(
    ${(inputTopPos.top + inputHeight + inputTopPos.bottom) / 2}px - 3rem
  );
  left: calc(${inputLeftPos + inputWidth / 2}px - 2rem);
  z-index: 2;
`;

interface InputProps {
  failed?: boolean;
}

const Input = styled.div`
  width: ${inputWidth}px;
  height: ${inputHeight}px;
  background-color: ${Colors.white};
  border: ${Colors.gray6} 2px solid;
  z-index: 0;
  position: absolute;
  left: ${inputLeftPos}px;

  ${(p: InputProps) =>
    p.failed &&
    `
    border-color: ${Colors.error};
    background-color: ${Colors.highlightError};
    ${Keyframes.vibrate};
  `}
`;

const InputTop = styled(Input)`
  top: ${inputTopPos.top}px;
`;

const InputBottom = styled(Input)`
  top: ${inputTopPos.bottom}px;
`;

const Button = styled(_Button)`
  max-width: 47%;
`;

const Buttons = styled.div`
  width: 35%;
  height: 6rem;
  position: absolute;
  top: 75%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateX(-50%);
`;

const TextInput = styled.input`
  height: 2rem;
  line-height: 2rem;
  font-size: 1.5rem;
`;

const TextInputArea = styled.div`
  position: absolute;

  top: 4rem;
  left: 2rem;
`;

const NewlyAchievedWindowWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Colors.white_op090};
  z-index: 3;
`;

const NewlyAchievedWindow = styled.div`
  width: 80rem;
  max-width: 90vw;
  min-height: 15rem;
  background-color: ${Colors.gray8_op080};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 4rem 0 2.3rem;
`;

const NewlyAchievedText = styled.p`
  height: 4rem;
  width: 100%;
  font-size: 3.3rem;
  line-height: 4rem;
  text-align: center;
  letter-spacing: 0.2rem;
  color: ${Colors.white};
`;
const NewlyAchievedList = styled.div`
  margin: 3rem 5% 0;
  width: 90%;
  text-align: center;
`;

const AchievedItem = styled.span`
  display: inline-block;
  width: 15rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: center;
  padding: 1rem 0;
  background-color: ${Colors.gray2};
  border: ${Colors.gray6} 1px solid;
  border-radius: 1rem;
  margin-bottom: 1.7rem;

  &:nth-child(2n + 1) {
    margin-right: calc(2rem - 0px);
  }
`;
const NewlyAchievedItem = styled(AchievedItem)`
  margin-right: 2rem;
  height: 2.2rem;
  line-height: 2.2rem;
`;

export default Stage1Tester;
