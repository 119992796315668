import { Styles } from 'react-select';

import Colors from '../../../styles/colors';

const containerStyle = {
  width: '10.5rem',
  height: '3.7rem',
  fontSize: '1.8rem',
  display: 'inline-block',
  borderRadius: '0.5rem',
  margin: '0',
  verticalAlign: 'middle',
};

const controlStyle = {
  boxShadow: 'none',
  borderRadius: '0.5rem',
};

export const selectStyles: Styles<any, false> = {
  container: provided => ({
    ...provided,
    ...containerStyle,
  }),
  control: (provided, { menuIsOpen }) => ({
    ...provided,
    ...controlStyle,
    border: menuIsOpen
      ? `0.2rem solid ${Colors.primary}`
      : `0.2rem solid ${Colors.gray6}`,
    '&:hover': {
      border: `0.2rem solid ${Colors.primary}`,
    },
    minHeight: '36px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: Colors.gray8,
    padding: '6px',
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'none',
    border: `0.2rem solid ${Colors.gray6}`,
    borderRadius: '0.5rem',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '400px',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected
      ? Colors.primary
      : isFocused
      ? Colors.highlight
      : 'transparent',
    textAlign: 'left',
    fontSize: '1.8rem',
    fontWeight: 400,
  }),
  singleValue: provided => {
    return {
      ...provided,
      fontSize: '1.8rem',
      fontWeight: 400,
    };
  },
};

export const selectErrorStyles: Styles<any, false> = {
  ...selectStyles,
  control: provided => ({
    ...provided,
    ...controlStyle,
    border: `0.2rem solid ${Colors.error}`,
    '&:hover': {
      border: `0.2rem solid ${Colors.error}`,
    },
    backgroundColor: Colors.highlightError,
  }),
};
