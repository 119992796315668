import * as React from 'react';
import styled from 'styled-components';

interface RightPaneProps {}
const RightPane: React.FC<RightPaneProps> = props => {
  return <RightPaneBody>{props.children}</RightPaneBody>;
};

const RightPaneBody = styled.div`
  flex: 1 0 auto;
  box-sizing: border-box;
  padding: 1.8rem 0 1.8rem 4rem;
  width: calc(100% - 340px);
`;

export default RightPane;
