import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import React from 'react';
import {
  DocumentDataHook,
  DocumentHook,
  useCollectionData,
  useDocument,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { GroupMemberItem } from '../components/eventProvider/stagePages/stage5/GroupInfo';
import { Stage5GroupInfo } from '../redux/reducers/gameReducer';
import { Maybe } from '../types';
import {
  GetGroupMembersQuery,
  useGetGroupMembersLazyQuery,
} from './__generated__/stage5.generated';
import { Game5 } from './firestoreTypes';

export const stage5Schema = gql`
  mutation createGroup($input: EventInput!) {
    createGroup(input: $input) {
      eventId
      searchId
      groupDocId
    }
  }
  mutation joinGroup($input: JoinGroupInput!) {
    joinGroup(input: $input) {
      eventId
      searchId
      groupDocId
    }
  }
  mutation dissolveGroup($input: JoinGroupInput!) {
    dissolveGroup(input: $input) {
      eventId
      searchId
      groupDocId
    }
  }
  mutation startGroup($input: GroupInput!) {
    startGroup(input: $input) {
      success
    }
  }
  mutation startProblem($input: GroupInput!) {
    startProblem(input: $input) {
      success
    }
  }
  mutation acceptStartRequest($input: GroupInput!) {
    acceptStartRequest(input: $input) {
      success
    }
  }
  mutation submitAnswer5($input: Answer5!) {
    submitAnswer5(input: $input) {
      success
    }
  }

  query getGroupMembers($input: GroupInput!) {
    getGroupMembers(input: $input) {
      uid
      name
    }
  }

  query getFinishedStage5Groups($input: EventInput!) {
    getFinishedStage5Groups(input: $input) {
      eventId
      searchId
      groupDocId
    }
  }
  query getAdminAllFinishedStage5Groups($input: EventInput!) {
    getAdminAllFinishedStage5Groups(input: $input) {
      eventId
      searchId
      groupDocId
      members
    }
  }
  query getAdminAllStage5Groups($input: EventInput!) {
    getAdminAllStage5Groups(input: $input) {
      eventId
      searchId
      groupDocId
      members
      state
    }
  }
`;
export type Stage5GroupDocument = Game5;

export const useStage5GroupDoc = (
  eventId?: Maybe<string>,
  groupDocId?: Maybe<string>
): DocumentHook => {
  const docRef =
    groupDocId != null && eventId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('stage5')
          .doc(eventId)
          .collection('groups')
          .doc(groupDocId)
      : undefined;
  return useDocument(docRef);
};

export const useStage5GroupDocData = (
  eventId?: Maybe<string>,
  groupDocId?: Maybe<string>
): DocumentDataHook<Stage5GroupDocument> => {
  const docRef =
    groupDocId != null && eventId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('stage5')
          .doc(eventId)
          .collection('groups')
          .doc(groupDocId)
      : undefined;
  return useDocumentData<Stage5GroupDocument>(docRef);
};

export const useStage5AllGroups = (
  eventId: string | null
): LoadingHook<Stage5GroupDocument[], Error> => {
  return useCollectionData<Stage5GroupDocument>(
    eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('stage5')
          .doc(eventId)
          .collection('groups')
      : undefined
  );
};

export const useMemberDatas = (
  groupDocData: Stage5GroupDocument | undefined,
  groupInfo: Stage5GroupInfo | null,
  eventId: string | null
): { memberDatas: GroupMemberItem[]; onReloadGroupMembers: () => void } => {
  const members = groupDocData?.members ?? [];
  const [memberDatas, setMemberDatas] = React.useState<GroupMemberItem[]>([]);

  const onLoadGroupMembersCompleted = React.useCallback(
    (data: GetGroupMembersQuery) => {
      const groupMembers = data.getGroupMembers;

      setMemberDatas(
        groupMembers.map(x => ({
          uid: x.uid,
          displayName: x.name ?? 'No Name',
        }))
      );
    },
    []
  );

  const [loadGroupMembers, { refetch, called }] = useGetGroupMembersLazyQuery({
    onCompleted: onLoadGroupMembersCompleted,
  });

  const onReloadGroupMembers = React.useCallback(() => {
    if (groupDocData === undefined || groupInfo === null || eventId === null) {
      setMemberDatas([]);
      return;
    }
    if (called) {
      refetch?.({
        input: {
          eventId,
          groupId: groupInfo.groupId,
        },
      }).then(res => {
        onLoadGroupMembersCompleted(res.data);
      });
      return;
    }

    loadGroupMembers({
      variables: {
        input: {
          eventId,
          groupId: groupInfo.groupId,
        },
      },
    });
  }, [
    groupDocData,
    groupInfo,
    eventId,
    called,
    loadGroupMembers,
    refetch,
    onLoadGroupMembersCompleted,
  ]);

  React.useEffect(() => {
    onReloadGroupMembers();
  }, [onReloadGroupMembers, members.length]);

  return { memberDatas, onReloadGroupMembers };
};
