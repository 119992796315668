import * as React from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';

import * as errorCode from '../../../../lib/errorCode';
import { appActions } from '../../../../redux/actions/appActions';
import { errorActions } from '../../../../redux/actions/errorActions';
import { useErrorState } from '../../../../redux/selectors/appSelectors';
import CommonErrorOverlay from './CommonErrorOverlay';
import CriticalErrorOverlay from './CriticalErrorOverlay';
import ManualErrorOverlay from './ManualErrorOverlay';

interface Stage3AnsInputProps {}

const ErrorOverlayController: React.FC<Stage3AnsInputProps> = () => {
  const state = useErrorState();
  const dispatch = useDispatch();
  const store: Omit<DefaultRootState, 'error'> = useSelector(state => {
    const { app, auth, game, time } = state;
    return { app, auth, game, time };
  });

  const onClose = React.useCallback(() => {
    dispatch(appActions.setErrorOverlayState(null));
  }, [dispatch]);

  const onBackButton = React.useCallback(() => {
    if (state == null || state?.errorType !== 'CommonError') return;
    state?.onBack?.();
    onClose();
  }, [onClose, state]);

  const onRetry = React.useCallback(() => {
    if (state == null || state?.errorType !== 'CommonError') return;
    onClose();
    state.onRetry?.();
  }, [onClose, state]);

  const sendError = React.useCallback(
    (message: string) => {
      dispatch(
        errorActions.pushReport({
          errorCode: errorCode.UNKNOWN_ERROR,
          storeDump: JSON.stringify(store),
          message,
        })
      );
      onClose();
    },
    [dispatch, onClose, store]
  );

  if (state === null) {
    return null;
  }

  switch (state.errorType) {
    case 'ManualError':
      return <ManualErrorOverlay onClose={onClose} sendError={sendError} />;
    case 'CriticalError':
      return <CriticalErrorOverlay />;
    default:
      return (
        <CommonErrorOverlay
          message={state.message}
          onRetry={state.onRetry === undefined ? undefined : onRetry}
          retryButtonText={state.retryButtonText}
          onBackButton={onBackButton}
          backButtonText={state.backButtonText}
        />
      );
  }
};

export default ErrorOverlayController;
