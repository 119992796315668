import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitAnswer1MutationVariables = Types.Exact<{
  input: Types.Answer1;
}>;


export type SubmitAnswer1Mutation = (
  { __typename?: 'Mutation' }
  & { submitAnswer1?: Types.Maybe<(
    { __typename?: 'submitAnswer1Response' }
    & { achieved: Array<(
      { __typename?: 'Stage1Target' }
      & Pick<Types.Stage1Target, 'targetId' | 'name' | 'score'>
    )> }
  )> }
);

export type GetAllStage1TargetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllStage1TargetsQuery = (
  { __typename?: 'Query' }
  & { getAllStage1Targets: Array<(
    { __typename?: 'Stage1Target' }
    & Pick<Types.Stage1Target, 'targetId' | 'name' | 'score'>
  )> }
);


export const SubmitAnswer1Document = gql`
    mutation SubmitAnswer1($input: Answer1!) {
  submitAnswer1(input: $input) {
    achieved {
      targetId
      name
      score
    }
  }
}
    `;
export type SubmitAnswer1MutationFn = Apollo.MutationFunction<SubmitAnswer1Mutation, SubmitAnswer1MutationVariables>;

/**
 * __useSubmitAnswer1Mutation__
 *
 * To run a mutation, you first call `useSubmitAnswer1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswer1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswer1Mutation, { data, loading, error }] = useSubmitAnswer1Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAnswer1Mutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswer1Mutation, SubmitAnswer1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswer1Mutation, SubmitAnswer1MutationVariables>(SubmitAnswer1Document, options);
      }
export type SubmitAnswer1MutationHookResult = ReturnType<typeof useSubmitAnswer1Mutation>;
export type SubmitAnswer1MutationResult = Apollo.MutationResult<SubmitAnswer1Mutation>;
export type SubmitAnswer1MutationOptions = Apollo.BaseMutationOptions<SubmitAnswer1Mutation, SubmitAnswer1MutationVariables>;
export const GetAllStage1TargetsDocument = gql`
    query getAllStage1Targets {
  getAllStage1Targets {
    targetId
    name
    score
  }
}
    `;

/**
 * __useGetAllStage1TargetsQuery__
 *
 * To run a query within a React component, call `useGetAllStage1TargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStage1TargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStage1TargetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStage1TargetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStage1TargetsQuery, GetAllStage1TargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStage1TargetsQuery, GetAllStage1TargetsQueryVariables>(GetAllStage1TargetsDocument, options);
      }
export function useGetAllStage1TargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStage1TargetsQuery, GetAllStage1TargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStage1TargetsQuery, GetAllStage1TargetsQueryVariables>(GetAllStage1TargetsDocument, options);
        }
export type GetAllStage1TargetsQueryHookResult = ReturnType<typeof useGetAllStage1TargetsQuery>;
export type GetAllStage1TargetsLazyQueryHookResult = ReturnType<typeof useGetAllStage1TargetsLazyQuery>;
export type GetAllStage1TargetsQueryResult = Apollo.QueryResult<GetAllStage1TargetsQuery, GetAllStage1TargetsQueryVariables>;