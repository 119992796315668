import * as React from 'react';
import styled from 'styled-components';

import Button from '../uiElements/button/MainButton';
import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';
import TextWindow from '../uiElements/TextWindow';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  buttonType: 'logout',
};

interface VerifyEmailViewProps {
  onResend: () => void;
  first: boolean;
}
const VerifyEmailView: React.FC<VerifyEmailViewProps> = props => {
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <Text>
          {props.first
            ? 'メールアドレスの確認メールを送信しました。\nメールに記載のURLを確認して登録を完了させてください。'
            : 'メールアドレスの確認メールを送信しました。\nメールに記載のURLを確認して変更を完了させてください。'}
        </Text>
        <TopButton size={'large'} color={'positive'} onClick={props.onResend}>
          確認メールを再送
        </TopButton>
      </Wrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 20rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;

const TopButton = styled(Button)`
  margin: 4.5rem auto 0;
  display: block;
`;

export default VerifyEmailView;
