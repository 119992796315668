import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useEventData, useEventResult } from '../../../api/event';
import { useStage5GroupDocData } from '../../../api/stage5';
import asContent from '../../../hoc/asContent';
import { isStageDebug } from '../../../lib/env';
import { gameActions } from '../../../redux/actions/gameActions';
import { timeActions } from '../../../redux/actions/timeActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
  useStagesFinished,
} from '../../../redux/selectors/gameSelectors';
import { EventStatus, GroupState } from '../../../types';
import UserNameView from '../../mypage/UserNameView';
import CommonBG from '../../uiElements/CommonBG';
import Stage1 from '../stagePages/stage1/Stage1';
import Stage2 from '../stagePages/stage2/Stage2';
import Stage3 from '../stagePages/stage3/Stage3';
import Stage4 from '../stagePages/stage4/Stage4';
import Stage5 from '../stagePages/stage5/Stage5';
import AllStagesFinished from '../stagesUtil/AllStagesFinished';
import Stage5FinishedManager from '../stagesUtil/Stage5FinishedManager';
import StageSelector from '../stagesUtil/StageSelector';

interface StagesProps {}

const StagesRouter: React.FC<StagesProps> = props => {
  const match = useRouteMatch();
  console.log('stagesrouter');
  return (
    <Switch>
      <Route path={`${match.url}/1`}>
        <Stage1 />
      </Route>
      <Route path={`${match.url}/2`}>
        <Stage2 />
      </Route>
      <Route path={`${match.url}/3`}>
        <Stage3 />
      </Route>
      <Route path={`${match.url}/4`}>
        <Stage4 />
      </Route>
      <Route path={`${match.url}/5`}>
        <Stage5 />
      </Route>
      <Route>
        <Stages {...props} />
      </Route>
    </Switch>
  );
};

const Stages: React.FC<StagesProps> = () => {
  const user = useCurrentUser();
  const stagesFinished = useStagesFinished();
  const allFinished = React.useMemo(
    () => !Object.values(stagesFinished).includes(false),
    [stagesFinished]
  );
  const eventId = useEventId();
  const [event] = useEventData(eventId);

  const dispatch = useDispatch();
  const groupInfo = useStage5GroupInfo();
  const [groupDocData] = useStage5GroupDocData(eventId, groupInfo?.groupId);

  React.useEffect(() => {
    if (groupDocData?.state === GroupState.Finished && !stagesFinished[5]) {
      console.log('5 finished');
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: true }));
    }
  }, [dispatch, groupDocData?.state, stagesFinished]);

  React.useEffect(() => {
    dispatch(timeActions.clearTimer());
  });

  return (
    <CommonBG withHeader withFooter headerOptions={{ buttonType: 'logout' }}>
      <StagesWrapper>
        <UserNameWrapper>
          <UserNameView userName={user?.displayName ?? 'NoName'} />
        </UserNameWrapper>

        {(!isStageDebug && allFinished) ||
        event?.status == EventStatus.Ended ? (
          <AllStagesFinished />
        ) : (
          <StageSelector />
        )}
      </StagesWrapper>
      <Stage5FinishedManager />
    </CommonBG>
  );
};

const StagesWrapper = styled.div`
  padding-top: 5rem;
  text-align: center;
`;

const UserNameWrapper = styled.div`
  width: 55rem;
  margin: 4rem auto 0;
`;

export default asContent(StagesRouter);
