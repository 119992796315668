import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  OrganizationDocument,
  useAdminAllOrganizations,
} from '../../../api/organization';
import AdminOrganizationTable from '../../eventProvider/AdminOrganizationTable';
import Pagination from '../../uiElements/Pagination';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import Filter from '../UIelements/Filter';
import { organizationSorter } from '../UIelements/sorter/OrganizationSorter';

interface AdminOrganizationViewProps {
  create?: boolean;
}

const initCreateOrganization: OrganizationDocument = {
  orgId: 'create',
  name: '新規企業',
  members: [],
  admin: [],
  players: [],
  createdOn: new Date(),
  enable: true,
  imgSrc: null,
};

const AdminOrganizationView: React.FC<AdminOrganizationViewProps> = props => {
  const history = useHistory();

  const [pageCursor, setPageCursor] = React.useState<number>(0);
  const pageSize = 10;
  const [organizationDataList] = useAdminAllOrganizations();

  const onAdminEdit = (eventId?: string) => {
    history.push(`/admin/events/${eventId}/edit`);
  };
  const onAdminControl = (eventId?: string) => {
    history.push(`/admin/events/${eventId}/control`);
  };
  const onCreateOrg = (orgId?: string) => {
    history.push(`/admin/organization/create`);
  };

  const [sortKey, setSortKey] = React.useState({
    key: 'name',
    sort: 'ASC',
  });

  const onChangeSort = (sortObj: any) => {
    setSortKey(sortObj);
  };

  const btnAdd: HeaderButton = {
    buttonText: '＋企業の追加',
    event: onCreateOrg,
  };

  const [filter, setFilter] = React.useState<{
    org_name?: string;
  }>({});
  const sorter = organizationSorter[sortKey.key][sortKey.sort];
  const filteredOrganizationList = React.useMemo(() => {
    return (organizationDataList ?? [])
      .sort(sorter)
      .filter(
        (e: { name: string | string[] }) =>
          !filter.org_name || e.name.indexOf(filter.org_name) !== -1
      );
  }, [organizationDataList, filter, sortKey]);

  return (
    <AdminCommonBG title='企業' headerButtons={[btnAdd]}>
      <Filter
        item={[
          { type: 'input', name: 'org_name', label: '組織名', width: 300 },
        ]}
        onSend={params => {
          setFilter(params);
          setPageCursor(0);
        }}
      />
      <AdminOrganizationWrapper>
        <AdminOrganizationTable
          organizationDataList={filteredOrganizationList.filter(
            (_: any, i: number) => pageCursor <= i && i < pageCursor + pageSize
          )}
          onAdminEdit={onAdminEdit}
          onAdminControl={onAdminControl}
          sortObj={sortKey}
          onChangeSort={onChangeSort}
        />
      </AdminOrganizationWrapper>
      <Pagination
        count={filteredOrganizationList.length}
        begin={pageCursor}
        end={Math.min(pageCursor + pageSize, filteredOrganizationList.length)}
        onNext={() => {
          setPageCursor(pageCursor + pageSize);
        }}
        onPrev={() => {
          setPageCursor(Math.max(pageCursor - pageSize, 0));
        }}
      />
    </AdminCommonBG>
  );
};

const AdminOrganizationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default AdminOrganizationView;
