export const administratorsSorter: any = {
  // rubyにするかfullNameにするか...
  fullName: {
    ASC: (a: any, b: any) => {
      if (a == undefined || b == undefined) return 0;
      if (a.ruby === null) a.ruby = '';
      if (b.ruby === null) b.ruby = '';

      return a.ruby < b.ruby ? -1 : 1;
    },
    DESC: (a: any, b: any) => {
      if (a == undefined || b == undefined) return 0;
      if (a.ruby === null) a.ruby = '';
      if (b.ruby === null) b.ruby = '';

      return a.ruby > b.ruby ? -1 : 1;
    },
  },
};
