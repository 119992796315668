import firebase from 'firebase/app';
import * as React from 'react';
import styled from 'styled-components';

import { useCurrentUser } from '../../redux/selectors/authSelectors';

interface AuthManagerProps {}
const AuthManager: React.FC<AuthManagerProps> = () => {
  const user = useCurrentUser();

  const [email, setEmail] = React.useState<string>('');
  const [pw, setPw] = React.useState<string>('');

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pw)
      .catch(error => {
        setErrorMessage(error.message);
      });
  };

  const onLogout = () => {
    window.localStorage.removeItem('selectedOrgId');
    firebase
      .auth()
      .signOut()
      .catch(() => {
        return;
      });
  };

  return (
    <AuthManagerWrapper>
      {user === null ? (
        <>
          <p>Log in</p>
          <input onChange={e => setEmail(e.target.value)} value={email} />
          <input onChange={e => setPw(e.target.value)} value={pw} />
          <button onClick={() => onLogin()}>Log in</button>

          {errorMessage !== '' && <p>Error: {errorMessage}</p>}
        </>
      ) : (
        <>
          <p>Hello {user.displayName || '[No Name]'}!</p>
          <button onClick={() => onLogout()}>Log out</button>
        </>
      )}
    </AuthManagerWrapper>
  );
};

const AuthManagerWrapper = styled.div`
  padding: 1rem 3rem;
  border: 1px solid #ccc;
`;

export default AuthManager;
