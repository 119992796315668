import * as React from 'react';
import styled from 'styled-components';

import { HandleFirebaseErrorResult } from '../../../lib/firebase/firebaseErrorHandlers';
import useFirebaseError from '../../../lib/firebase/useFirebaseError';
import {
  Validators,
  pwFormatValidator,
  useValidator,
} from '../../../lib/useValidator';
import ErrorText from '../../uiElements/ErrorText';
import SettingsForm from '../../uiElements/form/Form';
import FormSubmitButton from '../../uiElements/form/FormSubmitButton';
import { FormElement } from '../../uiElements/form/formTypes';
import SettingsItemBody from '../SettingsItemBody';
import SettingsItemTitle from '../SettingsItemTitle';

interface PWSettingsViewProps {
  done: boolean;
  error: HandleFirebaseErrorResult | null;
  onConfirmChange(currentPW: string, newPW: string): void;
}
const PWSettingsView: React.FC<PWSettingsViewProps> = props => {
  const { emailError, pwError, otherError, resetError } = useFirebaseError(
    props.error
  );

  const [currentPW, setCurrentPW] = React.useState('');
  const [newPW, setNewPW] = React.useState('');
  const [newPWRe, setNewPWRe] = React.useState('');

  const resetAll = React.useCallback(() => {
    setCurrentPW('');
    setNewPW('');
    setNewPWRe('');
  }, [setNewPWRe]);

  React.useEffect(() => {
    if (props.done) {
      resetAll();
    }
  }, [props.done, resetAll]);

  const currentPWValidators = React.useMemo<Validators>(
    () => [
      [(_, state) => state.isFocused],
      [val => val === ''],
      [() => !!pwError, pwError ?? ''],
    ],
    [pwError]
  );
  const [currentPWValidator, currentPWIsValid] =
    useValidator(currentPWValidators);
  const currentPWonChange = React.useCallback(
    (val: string) => {
      setCurrentPW(val);
      resetError('pw');
    },
    [resetError]
  );

  const newPWValidators = React.useMemo<Validators>(
    () => [
      [(_, state) => state.isFocused],
      [val => val === ''],
      [val => val === currentPW, '新しいパスワードを入力してください。'], //WIP 現在のパスワードを取得する必要がある...。
      pwFormatValidator,
    ],
    [currentPW]
  );
  const [newPWValidator, newPWIsValid] = useValidator(newPWValidators);

  const newPWReValidators = React.useMemo<Validators>(
    () => [
      [(_, state) => state.isFocused],
      [val => val === ''],
      [val => val !== newPW, 'パスワードが一致しません'],
      pwFormatValidator,
    ],
    [newPW]
  );
  const [newPWReValidator, newPWReIsValid] = useValidator(newPWReValidators);

  const onConfirmChange = React.useCallback(() => {
    props.onConfirmChange(currentPW, newPW);
  }, [currentPW, newPW, props]);

  const formElements = React.useMemo<FormElement[]>(
    () => [
      {
        id: 'current-password',
        label: '現在のパスワードを入力してください。',
        type: 'password',
        value: currentPW,
        onChange: currentPWonChange,
        autoComplete: 'current-password',
        validator: currentPWValidator,
      },
      {
        id: 'new-password',
        label: '新しいパスワードを8文字以上で入力してください。',
        type: 'password',
        value: newPW,
        onChange: setNewPW,
        autoComplete: 'new-password',
        validator: newPWValidator,
      },
      {
        id: 'new-password-re',
        label: '確認のため、もう一度新しいパスワードを入力してください。',
        type: 'password',
        value: newPWRe,
        onChange: setNewPWRe,
        autoComplete: 'new-password',
        validator: newPWReValidator,
      },
    ],
    [
      currentPW,
      currentPWValidator,
      currentPWonChange,
      newPW,
      newPWRe,
      newPWReValidator,
      newPWValidator,
    ]
  );

  return (
    <>
      <SettingsItemTitle>{'パスワードの変更'}</SettingsItemTitle>
      <SettingsItemBody>
        <SettingsForm elements={formElements} />
        <FormSubmitButton
          variant='main'
          size='large'
          color='positive'
          disabled={!currentPWIsValid || !newPWIsValid || !newPWReIsValid}
          onClick={onConfirmChange}
        >
          変更
        </FormSubmitButton>
        <Errors>
          {emailError && <ErrorText>{emailError}</ErrorText>}
          {otherError && <ErrorText>{otherError}</ErrorText>}
        </Errors>
      </SettingsItemBody>
    </>
  );
};

const Errors = styled.div`
  margin-top: 2rem;
`;

export default PWSettingsView;
