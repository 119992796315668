import { OrganizationDocument } from '../../../../api/organization';
export const organizationSorter: any = {
  name: {
    ASC: (a: OrganizationDocument, b: OrganizationDocument) => {
      if (a.name === null) a.name = '';
      if (b.name === null) b.name = '';

      return a.name < b.name ? -1 : 1;
    },
    DESC: (a: OrganizationDocument, b: OrganizationDocument) => {
      if (a.name === null) a.name = '';
      if (b.name === null) b.name = '';

      return a.name > b.name ? -1 : 1;
    },
  },
};
