import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import * as React from 'react';
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from '../../../api/__generated__/organization.generated';
import { useOrgAllRegistrationTokens } from '../../../api/adminRole';
import {
  OrganizationDocument,
  useOrganizationData,
} from '../../../api/organization';
import { resizeImage } from '../../../lib/resizeImage';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { useSetLoadingState } from '../../../redux/actions/appActions';
import { useAdminOrgId } from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import SubButton from '../../uiElements/button/SubButton';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminPopup from '../UIelements/AdminPopup';
import AdminOrganizationEditor from './AdminOrganizationEditor';

interface AdminOrganizationDetailProps {
  create: boolean;
}

const initCreateOrganization: OrganizationDocument = {
  orgId: 'create',
  name: '新規企業',
  members: [],
  admin: [],
  players: [],
  createdOn: new Date(),
  enable: true,
  imgSrc: null,
};

const frontendURI = process.env.REACT_APP_FRONTEND_URI;
const AdminOrganizationDetail: React.FC<AdminOrganizationDetailProps> =
  props => {
    const { create } = props;
    const [admins, setAdmins] = React.useState<string[]>([]);
    const history = useHistory();
    const setLoadingState = useSetLoadingState();

    const [organization, setOrganization] =
      React.useState<OrganizationDocument | null>(null);

    const onCompleteOrganization = React.useCallback(() => {
      setLoadingState({
        visible: false,
      });
      history.push(`/admin/organization`);
      window.location.reload();
    }, [history, setLoadingState]);

    const [createOrganization] = useRetryableMutationWithUI(
      useCreateOrganizationMutation,
      {
        hookOptions: {
          onCompleted: onCompleteOrganization,
        },
      }
    );
    const [updateOrganization] = useRetryableMutationWithUI(
      useUpdateOrganizationMutation,
      {
        hookOptions: {
          onCompleted: onCompleteOrganization,
        },
      }
    );

    const btnAdd: HeaderButton = {
      buttonText: '企業を追加する',
      event: () => {
        setLoadingState({
          visible: true,
        });

        if (imageFile) {
          onSubmit();
        } else {
          createOrganization({
            variables: {
              input: {
                name: organization?.name ?? '',
                members: [],
                admin: admins,
                imgSrc: organization?.imgSrc ?? undefined,
              },
            },
          });
        }
      },
    };

    const dispatch = useDispatch();
    const saveOrganization = () => {
      if (imageFile) {
        onSubmit();
      } else {
        updateOrganization({
          variables: {
            input: {
              orgId: organization?.orgId ?? '',
              name: organization?.name ?? '',
              members: organization?.members ?? [],
              admin: admins,
              imgSrc: organization?.imgSrc ?? undefined,
            },
          },
        });
      }
    };
    const btnSave: HeaderButton = {
      buttonText: '変更を保存する',
      event: () => {
        setLoadingState({
          visible: true,
        });
        saveOrganization();
      },
    };

    const orgId = useAdminOrgId();
    const orgIconId = React.useMemo(() => {
      const keys =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      return [...Array(16)]
        .map(e => keys.charAt(Math.random() * keys.length))
        .join('');
    }, [orgId]);
    const [initOrganization] = useOrganizationData(create ? null : orgId);

    React.useEffect(() => {
      if (create) {
        setOrganization(initCreateOrganization);
      }
      if (initOrganization !== undefined) {
        setOrganization(initOrganization);
      }
    }, [create, initOrganization]);

    React.useEffect(() => {
      if (!create && organization?.admin !== undefined) {
        setAdmins(organization.admin);
      }
    }, [create, organization?.admin]);

    React.useEffect(() => {
      if (!imgSrc) {
        setImgSrc(organization?.imgSrc ?? undefined);
      }
    }, [organization?.imgSrc]);

    // img
    const [imgSrc, setImgSrc] = React.useState<string | undefined>(undefined);
    const [imageFile, setImageFile] = React.useState<boolean | Blob>(false);
    const handleImage = async (e: any) => {
      const { imageFile, imageUri } = await resizeImage(e, 192);
      setImgSrc(imageUri);
      setImageFile(imageFile);
      return;
    };

    const next = (snapshot: {
      bytesTransferred: number;
      totalBytes: number;
    }) => {
      // 進行中のsnapshotを得る
      // アップロードの進行度を表示
      const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(percent + '% done');
      console.log(snapshot);
    };
    const error = (error: any) => {
      // エラーハンドリング
    };
    const complete = async () => {
      if (!organization) {
        return;
      }
      // 画像表示のため、アップロードした画像のURLを取得
      const firebaseUrl = await firebase
        .storage()
        .ref('images')
        .child(orgIconId)
        .getDownloadURL();

      setImgSrc(firebaseUrl);
      organization.imgSrc = firebaseUrl;
      if (orgId === 'create') {
        organization.imgSrc = firebaseUrl;
        createOrganization({
          variables: {
            input: {
              name: organization?.name ?? '',
              members: [],
              admin: admins,
              imgSrc: organization.imgSrc,
            },
          },
        });
      } else {
        updateOrganization({
          variables: {
            input: {
              orgId: organization.orgId,
              name: organization.name,
              members: organization.members,
              admin: admins,
              imgSrc: organization.imgSrc ?? undefined,
            },
          },
        });
      }
    };

    const onSubmit = () => {
      if (!imageFile) {
        console.log('ファイルが選択されていません');
      }
      if (!organization) {
        return;
      }
      // アップロード処理
      const uploadTask = firebase
        .storage()
        .ref(`/images/${orgIconId}`)
        .put(imageFile as Blob);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        next,
        error,
        complete
      );
      return;
    };

    // invite link
    const [inviteUrlCollection] = useOrgAllRegistrationTokens(orgId);

    const inviteUrl = React.useMemo(() => {
      if (inviteUrlCollection === undefined) {
        return undefined;
      }
      const token = inviteUrlCollection.filter(
        e => e.orgId === orgId && e.adminRole != null
      )[0];
      return frontendURI + '/register?token=' + (token?.token ?? '');
    }, [inviteUrlCollection, orgId]);

    const textAreaRef = React.useRef<HTMLDivElement>(null);
    const copyToClipboard = React.useCallback(
      (e: MouseEvent) => {
        if (inviteUrl) {
          navigator.clipboard.writeText(inviteUrl).then(e => {
            window.alert(`招待リンクをクリップボードにコピーしました。`);
          });
        }
      },
      [inviteUrl]
    );

    const UserInfoDetail = (
      <UserInfoPopup>
        <p>管理者アカウント招待リンク</p>
        <InviteType>
          <SingleType selected>企業</SingleType>
        </InviteType>
        <InviteUrl ref={textAreaRef}>{inviteUrl}</InviteUrl>
        <ButtonWrap>
          <SubButton
            size={'medium'}
            color={'negative'}
            onMouseDown={copyToClipboard}
          >
            クリップボードにコピー
          </SubButton>
        </ButtonWrap>
      </UserInfoPopup>
    );

    const btnInvite: HeaderButton = {
      buttonDom: (
        <AdminPopup width={360} key='popup' content={UserInfoDetail}>
          <SubButton size={'medium'} color={'positive'}>
            招待する
            <BtnSvg>
              <FontAwesomeIcon icon={faChevronDown} />
            </BtnSvg>
          </SubButton>
        </AdminPopup>
      ),
      chevron: true,
    };

    const btns: HeaderButton[] = [];
    if (props.create) {
      btns.push(btnAdd);
    } else {
      btns.push(btnInvite);
      btns.push(btnSave);
    }

    return (
      <AdminCommonBG
        title={props.create ? '企業の追加' : '企業の編集'}
        backButton
        headerButtons={btns}
      >
        <AdminOrganizationWrapper>
          {organization && (
            <AdminOrganizationEditor
              create={props.create}
              organization={organization}
              participantUids={admins}
              error={null}
              onUpdateParticipantUids={setAdmins}
              onSelectImage={handleImage}
              imgSrc={imgSrc}
            />
          )}
        </AdminOrganizationWrapper>
      </AdminCommonBG>
    );
  };

const AdminOrganizationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const UserInfoPopup = styled.div`
  padding: 1rem 1.6rem;
  text-align: left;

  p {
    font-size: 1.8rem;
  }
  span {
    font-size: 1.4rem;
  }
`;

const ButtonWrap = styled.div`
  padding: 1rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-bottom: -1rem;
  button {
    display: block;
    margin-left: auto;
  }
`;

const BtnSvg = styled.span`
  margin-left: 1rem;
`;

const InviteUrl = styled.div`
  margin-top: 1rem;
  background: ${Colors.gray2};
  border: 2px solid ${Colors.gray4};
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 1rem 1rem;
  overflow-x: scroll;
  white-space: nowrap;
  user-select: all;
`;

const InviteType = styled.div`
  display: flex;
  font-size: 1.6rem;
  jystify-contents: center;
  background: ${Colors.gray2};
  padding: 0rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-top: 1rem;
`;
interface SingleTypeProps {
  selected?: boolean;
}
const SingleType = styled.div`
  flex: 1;
  text-align: center;
  padding: 1rem;
  transition: 0.2s;
  border-bottom: 5px solid transparent;

  ${(p: SingleTypeProps) =>
    p.selected === true
      ? `
      color: ${Colors.primary};
      border-color: ${Colors.primary};
      font-weight: bold;
      `
      : `
      &:hover {
        cursor: pointer;
        background: ${Colors.gray4};
      }
  `}
`;

const OrgWrapper = styled.div`
  margin: 0 -1.8rem;
  p {
    font-size: 1.6rem !important;
  }
`;

export default AdminOrganizationDetail;
