import * as React from 'react';

import { Stage5Problem } from '../../../../lib/stage5problems';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import GamePresenterView from './GamePresenterView';
import GroupIdIndicator from './GroupIdIndicator';

interface GamePresenterProps {
  problem: Stage5Problem;
  searchId: number;
}

const GamePresenter: React.FC<GamePresenterProps> = ({ problem, searchId }) => {
  return (
    <CommonBG>
      <TimeGauge />
      <GamePresenterView problem={problem} />
      <GroupIdIndicator searchId={searchId} />
    </CommonBG>
  );
};

export default GamePresenter;
