import { start } from 'repl';

import format from 'date-fns/format';
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAdminUpdateUserInfoMutation,
  useDeleteUserMutation,
} from '../../../api/__generated__/adminRole.generated';
import { useUserInfo, useUserOrgInfo } from '../../../api/user';
import {
  employeeIdValidators,
  startYearValidators,
} from '../../../lib/profileValidator';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import {
  options_ind,
  options_occ,
  profileFormData,
} from '../../../lib/userProfiles';
import { Validators, useValidator } from '../../../lib/useValidator';
import { appActions } from '../../../redux/actions/appActions';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import Colors from '../../../styles/colors';
import _Button from '../../uiElements/button/SubButton';
import FormElementView from '../../uiElements/form/elements/FormElementView';
import { getYearOptions } from '../../uiElements/form/formUtils';
import Select, { SelectOverrideStyles } from '../../uiElements/select/Select';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';

interface AdminUserEditProps {}
type OptionType = {
  value: string;
  label: string;
};
interface ParamTypes {
  userId: string;
}
const defaultDate = new Date('1990-01-01T12:00:00Z');
const yearOptions = getYearOptions();
const formatDate = (d: Date) => format(d, 'yyyy-MM-dd');
const AdminUserEdit: React.FC<AdminUserEditProps> = props => {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const params = useParams<ParamTypes>();
  const userId = params.userId;
  const [currentUserInfo] = useUserInfo(userId);
  const orgId = useSelectedOrgId();
  const [currentOrgInfo] = useUserOrgInfo(userId, orgId);

  const [fullName, setFullName] = React.useState('');
  const [ruby, setRuby] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');
  const [birthday, setBirthday] = React.useState(defaultDate);
  const [startYear, setStartYear] = React.useState('2020');
  const [email, setEmail] = React.useState('');
  const [department, setDepartment] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [occupationId, setOccupationId] = React.useState('');
  const [industryId, setIndustryId] = React.useState('');
  const [adminUpdateUserInfo] = useAdminUpdateUserInfoMutation();
  const [deleteUser] = useRetryableMutationWithUI(useDeleteUserMutation, {
    hookOptions: {},
  });

  React.useEffect(() => {
    const c = currentUserInfo;

    if (c?.ruby) {
      setRuby(c.ruby);
    }
    if (c?.fullName) {
      setFullName(c.fullName);
    }
    if (c?.displayName) {
      setDisplayName(c.displayName);
    }
    if (c?.email) {
      setEmail(c.email);
    }
    if (c?.birthday) {
      setBirthday(c.birthday.toDate());
    }
  }, [currentUserInfo]);

  React.useEffect(() => {
    const c = currentOrgInfo;

    if (c?.department) {
      setDepartment(c.department);
    }
    if (c?.employeeId) {
      setEmployeeId(c.employeeId);
    }
    if (c?.occupationId) {
      setOccupationId(c.occupationId);
    }
    if (c?.industryId) {
      setIndustryId(c.industryId);
    }
    if (c?.startYear) {
      setStartYear(c.startYear.toString());
    }
  }, [currentOrgInfo]);

  const [startYearValidator, startYearIsValid] = useValidator(
    useMemo<Validators>(startYearValidators, [])
  );

  const [employeeIdValidator, employeeIdIsValid] = useValidator(
    useMemo<Validators>(employeeIdValidators, [])
  );

  const btnInvite: HeaderButton = {
    buttonText: '変更を保存する',
    disabled: !startYearIsValid || !employeeIdIsValid,
    event: () => {
      // Task Save User *
      if (orgId != null) {
        if (isNaN(parseInt(startYear)) || parseInt(startYear) > 9999) {
          dispatch(
            appActions.setErrorOverlayState({
              errorType: 'CommonError',
              message: '入社年を4桁の数値で入力してください。',
            })
          );
          return;
        }

        const operations: Promise<unknown>[] = [
          adminUpdateUserInfo({
            variables: {
              input: {
                uid: userId,
                orgId: orgId,
                startYear: parseInt(startYear),
                department,
                employeeId,
                industryId,
                occupationId,
              },
            },
          }),
        ];

        Promise.all(operations)
          .then(() => {
            dispatch(
              appActions.setErrorOverlayState({
                errorType: 'CommonError',
                message: 'プロフィールの変更が完了しました。',
              })
            );
          })
          .catch(() => {
            dispatch(
              appActions.setErrorOverlayState({ errorType: 'CommonError' })
            );
          })
          .finally(() => {
            dispatch(appActions.setLoadingState({ visible: false }));
          });
      }
    },
  };

  // dummy data
  const selectStyles: SelectOverrideStyles = {
    container: {
      width: '24rem',
      fontSize: '1.3rem',
    },
    option: {
      fontSize: '1.3rem',
    },
    singleValue: {
      fontSize: '1.3rem',
    },
  };

  return (
    <AdminCommonBG
      backButton
      title='アカウント編集'
      headerButtons={[btnInvite]}
    >
      <AdminEventControllerWrapper>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>氏名</TH>
                <TH>よみがな</TH>
                <TH>表示名</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>{fullName ?? ''}</TD>
                <TD>{ruby ?? ''}</TD>
                <TD>{displayName ?? ''}</TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>メールアドレス</TH>
                <TH>生年月日</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>{email ?? ''}</TD>
                <TD>{formatDate(birthday) ?? ''}</TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>
        <RowWrapper>
          <Row>
            <Item width={'100px'}>
              <FormElementView
                key={'startYear'}
                element={{
                  ...profileFormData.startYear,
                  value: startYear,
                  onChange: setStartYear,
                  validator: startYearValidator,
                }}
              />
            </Item>
          </Row>
          <Row>
            <Item width={'100%'}>
              <ItemTitle>業種</ItemTitle>
              <ItemBody>
                <SelectWrapper>
                  <Select
                    options={options_ind}
                    value={options_ind.find(op => op.value === industryId)}
                    overrideStyles={selectStyles}
                    onChange={e => setIndustryId(e?.value ?? '')}
                  />
                </SelectWrapper>
              </ItemBody>
            </Item>
          </Row>
        </RowWrapper>
        <RowWrapper>
          <Row>
            <Item width={'100%'}>
              <ItemTitle>部署</ItemTitle>
              <ItemBody>
                <LongInput
                  value={department}
                  onChange={e => setDepartment(e.target.value)}
                />
              </ItemBody>
            </Item>
          </Row>
          <Row>
            <Item width={'100%'}>
              <ItemTitle>職種</ItemTitle>
              <ItemBody>
                <SelectWrapper>
                  <Select
                    options={options_occ}
                    value={
                      (options_occ
                        .map(e => e.options)
                        .flat()
                        .find(op => op.value === occupationId) as any) ??
                      ({
                        label: '',
                        value: '',
                      } as any)
                    }
                    overrideStyles={selectStyles}
                    onChange={(e: any) => {
                      setOccupationId(e?.value ?? '');
                    }}
                  />
                </SelectWrapper>
              </ItemBody>
            </Item>
          </Row>
        </RowWrapper>
        <RowWrapper>
          <Row>
            <Item width={'100%'}>
              <ItemBody>
                <FormElementView
                  key={'employeeId'}
                  element={{
                    ...profileFormData.employeeId,
                    value: employeeId,
                    onChange: setEmployeeId,
                    validator: employeeIdValidator,
                  }}
                />
              </ItemBody>
            </Item>
          </Row>
        </RowWrapper>

        {
          <Buttons>
            <DeleteButton
              disabled={orgId === null}
              onClick={() => {
                // Task Delete User *
                console.log('delete user');
                dispatch(
                  appActions.setErrorOverlayState({
                    errorType: 'CommonError',
                    message: '本当にアカウントを削除しますか。',
                    backButtonText: 'いいえ',
                    retryButtonText: 'はい',
                    onRetry: () => {
                      if (orgId === null) {
                        return;
                      }
                      deleteUser({
                        variables: {
                          input: {
                            uid: userId,
                            orgId,
                          },
                        },
                      });
                    },
                    onBack: () => {
                      console.log('close');
                    },
                  })
                );
              }}
            >
              アカウントを削除する
            </DeleteButton>
          </Buttons>
        }
      </AdminEventControllerWrapper>
    </AdminCommonBG>
  );
};

const SelectWrapper = styled.div`
  > div {
    width: 100%;
  }
`;

const LongInput = styled.input`
  width: calc(100% - 1.6rem);
`;

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
`;

const Margin = styled.div`
  margin-bottom: 1rem;
`;

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 1.6rem;

  > div {
    flex: 1;
    max-width: calc(50% - 1rem);
  }

  > :first-child {
    margin-right: 2rem;
  }
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

interface ItemStyleProps {
  width?: string;
  marginRight?: number;
}

const Item = styled.div`
  ${(p: ItemStyleProps) => (p.width != null ? `width: ${p.width};` : '')}
  ${(p: ItemStyleProps) =>
    p.marginRight != null ? `margin-right: ${p.marginRight}px;` : ''}

  input {
    font-size: 1.3rem;
    padding: 0.8rem 0.7rem;
    border: ${Colors.gray6} 2px solid;
    border-radius: 0.4rem;

    &::placeholder {
      color: ${Colors.gray4};
    }
  }
`;

const ItemTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const Buttons = styled.div`
  border-top: 1px solid ${Colors.gray4};
  width: 100%;
  text-align: right;
  padding-top: 1rem;
  box-sizing: border-box;
  position: relative;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 18rem;
    }
    td:nth-child(3) {
      width: 20rem;

      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      width: 20rem;
      padding-right: 0;
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  tr:last-child {
    border: none !important;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: block;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;
  right: 0;
  position: absolute;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
  cursor: default;
  opacity: 0.4;
  pointer-events: none;

  &:hover {
    background: initial;
  }
  `}
`;

export default AdminUserEdit;
