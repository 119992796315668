import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import asContent from '../../hoc/asContent';
import {
  HandleFirebaseErrorResult,
  handleSendPasswordResetEmailErrorCode,
} from '../../lib/firebase/firebaseErrorHandlers';
import { appActions } from '../../redux/actions/appActions';
import PasswordResetView from './PasswordResetView';

interface PasswordResetProps {}
const PasswordReset: React.FC<PasswordResetProps> = () => {
  const dispatch = useDispatch();

  const [error, setError] =
    React.useState<HandleFirebaseErrorResult | null>(null);
  const [done, setDone] = React.useState(false);

  const onPasswordReset = React.useCallback(
    (email: string) => {
      dispatch(appActions.setLoadingState({ visible: true }));

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          dispatch(appActions.setLoadingState({ visible: false }));
          setDone(true);
        })
        .catch(error => {
          dispatch(appActions.setLoadingState({ visible: false }));
          setDone(false);
          setError(handleSendPasswordResetEmailErrorCode(error.code));
        });
    },
    [dispatch]
  );

  return (
    <PasswordResetView
      done={done}
      error={error}
      onPasswordReset={onPasswordReset}
    />
  );
};

export default asContent(PasswordReset);
