import React from 'react';
import styled from 'styled-components';

import { getResultCommnet } from '../../../lib/resultComments';
import { getRankMessage } from '../../../lib/stageResults';
import ResultTableRankImg from '../../../static/png/result/Result_Table_Rank.png';
import ResultRowHeadImg from '../../../static/svg/result/Result_Table_Head.svg';
import { colorWithAlpha } from '../../../styles/colors';
import StageIcon from '../../uiElements/StageIcon';
import StageNumber from '../../uiElements/StageNumber';
import StageTitle from '../../uiElements/StageTitle';

type Props = {
  ranks: [number, number, number, number, number];
  loaded: boolean;
};

const Rank = ['C', 'C+', 'B', 'B+', 'A', 'A+', 'S'] as const;

const ResultRow: React.FC<{
  stageNum: 1 | 2 | 3 | 4 | 5;
  rank: number | undefined;
  comment: string;
}> = props => {
  return (
    <Row>
      <HeadingWrapper>
        <NumberWrapper>
          <StageNumber stageNum={props.stageNum} variant='black' />
        </NumberWrapper>
        <IconWrapper>
          <StageIcon stageNum={props.stageNum} />
        </IconWrapper>
        <TitleWrapper>
          <StageTitle stageNum={props.stageNum} />
        </TitleWrapper>
      </HeadingWrapper>
      <RankTitle src={ResultTableRankImg} />
      <RankText>{getRankMessage(props.rank ?? -1)}</RankText>
      <DetailText>{props.comment}</DetailText>
    </Row>
  );
};
const Row = styled.div`
  height: 14.7rem;
  width: 95rem;
  margin: 2rem 0 0;
  background-image: url(${ResultRowHeadImg});
  background-repeat: no-repeat;
  background-position: left top;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  display: flex;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  margin-left: 4.5rem;
  height: 6rem;
  width: 27rem;
  display: flex;
  align-items: center;
`;
const NumberWrapper = styled.div`
  height: 6rem;
`;
const IconWrapper = styled.div`
  margin-left: 0.8rem;
  height: 3.3rem;
`;
const TitleWrapper = styled.div`
  margin-left: 0.8rem;
  height: 1.7rem;
`;

const RankTitle = styled.img`
  margin-left: 4rem;
  height: 2.8rem;
`;

const RankText = styled.div`
  color: ${colorWithAlpha('primary', 1)};
  font-size: 5rem;
  font-weight: bold;
  font-family: 'Open Sans';
  text-align: left;
  vertical-align: middle;
  height: 7rem;
  width: 6.5rem;
  margin-left: 1.2rem;
`;

const DetailText = styled.div`
  font-size: 1.6rem;
  margin-left: 3.5rem;
  padding-right: 1.5rem;
  width: 40rem;
  word-break: break-all;
`;

const ResultTable: React.FC<Props> = props => {
  return (
    <Table>
      {props.ranks.map((v, i) => (
        <ResultRow
          key={i}
          stageNum={(i + 1) as 1 | 2 | 3 | 4 | 5}
          rank={props.loaded ? v : undefined}
          comment={props.loaded ? getResultCommnet(i, v) : ''}
        />
      ))}
    </Table>
  );
};

const Table = styled.div``;

export default ResultTable;
