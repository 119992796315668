import firebase from 'firebase';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  useConfirmUpdateEmailMutation,
  useUpdateEmailMutation,
} from '../../../api/__generated__/user.generated';
import {
  HandleFirebaseErrorResult,
  handleReauthenticateWithCredentialErrorCode,
} from '../../../lib/firebase/firebaseErrorHandlers';
import { appActions } from '../../../redux/actions/appActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import MailChangeView from './MailChangeView';

interface MailChangeProps {}
const MailChange: React.FC<MailChangeProps> = () => {
  const user = useCurrentUser();
  const dispatch = useDispatch();

  const [error, setError] =
    React.useState<HandleFirebaseErrorResult | null>(null);
  const [done, setDone] = React.useState(false);
  const [confirmUpdateEmail] = useConfirmUpdateEmailMutation();

  const onConfirmChange = React.useCallback(
    async (currentPW: string, newMail: string) => {
      if (!user?.email) return;
      setError(null);

      dispatch(
        appActions.setLoadingState({
          visible: true,
        })
      );

      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPW
      );

      user
        .reauthenticateWithCredential(cred)
        .then(async () => {
          const operations: Promise<unknown>[] = [
            confirmUpdateEmail({
              variables: {
                input: {
                  email: newMail,
                },
              },
            }),
          ];

          return await Promise.all(operations)
            .then(() => {
              dispatch(
                appActions.setErrorOverlayState({
                  errorType: 'CommonError',
                  message: 'メールアドレスの変更を受け付けました。',
                })
              );
              setDone(true);
            })
            .catch(() => {
              dispatch(
                appActions.setErrorOverlayState({
                  errorType: 'CommonError',
                  message: 'メールアドレスの変更に失敗しました。',
                })
              );
            });
        })
        .catch((error: any) => {
          setError(handleReauthenticateWithCredentialErrorCode(error.code));
        })
        .finally(() => {
          dispatch(
            appActions.setLoadingState({
              visible: false,
            })
          );
        });
    },
    [dispatch, user, confirmUpdateEmail]
  );

  return (
    <MailChangeView
      done={done}
      error={error}
      onConfirmChange={onConfirmChange}
    />
  );
};

export default MailChange;
