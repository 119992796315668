import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminStageResetMutationVariables = Types.Exact<{
  input: Types.AdminResetInput;
}>;


export type AdminStageResetMutation = (
  { __typename?: 'Mutation' }
  & { adminStageReset: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type RecordStageStartMutationVariables = Types.Exact<{
  input: Types.StartInput;
}>;


export type RecordStageStartMutation = (
  { __typename?: 'Mutation' }
  & { recordStageStart: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);


export const AdminStageResetDocument = gql`
    mutation adminStageReset($input: AdminResetInput!) {
  adminStageReset(input: $input) {
    success
  }
}
    `;
export type AdminStageResetMutationFn = Apollo.MutationFunction<AdminStageResetMutation, AdminStageResetMutationVariables>;

/**
 * __useAdminStageResetMutation__
 *
 * To run a mutation, you first call `useAdminStageResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminStageResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminStageResetMutation, { data, loading, error }] = useAdminStageResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminStageResetMutation(baseOptions?: Apollo.MutationHookOptions<AdminStageResetMutation, AdminStageResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminStageResetMutation, AdminStageResetMutationVariables>(AdminStageResetDocument, options);
      }
export type AdminStageResetMutationHookResult = ReturnType<typeof useAdminStageResetMutation>;
export type AdminStageResetMutationResult = Apollo.MutationResult<AdminStageResetMutation>;
export type AdminStageResetMutationOptions = Apollo.BaseMutationOptions<AdminStageResetMutation, AdminStageResetMutationVariables>;
export const RecordStageStartDocument = gql`
    mutation recordStageStart($input: StartInput!) {
  recordStageStart(input: $input) {
    success
  }
}
    `;
export type RecordStageStartMutationFn = Apollo.MutationFunction<RecordStageStartMutation, RecordStageStartMutationVariables>;

/**
 * __useRecordStageStartMutation__
 *
 * To run a mutation, you first call `useRecordStageStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordStageStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordStageStartMutation, { data, loading, error }] = useRecordStageStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordStageStartMutation(baseOptions?: Apollo.MutationHookOptions<RecordStageStartMutation, RecordStageStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordStageStartMutation, RecordStageStartMutationVariables>(RecordStageStartDocument, options);
      }
export type RecordStageStartMutationHookResult = ReturnType<typeof useRecordStageStartMutation>;
export type RecordStageStartMutationResult = Apollo.MutationResult<RecordStageStartMutation>;
export type RecordStageStartMutationOptions = Apollo.BaseMutationOptions<RecordStageStartMutation, RecordStageStartMutationVariables>;