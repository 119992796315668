export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AddParticipantResult = {
  __typename?: 'AddParticipantResult';
  rejectedUids: Array<Scalars['String']>;
};

export type AdminMember = {
  __typename?: 'AdminMember';
  uid: Scalars['String'];
  email: Scalars['String'];
  displayName: Scalars['String'];
};

export type AdminResetInput = {
  eventId: Scalars['ID'];
  stageId: Scalars['String'];
  uid: Scalars['String'];
};

export type AdminRoleInput = {
  uid: Scalars['String'];
  role: Scalars['String'];
};

export type AdminUpdateUserInfoInput = {
  uid: Scalars['String'];
  orgId: Scalars['String'];
  startYear?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type Answer1 = {
  eventId: Scalars['ID'];
  answer: Scalars['String'];
};

export type Answer2 = {
  eventId: Scalars['ID'];
  answer: Scalars['String'];
};

export type Answer3 = {
  eventId: Scalars['ID'];
  problemId: Scalars['Int'];
  answer: Scalars['String'];
};

export type Answer4 = {
  eventId: Scalars['ID'];
  problemId: Scalars['Int'];
  time: Scalars['Int'];
};

export type Answer5 = {
  problemId: Scalars['Int'];
  eventId: Scalars['ID'];
  groupId: Scalars['ID'];
  answer: Scalars['String'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum CommonRoleType {
  RiddlerAdmin = 'RIDDLER_ADMIN',
  DentsuAdmin = 'DENTSU_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN'
}

export type CreateErrorResult = {
  __typename?: 'CreateErrorResult';
  reportId: Scalars['String'];
};

export type CreateEventInput = {
  name: Scalars['String'];
  scheduledStartTime: Scalars['Int'];
  scheduledEndTime: Scalars['Int'];
  place?: Maybe<Scalars['String']>;
  participantUids: Array<Scalars['String']>;
  orgId: Scalars['String'];
};

export type CreateEventResult = {
  __typename?: 'CreateEventResult';
  eventId: Scalars['String'];
  rejectedUids: Array<Scalars['String']>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  admin: Array<Maybe<Scalars['String']>>;
  members: Array<Maybe<Scalars['String']>>;
  imgSrc?: Maybe<Scalars['String']>;
};

export type CreateOrganizationResult = {
  __typename?: 'CreateOrganizationResult';
  orgId: Scalars['ID'];
};

export type CustomClaim = {
  __typename?: 'CustomClaim';
  orgs: Array<CustomClaimItem>;
  commonRoles: Array<CommonRoleType>;
};

export type CustomClaimItem = {
  __typename?: 'CustomClaimItem';
  orgId: Scalars['String'];
  roles: Array<OrgRoleType>;
};

export type EmailInput = {
  email?: Maybe<Scalars['String']>;
};

export type ErrorPayload = {
  errorCode: Scalars['String'];
  storeDump: Scalars['String'];
  userAgent: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  stackTrace?: Maybe<Scalars['String']>;
  gqlErrors?: Maybe<Array<GqlError>>;
  gqlPayload?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  eventId: Scalars['ID'];
  name: Scalars['String'];
  status: EventStatus;
  scheduledStartTime?: Maybe<Scalars['Int']>;
  scheduledEndTime?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  startedOn?: Maybe<Scalars['Int']>;
  endedOn?: Maybe<Scalars['Int']>;
  resultCalculatedOn?: Maybe<Scalars['Int']>;
};

export type EventInformationInput = {
  eventId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  scheduledStartTime?: Maybe<Scalars['Int']>;
  scheduledEndTime?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
};

export type EventInput = {
  eventId: Scalars['ID'];
};

export enum EventStatus {
  Preparing = 'PREPARING',
  Ongoing = 'ONGOING',
  Ending = 'ENDING',
  Ended = 'ENDED',
  Removed = 'REMOVED'
}

export type EventStatusInput = {
  eventId: Scalars['ID'];
  status: EventStatus;
};

export type GeneralExecutionResult = {
  __typename?: 'GeneralExecutionResult';
  success: Scalars['Boolean'];
};

export type GenerateRegistrationTokenInput = {
  orgId: Scalars['ID'];
  options: GenerateRegistrationTokenOptions;
  adminRole: Scalars['String'];
};

export type GenerateRegistrationTokenOptions = {
  expectedEmailRegex?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  expiresIn: Scalars['Int'];
};

export type GetParticipantDisplayNameInput = {
  eventId: Scalars['String'];
  uid: Scalars['String'];
};

export type GetParticipantUserDataInput = {
  eventId: Scalars['String'];
  uid: Scalars['String'];
};

export type GetRegistrationTokenInput = {
  orgId: Scalars['ID'];
  options?: Maybe<GetRegistrationTokenOptions>;
};

export type GetRegistrationTokenOptions = {
  createdBy?: Maybe<Scalars['ID']>;
};

export type GqlError = {
  locations?: Maybe<Array<Location>>;
  message: Scalars['String'];
  path?: Maybe<Array<Scalars['String']>>;
  code?: Maybe<Scalars['String']>;
};

export type GroupInfo = {
  __typename?: 'GroupInfo';
  eventId: Scalars['ID'];
  searchId: Scalars['Int'];
  groupDocId: Scalars['String'];
};

export type GroupInput = {
  eventId: Scalars['ID'];
  groupId: Scalars['ID'];
};

export type GroupMember = {
  __typename?: 'GroupMember';
  uid: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GroupMembersInfo = {
  __typename?: 'GroupMembersInfo';
  eventId: Scalars['ID'];
  searchId: Scalars['Int'];
  groupDocId: Scalars['String'];
  members: Array<Scalars['String']>;
  state: GroupState;
};

export enum GroupState {
  Initializing = 'INITIALIZING',
  Preparetostart = 'PREPARETOSTART',
  Polling = 'POLLING',
  Ongoing = 'ONGOING',
  Showinganswer = 'SHOWINGANSWER',
  Preparetonextset = 'PREPARETONEXTSET',
  Finished = 'FINISHED'
}

export type IncrementPenalty4Response = {
  __typename?: 'IncrementPenalty4Response';
  eventId: Scalars['ID'];
  penalty: Scalars['Int'];
};

export type InfoInput = {
  eventId: Scalars['ID'];
  infoId: Scalars['Int'];
  answer: Array<Scalars['String']>;
};

export type JoinGroupInput = {
  eventId: Scalars['ID'];
  searchId: Scalars['Int'];
};

export type Location = {
  column: Scalars['Int'];
  line: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createHoge?: Maybe<Scalars['String']>;
  removeAdminRole: GeneralExecutionResult;
  deleteUser: GeneralExecutionResult;
  adminUpdateUserInfo?: Maybe<GeneralExecutionResult>;
  registerUserInfo?: Maybe<GeneralExecutionResult>;
  updateUserInfo?: Maybe<GeneralExecutionResult>;
  updateEmail?: Maybe<GeneralExecutionResult>;
  sendRegisterEmail?: Maybe<GeneralExecutionResult>;
  sendUpdateEmail?: Maybe<GeneralExecutionResult>;
  confirmUpdateEmail?: Maybe<GeneralExecutionResult>;
  changeToVerifiedEmail?: Maybe<GeneralExecutionResult>;
  cancelUpdateEmail?: Maybe<GeneralExecutionResult>;
  createEvent: CreateEventResult;
  addParticipant: AddParticipantResult;
  removeParticipant: GeneralExecutionResult;
  changeEventStatus: GeneralExecutionResult;
  changeEventInformation: GeneralExecutionResult;
  recordStageStart: GeneralExecutionResult;
  adminStageReset: GeneralExecutionResult;
  submitAnswer1?: Maybe<SubmitAnswer1Response>;
  submitAnswer2Info?: Maybe<Scalars['Boolean']>;
  submitAnswer2?: Maybe<SubmitAnswer2Response>;
  submitAnswer3?: Maybe<SubmitAnswer3Response>;
  incrementPenalty4?: Maybe<IncrementPenalty4Response>;
  submitAnswer4?: Maybe<SubmitAnswer4Response>;
  createGroup: GroupInfo;
  joinGroup: GroupInfo;
  dissolveGroup: GroupInfo;
  startGroup: GeneralExecutionResult;
  startProblem: GeneralExecutionResult;
  acceptStartRequest: GeneralExecutionResult;
  submitAnswer5: GeneralExecutionResult;
  createOrganization?: Maybe<CreateOrganizationResult>;
  updateOrganization?: Maybe<CreateOrganizationResult>;
  deleteOrganization?: Maybe<CreateOrganizationResult>;
  addAdmin?: Maybe<Organization>;
  removeAdmin?: Maybe<Organization>;
  addMember?: Maybe<Organization>;
  removeMember?: Maybe<Organization>;
  recordError: CreateErrorResult;
  generateRegistrationToken: Token;
  revokeRegistrationToken: GeneralExecutionResult;
  register: UserId;
};


export type MutationRemoveAdminRoleArgs = {
  input: AdminRoleInput;
};


export type MutationDeleteUserArgs = {
  input: UserDeleteInput;
};


export type MutationAdminUpdateUserInfoArgs = {
  input: AdminUpdateUserInfoInput;
};


export type MutationRegisterUserInfoArgs = {
  input: RegisterUserInfoInput;
};


export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfoInput;
};


export type MutationUpdateEmailArgs = {
  input: EmailInput;
};


export type MutationSendRegisterEmailArgs = {
  input: RegisterEmailInput;
};


export type MutationSendUpdateEmailArgs = {
  input: SendUpdateEmailInput;
};


export type MutationConfirmUpdateEmailArgs = {
  input: EmailInput;
};


export type MutationChangeToVerifiedEmailArgs = {
  input: EmailInput;
};


export type MutationCancelUpdateEmailArgs = {
  input: EmailInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationAddParticipantArgs = {
  input: ParticipantInput;
};


export type MutationRemoveParticipantArgs = {
  input: ParticipantInput;
};


export type MutationChangeEventStatusArgs = {
  input: EventStatusInput;
};


export type MutationChangeEventInformationArgs = {
  input: EventInformationInput;
};


export type MutationRecordStageStartArgs = {
  input: StartInput;
};


export type MutationAdminStageResetArgs = {
  input: AdminResetInput;
};


export type MutationSubmitAnswer1Args = {
  input: Answer1;
};


export type MutationSubmitAnswer2InfoArgs = {
  input: InfoInput;
};


export type MutationSubmitAnswer2Args = {
  input: Answer2;
};


export type MutationSubmitAnswer3Args = {
  input: Answer3;
};


export type MutationIncrementPenalty4Args = {
  input: Penalty4;
};


export type MutationSubmitAnswer4Args = {
  input: Answer4;
};


export type MutationCreateGroupArgs = {
  input: EventInput;
};


export type MutationJoinGroupArgs = {
  input: JoinGroupInput;
};


export type MutationDissolveGroupArgs = {
  input: JoinGroupInput;
};


export type MutationStartGroupArgs = {
  input: GroupInput;
};


export type MutationStartProblemArgs = {
  input: GroupInput;
};


export type MutationAcceptStartRequestArgs = {
  input: GroupInput;
};


export type MutationSubmitAnswer5Args = {
  input: Answer5;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationAddAdminArgs = {
  input: OrganizationAdminInput;
};


export type MutationRemoveAdminArgs = {
  input: OrganizationAdminInput;
};


export type MutationAddMemberArgs = {
  input: OrganizationMemberInput;
};


export type MutationRemoveMemberArgs = {
  input: OrganizationMemberInput;
};


export type MutationRecordErrorArgs = {
  input: ErrorPayload;
};


export type MutationGenerateRegistrationTokenArgs = {
  input: GenerateRegistrationTokenInput;
};


export type MutationRevokeRegistrationTokenArgs = {
  input: RevokeRegistrationTokenInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};

export enum OrgRoleType {
  Admin = 'ADMIN',
  Player = 'PLAYER'
}

export type Organization = {
  __typename?: 'Organization';
  orgId: Scalars['ID'];
  name: Scalars['String'];
  admin: Array<Maybe<Scalars['String']>>;
  players: Array<Maybe<Scalars['String']>>;
  members: Array<Maybe<Scalars['String']>>;
  imgSrc?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Int']>;
};

export type OrganizationAdminInput = {
  orgId: Scalars['ID'];
  admin: Array<Maybe<Scalars['String']>>;
};

export type OrganizationInput = {
  orgId: Scalars['ID'];
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  uid: Scalars['String'];
  email: Scalars['String'];
  displayName: Scalars['String'];
  fullName: Scalars['String'];
  commonRoles: Array<Maybe<Scalars['String']>>;
  orgAdmin: Array<Maybe<Scalars['String']>>;
  ruby: Scalars['String'];
  enable: Scalars['Boolean'];
};

export type OrganizationMemberInput = {
  orgId: Scalars['ID'];
  member: Array<Maybe<Scalars['String']>>;
};

export type ParticipantInput = {
  eventId: Scalars['ID'];
  participantUids: Array<Scalars['String']>;
};

export type ParticipantUidLookupInput = {
  eventId: Scalars['String'];
  email: Scalars['String'];
};

export type Penalty4 = {
  eventId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** A simple type for getting started! */
  hello?: Maybe<Scalars['String']>;
  authTest?: Maybe<Scalars['String']>;
  getAdminMembers: Array<AdminMember>;
  getUser: User;
  getCustomClaim: CustomClaim;
  getEvent: Event;
  getParticipantUserData: UserData;
  getParticipantDisplayName?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  lookupParticipantUid?: Maybe<Scalars['String']>;
  getAllStage1Targets: Array<Stage1Target>;
  getGroupMembers: Array<GroupMember>;
  getFinishedStage5Groups: Array<GroupInfo>;
  getAdminAllFinishedStage5Groups: Array<GroupMembersInfo>;
  getAdminAllStage5Groups: Array<GroupMembersInfo>;
  getOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getOrganization?: Maybe<Organization>;
  getOrganizationMembers: Array<OrganizationMember>;
  getRegistrationTokens: TokenIds;
  validateRegistrationToken: OrgName;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QueryGetCustomClaimArgs = {
  uid: Scalars['String'];
};


export type QueryGetEventArgs = {
  input: EventInput;
};


export type QueryGetParticipantUserDataArgs = {
  input: GetParticipantUserDataInput;
};


export type QueryGetParticipantDisplayNameArgs = {
  input: GetParticipantDisplayNameInput;
};


export type QueryLookupParticipantUidArgs = {
  input: ParticipantUidLookupInput;
};


export type QueryGetGroupMembersArgs = {
  input: GroupInput;
};


export type QueryGetFinishedStage5GroupsArgs = {
  input: EventInput;
};


export type QueryGetAdminAllFinishedStage5GroupsArgs = {
  input: EventInput;
};


export type QueryGetAdminAllStage5GroupsArgs = {
  input: EventInput;
};


export type QueryGetOrganizationArgs = {
  input: OrganizationInput;
};


export type QueryGetOrganizationMembersArgs = {
  input: OrganizationInput;
};


export type QueryGetRegistrationTokensArgs = {
  input: GetRegistrationTokenInput;
};


export type QueryValidateRegistrationTokenArgs = {
  input: ValidateRegistrationTokenInput;
};

export type RegisterEmailInput = {
  email: Scalars['String'];
  orgId: Scalars['String'];
  fullName: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
  fullName: Scalars['String'];
};

export type RegisterUserInfoInput = {
  orgId: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  ruby: Scalars['String'];
  displayName: Scalars['String'];
  birthday: Scalars['String'];
  startYear: Scalars['Int'];
  department?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type RevokeRegistrationTokenInput = {
  tokenId: Scalars['String'];
};

export type SendUpdateEmailInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type Stage1Target = {
  __typename?: 'Stage1Target';
  targetId: Scalars['Int'];
  name: Scalars['String'];
  score: Scalars['Int'];
};

export type StartInput = {
  eventId: Scalars['ID'];
  stageId: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type TokenIds = {
  __typename?: 'TokenIds';
  tokenIds: Array<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  orgId: Scalars['ID'];
  name: Scalars['String'];
  admin: Array<Maybe<Scalars['String']>>;
  members: Array<Maybe<Scalars['String']>>;
  imgSrc?: Maybe<Scalars['String']>;
};

export type UpdateUserInfoInput = {
  orgId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  ruby?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  /** A simple type for getting started! */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserData = {
  __typename?: 'UserData';
  uid: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserDeleteInput = {
  uid: Scalars['String'];
  orgId: Scalars['String'];
};

export type UserId = {
  __typename?: 'UserId';
  uid: Scalars['String'];
};

export type ValidateRegistrationTokenInput = {
  token: Scalars['String'];
};

export type OrgName = {
  __typename?: 'orgName';
  organizationName: Scalars['String'];
};

export type SubmitAnswer1Response = {
  __typename?: 'submitAnswer1Response';
  achieved: Array<Stage1Target>;
};

export type SubmitAnswer2Response = {
  __typename?: 'submitAnswer2Response';
  correct?: Maybe<Scalars['Boolean']>;
};

export type SubmitAnswer3Response = {
  __typename?: 'submitAnswer3Response';
  score?: Maybe<Scalars['Int']>;
  correct: Scalars['Boolean'];
};

export type SubmitAnswer4Response = {
  __typename?: 'submitAnswer4Response';
  eventId: Scalars['ID'];
  problemId: Scalars['Int'];
  time: Scalars['Int'];
};
