import styled from 'styled-components';

import Colors from '../../styles/colors';

interface WindowProps {
  variant?: 'light' | 'dark';
}

const Window = styled.div`
  background-color: ${(p: WindowProps) =>
    p.variant === 'dark' ? Colors.gray8_op090 : Colors.gray4_op025};
  color: ${(p: WindowProps) =>
    p.variant === 'dark' ? Colors.white : 'inherit'};
`;

export default Window;
