import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { HandleFirebaseErrorResult } from '../../lib/firebase/firebaseErrorHandlers';
import useFirebaseError from '../../lib/firebase/useFirebaseError';
import {
  Validators,
  emailFormatValidator,
  pwFormatValidator,
  useValidator,
} from '../../lib/useValidator';
import SignUpImg from '../../static/png/Header_Signup.png';
import { colorWithAlpha } from '../../styles/colors';
import Colors from '../../styles/colors';
import CheckBox from '../uiElements/CheckBox';
import CommonBG from '../uiElements/CommonBG';
import ErrorText from '../uiElements/ErrorText';
import { HeaderOptions } from '../uiElements/Header';
import Input from '../uiElements/input/Input';
import {
  LoginButton,
  LoginSectionItem,
  LoginSectionTitle,
  PasswordNoticeText,
} from './LoginView';

const headerOptions: HeaderOptions = {
  buttonType: 'toLogin',
};

interface RegisterViewProps {
  orgName: string;
  error: HandleFirebaseErrorResult | null;
  onRegister(email: string, pw: string, name: string): void;
}
const RegisterView: React.FC<RegisterViewProps> = props => {
  const { orgName } = props;

  const [confirmRule, setConfirmRule] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [pw, setPw] = React.useState<string>('');

  const { emailError, pwError, otherError, resetError } = useFirebaseError(
    props.error
  );

  const emailOnChange = React.useCallback(
    (val: string) => {
      setEmail(prev => {
        if (prev !== val) {
          resetError('email');
        }
        return val;
      });
    },
    [resetError]
  );
  const pwOnChange = React.useCallback(
    (val: string) => {
      setPw(prev => {
        if (prev !== val) {
          resetError('pw');
        }
        return val;
      });
    },
    [resetError]
  );

  const emailValidators = React.useMemo<Validators>(
    () => [
      [() => !!emailError, emailError ?? ''],
      [(val, state) => val === '' || state.isFocused],
      emailFormatValidator,
    ],
    [emailError]
  );
  const [emailValidator, emailIsValid] = useValidator(emailValidators);

  const pwValidators = React.useMemo<Validators>(
    () => [
      [() => !!pwError, pwError ?? ''],
      [val => val === ''],
      pwFormatValidator,
    ],
    [pwError]
  );
  const [pwValidator, pwIsValid] = useValidator(pwValidators);

  const onRegister = React.useCallback(() => {
    props.onRegister(email, pw, name);
  }, [email, name, props, pw]);

  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <TopBar>
        <HeaderTitle src={SignUpImg} />
        <p>アカウントを作成してください。</p>
      </TopBar>
      <Form>
        <RegisterSectionItem>
          <LoginSectionTitle>所属</LoginSectionTitle>
          <Input value={orgName} readOnly disabled />
        </RegisterSectionItem>
        <RegisterSectionItem>
          <LoginSectionTitle>氏名</LoginSectionTitle>
          <Input autoComplete='username' value={name} onChange={setName} />
        </RegisterSectionItem>
        <RegisterSectionItem>
          <LoginSectionTitle>メールアドレス</LoginSectionTitle>
          <Input
            autoComplete='email'
            value={email}
            onChange={emailOnChange}
            validator={emailValidator}
          />
        </RegisterSectionItem>
        <RegisterSectionItem>
          <LoginSectionTitle>パスワード</LoginSectionTitle>
          <PasswordNoticeText>8文字以上で入力してください</PasswordNoticeText>
          <Input
            autoComplete='new-password'
            type='password'
            value={pw}
            onChange={pwOnChange}
            validator={pwValidator}
          />
        </RegisterSectionItem>
        <RegisterSectionItem>
          <Terms>
            <p>
              <a
                onClick={() => {
                  window.open(
                    '/dialog/term',
                    '',
                    'location=no, width=890, height=480'
                  );
                }}
              >
                利用規約
              </a>
              をご確認の上、ご登録ください。
            </p>
            <CheckBox
              id='store-identity'
              label='利用規約に同意する'
              onChange={val => {
                setConfirmRule(val);
              }}
            />
          </Terms>
        </RegisterSectionItem>
        <RegisterButton
          size='large'
          color='positive'
          onClick={onRegister}
          disabled={!emailIsValid || !pwIsValid || !confirmRule}
        >
          登録
        </RegisterButton>
        {otherError && <ErrorText>{otherError}</ErrorText>}
      </Form>
    </CommonBG>
  );
};

const Terms = styled.div`
  background: ${Colors.gray4_op025};
  padding: 1rem 1.2rem;
  text-align: center;
  font-size: 1.4rem;

  > div {
    display: flex;
    justify-content: center;
  }

  p {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
  }

  a {
    cursor: pointer;
    font-weight: bold;
    color: ${Colors.primary};
  }
`;

const Form = styled.div`
  width: 35rem;
  max-width: 90rem;
  margin: 5rem auto;
`;

const RegisterSectionItem = styled(LoginSectionItem)`
  margin: 0 auto;
  width: 32rem;
`;

const RegisterButton = styled(LoginButton)`
  margin-top: 5rem;
`;

const HeaderTitle = styled.img`
  width: 19.2rem;
  height: 3.2rem;
  margin-bottom: 1rem;
`;

const TopBar = styled.div`
  margin: 0rem auto;
  background: ${Colors.gray4_op025};
  text-align: center;
  padding: 3rem 0;
  margin-top: 1rem;
  margin-bottom: 5rem;

  p {
    font-size: 2.4rem;
    font-weight: bold;
  }
`;

export default RegisterView;
