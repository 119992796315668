import { Styles } from 'react-select';

import Colors from '../../../../styles/colors';

const containerStyle = {
  width: '10.5rem',
  height: '3.7rem',
  display: 'inline-block',
  borderRadius: 0,
  margin: '0px 0.6rem',
  verticalAlign: 'middle',
};

const containerLastStyle = {
  ...containerStyle,
  width: '16.1rem',
  height: '4.5rem',
};

const controlStyle = {
  borderRadius: 5,
  borderWidth: 2,
};

const controlLastStyle = {
  ...controlStyle,
  minHeight: '4.5rem',
};

export const selectStyles: Styles<any, false> = {
  container: provided => ({
    ...provided,
    ...containerStyle,
  }),
  control: provided => ({
    ...provided,
    ...controlStyle,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: Colors.gray8,
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '400px',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected
      ? Colors.primary
      : isFocused
      ? Colors.highlight
      : 'transparent',
    textAlign: 'left',
    fontSize: '1.8rem',
    fontWeight: 600,
  }),
  singleValue: provided => {
    return {
      ...provided,
      fontSize: '1.8rem',
      fontWeight: 600,
    };
  },
};

export const selectIncorrectStyles: Styles<any, false> = {
  ...selectStyles,
  control: provided => ({
    ...provided,
    ...controlStyle,
    backgroundColor: Colors.highlightError,
  }),
};

export const selectLastStyles: Styles<any, false> = {
  ...selectStyles,
  container: provided => ({
    ...provided,
    ...containerLastStyle,
    marginRight: '1rem',
    marginBottom: '1.4rem',
  }),
  control: provided => ({
    ...provided,
    ...controlLastStyle,
  }),
};
