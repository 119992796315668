import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../../../styles/colors';

interface SortTHProps {
  sortKey: string;
  enable?: boolean;
  currentSortObj: any;
  onClick?: (sortObj: any) => void;
  disable?: boolean; // 操作できない見出し
}

const SortTH: React.FC<SortTHProps> = props => {
  return (
    <STH
      enable={props.currentSortObj.key == props.sortKey}
      disable={props.disable}
      onClick={() => {
        if (props.disable === true) return;
        if (props.onClick === undefined) return;

        if (props.sortKey == props.currentSortObj.key) {
          if (props.currentSortObj.sort == 'ASC') {
            props.onClick({ key: props.sortKey, sort: 'DESC' });
          } else {
            props.onClick({ key: props.sortKey, sort: 'ASC' });
          }
        } else {
          props.onClick({ key: props.sortKey, sort: 'ASC' });
        }
      }}
    >
      {props.children}

      {props.currentSortObj.key == props.sortKey && (
        <>
          {props.currentSortObj.sort == 'ASC' && (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
          {props.currentSortObj.sort == 'DESC' && (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </>
      )}
    </STH>
  );
};

interface THProps {
  enable?: boolean;
  disable?: boolean;
}

const STH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;

  ${(p: THProps) =>
    p.enable === true && p.disable !== true
      ? `
        color: ${Colors.primary};
      `
      : `
  `}

  ${(p: THProps) =>
    p.disable !== true
      ? `
        cursor: pointer;
      `
      : `
  `}

  svg {
    margin-left: 0.6rem;
  }
`;

export default SortTH;
