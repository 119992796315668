import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { isPropertySignature } from 'typescript';

import Colors from '../../styles/colors';

interface PaginationProps {
  onNext: () => void;
  onPrev: () => void;
  count: number;
  begin: number;
  end: number;
}

const Pagination: React.FC<PaginationProps> = props => {
  return (
    <Wrap>
      <p>
        {props.count}件中{Math.min(props.begin + 1, props.count)}-{props.end}
        件目を表示中
      </p>
      <BtnWrap>
        <Btn enable={props.begin > 0} onClick={props.onPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>前へ</span>
        </Btn>
        <Btn enable={props.end < props.count} onClick={props.onNext}>
          <span>次へ</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Btn>
      </BtnWrap>
    </Wrap>
  );
};
const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  p {
    font-size: 1.3rem;
    margin-right: 2rem;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface BtnProps {
  enable: boolean;
}

const Btn = styled.div`
  font-size: 1.3rem;
  display: block;
  transition: 0.2s;
  align-items: center;
  display: flex;

  span {
    padding: 0.2rem 0.6rem;
  }

  svg {
    width: 2rem !important;
    height: 2rem !important;
  }

  &:first-child {
    border-right: 1px solid black;
    padding-right: 0.4rem;
  }
  &:nth-child(2) {
    padding-left: 0.4rem;
  }

  ${(p: BtnProps) =>
    p.enable === true
      ? `
        cursor: pointer;
        &:hover {
          color: ${Colors.primary};
        }
      `
      : `
      opacity: 0.4;
      pointer-events: none;
  `}
`;

export default Pagination;
