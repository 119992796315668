import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { UserInfo, UserOrgInfo } from '../../../api/firestoreTypes';
import {
  displayNameValidators,
  employeeIdValidators,
  fullNameValidators,
  rubyValidators,
  startYearValidators,
} from '../../../lib/profileValidator';
import { profileFormData } from '../../../lib/userProfiles';
import { Validators, useValidator } from '../../../lib/useValidator';
import HeaderWelcome from '../../../static/png/Header_Welcome.png';
import SettingsForm from '../../uiElements/form/Form';
import FormSubmitButton from '../../uiElements/form/FormSubmitButton';
import { FormElement } from '../../uiElements/form/formTypes';
import TextWindow from '../../uiElements/TextWindow';
import SettingsItemBody from '../SettingsItemBody';
import SettingsItemTitle from '../SettingsItemTitle';

const defaultDate = new Date('1990-01-01T12:00:00Z');

type Data = {
  displayName: string;
  fullName: string;
  ruby: string;
  birthday: Date;
  startYear: number;
  department: string;
  employeeId: string;
  industryId: string;
  occupationId: string;
};

interface ProfileSettingsViewProps {
  currentDisplayName: string | undefined | null;
  currentUserInfo: UserInfo | undefined;
  currentOrgInfo: UserOrgInfo | undefined;
  onSend: (data: Data) => void;
  welcome?: boolean;
}
const ProfileSettingsView: React.FC<ProfileSettingsViewProps> = props => {
  const { currentOrgInfo, currentUserInfo, currentDisplayName } = props;
  const [fullName, setFullName] = React.useState('');
  const [ruby, setRuby] = React.useState('');
  const [displayName, setDisplayName] = React.useState(
    currentDisplayName ?? ''
  );
  const [birthday, setBirthday] = React.useState(defaultDate);
  const [startYear, setStartYear] = React.useState('2020');
  const [department, setDepartment] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [industryId, setIndustryId] = React.useState('');
  const [occupationId, setOccupationId] = React.useState('');

  React.useMemo(() => {
    const c = currentUserInfo;

    if (c?.ruby) {
      setRuby(c.ruby);
    }
    if (c?.fullName) {
      setFullName(c.fullName);
    }
    if (c?.birthday) {
      setBirthday(c.birthday.toDate());
    }
    if (c?.displayName) {
      setDisplayName(c.displayName);
    }
  }, [currentUserInfo]);

  React.useMemo(() => {
    const c = currentOrgInfo;

    if (c?.department) {
      setDepartment(c.department);
    }
    if (c?.employeeId) {
      setEmployeeId(c.employeeId);
    }
    if (c?.startYear) {
      setStartYear(`${c.startYear}`);
    }
    if (c?.industryId) {
      setIndustryId(`${c.industryId}`);
    }
    if (c?.occupationId) {
      setOccupationId(`${c.occupationId}`);
    }
  }, [currentOrgInfo]);

  const onSend = React.useCallback(() => {
    props.onSend({
      displayName,
      fullName,
      ruby,
      birthday,
      startYear: Number(startYear),
      department,
      employeeId,
      industryId,
      occupationId,
    });
  }, [
    props,
    displayName,
    fullName,
    ruby,
    birthday,
    startYear,
    employeeId,
    department,
    industryId,
    occupationId,
  ]);

  const [fullNameValidator, fullNameIsValid] = useValidator(
    useMemo<Validators>(fullNameValidators, [])
  );

  const [startYearValidator, startYearIsValid] = useValidator(
    useMemo<Validators>(startYearValidators, [])
  );

  const [rubyValidator, rubyIsValid] = useValidator(
    useMemo<Validators>(rubyValidators, [])
  );

  const [displayNameValidator, displayNameIsValid] = useValidator(
    useMemo<Validators>(displayNameValidators, [])
  );

  const [employeeIdValidator, employeeIdIsValid] = useValidator(
    useMemo<Validators>(employeeIdValidators, [])
  );

  const formElements: FormElement[] = [
    {
      ...profileFormData.fullName,
      value: fullName,
      onChange: setFullName,
      validator: fullNameValidator,
    },
    {
      ...profileFormData.ruby,
      value: ruby,
      onChange: setRuby,
      validator: rubyValidator,
    },
    {
      ...profileFormData.displayName,
      value: displayName,
      onChange: setDisplayName,
      validator: displayNameValidator,
    },
    {
      ...profileFormData.birthDay,
      value: birthday,
      onChange: setBirthday,
    },
    {
      ...profileFormData.startYear,
      value: startYear,
      onChange: setStartYear,
      validator: startYearValidator,
    },
    {
      ...profileFormData.department,
      value: department,
      onChange: setDepartment,
    },
    {
      ...profileFormData.employeeId,
      value: employeeId,
      onChange: setEmployeeId,
      validator: employeeIdValidator,
    },
    {
      ...profileFormData.industry,
      value: industryId,
      onChange: setIndustryId,
    },
    {
      ...profileFormData.occupation,
      value: occupationId,
      onChange: setOccupationId,
    },
  ];
  return (
    <>
      {props.welcome !== true && (
        <SettingsItemTitle>{'プロフィールの変更'}</SettingsItemTitle>
      )}
      {props.welcome === true && (
        <Wrapper>
          <Window>
            <HeaderTitle src={HeaderWelcome} />
            <p>まずはあなたのプロフィールを登録しましょう。</p>
          </Window>
        </Wrapper>
      )}

      <SettingsItemBody>
        <SettingsForm elements={formElements} />
        <FormSubmitButton
          variant='main'
          color='positive'
          size='large'
          disabled={
            !fullNameIsValid ||
            !rubyIsValid ||
            !displayNameIsValid ||
            !startYearIsValid ||
            !employeeIdIsValid
          }
          onClick={onSend}
        >
          {props.welcome ? '登録' : '変更'}
        </FormSubmitButton>
      </SettingsItemBody>

      <Margin />
    </>
  );
};

const HeaderTitle = styled.img`
  width: 22.6rem;
  height: 3.2rem;
`;

const Window = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 2rem;
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 1rem;
`;

const Margin = styled.img`
  height: 3rem;
`;

export default ProfileSettingsView;
