import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useCurrentUser } from '../../redux/selectors/authSelectors';
import ResultDetail from '../eventProvider/eventResultPage/ResultDetail';
import CommonBG from '../uiElements/CommonBG';
import ProfileBar from '../uiElements/ProfileBar';

interface HistoryResultProps {
  prevEventId: string | null;
  nextEventId: string | null;
}
const HistoryResult: React.FC<HistoryResultProps> = props => {
  const history = useHistory();
  const user = useCurrentUser();
  const eventId =
    useRouteMatch<{ eventId: string }>('/history/:eventId')?.params.eventId ??
    null;
  const prevHref = props.prevEventId
    ? '/history/' + props.prevEventId
    : undefined;
  const nextHref = props.nextEventId
    ? '/history/' + props.nextEventId
    : undefined;
  React.useEffect(() => {
    if (eventId === null) {
      history.push('/history');
    }
  }, [eventId, history]);
  return (
    <CommonBG withHeader withFooter headerOptions={{ buttonType: 'logout' }}>
      <ProfileBar user={user} />
      <ResultDetail
        uid={user?.uid}
        eventId={eventId}
        listHref='/history'
        prefHref={prevHref}
        nextHref={nextHref}
      />
    </CommonBG>
  );
};

export default HistoryResult;
