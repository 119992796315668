import * as React from 'react';
import styled from 'styled-components';

import TermView from '../../components/settings/settingsItems/TermView';
import Colors from '../../styles/colors';
import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';

const headerOptions: HeaderOptions = {
  buttonType: 'close',
  hideHomeButton: true,
};

interface DialogTermViewProps {}
const DialogTermView: React.FC<DialogTermViewProps> = props => {
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <TermView />
      </Wrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  padding: 2rem 4rem;
`;

export default DialogTermView;
