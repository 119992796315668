import styled from 'styled-components';

import Colors from '../../styles/colors';

const SettingsItemTitle = styled.div`
  width: 100%;
  border-bottom: 0.2rem solid ${Colors.gray8};
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.gray8};
  padding-bottom: 1.5rem;
  margin-bottom: 3.2rem;
`;

export default SettingsItemTitle;
