import * as React from 'react';
import styled, { css } from 'styled-components';

import Colors from '../../../styles/colors';
import Spinner from '../Spinner';
import {
  ButtonSize,
  ButtonSizeStyleSet,
  ButtonStateStyleSet,
  ButtonStyleGenerator,
  SubButtonColorBasedDict,
  SubButtonProps,
} from './buttonConfigs';

const spinnerSizes: Record<ButtonSize, string> = {
  large: '4rem',
  medium: '2.6rem',
};

const SubButton: React.FC<SubButtonProps> = props => {
  return (
    <SubButtonWrapper {...props}>
      {props.children}
      {props.loading && (
        <SpinnerWrapper>
          <Spinner
            size={spinnerSizes[props.size]}
            color={Colors.white}
            widthRatio={0.2}
          />
        </SpinnerWrapper>
      )}
    </SubButtonWrapper>
  );
};

const styles: SubButtonColorBasedDict<ButtonStateStyleSet> = {
  positive: {
    default: css`
      background-color: ${Colors.primary};
      border-color: ${Colors.primary};
      color: ${Colors.white};
    `,
    hover: css`
      background-color: ${Colors.white};
      border-color: ${Colors.primary};
      color: ${Colors.primary};
    `,
    active: css`
      background-color: ${Colors.white};
      border-color: ${Colors.accent};
      color: ${Colors.accent};
    `,
  },
  neutral: {
    default: css`
      background-color: ${Colors.gray8};
      border-color: ${Colors.gray8};
      color: ${Colors.white};
    `,
    hover: css`
      background-color: ${Colors.white};
      border-color: ${Colors.gray8};
      color: ${Colors.gray8};
    `,
    active: css`
      background-color: ${Colors.white};
      border-color: ${Colors.gray5};
      color: ${Colors.gray5};
    `,
  },
  negative: {
    default: css`
      background-color: ${Colors.gray6};
      border-color: ${Colors.gray6};
      color: ${Colors.white};
    `,
    hover: css`
      background-color: ${Colors.white};
      border-color: ${Colors.gray6};
      color: ${Colors.gray6};
    `,
    active: css`
      background-color: ${Colors.white};
      border-color: ${Colors.gray4};
      color: ${Colors.gray4};
    `,
  },
};

const sizeStyles: ButtonSizeStyleSet = {
  large: css`
    height: 6rem;
    line-height: 5.3rem;
    min-width: 16rem;
    padding: 0 2.4rem;
    font-size: 2.8rem;
    border-radius: 1rem;
  `,
  medium: css`
    height: 4rem;
    line-height: 3.1rem;
    min-width: 8rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
    border-radius: 0.5rem;
  `,
};

const getStyle: ButtonStyleGenerator<SubButtonProps> = state => options => {
  return css`
    box-sizing: border-box;
    border-width: 0.5rem;
    border-style: solid;
    font-weight: bold;
    outline: 0;
    transition: 0.2s;
    position: relative;

    ${sizeStyles[options.size]}
    ${styles[options.color]?.[state]}

    ${options.loading &&
    `
      color: transparent;
    `}
  `;
};

const SubButtonWrapper = styled.button`
  border: none;

  ${getStyle('default')};

  &:hover {
    ${getStyle('hover')};
  }
  &:active {
    ${getStyle('active')};
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export default SubButton;
