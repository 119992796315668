import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
  useCombobox,
} from 'downshift';
import Fuse from 'fuse.js';
import * as React from 'react';
import styled from 'styled-components';

import { useOrganizationMembers } from '../../../api/organization';
import Colors from '../../../styles/colors';
import { AdminMember, OrganizationMember } from '../../../types';

export type OrgMember = Pick<
  OrganizationMember,
  | 'uid'
  | 'email'
  | 'displayName'
  | 'fullName'
  | 'commonRoles'
  | 'ruby'
  | 'orgAdmin'
>;

interface OrgMemberSelectorProps {
  orgMembers: OrgMember[] | null;
  adminMembers: OrgMember[] | null;
  currentParticipantUids: string[];
  isError?: boolean;
  onSelect: (item: OrgMember) => void;
  isAdmin: boolean;
  orgId: string;
}

const MAX_OPTIONS_COUNT = 4;

const OrgMemberSelector: React.FC<OrgMemberSelectorProps> = ({
  isError,
  adminMembers,
  orgMembers,
  onSelect,
  currentParticipantUids,
  isAdmin,
  orgId,
}) => {
  const origOrgMembers = React.useMemo(() => {
    return (isAdmin ? adminMembers : orgMembers) ?? [];
  }, [isAdmin, adminMembers, orgMembers]);

  const unincludedMembers = React.useMemo(() => {
    const ret = origOrgMembers.filter(
      mem => !currentParticipantUids.includes(mem.uid)
    );
    return ret;
  }, [currentParticipantUids, origOrgMembers]);

  const memberFuse = React.useMemo(
    () =>
      new Fuse<OrgMember>(unincludedMembers, {
        keys: ['email', 'displayName', 'fullName', 'ruby'],
      }),
    [unincludedMembers]
  );

  const [inputItems, setInputItems] =
    React.useState<OrgMember[]>(origOrgMembers);
  const onSelectedItemChange = React.useCallback(
    (change: UseComboboxStateChange<OrgMember>) =>
      change.selectedItem && onSelect(change.selectedItem),
    [onSelect]
  );

  const stateReducer = React.useCallback(
    (
      state: UseComboboxState<OrgMember>,
      actionChanges: UseComboboxStateChangeOptions<OrgMember>
    ): Partial<UseComboboxState<OrgMember>> => {
      const { type, changes } = actionChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            inputValue: '',
          };

        default:
          return changes;
      }
    },
    []
  );

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    onSelectedItemChange,
    stateReducer,
    onInputValueChange: ({ inputValue }) =>
      inputValue &&
      setInputItems(
        memberFuse
          .search(inputValue)
          .map(x => x.item)
          .slice(0, MAX_OPTIONS_COUNT)
      ),
  });

  const getAuthName = (item: OrgMember): string => {
    if (item.commonRoles.includes('riddlerAdmin')) {
      return 'RIDDLER';
    } else if (item.commonRoles.includes('dentsuAdmin')) {
      return '電通';
    } else if (item.orgAdmin.includes(orgId)) {
      return '企業管理者';
    }
    return '企業メンバー';
  };

  return (
    <OrgMemberSelectorWrapper>
      <InputBox {...getComboboxProps()}>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        <Input
          disabled={isError}
          placeholder={
            isError
              ? `${
                  isAdmin ? '管理者' : '参加者'
                }の読み込み中にエラーが発生しました。`
              : `${isAdmin ? '管理者' : '参加者'}を追加する`
          }
          {...getInputProps()}
        />
      </InputBox>

      <OptionList {...getMenuProps()} visible={isOpen}>
        {isOpen &&
          inputItems.map((item, index) => (
            <OptionItem
              key={item.uid}
              {...getItemProps({ item, index })}
              highlighted={highlightedIndex === index}
            >
              <OptionNameWrap isAdmin={isAdmin}>
                <OptionName>
                  {item.fullName === '' ? '(No Name)' : item.fullName}
                </OptionName>
                <OptionEmail>{item.email}</OptionEmail>
              </OptionNameWrap>
              {isAdmin && <OptionRole>{getAuthName(item)} </OptionRole>}
            </OptionItem>
          ))}

        {isOpen && inputItems.length === 0 && (
          <EmptyOptionItem>
            <OptionName>(該当なし)</OptionName>
            <OptionEmail></OptionEmail>
          </EmptyOptionItem>
        )}
      </OptionList>
    </OrgMemberSelectorWrapper>
  );
};

const OrgMemberSelectorWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const InputBox = styled.div`
  position: relative;

  span {
    position: absolute;
    color: ${Colors.gray6};
    display: block;
    margin-left: 7px;
    margin-top: 7px;

    svg {
      width: 20px !important;
      height: 20px;
    }
  }
`;

const Input = styled.input`
  font-size: 1.3rem;
  padding: 0.8rem 0.7rem;
  padding-left: 3rem !important;
  border: ${Colors.gray6} 2px solid;
  border-radius: 0.4rem;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: ${Colors.gray6};
  }
`;

interface OptionListProps {
  visible: boolean;
}
const OptionList = styled.ul`
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100%;
  list-style: none;
  background-color: ${Colors.white};
  z-index: 2;
  border: ${Colors.gray4} solid 1px;
  border-radius: 0.5rem;
  box-sizing: border-box;

  ${(p: OptionListProps) =>
    !p.visible &&
    `
    display: none;
  `}
`;

interface OptionItemProps {
  highlighted?: boolean;
}
const OptionItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 1.3rem;
  font-size: 1.2rem;
  color: ${Colors.gray8};

  display: flex;
  align-items: center;

  ${(p: OptionItemProps) =>
    p.highlighted &&
    `
    background-color: ${Colors.gray2};

  `}
`;
const EmptyOptionItem = styled(OptionItem)`
  color: ${Colors.gray4};
`;

interface IsAdminProps {
  isAdmin?: boolean;
}

const OptionNameWrap = styled.div`
  ${(p: IsAdminProps) =>
    p.isAdmin &&
    `
      flex: 70%;
  `}
`;

const OptionName = styled.div`
  font-size: 1.6rem;
`;
const OptionEmail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray8};
`;

const OptionRole = styled.div`
  flex: 30%;
  font-size: 1.6rem;
`;

export default OrgMemberSelector;
