import { OptionTypeBase, OptionsType } from 'react-select';

type Options = OptionsType<OptionTypeBase>;

interface Stage2ClueTextItem {
  type: 'text';
  id: string;
  content: string;
}

interface Stage2ClueSelectItem {
  type: 'select';
  id: string;
  // serverId: string;
  content: Options;
}

interface Stage2ClueInputItem {
  type: 'input';
  // serverId: string;
  id: string;
}

type Stage2ClueItem =
  | Stage2ClueTextItem
  | Stage2ClueSelectItem
  | Stage2ClueInputItem;

export interface Stage2Clue {
  id: string;
  items: Stage2ClueItem[];
  serverId: number;
}

interface Stage2Section {
  title: string;
  subTitle: string;
  clues: Stage2Clue[];
}

const timeOptions: Options = [
  '12:00',
  '12:10',
  '12:20',
  '12:30',
  '12:40',
  '12:50',
  '13:00',
].map(time => ({
  value: time,
  label: time,
}));

const nameOptions = ['灰原', '青木', '赤司', '桃瀬', '黄崎'].map(n => ({
  value: n,
  label: n,
}));

export const clues1: Stage2Clue[] = [
  {
    id: 'clue1-l1',
    serverId: 1,
    items: [
      {
        type: 'text',
        content: '101号室',
        id: 'clue1-l1-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue1-l1-2',
      },
    ],
  },
  {
    id: 'clue1-l2',
    serverId: 2,
    items: [
      {
        type: 'text',
        content: '102号室',
        id: 'clue1-l2-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue1-l2-2',
      },
    ],
  },
  {
    id: 'clue1-l3',
    serverId: 3,
    items: [
      {
        type: 'text',
        content: '103号室',
        id: 'clue1-l3-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue1-l3-2',
      },
    ],
  },
  {
    id: 'clue1-l4',
    serverId: 4,
    items: [
      {
        type: 'text',
        content: '104号室',
        id: 'clue1-l4-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue1-l4-2',
      },
    ],
  },
  {
    id: 'clue1-l5',
    serverId: 5,
    items: [
      {
        type: 'text',
        content: '105号室',
        id: 'clue1-l5-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue1-l5-2',
      },
    ],
  },
];

export const clues2: Stage2Clue[] = [
  {
    id: 'clue2-l1',
    serverId: 6,

    items: [
      {
        type: 'text',
        content: '灰原は',
        id: 'clue2-l1-1',
      },
      {
        type: 'select',
        content: timeOptions,
        id: 'clue2-l1-2',
      },
      {
        type: 'text',
        content: 'に展示室に行った。',
        id: 'clue2-l1-3',
      },
    ],
  },

  {
    id: 'clue2-l2',
    serverId: 7,

    items: [
      {
        type: 'text',
        content: '桃瀬が展示室に行った10分後に',
        id: 'clue2-l2-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue2-l2-2',
      },
      {
        type: 'text',
        content: 'が展示室に来た。',
        id: 'clue2-l2-3',
      },
    ],
  },

  {
    id: 'clue2-l3',
    serverId: 8,

    items: [
      {
        type: 'text',
        content: '青木が展示室に行った10分後に',
        id: 'clue2-l3-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue2-l3-2',
      },
      {
        type: 'text',
        content: 'が展示室に来た。',
        id: 'clue2-l3-3',
      },
    ],
  },

  {
    id: 'clue2-l4',
    serverId: 9,

    items: [
      {
        type: 'text',
        content: '赤司と黄崎の間に展示室に入室したのは',
        id: 'clue2-l4-1',
      },
      {
        type: 'select',
        content: nameOptions,
        id: 'clue2-l4-2',
      },
      {
        type: 'text',
        content: '。',
        id: 'clue2-l4-3',
      },
    ],
  },
];

export const section1: Stage2Section = {
  title: '1. それぞれの部屋は誰が使っている？',
  subTitle: '',
  clues: clues1,
};

export const section2: Stage2Section = {
  title: '2. 証言をもとに空欄を埋めていこう',
  subTitle: '',
  clues: clues2,
};

export const lastClueItems: [Stage2ClueTextItem, Stage2ClueSelectItem] = [
  {
    type: 'text',
    content: 'ダイヤを盗んだ犯人は',
    id: 'last-1',
  },
  {
    type: 'select',
    content: nameOptions,
    id: 'lasst-2',
  },
];

export const allSections = [section1, section2];
