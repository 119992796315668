import * as React from 'react';
import styled from 'styled-components';

interface GroupIdIndicatorProps {
  searchId: number;
}
const GroupIdIndicator: React.FC<GroupIdIndicatorProps> = ({ searchId }) => {
  return (
    <GroupIdIndicatorWrapper>グループID: {searchId}</GroupIdIndicatorWrapper>
  );
};

const GroupIdIndicatorWrapper = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-size: 1.3rem;
  z-index: 3;
`;

export default GroupIdIndicator;
