import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IncrementPenalty4MutationVariables = Types.Exact<{
  input: Types.Penalty4;
}>;


export type IncrementPenalty4Mutation = (
  { __typename?: 'Mutation' }
  & { incrementPenalty4?: Types.Maybe<(
    { __typename?: 'IncrementPenalty4Response' }
    & Pick<Types.IncrementPenalty4Response, 'eventId' | 'penalty'>
  )> }
);

export type SubmitAnswer4MutationVariables = Types.Exact<{
  input: Types.Answer4;
}>;


export type SubmitAnswer4Mutation = (
  { __typename?: 'Mutation' }
  & { submitAnswer4?: Types.Maybe<(
    { __typename?: 'submitAnswer4Response' }
    & Pick<Types.SubmitAnswer4Response, 'problemId'>
  )> }
);


export const IncrementPenalty4Document = gql`
    mutation incrementPenalty4($input: Penalty4!) {
  incrementPenalty4(input: $input) {
    eventId
    penalty
  }
}
    `;
export type IncrementPenalty4MutationFn = Apollo.MutationFunction<IncrementPenalty4Mutation, IncrementPenalty4MutationVariables>;

/**
 * __useIncrementPenalty4Mutation__
 *
 * To run a mutation, you first call `useIncrementPenalty4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementPenalty4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementPenalty4Mutation, { data, loading, error }] = useIncrementPenalty4Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncrementPenalty4Mutation(baseOptions?: Apollo.MutationHookOptions<IncrementPenalty4Mutation, IncrementPenalty4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncrementPenalty4Mutation, IncrementPenalty4MutationVariables>(IncrementPenalty4Document, options);
      }
export type IncrementPenalty4MutationHookResult = ReturnType<typeof useIncrementPenalty4Mutation>;
export type IncrementPenalty4MutationResult = Apollo.MutationResult<IncrementPenalty4Mutation>;
export type IncrementPenalty4MutationOptions = Apollo.BaseMutationOptions<IncrementPenalty4Mutation, IncrementPenalty4MutationVariables>;
export const SubmitAnswer4Document = gql`
    mutation SubmitAnswer4($input: Answer4!) {
  submitAnswer4(input: $input) {
    problemId
  }
}
    `;
export type SubmitAnswer4MutationFn = Apollo.MutationFunction<SubmitAnswer4Mutation, SubmitAnswer4MutationVariables>;

/**
 * __useSubmitAnswer4Mutation__
 *
 * To run a mutation, you first call `useSubmitAnswer4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswer4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswer4Mutation, { data, loading, error }] = useSubmitAnswer4Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAnswer4Mutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswer4Mutation, SubmitAnswer4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswer4Mutation, SubmitAnswer4MutationVariables>(SubmitAnswer4Document, options);
      }
export type SubmitAnswer4MutationHookResult = ReturnType<typeof useSubmitAnswer4Mutation>;
export type SubmitAnswer4MutationResult = Apollo.MutationResult<SubmitAnswer4Mutation>;
export type SubmitAnswer4MutationOptions = Apollo.BaseMutationOptions<SubmitAnswer4Mutation, SubmitAnswer4MutationVariables>;