import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as ErrorCode from '../../lib/errorCode';
import { ErrorPayload } from '../../types';
import { errorActions } from '../actions/errorActions';

export type ErrorReport = {
  errorCode: ErrorCode.T;
} & Omit<ErrorPayload, 'userAgent' | 'eventId'>;
export interface ErrorState {
  reportQueue: ErrorReport[];
}

export const initialErrorState: ErrorState = {
  reportQueue: [],
};

const ErrorReducer = reducerWithInitialState<ErrorState>(initialErrorState)
  .case(errorActions.pushReport, (state, payload) => ({
    ...state,
    reportQueue: [...state.reportQueue, payload],
  }))
  .case(errorActions.dropReport, state => ({
    ...state,
    reportQueue: state.reportQueue.slice(1),
  }));

export default ErrorReducer;
