import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useMemberDatas, useStage5GroupDocData } from '../../../../api/stage5';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { gameActions } from '../../../../redux/actions/gameActions';
import { Stage5GroupInfo } from '../../../../redux/reducers/gameReducer';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
  useStageState,
} from '../../../../redux/selectors/gameSelectors';
import { GroupState } from '../../../../types';
import StageManager from '../../stageProviders/StageManager';
import GameManager from './GameManager';
import GroupManager from './GroupManager';

interface Stage5Props {}
const Stage5: React.FC<Stage5Props> = () => {
  const dispatch = useDispatch();

  const eventId = useEventId();
  const stageState = useStageState({ stageId: 5 });
  const [showingGame, setShowingGame] = useLocalStorage<boolean>(
    eventId + ':showingGame',
    false
  );

  React.useState<boolean>(false);
  const groupInfo = useStage5GroupInfo();
  const user = useCurrentUser();
  const [persistantGroupInfo, setPersistantGroupInfo] =
    useLocalStorage<Stage5GroupInfo | null>(
      eventId + ':stage5GroupInfo:' + user?.uid,
      null
    );

  if (groupInfo == null && persistantGroupInfo != null) {
    dispatch(
      gameActions.setStage5GroupInfo({
        groupId: persistantGroupInfo.groupId,
        searchId: persistantGroupInfo.searchId,
      })
    );
  }

  const [groupDocData] = useStage5GroupDocData(eventId, groupInfo?.groupId);

  // const [joinedGame] = useJoinedGameLazyQuery(eventId);

  const { memberDatas, onReloadGroupMembers } = useMemberDatas(
    groupDocData,
    groupInfo,
    eventId
  );

  React.useEffect(() => {
    if (groupDocData === undefined) {
      setPersistantGroupInfo(null);
      setShowingGame(false);
      gameActions.setStage5GroupInfo(null);
    }
    if (
      groupDocData === undefined ||
      groupDocData.state === GroupState.Initializing
    ) {
      return;
    }
    if (!showingGame) {
      setShowingGame(true);
    }
  }, [
    dispatch,
    groupDocData,
    groupInfo?.groupId,
    setPersistantGroupInfo,
    setShowingGame,
    showingGame,
    stageState.finished,
  ]);

  const groupStart = React.useCallback(() => {
    setShowingGame(true);
  }, [setShowingGame]);

  React.useEffect(() => {
    if (groupDocData?.state === GroupState.Finished && !stageState.finished) {
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: true }));
    } else if (
      groupDocData?.state !== GroupState.Finished &&
      stageState.finished
    ) {
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: false }));
    }
  }, [dispatch, groupDocData?.state, stageState.finished]);

  return (
    <StageManager stageId={5} noCountDown noRecordStart>
      {showingGame ? (
        <GameManager groupDocData={groupDocData} memberDatas={memberDatas} />
      ) : (
        <GroupManager
          groupDocData={groupDocData}
          onGroupStart={groupStart}
          memberDatas={memberDatas}
          onReloadGroupMembers={onReloadGroupMembers}
        />
      )}
    </StageManager>
  );
};

export default Stage5;
