import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../styles/colors';

interface CheckBoxProps {
  label: string;
  id: string;
  onChange?: (checked: boolean) => void;
  defaultValue?: boolean;
  disabled?: boolean;
}
const CheckBox: React.FC<CheckBoxProps> = props => {
  const [checked, setChecked] = React.useState(
    props.defaultValue === null ? false : props.defaultValue
  );

  React.useEffect(() => {
    props.onChange?.(checked!);
  }, [checked, props.onChange]);

  return (
    <CheckBoxWrapper>
      <CheckBoxPure
        id={props.id}
        checked={checked}
        onChange={() => setChecked(p => !p)}
        disabled={props.disabled}
      />
      <Label htmlFor={props.id}>
        <span></span>
        {props.label}
      </Label>
    </CheckBoxWrapper>
  );
};

const Label = styled.label``;

const CheckBoxPure = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;

  & + ${Label} {
    position: relative;
    display: flex;
    margin: 0.6rem 0;
    align-items: center;
    color: ${Colors.gray8};
    transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
    font-size: 1.5rem;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
      background: white;
      border: 2px solid #868e96;
      border-radius: 5px;
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }
  }

  & + ${Label}:hover, &:focus + ${Label} {
    color: ${Colors.gray8};

    & > span {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &:checked + ${Label} {
    & > span {
      /* border: 0.5rem solid transparent;
      animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1); */

      &:before {
        content: '';
        position: absolute;
        top: 1rem;
        left: 0.53rem;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
          forwards;
      }
    }
  }
`;

const CheckBoxWrapper = styled.div`
  @keyframes shrink-bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes checkbox-check {
    0% {
      width: 0;
      height: 0;
      border-color: ${Colors.primary};
      transform: translate3d(0, 0, 0) rotate(45deg);
    }
    33% {
      width: 0.4rem;
      height: 0;
      transform: translate3d(0, 0, 0) rotate(45deg);
    }
    100% {
      width: 0.4rem;
      height: 1rem;
      border-color: ${Colors.primary};
      transform: translate3d(0, -1rem, 0) rotate(45deg);
    }
  }
`;

export default CheckBox;
