import firebase from 'firebase/app';
import * as React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors from '../../../styles/colors';

interface PresetDetailProps {
  tests: any[];
}
const PresetDetail: React.FC<PresetDetailProps> = props => {
  return (
    <PresetWrapper>
      <Head>
        <Item>
          <SVG src={Icon_Switch} />
          <p>Switch</p>
        </Item>
        <Item>
          <SVG src={Icon_Program} />
          <p>Program</p>
        </Item>
        <Item>
          <SVG src={Icon_Create} />
          <p>Create</p>
        </Item>
        <Item>
          <SVG src={Icon_Energy} />
          <p>Energy</p>
        </Item>
        <Item>
          <SVG src={Icon_Communicate} />
          <p>Communicate</p>
        </Item>
      </Head>
      <Body>
        {props.tests.map((single, i) => {
          return (
            <BodyItem key={i}>
              <p>{single.name}</p>
              <span>{single.detail}</span>
            </BodyItem>
          );
        })}
      </Body>
    </PresetWrapper>
  );
};

const PresetWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:last-child {
    margin-right: 0;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
`;
const BodyItem = styled.div`
  flex: 1;
  margin-right: 1rem;

  p {
    font-size: 1.6rem;
    margin: 0.6rem 0;
  }
  span {
    font-size: 1rem;
    color: ${Colors.gray6};
  }

  &:last-child {
    margin-right: 0;
  }
`;
export default PresetDetail;
