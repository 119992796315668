import styled from 'styled-components';

import ErrorIcon from '../../static/png/stage1/Stage1_ErrorIcon.png';
import Colors from '../../styles/colors';

const ErrorText = styled.p`
  color: ${Colors.error};
  height: 2rem;
  font-size: 1.5rem;
  padding-left: 2.3rem;
  position: relative;

  &:after {
    content: '';
    width: 3rem;
    height: 1.7rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${ErrorIcon});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export default ErrorText;
