import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useChangeEventStatusMutation } from '../../../api/__generated__/event.generated';
import {
  EventDocument,
  useEventData,
  useEventMembersData,
} from '../../../api/event';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { useCustomClaim } from '../../../redux/selectors/authSelectors';
import { useAdminEventId } from '../../../redux/selectors/gameSelectors';
import { EventStatus } from '../../../types';
import { AdminRole } from '../AdminAdministratorDetail';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminEventController from './AdminEventController';
import { useEventCsvCall } from './hooks/useEventCsv';

interface AdminEventControlViewProps {}

export const EventResultCsvContext = React.createContext(null);

const AdminEventControlView: React.FC<AdminEventControlViewProps> = () => {
  const history = useHistory();
  const [event, setEvent] = React.useState<EventDocument | null>(null);
  const [participantUids, setParticipantUids] = React.useState<string[]>([]);
  const eventId = useAdminEventId();
  const [initEvent] = useEventData(eventId);
  const [initEventMembers] = useEventMembersData(eventId);
  const customClaim = useCustomClaim();
  const role: AdminRole = customClaim?.commonRoles.includes('riddlerAdmin')
    ? 'riddlerAdmin'
    : customClaim?.commonRoles.includes('dentsuAdmin')
    ? 'dentsuAdmin'
    : 'organizationAdmin';

  React.useEffect(() => {
    if (initEvent !== undefined) {
      setEvent(initEvent);
    }
  }, [initEvent]);

  React.useEffect(() => {
    if (initEventMembers !== undefined) {
      setParticipantUids(initEventMembers.participants ?? []);
    }
  }, [initEventMembers]);

  const [startEvent] = useRetryableMutationWithUI(
    useChangeEventStatusMutation,
    {
      hookOptions: {
        variables: {
          input: {
            eventId: event?.eventId ?? '',
            status: EventStatus.Ongoing,
          },
        },
      },
    }
  );
  const onStartEvent = React.useCallback(() => {
    startEvent();
  }, [startEvent]);

  const [endEvent] = useRetryableMutationWithUI(useChangeEventStatusMutation, {
    hookOptions: {
      variables: {
        input: {
          eventId: event?.eventId ?? '',
          status: EventStatus.Ending,
        },
      },
    },
  });
  const onEndEvent = React.useCallback(() => {
    endEvent();
  }, [endEvent]);

  const onCompleted = React.useCallback(() => {
    history.push(`/admin/events`);
  }, [history]);

  const [removeEvent] = useRetryableMutationWithUI(
    useChangeEventStatusMutation,
    {
      hookOptions: {
        variables: {
          input: {
            eventId: event?.eventId ?? '',
            status: EventStatus.Removed,
          },
        },
        onCompleted,
      },
    }
  );
  const onRemoveEvent = React.useCallback(() => {
    removeEvent();
  }, [removeEvent]);

  const onEdit = React.useCallback(() => {
    history.push(`/admin/events/${eventId}/edit`);
  }, [eventId, history]);

  const { onCsvDownload, csvClicked, onCsvDownloadButton } = useEventCsvCall();

  let disableEditButton = false;
  if (event) {
    disableEditButton = event.status !== EventStatus.Preparing;
  }

  const btnEdit: HeaderButton = {
    buttonText: '編集する',
    event: onEdit,
    disabled: disableEditButton,
  };

  return (
    <AdminCommonBG
      title='テストイベント詳細'
      backButton
      headerButtons={[btnEdit]}
      downloadCsvButton={
        role === 'organizationAdmin' ? undefined : onCsvDownloadButton
      }
    >
      <AdminEventControlViewWrapper>
        {event && (
          <AdminEventController
            event={event}
            participantUids={participantUids}
            onStartEvent={onStartEvent}
            onEndEvent={onEndEvent}
            onRemoveEvent={onRemoveEvent}
            role={role}
            onCsvDownload={onCsvDownload}
            csvClicked={csvClicked}
          />
        )}
      </AdminEventControlViewWrapper>
    </AdminCommonBG>
  );
};

const AdminEventControlViewWrapper = styled.div``;

export default AdminEventControlView;
