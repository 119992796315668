import * as React from 'react';
import styled from 'styled-components';

import Stage4Problem, { Pos } from '../../../../lib/Stage4Problem';
import LockedImg from '../../../../static/svg/Stage4_Locked.svg';
import RunoutImg from '../../../../static/svg/Stage4_Runout.svg';
import Colors from '../../../../styles/colors';
import Button from '../../../uiElements/button/MainButton';
import TextWindow from '../../../uiElements/TextWindow';
import Stage4Dots from './Stage4Dots';
import Stage4ProblemView, {
  CELL_MARGIN,
  PANEL_SIZE,
} from './Stage4ProblemView';

interface Stage4ProblemContainerProps {
  problem: Stage4Problem;
  setProblem: React.Dispatch<React.SetStateAction<Stage4Problem>>;
  onClear: () => void;
  onEndFadingOut: () => void;
  fadingOutProblem: boolean;
  practicing: boolean;
  incrementPenalty: () => void;
}

const Stage4ProblemContainer: React.FC<Stage4ProblemContainerProps> = props => {
  const {
    problem,
    setProblem,
    fadingOutProblem,
    onClear,
    practicing,
    incrementPenalty,
  } = props;

  const onCleared = React.useCallback(() => {
    if (practicing) {
      return;
    }

    onClear();
  }, [onClear, practicing]);

  const onCellClick = React.useCallback(
    (pos: Pos) => {
      if (problem.remainingCount === 0) {
        return;
      }
      const next = problem.click(pos, practicing);
      setProblem(next);

      if (next.isCleared(practicing)) {
        onCleared();
      }
    },
    [onCleared, problem, practicing, setProblem]
  );

  const onReset = React.useCallback(() => {
    if (!practicing) incrementPenalty();
    setProblem(problem.reset());
  }, [practicing, problem, incrementPenalty, setProblem]);

  const runout = problem.remainingCount === 0;
  const cleared = problem.isCleared(practicing);
  const locked = !cleared && runout;
  const resettable = !cleared && (practicing || runout);

  return (
    <Stage4ProblemContainerWrapper>
      <Text locked={locked}>
        {problem.maxActionCount}手ですべて点灯させてください。
      </Text>
      <ProblemContainer>
        <Stage4ProblemView
          problem={problem}
          onCellClick={onCellClick}
          fadingOut={fadingOutProblem}
          onEndFadingOut={props.onEndFadingOut}
          locked={locked}
          cleared={cleared}
          clickedColor={practicing ? '#E67700' : Colors.primary}
        />
        <Runout locked={locked} />
      </ProblemContainer>

      <Margin />

      <Control>
        {resettable ? (
          <ResetButton onClick={onReset}>リセット</ResetButton>
        ) : (
          <Stage4Dots
            dotCount={problem.maxActionCount}
            activeDotCount={problem.remainingCount}
          />
        )}
      </Control>
      <Locked locked={locked} />
    </Stage4ProblemContainerWrapper>
  );
};

const Margin = styled.div`
  height: 6rem;
`;

const Stage4ProblemContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  box-sizing: border-box;
  position: relative;
`;

interface TextProps {
  locked: boolean;
}
const Text = styled(TextWindow).attrs({
  bracket: true,
})`
  width: 50rem;
  padding: 1rem 6rem;
  margin: 0 auto;
  visibility: ${(p: TextProps) => (p.locked ? 'hidden' : 'visible')};
`;

const ProblemContainer = styled.div`
  height: calc(${PANEL_SIZE} * 4 + ${CELL_MARGIN} * 3);
  width: 100%;
  position: relative;
  margin: 5rem auto;
`;

const ResetButton = styled(Button).attrs({
  variant: 'primary',
  size: 'large',
  color: 'negative',
})`
  margin: 1.4rem 0;
`;

const Control = styled.div`
  background: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 10px 0 20px 0;
  width: 100%;
`;

interface LockedProps {
  locked: boolean;
}

const Locked = styled.img.attrs({
  src: LockedImg,
})`
  height: 7rem;
  z-index: 2;
  position: absolute;
  top: 8rem;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  visibility: ${(p: LockedProps) => (p.locked ? 'visible' : 'hidden')};
  opacity: ${(p: LockedProps) => (p.locked ? 1 : 0)};
  transition: 0.2s;
`;

const Runout = styled.img.attrs({
  src: RunoutImg,
})`
  width: 40rem;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  visibility: ${(p: LockedProps) => (p.locked ? 'visible' : 'hidden')};
  opacity: ${(p: LockedProps) => (p.locked ? 1 : 0)};
  transition: 0.2s;
`;

export default Stage4ProblemContainer;
