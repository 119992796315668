import * as React from 'react';
import styled from 'styled-components';

import { HandleFirebaseErrorResult } from '../../lib/firebase/firebaseErrorHandlers';
import useFirebaseError from '../../lib/firebase/useFirebaseError';
import {
  Validators,
  emailFormatValidator,
  pwFormatValidator,
  useValidator,
} from '../../lib/useValidator';
import HeaderLogin from '../../static/png/Header_Login.png';
import { colorWithAlpha } from '../../styles/colors';
import Colors from '../../styles/colors';
import Button from '../uiElements/button/MainButton';
import CheckBox from '../uiElements/CheckBox';
import CommonBG from '../uiElements/CommonBG';
import _ErrorText from '../uiElements/ErrorText';
import { HeaderOptions } from '../uiElements/Header';
import Input from '../uiElements/input/Input';
import _Window from '../uiElements/Window';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
};

interface LoginViewProps {
  toPWReset: () => void;
  onLogin: (email: string, pw: string) => void;
  onCheckStoreIdentity: (checked: boolean) => void;
  error: HandleFirebaseErrorResult | null;
}
export const LoginView: React.FC<LoginViewProps> = props => {
  const { emailError, pwError, otherError, resetError } = useFirebaseError(
    props.error
  );

  const [email, setEmail] = React.useState<string>('');
  const [pw, setPw] = React.useState<string>('');

  const toPWReset = React.useCallback(() => {
    props.toPWReset();
  }, [props]);

  const onLogin = React.useCallback(() => {
    props.onLogin(email, pw);
  }, [email, pw, props]);
  const emailOnChange = React.useCallback(
    (val: string) => {
      setEmail(prev => {
        if (prev !== val) {
          resetError('email');
        }
        return val;
      });
    },
    [resetError]
  );
  const pwOnChange = React.useCallback(
    (val: string) => {
      setPw(prev => {
        if (prev !== val) {
          resetError('pw');
        }
        return val;
      });
    },
    [resetError]
  );

  const emailValidators = React.useMemo<Validators>(
    () => [
      [() => !!emailError, emailError ?? ''],
      [(val, state) => val === '' || state.isFocused],
      emailFormatValidator,
    ],
    [emailError]
  );
  const [emailValidator, emailIsValid] = useValidator(emailValidators);

  const pwValidators = React.useMemo<Validators>(
    () => [
      [() => !!pwError, pwError ?? ''],
      [val => val === ''],
      pwFormatValidator,
    ],
    [pwError]
  );
  const [pwValidator, pwIsValid] = useValidator(pwValidators);

  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <TopBar>
        <HeaderTitle src={HeaderLogin} />
        <p>ログインしてください。</p>
      </TopBar>
      <LoginWindow>
        <LoginSectionItem>
          <LoginSectionTitle>メールアドレス</LoginSectionTitle>
          <Input
            autoComplete='email'
            value={email}
            onChange={emailOnChange}
            validator={emailValidator}
          />
        </LoginSectionItem>
        <LoginSectionItem>
          <LoginSectionTitle>パスワード</LoginSectionTitle>
          <PasswordNoticeText>8文字以上で入力してください</PasswordNoticeText>
          <Input
            autoComplete='current-password'
            type='password'
            value={pw}
            onChange={pwOnChange}
            validator={pwValidator}
          />
        </LoginSectionItem>

        <CheckBoxWrapper>
          <CheckBox
            id='store-identity'
            label='ログイン状態を保存する'
            onChange={props.onCheckStoreIdentity}
          />
        </CheckBoxWrapper>

        <LoginButton
          size='large'
          color='positive'
          onClick={onLogin}
          disabled={!emailIsValid || !pwIsValid}
        >
          ログイン
        </LoginButton>
        {otherError && <ErrorText>{otherError}</ErrorText>}

        <PasswordResetLink onClick={toPWReset}>
          パスワードをお忘れですか？
        </PasswordResetLink>
      </LoginWindow>
    </CommonBG>
  );
};

export const ErrorText = styled(_ErrorText)`
  height: initial;
  margin-bottom: 20px;
`;

export const LoginWindow = styled(_Window)`
  background: transparent;
  padding: 0;
  margin: 0 auto;
  width: 32rem;
  margin-bottom: 3rem;
`;

interface InputActiveProps {
  active: boolean;
}

export const LoginSectionItem = styled.div`
  margin: 0rem auto;
`;

export const TopBar = styled.div`
  margin: 0rem auto;
  background: ${Colors.gray4_op025};
  text-align: center;
  padding: 3rem 0;
  margin-top: 1rem;
  margin-bottom: 5rem;

  p {
    font-size: 2.4rem;
    font-weight: bold;
  }
`;

const HeaderTitle = styled.img`
  width: 15rem;
  height: 3.2rem;
  margin-bottom: 1rem;
`;

export const LoginSectionTitle = styled.div`
  font-size: 1.6rem;
  margin: 0.7rem 0;
  font-weight: 500;
`;

export const TextInput = styled.input`
  width: 100%;
  height: 4rem;
  font-size: 1.5rem;
  padding: 0 1rem;
  box-sizing: border-box;
  border-style: solid;
  border-color: ${(p: InputActiveProps) =>
    p.active ? colorWithAlpha('gray8', 0) : colorWithAlpha('gray4', 0)};
  border-width: 0.06rem;
  margin-bottom: 1rem;

  &:disabled {
    background-color: ${colorWithAlpha('gray2', 0)};
  }
`;

export const PasswordInput = styled(TextInput)`
  width: 75%;
  letter-spacing: 0.15rem;
`;

export const PasswordNoticeText = styled.div`
  font-size: 1.3rem;
  margin: 0.7rem 0;
`;

export const PasswordVisible = styled.div`
  width: 25%;
  height: 4rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 4rem;
  display: inline-block;
  vertical-align: top;
  color: white;
  background-color: ${(p: InputActiveProps) =>
    p.active ? colorWithAlpha('gray8', 0) : colorWithAlpha('gray4', 0)};
  cursor: pointer;
`;

export const LoginButton = styled(Button).attrs({
  variant: 'primary',
})`
  display: block;
  margin: 3rem auto 4rem;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const PasswordResetLink = styled.a`
  text-align: center;
  text-decoration: underline;
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin: 0 auto;
  display: block;
  cursor: pointer;
`;

export default LoginView;
