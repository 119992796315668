import { FC } from 'react';
import styled from 'styled-components';

import { useCustomClaim } from '../../redux/selectors/authSelectors';
import BGImg from '../../static/png/bg.png';
import Colors, { colorWithAlpha } from '../../styles/colors';
import { AdminRole } from './AdminAdministratorDetail';
import AdminHeader from './AdminHeader';
import AdminNavigation, { CsvData } from './UIelements/AdminNavigation';
import { HeaderButton } from './UIelements/AdminNavigation';

interface CommonBGProps {
  title: string;
  headerButtons?: HeaderButton[];
  backButton?: boolean;
  downloadCsvButton?: () => void;
}
const CommonBG: FC<CommonBGProps> = props => {
  let _backButton = false;
  if (props.backButton != null) {
    _backButton = props.backButton;
  }
  const customClaim = useCustomClaim();
  const role: AdminRole = customClaim?.commonRoles.includes('riddlerAdmin')
    ? 'riddlerAdmin'
    : customClaim?.commonRoles.includes('dentsuAdmin')
    ? 'dentsuAdmin'
    : 'organizationAdmin';

  return (
    <CommonBGWrapper>
      <AdminHeader role={role} />
      <AdminNavigation
        role={role}
        title={props.title}
        backButton={_backButton}
        headerButtons={props.headerButtons}
        downloadCsvButton={props.downloadCsvButton}
      >
        <ContentWrapper>{props.children}</ContentWrapper>
      </AdminNavigation>
    </CommonBGWrapper>
  );
};

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  padding: 2rem 3rem 0;
  padding-top: 0;
`;

const ContentMinWidth = '900px';

/**
 * border指定はこれ関連
 * http://shanabrian.com/web/html-css-js-technics/css-over-margin.php
 *
 */

const CommonBGWrapper = styled.div`
  /* border: 1px solid transparent;
  box-sizing: border-box; */

  min-width: ${ContentMinWidth};

  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  position: relative;
  color: ${Colors.gray8};

  font-weight: 400;

  /* https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3 */
  display: flex;
  flex-direction: column;
`;

export default CommonBG;
