import firebase from 'firebase/app';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useCancelUpdateEmailMutation,
  useUpdateUserInfoMutation,
} from '../../../../api/__generated__/user.generated';
import {
  useSetErrorOverlayState,
  useSetLoadingState,
  useSetNoticeOverlayState,
} from '../../../../redux/actions/appActions';
import RecoverEmailView from '../RecoverEmail/RecoverEmailView';

interface RecoverEmailProps {}

interface ParamTypes {
  email: string;
}

const CancelEmail: React.FC<RecoverEmailProps> = () => {
  const history = useHistory();

  const setNoticeOverlayState = useSetNoticeOverlayState();
  const setErrorOverlayState = useSetErrorOverlayState();
  const setLoadingState = useSetLoadingState();
  const [cancelEmail] = useCancelUpdateEmailMutation();
  const params = useParams<ParamTypes>();
  const previousEmail = Buffer.from(params.email, 'base64').toString();
  const onClick = React.useCallback(() => {
    setLoadingState({
      visible: true,
      text: '通信中...',
    });
    cancelEmail({
      variables: {
        input: {
          email: previousEmail,
        },
      },
    })
      .then(() => {
        setNoticeOverlayState({
          errorType: 'CommonError',
          message:
            'メールアドレスの変更取り消しが完了しました。トップページから再度ログインしてください。',
          onBack: () => history.push('/'),
          backButtonText: 'トップページへ',
        });
      })
      .catch(() => {
        setErrorOverlayState({ errorType: 'CommonError' });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      })
      .finally(() => {
        setLoadingState({ visible: false });
      });
  }, [
    history,
    previousEmail,
    setErrorOverlayState,
    setLoadingState,
    setNoticeOverlayState,
  ]);
  return <RecoverEmailView onClick={onClick} previousEmail={previousEmail} />;
};
export default CancelEmail;
