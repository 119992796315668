import firebase from 'firebase/app';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { appActions } from '../../redux/actions/appActions';
import {
  useCurrentUser,
  useIsUserEmailVerified,
} from '../../redux/selectors/authSelectors';
import HomeImg from '../../static/svg/Icon-Home.svg';
import GearImg from '../../static/svg/Icon-Setting.svg';
import LogoImg from '../../static/svg/specc_logotype.svg';
import Colors from '../../styles/colors';
import SubButton from '../uiElements/button/SubButton';

export type HeaderButtonType =
  | 'toLogin'
  | 'toRegister'
  | 'toMyPage'
  | 'logout'
  | 'close';
export interface HeaderOptions {
  buttonType?: HeaderButtonType;
  showEmail?: boolean;
  hideHomeButton?: boolean;
  hideSettingButton?: boolean;
}

interface HeaderProps {
  options?: HeaderOptions;
}
const Header: React.FC<HeaderProps> = props => {
  const history = useHistory();
  const user = useCurrentUser();

  const goToMyPage = React.useCallback(() => {
    history.push('/');
  }, [history]);
  const goToLogin = React.useCallback(() => {
    history.push('/');
  }, [history]);
  const goToSettings = React.useCallback(() => {
    history.push('/settings/profile');
  }, [history]);

  const signOut = React.useCallback(() => {
    window.localStorage.removeItem('selectedOrgId');
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/');
      });
  }, [history]);

  const isStageSelect =
    useRouteMatch<{ eventId: string }>('/events/:eventId/stages') !== null;
  const emailVerified = useIsUserEmailVerified();
  const dispatch = useDispatch();
  return (
    <HeaderWrapper>
      <LogoWrapper>
        {props.options?.hideHomeButton === true ? (
          <></>
        ) : (
          <HeaderBtn>
            {isStageSelect ? (
              <SVG
                src={HomeImg}
                onClick={() => {
                  dispatch(
                    appActions.setErrorOverlayState({
                      errorType: 'CommonError',
                      message: 'ホームに戻りますか？（進捗は保存されます）',
                      backButtonText: 'いいえ',
                      retryButtonText: 'はい',
                      onRetry: () => {
                        history.push('/');
                      },
                    })
                  );
                }}
              />
            ) : (
              <Link to={'/'}>
                <SVG src={HomeImg} />
              </Link>
            )}
          </HeaderBtn>
        )}
        <Link to={'/'}>
          <SVG src={LogoImg} />
        </Link>
      </LogoWrapper>

      <RightContents>
        {props.options?.showEmail && <SubText>{user?.email ?? ''} </SubText>}
        {emailVerified && props.options?.hideSettingButton !== true && (
          <HeaderBtn onClick={goToSettings}>
            <SVG src={GearImg} />
          </HeaderBtn>
        )}
        {props.options?.buttonType === 'toMyPage' && (
          <SubButton size='medium' color='neutral' onClick={goToMyPage}>
            マイページに戻る
          </SubButton>
        )}
        {props.options?.buttonType === 'toLogin' && (
          <>
            <SubText>すでに登録がお済みの方はこちらから</SubText>
            <SubButton size='medium' color='neutral' onClick={goToLogin}>
              ログイン
            </SubButton>
          </>
        )}
        {props.options?.buttonType === 'logout' && (
          <SubButton size='medium' color='neutral' onClick={signOut}>
            ログアウト
          </SubButton>
        )}
        {props.options?.buttonType === 'close' && (
          <SubButton
            size='medium'
            color='neutral'
            onClick={() => {
              window.close();
            }}
          >
            閉じる
          </SubButton>
        )}
      </RightContents>
    </HeaderWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  svg .cls-1 {
    fill: ${Colors.primary};
  }
`;

const Logo = styled.svg`
  height: 2.6rem;
  display: inline-block;
  margin-right: 2rem;
`;

const Home = styled.img`
  height: 2.6rem;
  display: inline-block;
  margin-right: 2rem;
`;

const SubText = styled.span`
  display: inline-block;
  color: ${Colors.gray8};
  margin-right: 1rem;
  font-size: 1.5rem;
  vertical-align: text-bottom;
`;

const RightContents = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

const HeaderWrapper = styled.div`
  padding: 2rem 0;
  border-bottom: ${Colors.primary} 3px solid;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 4rem;
`;

const HeaderBtn = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;

  svg .cls-1 {
    fill: ${Colors.gray5};
    transition: 0.2s;
  }

  &:hover {
    svg .cls-1 {
      fill: ${Colors.primary};
    }
  }
`;

const AdminUserInfo = styled.div`
  background: white;
  width: 160px;
  padding: 0.8rem 1.4em;
  border-radius: 6px;
  margin: 0.2rem 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  border: 1px solid ${Colors.gray4};

  span {
    flex: 1;
    text-align: left;
    display: block;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

const AdminBadge = styled.span`
  white-space: nowrap;
  padding: 0.4rem 0.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 4px;
  color: ${Colors.primary};
  border: 2px solid ${Colors.primary};
`;
export default Header;
