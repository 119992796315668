export type Stage4Row = boolean[];
export type Stage4Field = Stage4Row[];

export type Pos = [number, number];

export type Stage4UpdateRule = (
  prevProblem: Stage4Problem,
  clickedPos: Pos,
  clickedPosBefore?: Pos
) => Stage4Field;

interface Stage4ProblemProps {
  field?: Stage4Field;
  initField: Stage4Field;
  updateRule: Stage4UpdateRule;
  origin: number;
  maxActionCount: number;
  remainingCount?: number;
  clickedPosBefore?: Pos;
}

export default class Stage4Problem {
  readonly field: Stage4Field;
  readonly initField: Stage4Field;
  readonly updateRule: Stage4UpdateRule;

  readonly clickedPosBefore: Pos | undefined;

  // 操作回数制限
  readonly maxActionCount: number;
  readonly remainingCount: number;

  // 左上がなにから始まるか 指定しなければ1-origin
  readonly origin: number;

  constructor(props: Stage4ProblemProps) {
    this.field = props.field || props.initField;
    this.initField = props.initField;
    this.updateRule = props.updateRule;
    this.origin = props.origin;
    this.maxActionCount = props.maxActionCount;
    this.remainingCount = props.remainingCount ?? props.maxActionCount;
    this.clickedPosBefore = props.clickedPosBefore;
  }

  click(pos: Pos, practicing: boolean) {
    return new Stage4Problem({
      field: this.updateRule(this, pos, this.clickedPosBefore),
      initField: this.initField,
      updateRule: this.updateRule,
      origin: this.origin,
      maxActionCount: this.maxActionCount,
      remainingCount: this.remainingCount - (practicing ? 0 : 1),
      clickedPosBefore: pos,
    });
  }

  reset() {
    return new Stage4Problem({
      initField: this.initField,
      updateRule: this.updateRule,
      origin: this.origin,
      maxActionCount: this.maxActionCount,
    });
  }

  isCleared(practicing: boolean) {
    if (practicing) return false;
    return !this.field.flat().includes(false);
  }

  get width() {
    return this.field[0].length;
  }
}
