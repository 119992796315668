import * as React from 'react';
import { useDispatch } from 'react-redux';
import actionCreatorFactory, { Action } from 'typescript-fsa';

import { ErrorOverlayState } from '../reducers/appReducer';

const actionCreator = actionCreatorFactory();

export interface SetLoadingStatePayload {
  visible: boolean;
  text?: string;
}

export const appActions = {
  setLoadingState: actionCreator<SetLoadingStatePayload>(
    'APP_SET_LOADING_STATE'
  ),
  setErrorOverlayState: actionCreator<ErrorOverlayState | null>(
    'APP_SET_ERROR_OVERLAY_STATE'
  ),
};

export const useSetLoadingState = (): ((
  payload: SetLoadingStatePayload
) => Action<SetLoadingStatePayload>) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (payload: SetLoadingStatePayload) => {
      return dispatch(appActions.setLoadingState(payload));
    },
    [dispatch]
  );
};

export const useSetErrorOverlayState = (): ((
  payload: ErrorOverlayState | null
) => Action<ErrorOverlayState | null>) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (payload: ErrorOverlayState | null) => {
      return dispatch(appActions.setErrorOverlayState(payload));
    },
    [dispatch]
  );
};

// TODO: implement
export const useSetNoticeOverlayState = useSetErrorOverlayState;
