import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  DissolveGroupMutation,
  useDissolveGroupMutation,
} from '../../../../api/__generated__/stage5.generated';
import { Stage5GroupDocument } from '../../../../api/stage5';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../../redux/actions/gameActions';
import { Stage5GroupInfo } from '../../../../redux/reducers/gameReducer';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5Flags,
  useStage5GroupInfo,
} from '../../../../redux/selectors/gameSelectors';
import Button from '../../../uiElements/button/Button';
import CommonBG from '../../../uiElements/CommonBG';
import TextWindow from '../../../uiElements/TextWindow';

export interface GroupMemberItem {
  uid: string;
  displayName: string;
}

interface GroupInfoProps {
  groupDocData?: Stage5GroupDocument;
  onGameStart: () => void;
  onReloadGroupMembers: () => void;
  memberDatas: GroupMemberItem[];
  setSelfLeave: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const GroupInfo: React.FC<GroupInfoProps> = ({
  groupDocData,
  onGameStart,
  memberDatas,
  onReloadGroupMembers,
  setSelfLeave,
}) => {
  const dispatch = useDispatch();
  const eventId = useEventId();
  const groupInfo = useStage5GroupInfo();
  const { isGroupOwner } = useStage5Flags(groupDocData);

  const user = useCurrentUser();
  const [persistantGroupInfo, setPersistantGroupInfo] =
    useLocalStorage<Stage5GroupInfo | null>(
      eventId + ':stage5GroupInfo:' + user?.uid,
      null
    );

  const onDissolveGroupCompleted = React.useCallback(
    (_: DissolveGroupMutation) => {
      // const data = res.dissolveGroup;
      dispatch(gameActions.setStage5GroupInfo(null));
    },
    [dispatch]
  );

  const [dissolveGroup] = useRetryableMutationWithUI(useDissolveGroupMutation, {
    hookOptions: {
      onCompleted: onDissolveGroupCompleted,
    },
    loading: { options: { text: 'グループを退出中...' } },
  });

  const onLeave = React.useCallback(() => {
    if (!groupInfo?.searchId || !eventId) {
      return;
    }
    setPersistantGroupInfo(null);
    dissolveGroup({
      variables: {
        input: {
          eventId,
          searchId: groupInfo.searchId,
        },
      },
    });
    setSelfLeave(true);
  }, [dissolveGroup, eventId, groupInfo?.searchId]);

  return (
    <CommonBG>
      <GroupInfoWrapper>
        <Window>{`グループIDは「${groupInfo?.searchId}」です。\n全員揃っているか確認してください。`}</Window>

        <Members>
          {memberDatas.map(m => (
            <MemberItem key={m.uid}>{m.displayName}</MemberItem>
          ))}
        </Members>

        <Buttons>
          <SmallButtons>
            <SmallButton onClick={onReloadGroupMembers}>
              メンバーを再読み込み
            </SmallButton>
            <SmallButton onClick={onLeave}>退出する</SmallButton>
          </SmallButtons>

          {isGroupOwner && (
            <StartButton
              onClick={onGameStart}
              disabled={memberDatas.length < 3}
            >
              このメンバーで開始する
            </StartButton>
          )}
        </Buttons>
      </GroupInfoWrapper>
    </CommonBG>
  );
};

const Window = styled(TextWindow).attrs({
  bracket: true,
})`
  width: 60rem;
  text-align: center;
`;

const GroupInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
`;

const Members = styled.div`
  margin: 4rem 0;
`;

const MemberItem = styled.div`
  height: 4rem;
  line-height: 4rem;
  font-size: 4rem;
  font-weight: bold;

  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SmallButtons = styled.div`
  display: inline-block;
  margin-bottom: 3rem;
`;

const SmallButton = styled(Button).attrs({
  variant: 'sub',
  size: 'medium',
  color: 'neutral',
})`
  vertical-align: top;
  margin-right: 3rem;

  &:last-child {
    margin-right: 0;
  }
`;

const StartButton = styled(Button).attrs({
  variant: 'main',
  color: 'positive',
  size: 'large',
})`
  width: 100%;
`;

const Buttons = styled.div`
  display: inline-grid;
  margin-top: 3rem;
`;

export default GroupInfo;
