import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import { useEffect, useMemo, useState } from 'react';
import {
  DocumentDataHook,
  useCollection,
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

// import { useDocumentData } from '../lib/firebase/firestoreDataHook';
import { TS2DateType } from '../lib/firebase/firestoreTypeUtils';
import { useEventId } from '../redux/selectors/gameSelectors';
import {
  Event as EventDocumentRaw,
  EventMember as EventMembersDocumentNoId,
  UserRecords,
  EventStats as _EventStats,
} from './firestoreTypes';

export type EventStats = _EventStats;

export type EventDocumentNoId = TS2DateType<EventDocumentRaw>;
export type EventDocument = EventDocumentNoId & {
  eventId: string;
};
export type EventMembersDocument = EventMembersDocumentNoId & {
  eventId: string;
};

export type EventWithMembers = {
  eventId: string;
  event: EventDocument | null;
  members: EventMembersDocument | null;
};

export type EventWithMembersNonNull = {
  eventId: string;
  event: EventDocument;
  members: EventMembersDocument;
};

export interface StartTime {
  1?: firebase.firestore.Timestamp;
  2?: firebase.firestore.Timestamp;
  3?: firebase.firestore.Timestamp;
  4?: firebase.firestore.Timestamp;
  5?: firebase.firestore.Timestamp;
}

export const eventSchema = gql`
  query getParticipantUserData($input: GetParticipantUserDataInput!) {
    getParticipantUserData(input: $input) {
      uid
      displayName
      email
    }
  }

  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      eventId
    }
  }

  mutation changeEventStatus($input: EventStatusInput!) {
    changeEventStatus(input: $input) {
      success
    }
  }

  mutation changeEventInformation($input: EventInformationInput!) {
    changeEventInformation(input: $input) {
      success
    }
  }

  mutation addParticipant($input: ParticipantInput!) {
    addParticipant(input: $input) {
      rejectedUids
    }
  }

  mutation removeParticipant($input: ParticipantInput!) {
    removeParticipant(input: $input) {
      success
    }
  }
`;

export const useAvailableEventIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [memberEvents, memberEventsLoading, memberEventsError] = useCollection(
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_members')
          .where('participants', 'array-contains', user.uid)
      : undefined
  );
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!memberEventsLoading) {
      const memberEventIds = memberEvents
        ? memberEvents.docs.map((d: any) => d.id)
        : [];

      setIds(Array.from(new Set(memberEventIds)));
    }
    setLoading(memberEventsLoading);
  }, [memberEvents, memberEventsLoading]);
  const error = memberEventsError;

  return [ids, loading, error];
};

export const useJoinableEventIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [memberEvents, loading, error] = useCollection(
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_members')
          .where('participants', 'array-contains', user.uid)
      : undefined
  );

  const [memberEventIds, setMemberEventIds] = useState<string[]>([]);
  useEffect(() => {
    if (memberEvents != null) {
      setMemberEventIds(memberEvents.docs.map((d: any) => d.id));
    }
  }, [memberEvents]);

  return [memberEventIds, loading, error];
};

export const useEditableEventIds = (
  user: firebase.User | null,
  orgId: string
): LoadingHook<string[], Error> => {
  const ref =
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_members')
          .where('orgId', '==', orgId)
      : undefined;

  const ownerEventsResult = useCollectionData<EventMembersDocument>(ref, {
    idField: 'eventId',
  });
  return useMemo(() => {
    const ids =
      ownerEventsResult[0]?.map(
        (d: EventMembersDocument & { eventId: string }) => d.eventId
      ) ?? [];
    const loading = ownerEventsResult[1];
    const error = ownerEventsResult[2];
    return [ids, loading, error];
  }, [ownerEventsResult]);
};

const isNonnull = <T>(x: T | null): x is T => x !== null;

export const useEndedEventIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [availableIds, availableIdsLoading, error] = useAvailableEventIds(user);
  const [ids, setIds] = useState(availableIds);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (availableIdsLoading) setLoading(true);
  }, [availableIdsLoading]);
  useEffect(() => {
    if (user == null || availableIdsLoading || availableIds == null) return;
    Promise.all(availableIds.map(id => getEventData(id)))
      .then(results => {
        results
          .filter(isNonnull)
          .filter(ev => ev.status === 'ENDED')
          .sort(
            (a, b) =>
              b.scheduledStartTime.getTime() - a.scheduledStartTime.getTime()
          );
        setIds(
          availableIds.filter((_, idx) => results[idx]?.status === 'ENDED')
        );
      })
      .finally(() => setLoading(false));
  }, [setIds, availableIds, user, availableIdsLoading]);
  return [ids, loading, error];
};

const mapper = (raw: EventDocumentRaw): EventDocumentNoId => ({
  ...raw,
  scheduledStartTime: raw.scheduledStartTime.toDate(),
  scheduledEndTime: raw.scheduledEndTime.toDate(),
  startedOn: raw.startedOn?.toDate() ?? null,
  endedOn: raw.endedOn?.toDate() ?? null,
  resultCalculatedOn: raw.resultCalculatedOn?.toDate() ?? null,
});

export const useEventData = (
  eventId: string | null
): DocumentDataHook<EventDocumentNoId, 'eventId'> => {
  const ref =
    eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('events')
          .doc(eventId)
      : undefined;

  return useDocumentData<EventDocumentNoId, 'eventId'>(ref, {
    transform: mapper,
    idField: 'eventId',
  });
};

export const useEventDataOnce = (
  eventId: string | null
): DocumentDataHook<EventDocumentNoId, 'eventId'> => {
  const ref =
    eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('events')
          .doc(eventId)
      : undefined;

  return useDocumentDataOnce<EventDocumentNoId, 'eventId'>(ref, {
    transform: mapper,
    idField: 'eventId',
  });
};

export const getEventData = async (
  eventId: string | null
): Promise<EventDocument | null> => {
  if (eventId === null) {
    return null;
  }

  const ref = firebase
    .firestore()
    .collection('version')
    .doc('1')
    .collection('events')
    .doc(eventId);

  const snapshot = await ref.get();
  const data = snapshot.data() as EventDocumentRaw | undefined;
  if (!snapshot.exists || data === undefined) {
    return null;
  }

  const event: EventDocument = {
    ...data,
    eventId,
    scheduledStartTime: data.scheduledStartTime.toDate(),
    scheduledEndTime: data.scheduledEndTime.toDate(),
    startedOn: data.startedOn?.toDate() ?? null,
    endedOn: data.endedOn?.toDate() ?? null,
    resultCalculatedOn: data.resultCalculatedOn?.toDate() ?? null,
  };
  return event;
};

const getEventMembersData = async (
  eventId: string | null
): Promise<EventMembersDocument | null> => {
  if (eventId === null) {
    return null;
  }

  const ref = firebase
    .firestore()
    .collection('version')
    .doc('1')
    .collection('event_members')
    .doc(eventId);

  const snapshot = await ref.get();
  const data = snapshot.data() as EventMembersDocument | undefined;
  if (!snapshot.exists || data === undefined) {
    return null;
  }
  return {
    ...data,
    eventId,
  };
};

export const getEventWithMemberData = async (
  eventId: string | null
): Promise<EventWithMembers | null> => {
  if (eventId === null) {
    return null;
  }
  const event = await getEventData(eventId);
  const members = await getEventMembersData(eventId);
  return {
    eventId,
    event,
    members,
  };
};

export const useEventMembersData = (
  eventId: string | null | undefined
): DocumentDataHook<Partial<EventMembersDocument>> => {
  return useDocumentData<Partial<EventMembersDocument>>(
    eventId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_members')
          .doc(eventId)
      : undefined
  );
};

export const useEventAvailable = (): boolean[] => {
  const eventId = useEventId();
  const [available, setAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (eventId == null) {
      setAvailable(true);
      setLoading(false);
    } else {
      setLoading(true);
      firebase
        .firestore()
        .collection('version')
        .doc('1')
        .collection('events')
        .doc(eventId)
        .get()
        .then(() => setAvailable(true))
        .catch(() => setAvailable(false))
        .finally(() => setLoading(false));
    }
  }, [eventId]);
  return [available, loading];
};
export const useUserEventHistory = (uid: string | null) => {
  return useCollection(
    uid !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('user_event_records')
          .doc(uid)
          .collection('events')
      : undefined
  );
};

export const useEventRecords = (eventId: string | null) => {
  return useCollection(
    eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_user_records')
          .doc(eventId)
          .collection('users')
      : undefined
  );
};

export const useEventResult = (
  uid: string | null,
  eventId: string | null
): DocumentDataHook<UserRecords> => {
  return useDocumentData<UserRecords>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_user_records')
          .doc(eventId)
          .collection('users')
          .doc(uid)
      : undefined
  );
};

export const useEventStats = (
  eventId: string | null
): DocumentDataHook<EventStats> => {
  return useDocumentData<EventStats>(
    eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_stats')
          .doc(eventId)
      : undefined
  );
};

export const useOrgAllUserEventStats = (orgId: string | null) => {
  return useCollection(
    orgId
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('event_stats')
          .where('orgId', '==', orgId)
      : undefined
  );
};

export const useOrgAllEvents = (orgId: string | null) => {
  return useCollection(
    orgId
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('events')
          .where('orgId', '==', orgId)
      : undefined
  );
};
