import * as React from 'react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Colors from '../../../styles/colors';

interface SingleMenu {
  title: string;
  onClick: () => void;
  red?: boolean;
}

interface MenuPopupProps {
  contents: SingleMenu[];
}
const AdminPopup: React.FC<MenuPopupProps> = props => {
  return (
    <MenuPopup>
      {props.contents.map((single, i) => {
        return (
          <SingleMenu key={i} red={single.red} onClick={single.onClick}>
            {single.title}
          </SingleMenu>
        );
      })}
    </MenuPopup>
  );
};

const MenuPopup = styled.div`
  text-align: left;
`;

interface SingleMenuProps {
  red?: boolean;
}

const SingleMenu = styled.div`
  font-size: 1.4rem;
  padding: 0.6rem 1.2rem;

  ${(p: SingleMenuProps) =>
    p.red
      ? `
      color: ${Colors.error};
    `
      : `
      color: ${Colors.accent};
  `}

  &:hover {
    background: ${Colors.gray2};
  }
`;

export default AdminPopup;
