import { ValueType } from 'react-select';

import { FormSelectOption } from '../form/formTypes';

export const asSelectValueCallback = <T>(
  v: ValueType<FormSelectOption<T>, false>,
  callback: (x: T) => void
) => {
  if (v === null || v === undefined) return;
  if (Array.isArray(v)) return;
  callback((v as FormSelectOption<T>).value);
};
