import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

import Colors, { colorWithAlpha } from '../../styles/colors';
import {
  settingsItemMenuLabel,
  settingsKeys,
  settingsPaths,
} from './settingsPaths';
import SettingsTitle from './SettingsTitle';

interface SettingsMenuViewProps {
  currentPath: string;
  goto(url: string): void;
}
const SettingsMenuView: React.FC<SettingsMenuViewProps> = ({
  currentPath,
  goto,
}) => {
  return (
    <SettingsMenuWrapper>
      <SettingsTitle />
      <MenuList>
        {settingsKeys.map(k => (
          <MenuItem
            key={k}
            onClick={() => goto(settingsPaths[k])}
            active={currentPath === settingsPaths[k]}
          >
            <p>{settingsItemMenuLabel[k]}</p>
            <FontAwesomeIcon icon={faChevronRight} />
          </MenuItem>
        ))}
      </MenuList>
    </SettingsMenuWrapper>
  );
};

const SettingsMenuWrapper = styled.div`
  background-color: ${colorWithAlpha('gray4', 0.25)};
  flex: 0 0 34rem;
  padding: 3rem;
  box-sizing: border-box;
`;

const MenuList = styled.nav`
  overflow: hidden;
  margin-top: 3rem;
  width: 100%;
  border: 0.2rem solid ${Colors.gray6};
  border-radius: 0.5rem;
`;

interface MenuItemProps {
  active?: boolean;
}
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  background: white;
  height: 5rem;
  line-height: 2.4rem;
  box-sizing: border-box;
  padding: 1.3rem 1rem;
  width: 100%;
  font-size: 1.9rem;
  font-weight: bold;
  border-bottom: 0.05rem solid ${Colors.gray5};
  transition: 0.1s;

  p {
    flex: 1;
  }

  svg {
    color: ${Colors.primary};
  }

  ${(p: MenuItemProps) =>
    p.active &&
    `
    background-color: ${Colors.primary};
    color: ${Colors.white};

    svg {
      color: white;
    }
  `}

  ${(p: MenuItemProps) =>
    p.active == false &&
    `
    cursor: pointer;
    &:hover
    {
      background-color: ${Colors.highlight};
    }
  `}

  &:last-child {
    border: none;
  }
`;

export default SettingsMenuView;
