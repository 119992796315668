import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import * as React from 'react';
import { MouseEvent } from 'react';
import SVG from 'react-inlinesvg';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  useCurrentUser,
  useIsUserEmailVerified,
} from '../../redux/selectors/authSelectors';
import LogoImg from '../../static/svg/specc_logotype.svg';
import Colors from '../../styles/colors';
import SubButton from '../uiElements/button/SubButton';
import { AdminRole } from './AdminAdministratorDetail';
import AdminPopup from './UIelements/AdminPopup';

interface HeaderProps {
  role: AdminRole;
}
const Header: React.FC<HeaderProps> = props => {
  const history = useHistory();
  const user = useCurrentUser();

  const signOut = React.useCallback(
    (e: MouseEvent) => {
      window.localStorage.removeItem('selectedOrgId');
      firebase
        .auth()
        .signOut()
        .then(() => {
          history.push('/');
        });
      e.preventDefault();
    },
    [history]
  );

  const emailVerified = useIsUserEmailVerified();

  const UserInfoDetail = (
    <UserInfoPopup>
      <p>{user?.displayName ?? ''}</p>
      <span>{user?.email ?? ''}</span>
      <UserRole>
        {props.role === 'organizationAdmin' && <p>カスタマー企業</p>}
        {props.role === 'dentsuAdmin' && <p>電通</p>}
        {props.role === 'riddlerAdmin' && <p>RIDDLER</p>}
      </UserRole>
      <ButtonWrap>
        <SubButton size={'medium'} color={'negative'} onMouseDown={signOut}>
          ログアウト
        </SubButton>
      </ButtonWrap>
    </UserInfoPopup>
  );

  return (
    <HeaderWrapper role={props.role}>
      <LogoWrapper>
        <HeaderBtn role={props.role}>
          <Link to={'/admin'}>
            <SVG src={LogoImg} />
          </Link>
        </HeaderBtn>
        {props.role === 'organizationAdmin' && (
          <AdminBadge role={props.role}>管理画面</AdminBadge>
        )}
        {props.role === 'dentsuAdmin' && (
          <AdminBadge role={props.role}>電通管理画面</AdminBadge>
        )}
        {props.role === 'riddlerAdmin' && (
          <AdminBadge role={props.role}>RIDDLER管理画面</AdminBadge>
        )}
      </LogoWrapper>

      <RightContents>
        <AdminPopup width={360} content={UserInfoDetail}>
          <AdminUserInfo role={props.role}>
            <SubText>{user?.displayName ?? ''}</SubText>
            <FontAwesomeIcon icon={faChevronDown} />
          </AdminUserInfo>
        </AdminPopup>
      </RightContents>
    </HeaderWrapper>
  );
};

const UserInfoPopup = styled.div`
  padding: 1rem 1.6rem;
  text-align: left;

  p {
    font-size: 1.8rem;
  }
  span {
    font-size: 1.4rem;
  }
`;
const ButtonWrap = styled.div`
  background: ${Colors.gray2};
  padding: 1rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  button {
    display: block;
    margin-left: auto;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const Logo = styled.img`
  height: 2.6rem;
  display: inline-block;
`;

const Home = styled.img`
  height: 2.6rem;
  display: inline-block
  margin-right: 2rem;
`;

const SubText = styled.span`
  display: inline-block;
  color: ${Colors.gray8};
  margin-right: 1rem;
  font-size: 1.5rem;
  vertical-align: text-bottom;
`;

const RightContents = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

const HeaderWrapper = styled.div`
  padding: 2rem 0;
  border-bottom: ${Colors.primary} 3px solid;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0;
  padding: 0.8rem 2.4rem;
  background: white;

  ${(p: HeaderProps) =>
    p.role === 'riddlerAdmin'
      ? `
      background: ${Colors.primary};
      border: none;
      `
      : `
  `}
`;

const HeaderBtn = styled.div`
  display: inline-block;
  margin-right: 2rem;

  a {
    display: block;
  }

  ${(p: HeaderProps) =>
    p.role === 'riddlerAdmin'
      ? `
      svg .cls-1 {
        fill: white;
        transition: 0.2s;
      }
    
      &:hover {
        svg .cls-1 {
          fill: white;
        }
      }
      `
      : `
      svg .cls-1 {
        fill: ${Colors.primary};
        transition: 0.2s;
      }
    
      &:hover {
        svg .cls-1 {
          fill: ${Colors.primary};
        }
      }
  `}
`;

const AdminUserInfo = styled.div`
  background: white;
  width: 160px;
  padding: 0.8rem 1.4em;
  border-radius: 6px;
  margin: 0.2rem 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  border: 1px solid ${Colors.gray4};

  span {
    flex: 1;
    text-align: left;
    display: block;
  }

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: HeaderProps) =>
    p.role === 'riddlerAdmin'
      ? `
      background: ${Colors.primary};
      color: white;

      span {
        color: white;
      }

      &:hover {
        background: ${Colors.highlight};
      }    
      `
      : `
  `}
`;

const AdminBadge = styled.span`
  white-space: nowrap;
  padding: 0.4rem 0.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 4px;
  color: ${Colors.primary};
  border: 2px solid ${Colors.primary};

  ${(p: HeaderProps) =>
    p.role === 'riddlerAdmin'
      ? `
      color: white;
      border-color: white;
      `
      : `
  `}
`;

const UserRole = styled.div`
  display: flex;
  margin-top: 1rem;

  p {
    background: ${Colors.gray2};
    border-radius: 99px;
    font-size: 1.2rem;
    padding: 0.4rem 1rem;
    margin-right: 10rem;
    border: 1px solid ${Colors.gray4};
  }
`;

export default Header;
