import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import firebase from 'firebase/app';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { bareClient, getClient } from './api/client';
import { useServerTimeOffsetEffect } from './api/common';
import { useUserInfo } from './api/user';
import AdminRouter from './components/admin/AdminRouter';
import AuthAction from './components/auth/authAction/AuthAction';
import CancelEmail from './components/auth/authAction/CancelEmail/CancelEmail';
import UpdateEmail from './components/auth/authAction/UpdateEmail/UpdateEmail';
import Login from './components/auth/Login';
import PasswordReset from './components/auth/PasswordReset';
import Register from './components/auth/Register';
import VerifyEmail from './components/auth/VerifyEmail';
import DevEnvIndicator from './components/dev/DevEnvIndicator';
import TestPage from './components/dev/TestPage';
import DialogPrivacy from './components/dialog/DialogPrivacyView';
import DialogTerm from './components/dialog/DialogTermView';
import EventsRouter from './components/eventProvider/EventsRouter';
import Stage1Tester from './components/eventProvider/stagePages/stage1/Stage1Tester';
import AuthStateListener from './components/global/AuthStateListener';
import ErrorConsole from './components/global/ErrorConsole';
import ErrorOverlayController from './components/global/overlays/error/ErrorOverlayController';
import LoadingOverlay from './components/global/overlays/loading/LoadingOverlay';
import PanicConsole from './components/global/PanicConsole';
import History from './components/mypage/History';
import MyPage from './components/mypage/MyPage';
// import MyResults from './components/mypage/MyResults';
import Settings from './components/settings/Settings';
import Welcome from './components/uiElements/Welcome';
// import Preferences from './components/preferences/Preferences';
import {
  useCurrentUser,
  useIsUserEmailVerified,
  useIsUserInitialized,
} from './redux/selectors/authSelectors';
import Store from './redux/store';

export default function App() {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>(bareClient);

  const updateApolloClient = () => {
    getClient().then(client => setClient(client));
  };
  React.useEffect(() => {
    updateApolloClient();

    firebase.auth().onAuthStateChanged(() => {
      updateApolloClient();
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={Store}>
        <ErrorOverlayController />
        <LoadingOverlay />
        <AuthStateListener />
        <Router />
      </Provider>
    </ApolloProvider>
  );
}

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Redirect to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

interface RouterProps {}
const Router: React.FC<RouterProps> = () => {
  const user = useCurrentUser();
  const isUserInitialized = useIsUserInitialized();
  const emailVerified = useIsUserEmailVerified();
  const [currentUserInfo] = useUserInfo(user?.uid);
  const profileRegistered =
    currentUserInfo === undefined || !!currentUserInfo.email; // WIP
  const signedIn = user !== null;
  useServerTimeOffsetEffect();

  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: signedIn,
        redirectPathOnFail: '/',
      },
      {
        condition: emailVerified,
        redirectPathOnFail: '/verifyEmail',
      },
      {
        condition: profileRegistered,
        redirectPathOnFail: '/welcome',
      },
    ];
  }, [emailVerified, profileRegistered, signedIn]);

  const onlySignedOutUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: !signedIn,
        redirectPathOnFail: '/mypage',
      },
    ];
  }, [signedIn]);

  const onlyVerifyEmailUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: !emailVerified && signedIn,
        redirectPathOnFail: '/',
      },
    ];
  }, [emailVerified, signedIn]);

  const requireProfileSettingRules = React.useMemo<
    ConditionedRouteRule[]
  >(() => {
    return [
      {
        condition: !profileRegistered && signedIn,
        redirectPathOnFail: '/',
      },
    ];
  }, [profileRegistered, signedIn]);

  if (!isUserInitialized) {
    return null;
  }

  return (
    <BrowserRouter>
      <PanicConsole />
      <ErrorConsole />
      <DevEnvIndicator />
      <Switch>
        <Route path='/test'>
          <TestPage />
        </Route>
        <Route path='/register'>
          <ConditionedRoute rules={onlySignedOutUserRules}>
            <Register />
          </ConditionedRoute>
        </Route>
        <Route path='/admin'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <AdminRouter />
          </ConditionedRoute>
        </Route>
        <Route path='/settings'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <Settings />
          </ConditionedRoute>
        </Route>
        <Route path='/events'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <EventsRouter />
          </ConditionedRoute>
        </Route>
        <Route path='/history'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <History />
          </ConditionedRoute>
        </Route>
        <Route path='/dev/stage1test'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <Stage1Tester />
          </ConditionedRoute>
        </Route>
        <Route path='/verifyEmail'>
          <ConditionedRoute rules={onlyVerifyEmailUserRules}>
            <VerifyEmail />
          </ConditionedRoute>
        </Route>
        <Route path='/updateEmail/:email'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <UpdateEmail />
          </ConditionedRoute>
        </Route>
        <Route path='/cancelEmail/:email'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <CancelEmail />
          </ConditionedRoute>
        </Route>
        <Route path='/welcome'>
          <ConditionedRoute rules={requireProfileSettingRules}>
            <Welcome />
          </ConditionedRoute>
        </Route>
        <Route path='/mypage'>
          <ConditionedRoute rules={onlyValidUserRules}>
            <MyPage />
          </ConditionedRoute>
        </Route>
        <Route path='/password-reset'>
          <ConditionedRoute rules={onlySignedOutUserRules}>
            <PasswordReset />
          </ConditionedRoute>
        </Route>
        <Route path='/auth-action'>
          <AuthAction />
        </Route>
        <Route path='/dialog/term'>
          <DialogTerm />
        </Route>
        <Route path='/dialog/privacy'>
          <DialogPrivacy />
        </Route>
        <Route exact path='/'>
          <ConditionedRoute rules={onlySignedOutUserRules}>
            <Login />
          </ConditionedRoute>
        </Route>
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
};
