const envTypes = ['development', 'staging', 'production'] as const;
export type EnvType = typeof envTypes[number];

function assertEnvType(x: string): asserts x is EnvType {
  if (!envTypes.includes(x as EnvType)) {
    throw new Error('Unknown environment');
  }
}

export const getEnv = (): EnvType => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV ?? '';
  assertEnvType(REACT_APP_ENV);
  return REACT_APP_ENV;
};

export const env: EnvType = getEnv();

export const isStageDebug = process.env.REACT_APP_STAGE_DEBUG === 'true';
