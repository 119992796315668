import firebase from 'firebase/app';
import {
  DocumentDataHook,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import { PublicDocument } from './firestoreTypes';

export const usePublicDocuments = (
  documentId: string | null
): DocumentDataHook<PublicDocument> => {
  return useDocumentData<PublicDocument>(
    documentId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('public_documents')
          .doc(documentId)
      : undefined
  );
};
