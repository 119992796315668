import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TestQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'hello'>
);

export type AuthTestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthTestQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'authTest'>
);


export const TestDocument = gql`
    query Test {
  hello
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<TestQuery, TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestQuery, TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<TestQuery, TestQueryVariables>;
export const AuthTestDocument = gql`
    query AuthTest {
  authTest
}
    `;

/**
 * __useAuthTestQuery__
 *
 * To run a query within a React component, call `useAuthTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthTestQuery(baseOptions?: Apollo.QueryHookOptions<AuthTestQuery, AuthTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthTestQuery, AuthTestQueryVariables>(AuthTestDocument, options);
      }
export function useAuthTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthTestQuery, AuthTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthTestQuery, AuthTestQueryVariables>(AuthTestDocument, options);
        }
export type AuthTestQueryHookResult = ReturnType<typeof useAuthTestQuery>;
export type AuthTestLazyQueryHookResult = ReturnType<typeof useAuthTestLazyQuery>;
export type AuthTestQueryResult = Apollo.QueryResult<AuthTestQuery, AuthTestQueryVariables>;