export const settingsRoot = '/settings';

export const settingsKeys = [
  'profile',
  'password',
  'mail',
  'term',
  'privacy',
] as const;
export type SettingsKey = typeof settingsKeys[number];

export const settingsPaths: Record<SettingsKey, string> = {
  profile: `${settingsRoot}/profile`,
  password: `${settingsRoot}/password`,
  mail: `${settingsRoot}/mail`,
  term: `${settingsRoot}/term`,
  privacy: `${settingsRoot}/privacy`,
};

export const allSettingsPaths = [...Object.values(settingsPaths)];

export const settingsItemMenuLabel: Record<SettingsKey, string> = {
  profile: 'プロフィールの変更',
  password: 'パスワードの変更',
  mail: 'メールアドレスの変更',
  term: '利用規約',
  privacy: 'プライバシーポリシー',
};
