import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SettingsMenuView from './SettingsMenuView';

interface SettingsMenuProps {}
const SettingsMenu: React.FC<SettingsMenuProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const goto = React.useCallback(
    (url: string) => {
      history.push(url);
    },
    [history]
  );

  const currentPath = location.pathname;

  return <SettingsMenuView goto={goto} currentPath={currentPath} />;
};

export default SettingsMenu;
