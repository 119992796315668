import React from 'react';
import styled from 'styled-components';

import { colorWithAlpha } from '../../../styles/colors';
import StageIcon from '../../uiElements/StageIcon';
import StageTitle from '../../uiElements/StageTitle';

const DeviationCap: React.FC = () => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 46.944 14.944'>
      <path
        id='パス_1168'
        data-name='パス 1168'
        className='cls-1'
        d='M15.376-9.7v-3.712H5.152V-9.7ZM15.36,1.376V-5.168H8.912a1.089,1.089,0,0,0,.032-.24h6.224V-9.456H5.408V-6.5c0,.96-.112,1.408-.5,1.68V1.392A5.922,5.922,0,0,0,6.112.56v.816H8.736V.464h.272v.912H10.64V.464h.272v.912h1.552V.464h.272v.912ZM5.136-13.552H1.552C1.536-10.928,1.1-9.76.48-9.184v6.016a4.15,4.15,0,0,0,.624-.4V1.376h3.52v-9.04H4.112A16,16,0,0,0,5.136-13.552Zm6,6.3H9.456v-.368h1.68Zm1.712,5.184h-.384v-.544h.384Zm-1.84,0h-.464v-.544h.464Zm-1.92,0H8.624v-.544h.464ZM31.376,1.328v-2.4H28V-1.36h2.976V-3.7h-8.1c.064-.112.112-.24.176-.368h8.32V-7.248h-5.12v-.288h4.72v-2h-4.72v-.288h5.12v-3.2H29.584l.08-.5h-4.48l-.08.5H23.136a3.545,3.545,0,0,0-.16-.5H18.544a4.49,4.49,0,0,0,.128.5h-2.1v3.2h5.1v.288h-4.7v2h4.7v.288h-5.1v3.184h1.616a2.421,2.421,0,0,1-1.616.816v4.64a6.216,6.216,0,0,0,1.968-.7v.64Zm-7.808-2.4H20.9A10.106,10.106,0,0,0,22-2.32v.96h1.568ZM47.424-9.536v-3.648H45.648l.048-.368H41.568l-.064.368H37.136v3.648H41.12l-.048.288h-.528v6.592h6.528V-9.248H45.008l.048-.288Zm0,10.816V-2.384H40.256V-8.96h-3.12V1.28ZM37.04-13.552H33.456c-.016,2.624-.352,3.792-.976,4.368v6.016a7.375,7.375,0,0,0,.784-.512V1.376h3.52v-9.04h-.656A18.13,18.13,0,0,0,37.04-13.552Zm7.232,6.288h-.928v-.464h.928Zm0,1.424h-.928v-.432h.928Zm0,1.552h-.928V-4.7h.928Z'
        transform='translate(-0.48 13.552)'
      />
    </Svg>
  );
};
const Svg = styled.svg`
  height: 1.6rem;
  margin: 0.4rem 0 0;
  .cls-1 {
    fill: ${colorWithAlpha('gray8', 1)};
  }
`;

type RadarChartScoreProps = {
  stageNum: 1 | 2 | 3 | 4 | 5;
  deviation: number;
};
const RadarChartScore: React.FC<RadarChartScoreProps> = props => {
  console.log(props);
  return (
    <Wrapper {...pos[props.stageNum - 1]}>
      <Heading>
        <IconWrapper>
          <StageIcon stageNum={props.stageNum} />
        </IconWrapper>
        <TitleWrapper>
          <StageTitle stageNum={props.stageNum} />
        </TitleWrapper>
      </Heading>
      <Deviation>
        <DeviationCap />
        <DeviationFig>
          {props.deviation == 0 ? '-' : props.deviation.toFixed(1)}
        </DeviationFig>
      </Deviation>
    </Wrapper>
  );
};

type WrapperProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  transform?: string;
};
const pos: WrapperProps[] = [
  { top: '10rem', right: 'calc(50% + 16.5rem)' },
  { bottom: '31rem', left: '50%', transform: 'translateX(-50%)' },
  { top: '10rem', left: 'calc(50% + 16.5rem)' },
  { bottom: '0', left: 'calc(50% + 14rem)' },
  { bottom: '0', right: 'calc(50% + 14rem)' },
];

const Wrapper = styled.div`
  position: absolute;
  height: 7.5rem;
  font-size: 1.6rem;
  color: black;
  display: flex;
  ${(p: WrapperProps) => (p.top ? 'top: ' + p.top + ';' : '')}
  ${(p: WrapperProps) => (p.bottom ? 'bottom: ' + p.bottom + ';' : '')}
  ${(p: WrapperProps) => (p.left ? 'left: ' + p.left + ';' : '')}
  ${(p: WrapperProps) => (p.right ? 'right: ' + p.right + ';' : '')}
  ${(p: WrapperProps) => (p.transform ? 'transform: ' + p.transform + ';' : '')}
`;

const IconWrapper = styled.div`
  height: 4rem;
`;
const TitleWrapper = styled.div`
  margin-left: 0.8rem;
  height: 1.2rem;
`;
const Heading = styled.div`
  margin-top: 3px;
  display: block;
  text-align: center;
`;
const Deviation = styled.div`
  margin-left: 0.5rem;
`;
const DeviationFig = styled.div`
  line-height: 4rem;
  font-size: 3.9rem;
  font-weight: bold;
  font-family: 'Open Sans';
  color: ${colorWithAlpha('primary', 1)};
`;

export default RadarChartScore;
