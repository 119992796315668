import * as React from 'react';

import { useUserInfo } from '../../api/user';
import {
  useSetErrorOverlayState,
  useSetLoadingState,
} from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import VerifyEmailView from './VerifyEmailView';

interface VerifyEmailProps {}
const VerifyEmail: React.FC<VerifyEmailProps> = () => {
  const user = useCurrentUser();
  const [userInfo] = useUserInfo(user?.uid);
  const first = React.useMemo(() => {
    return !userInfo?.email;
  }, [userInfo]);
  const setLoadingState = useSetLoadingState();
  const setErrorOverlayState = useSetErrorOverlayState();
  const onResend = React.useCallback(() => {
    setLoadingState({ visible: true });

    user
      ?.sendEmailVerification()
      .then(() => {
        return;
      })
      .catch(() => {
        setErrorOverlayState({
          errorType: 'CommonError',
          message: 'エラーが発生しました。時間を置いてお試しください。',
        });
      })
      .finally(() => {
        setLoadingState({ visible: false });
      });
  }, [setErrorOverlayState, setLoadingState, user]);

  return <VerifyEmailView onResend={onResend} first={first} />;
};

export default VerifyEmail;
