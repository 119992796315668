import { finished } from 'stream';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  SubmitAnswer3Mutation,
  useSubmitAnswer3Mutation,
} from '../../../../api/__generated__/stage3.generated';
import { useStage3Submission } from '../../../../api/stage3';
import { stage3problems } from '../../../../lib/Stage3Problems';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import StageManager from '../../stageProviders/StageManager';
import Stage3View from './Stage3View';

interface Stage3Props {}

type AnsSent = { [v: number]: boolean };

const Stage3: React.FC<Stage3Props> = () => {
  const eventId = useEventId();
  const user = useCurrentUser();

  const [judge, setJudge] = React.useState<'correct' | 'wrong' | null>(null);

  const [previousSubmissions] = useStage3Submission(user, eventId);
  const dispatch = useDispatch();

  const [stage3Status, setStage3Status] = useLocalStorage<string | null>(
    'stage3:' + eventId + ':' + user?.uid,
    null
  );

  const ansSent = React.useMemo<AnsSent>(() => {
    const sent: AnsSent = {};
    previousSubmissions?.forEach(doc => {
      const correct = doc.answers.map(ans => ans.correct).includes(true);
      sent[doc.problemId] = correct;
    });
    return sent;
  }, [previousSubmissions]);

  const onCompleted = React.useCallback(
    (res: SubmitAnswer3Mutation) => {
      const correct = !!res.submitAnswer3?.correct;
      setJudge(correct ? 'correct' : 'wrong');
    },
    [setJudge]
  );

  React.useEffect(() => {
    if (
      !stage3Status &&
      stage3problems.every(problem => ansSent[problem.problemId])
    ) {
      setStage3Status('finished');
      dispatch(gameActions.setHasStageFinished({ stage: 3, finished: true }));
    }
  }, [ansSent, dispatch, setStage3Status]);

  const [submitAnswer3] = useRetryableMutationWithUI(useSubmitAnswer3Mutation, {
    hookOptions: {
      onCompleted,
    },
  });

  const onSend = React.useCallback(
    (ans: string, problemId: number) => {
      if (!eventId) {
        return;
      }

      setJudge(null);
      submitAnswer3({
        variables: {
          input: {
            eventId,
            answer: ans,
            problemId,
          },
        },
      });
    },
    [eventId, submitAnswer3]
  );

  return (
    <StageManager stageId={3} finishOnTimeUp>
      <CommonBG>
        <TimeGauge />
        {previousSubmissions !== undefined && (
          <Stage3View onSend={onSend} judge={judge} cleared={ansSent} />
        )}
      </CommonBG>
    </StageManager>
  );
};

export default Stage3;
