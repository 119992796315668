import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  CreateEventMutation,
  useAddParticipantMutation,
  useChangeEventInformationMutation,
  useCreateEventMutation,
  useRemoveParticipantMutation,
} from '../../../api/__generated__/event.generated';
import {
  EventDocument,
  useEventData,
  useEventMembersData,
} from '../../../api/event';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import {
  appActions,
  useSetLoadingState,
} from '../../../redux/actions/appActions';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import { useAdminEventId } from '../../../redux/selectors/gameSelectors';
import { EventStatus } from '../../../types';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminEventEditor, { date2UnixSec, setTime } from './AdminEventEditor';

const eventCreator = (orgId: string): EventDocument => ({
  eventId: 'create',
  name: '新規イベント',
  status: EventStatus.Preparing,
  scheduledStartTime: setTime(new Date(), { hour: 0, minute: 0 }),
  scheduledEndTime: setTime(new Date(), { hour: 23, minute: 59 }),
  place: '',
  startedOn: null,
  endedOn: null,
  resultCalculatedOn: null,
  orgId: orgId,
});

interface AdminEventEditViewProps {
  create?: boolean;
}
const AdminEventEditView: React.FC<AdminEventEditViewProps> = props => {
  const { create } = props;
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const orgId = useSelectedOrgId();
  const email = user?.email;
  const history = useHistory();
  const [event, setEvent] = React.useState<EventDocument | null>(null);
  const [participants, setParticipants] = React.useState<string[]>([]);
  const eventId = useAdminEventId();
  const [initEvent] = useEventData(eventId);
  const [initEventMembers] = useEventMembersData(eventId);
  const [valid, setValid] = React.useState<boolean>(true);

  const setLoadingState = useSetLoadingState();

  React.useEffect(() => {
    if (create && orgId) {
      setEvent(eventCreator(orgId));
    }

    if (initEvent !== undefined) {
      setEvent(initEvent);
    }
  }, [create, initEvent, orgId]);

  React.useEffect(() => {
    if (initEventMembers !== undefined) {
      setParticipants(initEventMembers.participants ?? []);
    }
  }, [initEventMembers]);

  const [changeEventInformation] = useChangeEventInformationMutation();
  const [addParticipant] = useAddParticipantMutation();
  const [removeParticipant] = useRemoveParticipantMutation();

  const onCreateEventCompleted = React.useCallback(
    (res: CreateEventMutation) => {
      setLoadingState({
        visible: false,
      });
      const { eventId } = res.createEvent;
      history.push(`/admin/events`);
    },
    [history, setLoadingState]
  );

  const [createEvent] = useRetryableMutationWithUI(useCreateEventMutation, {
    hookOptions: {
      onCompleted: onCreateEventCompleted,
    },
  });
  // TODO: 最初に終わった通信が、loadingを消してしまう問題
  const onEditConfirm = React.useCallback(() => {
    if (event === null) {
      return;
    }

    const operations: Promise<unknown>[] = [];

    if (create) {
      if (email == null) {
        return;
      }

      createEvent({
        variables: {
          input: {
            name: event.name,
            place: event.place,
            scheduledStartTime: date2UnixSec(event.scheduledStartTime),
            scheduledEndTime: date2UnixSec(event.scheduledEndTime),
            participantUids: participants,
            orgId: orgId ?? '',
          },
        },
      });
      return;
    }

    operations.push(
      changeEventInformation({
        variables: {
          input: {
            eventId: event.eventId,
            name: event.name,
            scheduledStartTime: date2UnixSec(event.scheduledStartTime),
            scheduledEndTime: date2UnixSec(event.scheduledEndTime),
            place: event.place,
            orgId: orgId ?? '',
          },
        },
      })
    );

    // メンバー整理
    const originalParticipants = initEventMembers?.participants ?? [];
    const addedParticipants = participants.filter(
      p => !originalParticipants.includes(p)
    );
    const removedParticipants = originalParticipants.filter(
      (p: string) => !participants.includes(p)
    );

    if (addedParticipants.length > 0) {
      operations.push(
        addParticipant({
          variables: {
            input: {
              eventId: event.eventId,
              participantUids: addedParticipants,
            },
          },
        })
      );
    }
    if (removedParticipants.length > 0) {
      operations.push(
        removeParticipant({
          variables: {
            input: {
              eventId: event.eventId,
              participantUids: removedParticipants,
            },
          },
        })
      );
    }

    Promise.all(operations)
      .then(() => {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: 'イベントの変更が保存されました。',
          })
        );
      })
      .catch(() => {
        dispatch(appActions.setErrorOverlayState({ errorType: 'CommonError' }));
      })
      .finally(() => {
        dispatch(appActions.setLoadingState({ visible: false }));
      });
  }, [
    event,
    create,
    changeEventInformation,
    orgId,
    initEventMembers?.participants,
    participants,
    email,
    createEvent,
    addParticipant,
    removeParticipant,
    dispatch,
  ]);

  const btnEdit: HeaderButton = {
    buttonText: event?.eventId == 'create' ? '作成する' : '更新する',
    event: onEditConfirm,
    disabled: !valid,
  };

  return (
    <AdminCommonBG
      backButton
      title={
        event?.eventId == 'create'
          ? 'テストイベント新規作成'
          : 'テストイベント編集'
      }
      headerButtons={[btnEdit]}
    >
      <AdminEventEditViewWrapper>
        {event && (
          <AdminEventEditor
            event={event}
            onUpdate={setEvent}
            participantUids={participants}
            onUpdateParticipantUids={setParticipants}
            changeValidCallback={v => {
              setValid(v);
            }}
          />
        )}
      </AdminEventEditViewWrapper>
    </AdminCommonBG>
  );
};

const AdminEventEditViewWrapper = styled.div``;

export default AdminEventEditView;
