import Stage4Problem, { Pos, Stage4Field } from './Stage4Problem';

const toggle: (prevField: Stage4Field, pos: Pos) => Stage4Field = (
  prevField,
  pos
) => {
  if (
    pos[0] < 0 ||
    pos[1] < 0 ||
    pos[0] >= prevField.length ||
    pos[1] >= prevField[0].length
  ) {
    return prevField;
  }

  return prevField.map((row, i) =>
    row.map((item, j) => {
      if (i === pos[0] && j === pos[1]) {
        return !item;
      } else {
        return item;
      }
    })
  );
};

const toggleAll = (prevField: Stage4Field, poses: Pos[]) => {
  let next = prevField;
  for (const pos of poses) {
    next = toggle(next, pos);
  }
  return next;
};

// 番号から位置に変換
const num2Pos: (n: number, problem: Stage4Problem) => Pos = (n, problem) => {
  const width = problem.width;
  const origin = problem.origin;
  const i = Math.floor((n - origin) / width);
  const j = (n - origin) % width;
  return [i, j];
};

// 位置から番号に変換
const pos2Num: (pos: Pos, problem: Stage4Problem) => number = (
  pos,
  problem
) => {
  const width = problem.width;
  const [i, j] = pos;
  return i * width + j + problem.origin;
};

export const stage4Problems: Stage4Problem[] = [
  // ルール:押した場所がつく
  new Stage4Problem({
    initField: [
      [false, false],
      [false, false],
    ],
    updateRule: (prevProblem, pos) => {
      return toggle(prevProblem.field, pos);
    },
    origin: 1,
    maxActionCount: 4,
  }),

  // Stage 1
  // ルール:押したボタンの斜め位置も同時に切り替わる
  new Stage4Problem({
    initField: [
      [false, false, true, false],
      [true, false, false, true],
      [true, false, false, false],
      [false, true, true, false],
    ],
    updateRule: (prevProblem, pos) => {
      const [i, j] = pos;
      const poses: Pos[] = [
        [i, j],
        [i + 1, j + 1],
        [i + 2, j + 2],
        [i + 3, j + 3],
        [i - 1, j - 1],
        [i - 2, j - 2],
        [i - 3, j - 3],
        [i + 1, j - 1],
        [i + 2, j - 2],
        [i + 3, j - 3],
        [i - 1, j + 1],
        [i - 2, j + 2],
        [i - 3, j + 3],
      ];
      return toggleAll(prevProblem.field, poses);
    },
    origin: 1,
    maxActionCount: 2,
  }),

  // Stage 2
  // ルール:タテヨコに隣接した場所も同時に切り替わる
  new Stage4Problem({
    initField: [
      [false, false, false],
      [true, false, false],
      [false, false, false],
    ],
    updateRule: (prevProblem, pos) => {
      const [i, j] = pos;
      const poses: Pos[] = [
        [i - 1, j],
        [i, j - 1],
        [i, j],
        [i, j + 1],
        [i + 1, j],
      ];
      return toggleAll(prevProblem.field, poses);
    },
    origin: 1,
    maxActionCount: 3,
  }),

  // Stage 3
  // ルール:押した数の約数・倍数も同時に切り替わる
  new Stage4Problem({
    initField: [
      [false, false, true],
      [true, true, true],
      [false, true, false],
    ],
    updateRule: (prevProblem, pos) => {
      const n = pos2Num(pos, prevProblem);

      const poses: Pos[] = [pos];

      for (let i = 1; i <= 9; i++) {
        if ((n > i && n % i === 0) || (n < i && i % n === 0)) {
          poses.push(num2Pos(i, prevProblem));
        }
      }

      return toggleAll(prevProblem.field, poses);
    },
    origin: 1,
    maxActionCount: 3,
  }),

  // Stage 4
  // ルール:「押した数と前に押した数の差」のボタンだけ切り替わる
  new Stage4Problem({
    initField: [
      [false, true, true, false],
      [true, true, true, true],
      [false, true, true, true],
      [true, true, false, true],
    ],
    updateRule: (prevProblem, pos, posBefore) => {
      if (posBefore === undefined) {
        return prevProblem.field;
      }

      const n = pos2Num(pos, prevProblem);
      const nBefore = pos2Num(posBefore, prevProblem);

      const poses: Pos[] = [num2Pos(Math.abs(n - nBefore), prevProblem)];
      return toggleAll(prevProblem.field, poses);
    },
    origin: 0,
    maxActionCount: 5,
  }),

  // Stage 5
  // ルール:「全体でついているライトの数と押した数の差」のボタンだけ同時に切り替わる
  new Stage4Problem({
    initField: [
      [false, true, true, false],
      [true, false, true, true],
      [true, true, false, true],
      [true, true, true, false],
    ],
    updateRule: (prevProblem, pos) => {
      const n = pos2Num(pos, prevProblem);
      const trueCount = prevProblem.field.flat().filter(x => x).length;

      const poses: Pos[] = [num2Pos(Math.abs(n - trueCount), prevProblem)];
      return toggleAll(prevProblem.field, poses);
    },
    origin: 0,
    maxActionCount: 5,
  }),
];
