import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useOrganizationMembers } from '../../../api/organization';
import { appActions } from '../../../redux/actions/appActions';
import CautionIconImg from '../../../static/svg/Icon-Caution.svg';
import Colors, { colorWithAlpha } from '../../../styles/colors';
import { Maybe } from '../../../types';
import OrgMemberSelector, { OrgMember } from './OrgMemberSelector';

type ParticipantManagerEdit = {
  type: 'edit';
  participantUids: string[];
  onUpdateParticipantUids: React.Dispatch<React.SetStateAction<string[]>>;
  onMoveToResult?: null;
  orgId: string;
  isAdmin: boolean;
  prevOrgAdmin?: string[];
};

type ParticipantManagerProps = ParticipantManagerEdit;
type OrgMemberRecord = {
  displayName: string;
  fullName: string;
  email: string;
  orgAdmin: Maybe<string>[];
  commonRoleType: Maybe<string>[];
};

export interface CsvData {
  data: Data;
  headers: Headers;
  filename: string;
  onCsvDownload: () => void;
}

const EditParticipantManager: React.FC<ParticipantManagerProps> = props => {
  const { participantUids, type, orgId, isAdmin } = props;

  const { orgMembers, orgMemberDoc, isError } = useOrganizationMembers(
    orgId === 'create' ? null : orgId
  );
  const {
    orgMembers: riddlerAdminMembers,
    orgMemberDoc: riddlerAdminMemberDoc,
    isError: riddlerIsError,
  } = useOrganizationMembers(isAdmin ? 'adminRiddler' : null);
  const {
    orgMembers: dentsuAdminMembers,
    orgMemberDoc: dentsuAdminMemberDoc,
    isError: dentsuIsError,
  } = useOrganizationMembers(isAdmin ? 'adminDentsu' : null);

  const adminMembers = React.useMemo(() => {
    return isAdmin
      ? (riddlerAdminMembers ?? [])
          .filter(e => e.commonRoles.includes('riddlerAdmin'))
          .concat(
            (dentsuAdminMembers ?? []).filter(e =>
              e.commonRoles.includes('dentsuAdmin')
            )
          )
          .concat(orgMembers ?? [])
      : null;
  }, [dentsuAdminMembers, isAdmin, orgMembers, riddlerAdminMembers]);

  const adminMemberDoc: Record<string, OrgMemberRecord | undefined> =
    React.useMemo(() => {
      return Object.fromEntries(
        Object.entries(dentsuAdminMemberDoc).concat(
          Object.entries(riddlerAdminMemberDoc)
        )
      );
    }, [dentsuAdminMemberDoc, riddlerAdminMemberDoc]);

  const onAddParticipant = React.useCallback(
    (newUser: OrgMember) => {
      props.onUpdateParticipantUids(p => [...p, newUser.uid]);
    },
    // propsをdependencyに入れたくないので，disable
    // destructするとtype discriminationが効かなくなるので
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onUpdateParticipantUids, props.type]
  );

  const dispatch = useDispatch();

  const onRemoveParticipants = React.useCallback(
    (uid: string) => {
      if (isAdmin) {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: '保存すると、管理権限が削除されます',
            backButtonText: 'キャンセル',
            retryButtonText: 'OK',
            onRetry: () => {
              props.onUpdateParticipantUids(parts =>
                parts.filter(p => p !== uid)
              );
            },
            onBack: () => {
              console.log('close');
            },
          })
        );
      } else {
        props.onUpdateParticipantUids(parts => parts.filter(p => p !== uid));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onUpdateParticipantUids, props.type]
  );
  const getAuthName = (p: string) => {
    return riddlerAdminMemberDoc[p]
      ? 'RIDDLER'
      : dentsuAdminMemberDoc[p]
      ? '電通'
      : orgMemberDoc[p] && orgMemberDoc[p]?.orgAdmin.includes(orgId)
      ? '企業管理者'
      : '企業メンバー';
  };
  const addIds = participantUids.filter(
    uid => !(props.prevOrgAdmin ?? []).includes(uid)
  );

  return (
    <ParticipantManagerWrapper>
      <TableWrapper>
        <OrgMemberSelector
          orgId={orgId}
          orgMembers={orgMembers}
          adminMembers={adminMembers}
          isError={isAdmin ? riddlerIsError && dentsuIsError : isError}
          onSelect={onAddParticipant}
          currentParticipantUids={participantUids}
          isAdmin={isAdmin}
        />
        <Table>
          <THead>
            <TR>
              <TH>氏名</TH>
              {isAdmin && (
                <>
                  <TH>権限</TH>
                </>
              )}
            </TR>
          </THead>
          <TBody type={type}>
            {!isAdmin && orgMembers === null ? (
              <TR>
                <TD>
                  <LoadingText>読み込み中...</LoadingText>
                </TD>
              </TR>
            ) : (
              <>
                {participantUids.filter(
                  p => orgMemberDoc[p] ?? adminMemberDoc[p]
                ).length == 0 ? (
                  <TR>
                    <TD>
                      <LoadingText>
                        {isAdmin ? '管理者' : '参加者'}がいません。
                      </LoadingText>
                    </TD>
                  </TR>
                ) : (
                  <>
                    {participantUids
                      .filter(p => orgMemberDoc[p] ?? adminMemberDoc[p])
                      .map((p, i) => (
                        <TR key={i}>
                          <TD>
                            <Name>
                              {orgMemberDoc[p]?.fullName ??
                                adminMemberDoc[p]?.fullName ??
                                `不明ユーザー (${p})`}
                              {isAdmin && addIds.includes(p) && (
                                <BalloonWrap>
                                  <CautionIcon />
                                  <Balloon>
                                    保存すると企業管理者に変更されます
                                  </Balloon>
                                </BalloonWrap>
                              )}
                            </Name>
                            <Mail>
                              {orgMemberDoc[p]?.email ??
                                adminMemberDoc[p]?.email ??
                                ''}
                            </Mail>
                            <ParticipantRemoveButton
                              onClick={() => onRemoveParticipants(p)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </ParticipantRemoveButton>
                          </TD>
                          {isAdmin && <TD>{getAuthName(p)}</TD>}
                        </TR>
                      ))}
                  </>
                )}
              </>
            )}
          </TBody>
        </Table>
      </TableWrapper>
    </ParticipantManagerWrapper>
  );
};

const ParticipantManagerWrapper = styled.div``;

const TableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  display: table;

  th:nth-child(2),
  td:nth-child(2) {
    flex: initial;
    width: 7rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    flex: initial;
    width: 7rem;
  }
  th:last-child,
  td:last-child {
    flex: initial;
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const THead = styled.thead`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray4};
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.6rem 0;

  th:first-child {
    width: 30%;
    /*min-width: 30%;*/
  }
`;

const TBody = styled.tbody`
  cursor: default;
  width: 100%;
  display: block;
  min-height: 40rem;

  td:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;

  }

  tr {
    padding: 0.6rem 0;
    position: relative;
    border-bottom: 1px solid ${Colors.gray4};
    transition: 0.2s;
  }

  tr:hover {
    background-color: ${colorWithAlpha('gray4', 0.5)};

    ${(p: ParticipantsListItemProps) =>
      `
      & ${ParticipantRemoveButton} {
        display: block !important;
      }
      `}
    }
  }
`;

const TR = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TH = styled.th`
  flex: 1;
  margin-left: 1rem;

  &:last-child {
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const TD = styled.td`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  white-space: nowrap;
  overflow: hidden;
`;

const ParticipantRemoveButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: none;
  line-height: 2.4rem;
  height: 2.4rem;
  right: 1.6rem;
  top: 1.6rem;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    color: ${Colors.error};
  }
`;

type ParticipantsListItemProps = Pick<ParticipantManagerProps, 'type'>;

const Name = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingText = styled.div`
  color: ${Colors.gray6};
  padding: 0.6rem 0;
  &:hover {
    background-color: initial;
  }
`;

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;

  &:last-child {
    border: none;
  }
`;

const UserEditIcon = styled.div`
  display: block;
  position: relative;
  right: -0.3rem;
  top: 0.2rem;
  color: ${Colors.gray4};

  svg {
    width: 28px !important;
    height: 28px;
  }
`;

const UserMinusIcon = styled.div`
  display: block;
  position: relative;
  right: -0.3rem;
  top: -0.6rem;
  color: ${Colors.gray4};

  svg {
    width: 12px !important;
    height: 12px;
  }
`;

const CautionIcon = styled.span`
  width: 1.9rem;
  height: 1.6rem;
  background-image: url(${CautionIconImg});
  background-size: contain;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  margin-left: 5px;
`;

const Balloon = styled.i`
  display: none;
  position: absolute;
  background-color: ${Colors.gray2};
  font-style: normal;
  border: 1px solid ${Colors.gray4};
  padding: 0px 4px;
  top: 36px;
  font-size: 1.4rem;
`;

const BalloonWrap = styled.div`
  display: flex;
  align-items: center;

  &:hover > i {
    display: block !important;
  }
`;

export default EditParticipantManager;
