import * as React from 'react';
import styled from 'styled-components';

import { useStage5AllGroups } from '../../api/stage5';
import { useAdminEventId } from '../../redux/selectors/gameSelectors';

interface AdminStage5OverviewProps {}
const AdminStage5Overview: React.FC<AdminStage5OverviewProps> = () => {
  const eventId = useAdminEventId();

  const [stage5groups] = useStage5AllGroups(eventId);

  return (
    <AdminStage5OverviewWrapper>
      {stage5groups && (
        <Stage5>
          {stage5groups.map(gr => (
            <Stage5Item key={gr.searchId}>
              <Stage5ItemItem>Presenter: {gr.presenter}</Stage5ItemItem>
              <Stage5ItemItem>STATE: {gr.state}</Stage5ItemItem>
              <Stage5ItemItem>
                current prob num: {gr.currentProblemNumber}
              </Stage5ItemItem>
              <Stage5ItemItem>
                current prob id: {gr.currentProblemId}
              </Stage5ItemItem>
              <Stage5ItemItem>
                active mem: {gr.activeMembers.join(', ')}
              </Stage5ItemItem>
              <Stage5ItemItem>members: {gr.members.join(', ')}</Stage5ItemItem>
              <Stage5ItemItem>searchid: {gr.searchId}</Stage5ItemItem>
              <Stage5ItemItem>
                accept ans: {gr.acceptingAnswer ? 'yes' : 'no'}
              </Stage5ItemItem>
            </Stage5Item>
          ))}
        </Stage5>
      )}
    </AdminStage5OverviewWrapper>
  );
};

const AdminStage5OverviewWrapper = styled.div``;
const Stage5 = styled.div``;
const Stage5Item = styled.div`
  padding: 1rem;
  font-size: 1.3rem;
  border: 1px solid #000;
`;
const Stage5ItemItem = styled.div``;

export default AdminStage5Overview;
