import Q1 from '../static/png/stage3/problems/question_01.png';
import Q2 from '../static/png/stage3/problems/question_02.png';
import Q3 from '../static/png/stage3/problems/question_03.png';
import Q4 from '../static/png/stage3/problems/question_04.png';
import Q5 from '../static/png/stage3/problems/question_05.png';
import Q6 from '../static/png/stage3/problems/question_06.png';
import Q7 from '../static/png/stage3/problems/question_07.png';
import Q8 from '../static/png/stage3/problems/question_08.png';
import Q9 from '../static/png/stage3/problems/question_09.png';
import Q10 from '../static/png/stage3/problems/question_10.png';
import Q11 from '../static/png/stage3/problems/question_11.png';
import Q12 from '../static/png/stage3/problems/question_12.png';
import Q13 from '../static/png/stage3/problems/question_13.png';
import Q14 from '../static/png/stage3/problems/question_14.png';
import Q15 from '../static/png/stage3/problems/question_15.png';
import Q16 from '../static/png/stage3/problems/question_16.png';
import Q17 from '../static/png/stage3/problems/question_17.png';
import Q18 from '../static/png/stage3/problems/question_18.png';
import Q19 from '../static/png/stage3/problems/question_19.png';
import Q20 from '../static/png/stage3/problems/question_20.png';

export type Stage3Problem = { problemId: number; problemImg: string };
export const stage3problems: Stage3Problem[] = [
  { problemId: 1, problemImg: Q1 },
  { problemId: 2, problemImg: Q2 },
  { problemId: 3, problemImg: Q3 },
  { problemId: 4, problemImg: Q4 },
  { problemId: 5, problemImg: Q5 },
  { problemId: 6, problemImg: Q6 },
  { problemId: 7, problemImg: Q7 },
  { problemId: 8, problemImg: Q8 },
  { problemId: 9, problemImg: Q9 },
  { problemId: 10, problemImg: Q10 },
  { problemId: 11, problemImg: Q11 },
  { problemId: 12, problemImg: Q12 },
  { problemId: 13, problemImg: Q13 },
  { problemId: 14, problemImg: Q14 },
  { problemId: 15, problemImg: Q15 },
  { problemId: 16, problemImg: Q16 },
  { problemId: 17, problemImg: Q17 },
  { problemId: 18, problemImg: Q18 },
  { problemId: 19, problemImg: Q19 },
  { problemId: 20, problemImg: Q20 },
];
