import * as React from 'react';
import styled from 'styled-components';

import FormElementView from './elements/FormElementView';
import { FormElement } from './formTypes';

interface FormProps {
  elements: FormElement[];
}

const Form: React.FC<FormProps> = ({ elements }) => {
  return (
    <FormWrapper>
      {elements.map(e => (
        <FormElementView key={e.id} element={e} />
      ))}
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  margin-bottom: 3rem;
`;

export default Form;
