import * as Apollo from '@apollo/client';
import * as React from 'react';

import { useMemorizedObject } from './useMemorizedObject';

export type GeneratedUseMutationType<T, U> = (
  baseOptions: Apollo.MutationHookOptions<T, U>
) => Apollo.MutationTuple<T, U>;

export type MutateOptions<T, U> =
  | Apollo.MutationFunctionOptions<T, U>
  | undefined;

type RetryableMutationTuple<T, U> = [
  // requestMutate
  (options?: MutateOptions<T, U>) => void,
  // result
  Apollo.MutationResult<T>,
  // retry
  () => void,
  // options
  MutateOptions<T, U>
];
export interface RetryableMutationOptions<T, U> {
  hookOptions: Apollo.MutationHookOptions<T, U>;
  retryOptions?: {
    auto?: {
      enabled: boolean;
      timeout?: number;
      limit?: number;
    };
  };
}

export const useRetryableMutation = <T, U>(
  useMutation: GeneratedUseMutationType<T, U>,
  options: RetryableMutationOptions<T, U>
): RetryableMutationTuple<T, U> => {
  const [mutateRequested, setMutateRequested] = React.useState<boolean>(false);
  const [mutateOptions, setMutateOptions] =
    React.useState<MutateOptions<T, U>>(undefined);
  const [autoRetryCount, setAutoRetryCount] = React.useState<number>(0);

  const hookOptions = useMemorizedObject(options.hookOptions);

  const autoRetryOptions = options.retryOptions?.auto;
  const autoRetryEnabled = autoRetryOptions?.enabled;

  const retryTimeout = autoRetryOptions?.timeout ?? 3000;
  const autoRetryCountLimit = autoRetryOptions?.limit ?? 3;

  const shouldMutate =
    mutateRequested &&
    !(autoRetryCount >= autoRetryCountLimit && autoRetryEnabled);

  const retry = React.useCallback(() => {
    setMutateRequested(true);
  }, []);

  const onCompleted = React.useCallback(
    (data: T) => {
      hookOptions?.onCompleted?.(data);
    },
    [hookOptions]
  );

  const onError = React.useCallback(
    (e: Apollo.ApolloError) => {
      hookOptions?.onError?.(e);

      if (autoRetryEnabled) {
        setAutoRetryCount(c => c + 1);
        setTimeout(retry, retryTimeout);
      }
    },
    [autoRetryEnabled, hookOptions, retry, retryTimeout]
  );

  const mutationOptions = React.useMemo(
    () => ({
      ...hookOptions,
      onCompleted,
      onError,
    }),
    [hookOptions, onCompleted, onError]
  );

  // TODO: mutateがPromiseを返すので、requestMutateもそうしないといけない気がする
  const [mutate, result] = useMutation(mutationOptions);

  React.useEffect(() => {
    if (shouldMutate) {
      setMutateRequested(false);
      mutate(mutateOptions);
    }
  }, [mutate, mutateOptions, shouldMutate]);

  const requestMutate = React.useCallback((options?: MutateOptions<T, U>) => {
    setMutateOptions(options);
    setMutateRequested(true);
  }, []);

  return [requestMutate, result, retry, mutateOptions];
};
