import * as React from 'react';
import styled from 'styled-components';

import CautionIconImg from '../../../static/svg/Icon-Caution.svg';
import CompleteIconImg from '../../../static/svg/Icon-Complete.svg';
import Colors from '../../../styles/colors';
import {
  ExtendedInputProps,
  InputBaseProps,
  ValidationResult,
} from './inputConfigs';
import TextInput from './TextInput';

type InputFullProps = ExtendedInputProps<InputBaseProps>;

const Input: React.FC<InputFullProps> = props => {
  const {
    value,
    validator,
    fullWidth,
    onFocus: parentOnFocus,
    onBlur: parentOnBlur,
  } = props;
  const [isFocused, setIsFocused] = React.useState(false);

  const validationResult = React.useMemo<ValidationResult>(
    () => validator?.(value, { isFocused }) ?? { type: 'none' },
    [validator, value, isFocused]
  );

  const onFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      parentOnFocus?.(e);
      setIsFocused(true);
    },
    [parentOnFocus]
  );

  const onBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      parentOnBlur?.(e);
      setIsFocused(false);
    },
    [parentOnBlur]
  );

  return (
    <InputWrapper fullWidth={fullWidth}>
      <TextInput
        invalid={validationResult.type === 'error'}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />
      {<Message textAlign={props.messageAlign} {...validationResult} />}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  margin-bottom: 1rem;

  input {
    margin-bottom: 0;
  }

  ${(p: Pick<InputBaseProps, 'fullWidth'>) => p.fullWidth && 'width: 100%;'}
  display: inline-block;
`;

interface MessageProps extends ValidationResult {
  textAlign?: InputFullProps['messageAlign'];
}

const Message: React.FC<MessageProps> = props => (
  <>
    {props.type === 'none' || !props.message ? (
      <></>
    ) : (
      <MessageText {...props}>
        <MessageWithIcon {...props} />
      </MessageText>
    )}
  </>
);

const MessageWithIcon: React.FC<MessageProps> = props => (
  <>
    {props.type === 'ok' ? (
      <CompleteIcon />
    ) : props.type === 'error' ? (
      <CautionIcon />
    ) : null}
    {props.message}
  </>
);

const MessageText = styled.div`
  margin-top: 0.6rem;
  font-size: 1.3rem;
  font-weight: medium;

  text-align: ${({ textAlign }: MessageProps) => textAlign ?? 'left'};

  ${(p: MessageProps) => p.type === 'error' && `color: ${Colors.error};`}
`;

const CautionIcon = styled.span`
  width: 1.9rem;
  height: 1.6rem;
  background-image: url(${CautionIconImg});
  background-size: contain;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

const CompleteIcon = styled.span`
  width: 2rem;
  height: 2rem;
  background-image: url(${CompleteIconImg});
  background-size: contain;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

export default Input;
