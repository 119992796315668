import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  EventDocument,
  getEventData,
  useEditableEventIds,
} from '../../../api/event';
import {
  EventWithMembersNonNull,
  getEventWithMemberData,
} from '../../../api/event';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import { EventStatus } from '../../../types';
import Pagination from '../../uiElements/Pagination';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import Filter from '../UIelements/Filter';
import { adminEventsSorter } from '../UIelements/sorter/AdminEventsSorter';
import AdminEventTable from './AdminEventTable';

interface AdminEventsProps {}
const AdminEvents: React.FC<AdminEventsProps> = props => {
  const user = useCurrentUser();
  const history = useHistory();
  const orgId = useSelectedOrgId();
  const [eventIds, loading, error] = useEditableEventIds(user, orgId ?? '');

  const onAdminEdit = (eventId?: string) => {
    history.push(`/admin/events/${eventId}/edit`);
  };
  const onAdminControl = (eventId?: string) => {
    history.push(`/admin/events/${eventId}/control`);
  };
  const onAdminCreate = React.useCallback(() => {
    history.push(`/admin/events/create`);
  }, [history]);

  const onAdminDelete = React.useCallback(() => {
    history.go(0);
  }, [history]);

  const [sortKey, setSortKey] = React.useState({
    key: 'scheduledStartTime',
    sort: 'ASC',
  });

  const onChangeSort = (sortObj: any) => {
    setSortKey(sortObj);
  };

  const btnAdd: HeaderButton = {
    buttonText: '＋新規作成',
    event: onAdminCreate,
    disabled: false,
  };

  const [pageCursor, setPageCursor] = React.useState<number>(0);
  const pageSize = 10;

  const [eventWithMemberDataList, setEventWithMemberDataList] = React.useState<
    EventWithMembersNonNull[]
  >([]);

  React.useEffect(() => {
    if (eventIds == null) {
      return;
    }
    setEventWithMemberDataList([]);
    eventIds.forEach(eid => {
      getEventWithMemberData(eid).then(data => {
        if (data === null) return;
        if (data.event === null) return;
        if (data.members === null) return;
        console.log(data);
        const nonNullData: EventWithMembersNonNull = {
          eventId: data.eventId,
          event: data.event,
          members: data.members,
        };
        setEventWithMemberDataList((prev: EventWithMembersNonNull[]) => {
          if (prev.some(e => e.eventId === nonNullData.eventId)) return prev;
          return [...prev, nonNullData];
        });
      });
    });
  }, [eventIds]);

  const [filter, setFilter] = React.useState<{
    Ongoing: boolean;
    Ended: boolean;
    Preparing: boolean;
    event_name?: string;
    place_name?: string;
  }>({
    Ongoing: true,
    Ended: true,
    Preparing: true,
  });

  const sorter = adminEventsSorter[sortKey.key][sortKey.sort];
  const filteredEventDataList = React.useMemo(() => {
    return eventWithMemberDataList
      .sort(sorter)
      .filter(
        e =>
          (!filter.event_name ||
            e.event.name.indexOf(filter.event_name) !== -1) &&
          (!filter.place_name ||
            (e.event.place &&
              e.event.place.indexOf(filter.place_name) !== -1)) &&
          ((filter.Ongoing &&
            (e.event.status == EventStatus.Ongoing ||
              e.event.status == EventStatus.Ending)) ||
            (filter.Ended && e.event.status == EventStatus.Ended) ||
            (filter.Preparing && e.event.status == EventStatus.Preparing))
      );
  }, [
    eventWithMemberDataList,
    filter.Ended,
    filter.Ongoing,
    filter.Preparing,
    filter.event_name,
    filter.place_name,
    sorter,
  ]);

  return (
    <AdminCommonBG title='テストイベント' headerButtons={[btnAdd]}>
      <Filter
        item={[
          { type: 'input', name: 'event_name', label: 'テストイベント名' },
          { type: 'input', name: 'place_name', label: '会場名' },
          {
            type: 'checkbox',
            name: 'Ongoing',
            label: '開催中',
            defaultValue: true,
          },
          {
            type: 'checkbox',
            name: 'Preparing',
            label: '未開催',
            defaultValue: true,
          },
          {
            type: 'checkbox',
            name: 'Ended',
            label: '終了したテスト',
            defaultValue: true,
          },
        ]}
        onSend={params => {
          // Task Event Filter *
          setFilter(params);
          setPageCursor(0);
        }}
      />
      <AdminEventsWrapper>
        {
          <AdminEventTable
            eventDatas={filteredEventDataList.filter(
              (_, i) => pageCursor <= i && i < pageCursor + pageSize
            )}
            onAdminEdit={onAdminEdit}
            onAdminControl={onAdminControl}
            onAdminCreate={onAdminCreate}
            onAdminDelete={onAdminDelete}
            sortObj={sortKey}
            onChangeSort={onChangeSort}
          />
        }
      </AdminEventsWrapper>
      <Pagination
        count={filteredEventDataList.length}
        begin={pageCursor}
        end={Math.min(pageCursor + pageSize, filteredEventDataList.length)}
        onNext={() => {
          setPageCursor(pageCursor + pageSize);
        }}
        onPrev={() => {
          setPageCursor(Math.max(pageCursor - pageSize, 0));
        }}
      />
    </AdminCommonBG>
  );
};

const AdminEventsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default AdminEvents;
