import format from 'date-fns/format';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  useRegisterUserInfoMutation,
  useUpdateUserInfoMutation,
} from '../../../api/__generated__/user.generated';
import { useUserInfo, useUserOrgInfo } from '../../../api/user';
import { appActions } from '../../../redux/actions/appActions';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import ProfileSettingsView from './ProfileSettingsView';

const formatDate = (d: Date) => format(d, 'yyyy-MM-dd');

type Data = {
  displayName: string;
  fullName: string;
  ruby: string;
  birthday: Date;
  startYear: number;
  department: string;
  employeeId: string;
  industryId: string;
  occupationId: string;
};
interface ProfileSettingsProps {
  welcome?: boolean;
}

const ProfileSettings: React.FC<ProfileSettingsProps> = props => {
  const user = useCurrentUser();
  const [updateUserInfo] = useUpdateUserInfoMutation();
  const [registerUserInfo] = useRegisterUserInfoMutation();
  const dispatch = useDispatch();

  const [currentUserInfo] = useUserInfo(user?.uid);
  const orgId = useSelectedOrgId() ?? currentUserInfo?.mainOrgId;
  const [currentOrgInfo] = useUserOrgInfo(user?.uid, orgId);

  const onSend = React.useCallback(
    ({
      displayName,
      fullName,
      ruby,
      birthday,
      startYear,
      department,
      employeeId,
      industryId,
      occupationId,
    }: Data) => {
      if (!user || !orgId) {
        console.log(user, orgId);
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: 'エラーが発生しました。',
          })
        );

        return;
      }

      if (currentUserInfo?.email) {
        // TODO: Error対応
        dispatch(appActions.setLoadingState({ visible: true }));
        const operations: Promise<unknown>[] = [
          user.updateProfile({
            displayName,
          }),
          updateUserInfo({
            variables: {
              input: {
                orgId,
                fullName,
                ruby,
                birthday: formatDate(birthday),
                startYear: startYear,
                department,
                employeeId,
                industryId,
                occupationId,
                email: user.email,
                displayName,
              },
            },
          }),
        ];

        Promise.all(operations)
          .then(() => {
            dispatch(
              appActions.setErrorOverlayState({
                errorType: 'CommonError',
                message: 'プロフィールの変更が完了しました。',
                onBack: () => {
                  window.location.reload();
                },
              })
            );
          })
          .catch(() => {
            dispatch(
              appActions.setErrorOverlayState({ errorType: 'CommonError' })
            );
          })
          .finally(() => {
            dispatch(appActions.setLoadingState({ visible: false }));
          });
      } else {
        // TODO: Error対応
        dispatch(appActions.setLoadingState({ visible: true }));

        const operations: Promise<unknown>[] = [
          user.updateProfile({
            displayName,
          }),
          registerUserInfo({
            variables: {
              input: {
                orgId,
                fullName,
                ruby,
                birthday: formatDate(birthday),
                startYear: startYear,
                department,
                employeeId,
                industryId,
                occupationId,
                email: user.email,
                displayName,
              },
            },
          }),
        ];

        Promise.all(operations)
          .then(() => {
            dispatch(
              appActions.setErrorOverlayState({
                errorType: 'CommonError',
                message: 'プロフィールの登録が完了しました。',
                onBack: () => {
                  window.location.reload();
                },
              })
            );
          })
          .catch(() => {
            dispatch(
              appActions.setErrorOverlayState({ errorType: 'CommonError' })
            );
          })
          .finally(() => {
            dispatch(appActions.setLoadingState({ visible: false }));
          });
      }
    },
    [user, orgId, dispatch, updateUserInfo]
  );

  return (
    <ProfileSettingsView
      welcome={props.welcome}
      currentOrgInfo={currentOrgInfo}
      currentUserInfo={currentUserInfo}
      currentDisplayName={user?.displayName}
      onSend={onSend}
    />
  );
};

export default ProfileSettings;
