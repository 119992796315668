import * as React from 'react';
import styled from 'styled-components';

import ClearedImg from '../../../../static/png/stage3/Stage3_cleared.png';
import Colors, { colorWithAlpha } from '../../../../styles/colors';

interface Stage3ProblemSelectorProps {
  problemIdx: number;
  setProblemIdx: (pIdx: number) => void;
  cleared: Partial<Record<number, boolean>>;
  problems: { problemId: number; problemImg: string }[];
}

const Stage3ProblemSelector: React.FC<Stage3ProblemSelectorProps> = ({
  problemIdx,
  setProblemIdx,
  cleared,
  problems,
}) => {
  return (
    <Stage3SelectorWrapper>
      {problems.map((problem, idx) => (
        <SelectorItem
          selected={idx === problemIdx}
          img={problem.problemImg}
          cleared={cleared[problem.problemId]}
          problemIdx={idx}
          setProblemIdx={setProblemIdx}
          key={idx}
        />
      ))}
    </Stage3SelectorWrapper>
  );
};

const Stage3SelectorWrapper = styled.div`
  flex: 3;
  flex-direction: column;
  overflow-y: scroll;
  padding: 6rem 0 1rem;
  background-color: ${Colors.gray4_op075};
`;

interface SelectorItemProps {
  selected?: boolean;
  cleared?: boolean;
  img: string;
  problemIdx: number;
  setProblemIdx: (idx: number) => void;
}
const SelectorItem: React.FC<SelectorItemProps> = ({
  selected,
  cleared,
  img,
  problemIdx,
  setProblemIdx,
}) => {
  const onClick = React.useCallback(() => {
    if (cleared) return;
    setProblemIdx(problemIdx);
  }, [setProblemIdx, problemIdx, cleared]);
  return (
    <SelectorItemWrapper selected={selected} cleared={cleared}>
      <SelectorItemImg
        selected={selected}
        img={img}
        cleared={cleared}
        onClick={onClick}
      />
    </SelectorItemWrapper>
  );
};

interface SelectorItemWrapperProps {
  cleared?: boolean;
  selected?: boolean;
}

const SelectorItemWrapper = styled.div`
  width: 80%;
  margin: 2rem auto;

  transition: border-color 0.2s;
  border-style: solid;
  border-width: 0.3rem;
  border-color: ${(p: SelectorItemWrapperProps) =>
    p.selected ? Colors.primary : 'transparent'};
  position: relative;
  &:hover {
    border-color: ${(p: SelectorItemWrapperProps) =>
      p.selected ? Colors.primary : p.cleared ? 'transparent' : Colors.gray4};
  }
`;

interface SelectorItemImgProps {
  img: string;
  cleared?: boolean;
  selected?: boolean;
}
const SelectorItemImg = styled.div`
  background-image: ${(p: SelectorItemImgProps) => 'url(' + p.img + ')'};
  background-size: cover;
  width: 100%;
  padding-top: ${(p: SelectorItemImgProps) =>
    p.cleared ? 'calc(100%/16*3)' : 'calc(100%/16*9)'};

  ::after {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.3rem;
    border-color: transparent;

    background: no-repeat
      ${(p: SelectorItemImgProps) =>
        p.cleared ? 'url(' + ClearedImg + ')' : ''};
    background-position: 75% 50%;
    background-size: 60%;
    background-color: ${(p: SelectorItemImgProps) =>
      p.cleared ? colorWithAlpha('gray8', 0.75) : 'transparent'};
  }
`;

export default Stage3ProblemSelector;
