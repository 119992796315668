import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useSendError } from '../../api/error';
import { errorActions } from '../../redux/actions/errorActions';
import { useErrorReport } from '../../redux/selectors/errorSelectors';

interface Props {}

const ErrorConsole: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const errorReport = useErrorReport();
  const [sending, setSending] = React.useState(false);

  const onCompleted = React.useCallback(() => {
    dispatch(errorActions.dropReport());
    setSending(false);
  }, [dispatch]);
  const onError = React.useCallback(() => {
    setTimeout(() => {
      setSending(false);
    }, 3000);
  }, []);
  const sendError = useSendError(onCompleted, onError);

  React.useEffect(() => {
    if (errorReport == null || sending) return;
    setSending(true);
    sendError(errorReport);
  }, [dispatch, errorReport, sendError, sending]);
  return null;
};

export default ErrorConsole;
