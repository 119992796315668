const emailLocalUnitPattern = "[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+";
const emailLocalPattern =
  emailLocalUnitPattern + '(?:\\.' + emailLocalUnitPattern + ')*';

const emailDomainUnitPattern = '[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?';
const emailDomainPattern =
  emailDomainUnitPattern + '(?:\\.' + emailDomainUnitPattern + ')*';

const emailPattern = emailLocalPattern + '@' + emailDomainPattern;

export const validEmailRegEx = new RegExp('^' + emailPattern + '$');

const separatedEmailsPattern =
  '(' + emailPattern + ')(?:[,\\s]+(' + emailPattern + '))*';
export const separatedEmailsRegEx = new RegExp(
  '^' + separatedEmailsPattern + '$'
);

// PW
export const validPasswordLength = /^[A-Za-z0-9]{8,}$/;
