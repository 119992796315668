import * as React from 'react';
import styled from 'styled-components';

import Stage4Problem, { Stage4Row } from '../../../../lib/Stage4Problem';
import Colors from '../../../../styles/colors';

interface Stage4ProblemViewProps {
  problem: Stage4Problem;
  onCellClick: (pos: [number, number]) => void;
  fadingOut?: boolean;
  locked: boolean;
  onEndFadingOut?: () => void;
  cleared: boolean;
  clickedColor: string;
}

const Stage4ProblemView: React.FC<Stage4ProblemViewProps> = props => {
  const { cleared, locked } = props;

  const field = props.problem.field;
  const origin = props.problem.origin;

  return (
    <Stage4ProblemViewWrapper fadingOut={props.fadingOut}>
      <Field>
        <Overlay locked={locked} />
        {field.map((row: Stage4Row, i: number) => (
          <FieldRow key={i}>
            {row.map((state, j) => (
              <Cell
                key={j}
                active={state}
                onClick={() => props.onCellClick([i, j])}
                clickedColor={props.clickedColor}
              >
                <CellText>{j + row.length * i + origin}</CellText>
              </Cell>
            ))}
          </FieldRow>
        ))}
        <ClearOverlay visible={cleared} />
      </Field>
    </Stage4ProblemViewWrapper>
  );
};

interface Stage4ProblemViewWrapperProps {
  fadingOut?: boolean;
}

export const PANEL_SIZE = '8rem';

const Stage4ProblemViewWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  opacity: ${(p: Stage4ProblemViewWrapperProps) => (p.fadingOut ? 0 : 1)};
  animation: ${(p: Stage4ProblemViewWrapperProps) =>
    p.fadingOut ? 'fadeout 2s' : 'fadein 1s'};
`;

interface LockedProps {
  locked: boolean;
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: ${Colors.white};
  width: 100%;
  height: 100%;
  visibility: ${(p: LockedProps) => (p.locked ? 'visible' : 'hidden')};
  opacity: ${(p: LockedProps) => (p.locked ? 0.85 : 0)};
  transition: 0.2s;
`;

const Field = styled.div`
  position: relative;
`;

export const CELL_MARGIN = '3px';

const FieldRow = styled.div`
  margin-bottom: ${CELL_MARGIN};
  &:last-child {
    margin-bottom: 0;
  }
  height: ${PANEL_SIZE};
`;

interface CellProps {
  active: boolean;
  clickedColor: string;
}

const Cell = styled.div`
  display: inline-block;
  background-color: ${(p: CellProps) =>
    p.active ? p.clickedColor : Colors.gray6};
  width: ${PANEL_SIZE};
  height: ${PANEL_SIZE};
  margin-right: ${CELL_MARGIN};
  transition: 0.2s background-color;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
`;

const CellText = styled.span`
  font-size: 4rem;
  font-weight: 600;
  color: ${Colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  user-select: none;
`;

interface ClearOverlayProps {
  visible: boolean;
}
const ClearOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.primary};
  z-index: 2;
  pointer-events: ${(p: ClearOverlayProps) => (p.visible ? 'inherit' : 'none')};

  opacity: ${(p: ClearOverlayProps) => (p.visible ? 1 : 0)};
  transition: 0.8s;
`;

export default Stage4ProblemView;
