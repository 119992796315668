import React, { FC } from 'react';
import styled from 'styled-components';

import _Button from './button/Button';

const Icon: FC = () => (
  <svg viewBox='0 0 40 40' width='100%'>
    <defs>
      <clipPath id='clip-path'>
        <rect width='40' height='40' fill='none' />
      </clipPath>
    </defs>
    <g id='Icon_-_User' data-name='Icon - User' clipPath='url(#clip-path)'>
      <path
        id='合体_1'
        data-name='合体 1'
        d='M0,40V35c0-5.5,9-10,20-10s20,4.5,20,10v5ZM10,10A10,10,0,1,1,20,20,10,10,0,0,1,10,10Z'
        fill='#343a40'
      />
    </g>
  </svg>
);

interface ProfileBarProps {
  user?: { displayName?: string | null; email?: string | null } | null;
  goToMyPage?: () => void;
  disableEmail?: boolean;
}

const ProfileBar: FC<ProfileBarProps> = ({
  user,
  goToMyPage,
  disableEmail,
}) => {
  return (
    <Wrapper>
      <ProfileWrapper>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <PlayerInfo>
          <PlayerName>{user?.displayName ?? 'NoName'}</PlayerName>
          {(disableEmail == null || disableEmail == false) && (
            <Mail>{user?.email ?? ''}</Mail>
          )}
        </PlayerInfo>
      </ProfileWrapper>
      {goToMyPage && (
        <Button variant='main' onClick={goToMyPage}>
          マイページに戻る
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem 4rem 0;
  display: flex;
  justify-content: space-between;
`;
const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 4rem;
`;

const Button = styled(_Button)`
  height: 4rem;
  margin: 2.5rem;
  line-height: 3.8rem;
  font-size: 1.5rem;
  display: inline-block;
  min-width: 0;
`;

const PlayerName = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
`;
const Mail = styled.div`
  font-size: 1.6rem;
`;
const PlayerInfo = styled.div`
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
`;

export default ProfileBar;
