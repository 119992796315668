import firebase from 'firebase/app';
import * as React from 'react';
import styled from 'styled-components';

import { useEventResult } from '../../../api/event';
import { useStage1SubmissionByUID } from '../../../api/stage1';
import {
  useStage2InfoSubmissionByUID,
  useStage2SubmissionByUID,
} from '../../../api/stage2';
import { useStage3SubmissionByUID } from '../../../api/stage3';
import { useStage4StageSubmissionsByUID } from '../../../api/stage4';
import { calcRank } from '../../../lib/stageResults';
import { useAdminEventId } from '../../../redux/selectors/gameSelectors';
import Stage1Result from './Stage1Result';

interface OverviewRowProps {
  uid: string | null;
  email?: string;
  displayName?: string;
}

const rankChars = ['C', 'C+', 'B', 'B+', 'A', 'A+', 'S'] as const;

const OverviewRow: React.FC<OverviewRowProps> = props => {
  const { uid, displayName } = props;

  const now = new Date().getTime();

  const eventId = useAdminEventId();
  const [result] = useEventResult(uid, eventId);
  const [eventResult] = useEventResult(uid, eventId);
  const startTimes = {
    1: eventResult?.startTimes1,
    2: eventResult?.startTimes2,
    3: eventResult?.startTimes3,
    4: eventResult?.startTimes4,
    5: eventResult?.startTimes5,
  };

  const [stage1subs] = useStage1SubmissionByUID(uid, eventId);
  const [stage2subs] = useStage2SubmissionByUID(uid, eventId);
  const [stage2InfoSubs] = useStage2InfoSubmissionByUID(uid, eventId);
  const [stage3subs] = useStage3SubmissionByUID(uid, eventId);
  const [stage4subs] = useStage4StageSubmissionsByUID(uid, eventId);

  const elapsedTime = (t: firebase.firestore.Timestamp | undefined): number => {
    if (!t) return 0;
    return (now - t.toMillis()) / 1000;
  };
  const elapsedString = (t: firebase.firestore.Timestamp | undefined) => {
    if (t === undefined) return '未開始';
    const sec = elapsedTime(t);
    if (sec === 0) return '未開始';
    if (sec < 363) return 'プレイ中';
    return '終了';
  };

  return (
    <OverviewRowWrapper>
      <Name>{displayName}</Name>
      <UID>{uid}</UID>
      <ResultContainer>
        <StageItem>
          <StageItemItem>Stage1</StageItemItem>
          <StageItemItem>
            状態: {elapsedString(startTimes?.['1'] ?? undefined)}
          </StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.stage1.score} ={' '}
                {
                  rankChars[
                    calcRank(result.calculatedScores.stage1.score ?? 0, 0)
                  ]
                }
              </StageItemItem>
            </>
          )}
          {stage1subs && <Stage1Result subs={stage1subs} />}
        </StageItem>
        <StageItem>
          <StageItemItem>Stage2</StageItemItem>
          <StageItemItem>
            状態: {elapsedString(startTimes?.['2'] ?? undefined)}
          </StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.stage2.score} ={' '}
                {
                  rankChars[
                    calcRank(result.calculatedScores.stage2.score ?? 0, 1)
                  ]
                }
              </StageItemItem>
            </>
          )}
          <Nums>
            {stage2InfoSubs && (
              <div>
                {[...stage2InfoSubs.answers.entries()].map(([id, ans]) => (
                  <span key={id}>id: {ans.correct ? '正解' : 'まだ'}</span>
                ))}
              </div>
            )}
            {stage2subs && <div>{stage2subs.finalAnswer}</div>}
          </Nums>
        </StageItem>
        <StageItem>
          <StageItemItem>Stage3</StageItemItem>
          <StageItemItem>
            状態: {elapsedString(startTimes?.['3'] ?? undefined)}
          </StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.stage3.score} ={' '}
                {
                  rankChars[
                    calcRank(result.calculatedScores.stage3.score ?? 0, 2)
                  ]
                }
              </StageItemItem>
            </>
          )}
          <Nums>
            {stage3subs &&
              [...stage3subs.entries()]
                .filter(([, ans]) =>
                  ans.answers.map(a => a.correct).includes(true)
                )
                .sort((a, b) => Number(a[1].problemId) - Number(b[1].problemId))
                .map(([, ans]) => (
                  <InlineNum key={ans.problemId}>{ans.problemId}</InlineNum>
                ))}
          </Nums>
        </StageItem>
        <StageItem>
          <StageItemItem>Stage4</StageItemItem>
          <StageItemItem>
            状態: {elapsedString(startTimes?.['4'] ?? undefined)}
          </StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.stage4.score} ={' '}
                {
                  rankChars[
                    calcRank(result.calculatedScores.stage4.score ?? 0, 3)
                  ]
                }
              </StageItemItem>
            </>
          )}
          <Nums>
            {stage4subs &&
              [...stage4subs.entries()].map(([id]) => (
                <InlineNum key={id}>{id}</InlineNum>
              ))}
          </Nums>
        </StageItem>
        <StageItem>
          <StageItemItem>Stage5</StageItemItem>
          <StageItemItem>
            状態: {elapsedString(startTimes?.['5'] ?? undefined)}
          </StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.stage5.score} ={' '}
                {
                  rankChars[
                    calcRank(result.calculatedScores.stage5.score ?? 0, 4)
                  ]
                }
              </StageItemItem>
            </>
          )}
        </StageItem>
        <StageItem>
          <StageItemItem>Total</StageItemItem>
          {result && (
            <>
              <StageItemItem>
                Score: {result.calculatedScores.total.score}
              </StageItemItem>
            </>
          )}
        </StageItem>
      </ResultContainer>
    </OverviewRowWrapper>
  );
};

const OverviewRowWrapper = styled.div`
  display: inline-block;
  border: 2px solid #000;
  box-sizing: border-box;
  padding: 1rem;
  flex: 0 0 32%;
  margin-bottom: 1rem;
`;

const StageItem = styled.div`
  margin: 2rem 2rem;
`;

const ResultContainer = styled.div``;

const StageItemItem = styled.span`
  display: inline-block;
  margin: 0 2rem;
  font-size: 1.4rem;
`;

const Name = styled.div`
  font-size: 1.6rem;
`;

const UID = styled.div`
  font-size: 1rem;
`;

const Nums = styled.div`
  margin: 0 3rem;
`;

const InlineNum = styled.span`
  display: inline-block;
  margin: 0.5rem;
  font-size: 1.3rem;
`;

export default OverviewRow;
