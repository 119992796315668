import * as React from 'react';
import styled from 'styled-components';

import Select, { SelectOverrideStyles } from '../../select/Select';
import { asSelectValueCallback } from '../../select/selectUtils';
import { FormDateElement } from '../formTypes';
import { getDayOptions, getNumberOptions, getYearOptions } from '../formUtils';

const yearOptions = getYearOptions(1940);
const monthOptions = getNumberOptions(1, 12);
interface FormDateElementViewProps {
  element: FormDateElement;
}

const selectStyles: SelectOverrideStyles = {
  container: {
    width: '9rem',
    fontSize: '1.5rem',
  },
  option: {
    fontSize: '1.5rem',
  },
  singleValue: {
    fontSize: '1.5rem',
  },
};

const selectStylesNarrow: SelectOverrideStyles = {
  container: {
    width: '7rem',
    fontSize: '1.5rem',
  },
  option: {
    fontSize: '1.5rem',
  },
  singleValue: {
    fontSize: '1.5rem',
  },
};

const FormDateElementView: React.FC<FormDateElementViewProps> = ({
  element: e,
}) => {
  const year = e.value.getFullYear();
  const month = e.value.getMonth() + 1;
  const day = e.value.getDate();

  const dayOptions = React.useMemo(() => {
    return getDayOptions(year, month);
  }, [month, year]);

  const yearValue = React.useMemo(() => {
    return yearOptions.find(op => op.value === year);
  }, [year]);
  const monthValue = React.useMemo(() => {
    return monthOptions.find(op => op.value === month);
  }, [month]);
  const dayValue = React.useMemo(() => {
    return dayOptions.find(op => op.value === day);
  }, [dayOptions, day]);

  React.useEffect(() => {
    e.onChange(new Date(`${year}/${month}/${day}`));
  }, [year, month, day]);

  const setYear = React.useCallback(
    (v: number) => {
      e.onChange(new Date(`${v}/${month}/${day}`));
    },
    [e.onChange, month, day]
  );
  const setMonth = React.useCallback(
    (v: number) => {
      e.onChange(new Date(`${year}/${v}/${day}`));
    },
    [e.onChange, year, day]
  );
  const setDay = React.useCallback(
    (v: number) => {
      e.onChange(new Date(`${year}/${month}/${v}`));
    },
    [e.onChange, year, month]
  );

  return (
    <Wrapper>
      <Select
        options={yearOptions}
        onChange={v => asSelectValueCallback(v, setYear)}
        overrideStyles={selectStyles}
        value={yearValue}
      />
      <Text>年</Text>
      <Select
        options={monthOptions}
        onChange={v => asSelectValueCallback(v, setMonth)}
        overrideStyles={selectStylesNarrow}
        value={monthValue}
      />
      <Text>月</Text>
      <Select
        options={dayOptions}
        onChange={v => asSelectValueCallback(v, setDay)}
        overrideStyles={selectStylesNarrow}
        value={dayValue}
      />
      <Text>日</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 32rem;
  display: flex;
`;

const Text = styled.span`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.3rem;
  align-self: center;
`;

export default FormDateElementView;
