import { Validators } from './useValidator';

export const displayNameValidators: () => Validators = () => [
  [val => !/^.{0,20}$/.test(val), '20文字以内で入力してください。'],
  [(_, state) => state.isFocused],
  [val => val === '', '表示名を入力してください'],
];
export const rubyValidators: () => Validators = () => [
  [(_, state) => state.isFocused],
  [val => !/^[ぁ-ゞー\u3000]*$/.test(val), '全角ひらがなで入力してください。'],
  [val => val === '', 'よみがなを入力してください'],
];
export const startYearValidators: () => Validators = () => [
  [val => !/^\d{0,4}$/.test(val), '4桁の数字で入力してください。'],
  [(_, state) => state.isFocused],
  [val => val === '', '入社年を入力してください'],
];
export const fullNameValidators: () => Validators = () => [
  [(_, state) => state.isFocused],
  [val => val === '', '氏名を入力してください'],
];
export const employeeIdValidators: () => Validators = () => [
  [val => !/^[a-zA-Z0-9]*$/.test(val), '半角英数字で入力してください'],
];
