import * as React from 'react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Colors from '../../../styles/colors';

interface AdminPopupProps {
  width: number;
  height?: number;
  content: ReactNode;
}
const AdminPopup: React.FC<AdminPopupProps> = props => {
  const [isFocused, setIsFocused] = React.useState(false);
  const onFocus = React.useCallback((e: React.FocusEvent) => {
    setIsFocused(true);
  }, []);

  const onBlur = React.useCallback((e: React.FocusEvent) => {
    setIsFocused(false);
  }, []);

  return (
    <Item>
      <ItemWrapper
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={0}
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          ev.nativeEvent.stopImmediatePropagation();
        }}
      >
        {props.children}
        <Wrapper>
          <Popup width={props.width} height={props.height} show={!isFocused}>
            {props.content}
          </Popup>
        </Wrapper>
      </ItemWrapper>
    </Item>
  );
};

const Item = styled.div``;

const ItemWrapper = styled.div`
  display: block;
`;

const Wrapper = styled.div`
  position: relative;
  width: 0px;
  height: 0px;
  margin-left: auto;
`;

interface StyleProps {
  show: boolean;
  width: number;
  height?: number;
}

const Popup = styled.div`
  ${(p: StyleProps) =>
    `
    width: ${p.width}px;
    height: ${p.height ? p.height + 'px;' : ''};
    left: -${p.width}px;
    ${p.show ? `display: none;` : ``}
  `}
  position: absolute;
  background: white;
  z-index: 99;
  border: 2px solid #ced4da;
  user-select: none;
`;

export default AdminPopup;
