import 'firebase/auth';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase/app';

const uri_prod = process.env.REACT_APP_APOLLO_CLIENT_URI;
const uri_dev = process.env.REACT_APP_APOLLO_CLIENT_URI_DEV;
const uri =
  process.env.REACT_APP_LOCAL_APOLLO_DEBUG === 'true' ? uri_dev : uri_prod;

const httpLink = createHttpLink({
  uri,
});

export const getClient: () => Promise<ApolloClient<NormalizedCacheObject>> =
  async () => {
    const auth = firebase.auth();

    const authLink = setContext(async (_, { headers }) => {
      // get the firebase  authentication id token
      const token =
        (auth.currentUser && (await auth.currentUser.getIdToken())) || null;
      // return the headers to the context so httpLink can read them

      return {
        headers: {
          ...headers,
          authorization: token ?? '',
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return client;
  };

export const bareClient = new ApolloClient({
  uri,
  cache: new InMemoryCache(),
});
