import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import * as React from 'react';
import {
  CollectionDataHook,
  DocumentDataHook,
  useCollection,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { TS2DateType } from '../lib/firebase/firestoreTypeUtils';
import { Maybe, OrganizationMember } from '../types';
import { useGetOrganizationMembersLazyQuery } from './__generated__/organization.generated';
import { commonRoleType } from './firestoreTypes';
import {
  Organization as OrganizationDocumentRaw,
  UserOrgInfo as UserOrgDocumentRaw,
} from './firestoreTypes';

export const orgSchema = gql`
  query getOrganizationMembers($input: OrganizationInput!) {
    getOrganizationMembers(input: $input) {
      uid
      email
      displayName
      fullName
      commonRoles
      orgAdmin
      ruby
      enable
    }
  }

  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      orgId
    }
  }

  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      orgId
    }
  }

  mutation deleteOrganization($input: OrganizationInput!) {
    deleteOrganization(input: $input) {
      orgId
    }
  }
`;

export type OrganizationDocumentNoId = TS2DateType<OrganizationDocumentRaw>;
export type OrganizationDocument = OrganizationDocumentNoId & {
  orgId: string;
};
export type UserOrgDocumentNoId = TS2DateType<UserOrgDocumentRaw>;
export type UserOrgDocument = UserOrgDocumentNoId & {
  uid: string;
};

const useMappedOrganizationDocument = <TError extends Error>(
  orgId: string | null,
  raw: LoadingHook<OrganizationDocumentRaw, TError>
): LoadingHook<OrganizationDocument, TError> => {
  const [data, loading, error] = raw;

  const mappedData: OrganizationDocument | undefined = React.useMemo(() => {
    if (orgId === null) return undefined;

    if (data) {
      return {
        ...data,
        orgId,
        createdOn: data.createdOn?.toDate() ?? null,
      };
    }

    return data;
  }, [data, orgId]);

  return [mappedData, loading, error];
};

const mapper = (raw: OrganizationDocumentRaw): OrganizationDocumentNoId => ({
  ...raw,
  createdOn: raw.createdOn?.toDate() ?? null,
});

export const useOrganizationData = (
  orgId: string | null
): DocumentDataHook<OrganizationDocumentNoId, 'orgId'> => {
  const ref =
    orgId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('organizations')
          .doc(orgId)
      : undefined;
  return useDocumentData<OrganizationDocumentNoId, 'orgId'>(ref, {
    transform: mapper,
    idField: 'orgId',
  });
};

export const useOrganization = (orgId: string | null) => {
  return useDocumentData<OrganizationDocumentRaw>(
    orgId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('organizations')
          .doc(orgId)
      : undefined
  );
};

export const useOwnerOrganizations = (user: firebase.User | null) => {
  return useCollectionData<OrganizationDocumentRaw>(
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('organizations')
          .where('enable', '==', true)
          .where('admin', 'array-contains', user.uid)
      : undefined
  );
};

type OrgMember = Pick<
  OrganizationMember,
  | 'uid'
  | 'email'
  | 'displayName'
  | 'commonRoles'
  | 'fullName'
  | 'ruby'
  | 'enable'
  | 'orgAdmin'
>;
export type OrgMemberRecord = {
  displayName: string;
  email: string;
  fullName: string;
  ruby: string;
  enable: boolean;
  commonRoleType: Maybe<string>[];
  orgAdmin: Maybe<string>[];
};
type OrgMembersResult = {
  orgMembers: OrgMember[] | null;
  orgMemberDoc: Record<string, OrgMemberRecord | undefined>;
  isError: boolean;
};

export const useOrganizationMembers = (
  orgId: string | null
): OrgMembersResult => {
  const [getOrgMembers, { data, error }] = useGetOrganizationMembersLazyQuery(
    {}
  );
  const isError = error !== undefined;

  React.useEffect(() => {
    if (orgId !== null) {
      getOrgMembers({
        variables: {
          input: {
            orgId,
          },
        },
      });
    }
  }, [getOrgMembers, orgId]);

  const orgMembers = data?.getOrganizationMembers ?? null;

  // uid => [displayName, email]
  const orgMemberDoc: Record<string, OrgMemberRecord | undefined> =
    React.useMemo(() => {
      if (orgMembers === null) return {};
      return Object.fromEntries(
        orgMembers.map(mem => {
          const name = mem.displayName === '' ? '(No Name)' : mem.displayName;
          return [
            mem.uid,
            {
              displayName: name,
              email: mem.email,
              fullName: mem.fullName,
              ruby: mem.ruby,
              enable: mem.enable,
              commonRoleType: mem.commonRoles,
              orgAdmin: mem.orgAdmin,
            },
          ];
        })
      );
    }, [orgMembers]);

  return {
    orgMembers,
    orgMemberDoc,
    isError,
  };
};

export const useOrganizationUserOrgs = (
  orgId: string | null
): CollectionDataHook<UserOrgDocumentNoId, 'uid'> => {
  return useCollectionData<UserOrgDocumentNoId, 'uid'>(
    orgId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('organizations')
          .doc(orgId)
          .collection('user_orgs')
      : undefined,
    {
      idField: 'uid',
    }
  );
};

export const useAdminAllOrganizations = (): CollectionDataHook<
  OrganizationDocumentNoId,
  'orgId'
> => {
  return useCollectionData<OrganizationDocumentNoId, 'orgId'>(
    firebase
      .firestore()
      .collection('version')
      .doc('1')
      .collection('organizations')
      .where('enable', '==', true),
    {
      transform: mapper,
      idField: 'orgId',
    }
  );
};

export const useAdminOrganizations = (
  uid?: string
): CollectionDataHook<OrganizationDocumentNoId, 'orgId'> => {
  return useCollectionData<OrganizationDocumentNoId, 'orgId'>(
    uid
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('organizations')
          .where('enable', '==', true)
          .where('admin', 'array-contains', uid)
      : undefined,
    {
      transform: mapper,
      idField: 'orgId',
    }
  );
};
