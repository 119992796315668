import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitAnswer2MutationVariables = Types.Exact<{
  input: Types.Answer2;
}>;


export type SubmitAnswer2Mutation = (
  { __typename?: 'Mutation' }
  & { submitAnswer2?: Types.Maybe<(
    { __typename?: 'submitAnswer2Response' }
    & Pick<Types.SubmitAnswer2Response, 'correct'>
  )> }
);

export type SubmitAnswer2InfoMutationVariables = Types.Exact<{
  input: Types.InfoInput;
}>;


export type SubmitAnswer2InfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'submitAnswer2Info'>
);


export const SubmitAnswer2Document = gql`
    mutation SubmitAnswer2($input: Answer2!) {
  submitAnswer2(input: $input) {
    correct
  }
}
    `;
export type SubmitAnswer2MutationFn = Apollo.MutationFunction<SubmitAnswer2Mutation, SubmitAnswer2MutationVariables>;

/**
 * __useSubmitAnswer2Mutation__
 *
 * To run a mutation, you first call `useSubmitAnswer2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswer2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswer2Mutation, { data, loading, error }] = useSubmitAnswer2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAnswer2Mutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswer2Mutation, SubmitAnswer2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswer2Mutation, SubmitAnswer2MutationVariables>(SubmitAnswer2Document, options);
      }
export type SubmitAnswer2MutationHookResult = ReturnType<typeof useSubmitAnswer2Mutation>;
export type SubmitAnswer2MutationResult = Apollo.MutationResult<SubmitAnswer2Mutation>;
export type SubmitAnswer2MutationOptions = Apollo.BaseMutationOptions<SubmitAnswer2Mutation, SubmitAnswer2MutationVariables>;
export const SubmitAnswer2InfoDocument = gql`
    mutation submitAnswer2Info($input: InfoInput!) {
  submitAnswer2Info(input: $input)
}
    `;
export type SubmitAnswer2InfoMutationFn = Apollo.MutationFunction<SubmitAnswer2InfoMutation, SubmitAnswer2InfoMutationVariables>;

/**
 * __useSubmitAnswer2InfoMutation__
 *
 * To run a mutation, you first call `useSubmitAnswer2InfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswer2InfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswer2InfoMutation, { data, loading, error }] = useSubmitAnswer2InfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAnswer2InfoMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswer2InfoMutation, SubmitAnswer2InfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswer2InfoMutation, SubmitAnswer2InfoMutationVariables>(SubmitAnswer2InfoDocument, options);
      }
export type SubmitAnswer2InfoMutationHookResult = ReturnType<typeof useSubmitAnswer2InfoMutation>;
export type SubmitAnswer2InfoMutationResult = Apollo.MutationResult<SubmitAnswer2InfoMutation>;
export type SubmitAnswer2InfoMutationOptions = Apollo.BaseMutationOptions<SubmitAnswer2InfoMutation, SubmitAnswer2InfoMutationVariables>;