import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import {
  DocumentDataHook,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { PublicDocument, RegistrationToken } from './firestoreTypes';

export const adminRoleSchema = gql`
  mutation removeAdminRole($input: AdminRoleInput!) {
    removeAdminRole(input: $input) {
      success
    }
  }
  mutation deleteUser($input: UserDeleteInput!) {
    deleteUser(input: $input) {
      success
    }
  }
  mutation adminUpdateUserInfo($input: AdminUpdateUserInfoInput!) {
    adminUpdateUserInfo(input: $input) {
      success
    }
  }
`;

export const useAllRegistrationTokens = (): LoadingHook<
  RegistrationToken[],
  Error
> => {
  return useCollectionData<RegistrationToken>(
    firebase
      .firestore()
      .collection('version')
      .doc('1')
      .collection('registration_tokens')
      .where('disabled', '==', false)
  );
};

export const useOrgAllRegistrationTokens = (
  orgId: string | null
): LoadingHook<RegistrationToken[], Error> => {
  return useCollectionData<RegistrationToken>(
    orgId
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('registration_tokens')
          .where('disabled', '==', false)
          .where('orgId', '==', orgId)
      : undefined
  );
};
