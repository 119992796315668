import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SettingsMain from './SettingsMain';
import { allSettingsPaths, settingsPaths, settingsRoot } from './settingsPaths';

interface SettingsProps {}
const Settings: React.FC<SettingsProps> = () => {
  return (
    <Switch>
      <Route path={allSettingsPaths}>
        <SettingsMain />
      </Route>
      <Route exact path={settingsRoot}>
        <Redirect to={settingsPaths.profile} />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>
    </Switch>
  );
};

export default Settings;
