import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useRemainingTime = () =>
  useSelector(state => state.time.timerRemainingTime);
export const useTimerStartTime = () =>
  useSelector(state => state.time.timerStartTime);
export const useTimerDuration = () =>
  useSelector(state => state.time.timerDuration);
export const useTimer = () =>
  useSelector(state => ({
    remainingTime: state.time.timerRemainingTime,
    duration: state.time.timerDuration,
    noGaugeTransition: state.time.noGaugeTransition,
  }));
export const useGetServerTime = () => {
  const offset = useSelector(state => state.time.serverTimeOffset);
  return useCallback(() => Date.now() / 1000 + offset, [offset]);
};
