import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../../styles/colors';
import { TextButtonProps } from './buttonConfigs';

const TextButton: React.FC<TextButtonProps> = props => {
  return <TextButtonBody>{props.children}</TextButtonBody>;
};

const TextButtonBody = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${Colors.primary};
  font-size: 1.3rem;
  line-height: 1.3rem;
  position: relative;
  padding-right: 1.2rem;
  /* padding: 0.1rem 1rem 0.1rem 0; */

  &:hover {
    border-bottom: ${Colors.primary} 0.1rem solid;
  }

  &:after {
    position: absolute;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-color: ${Colors.primary};
    border-style: solid;
    border-width: 0.2rem 0.2rem 0 0;
    transform: translateY(-50%) translateY(-0.1rem) rotate(45deg);
    top: 50%;
    right: 0.2rem;
  }
`;

export default TextButton;
