import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game2, Game2Info } from './firestoreTypes';

export const stage2Schema = gql`
  mutation SubmitAnswer2($input: Answer2!) {
    submitAnswer2(input: $input) {
      correct
    }
  }

  mutation submitAnswer2Info($input: InfoInput!) {
    submitAnswer2Info(input: $input)
  }
`;

export type Stage2InfoSubmission = Game2Info;
export type Stage2Submission = Game2;

export const useStage2Submission = (
  user: firebase.User | null,
  eventId: string | null
): LoadingHook<Stage2Submission, Error> => {
  return useDocumentData<Stage2Submission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('2')
      : undefined
  );
};
export const useStage2SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage2Submission, Error> => {
  return useDocumentData<Stage2Submission>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(uid)
          .doc('2')
      : undefined
  );
};
export const useStage2InfoSubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage2InfoSubmission, Error> => {
  return useDocumentData<Stage2InfoSubmission>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(uid)
          .doc('2Info')
      : undefined
  );
};

export const useStage2InfoSubmission = (
  user: firebase.User | null,
  eventId: string | null
): LoadingHook<Stage2InfoSubmission[], Error> => {
  return useCollectionData<Stage2InfoSubmission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('2Info')
          .collection('problem_id')
      : undefined
  );
};
