import {
  faArrowLeft,
  faBuilding,
  faCalendar,
  faChevronDown,
  faFileExport,
  faUser,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ReactNode } from 'react';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useUserInfo } from '../../../api/user';
import * as AuthActions from '../../../redux/actions/authActions';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import Colors from '../../../styles/colors';
import _SubButton from '../../uiElements/button/SubButton';
import { AdminRole } from '../AdminAdministratorDetail';
import OrganizationSelector from '../UIelements/OrganizationSelector';

export interface HeaderButton {
  buttonText?: string;
  buttonDom?: ReactNode;
  event?: () => void;
  chevron?: boolean;
  disabled?: boolean;
}

export interface CsvData {
  data: Data;
  headers: Headers;
  filename: string;
}

interface AdminNavigationProps {
  role: AdminRole;
  headerButtons?: HeaderButton[];
  title: string;
  backButton?: boolean;
  downloadCsvButton?: () => void;
}

const AdminNavigation: React.FC<AdminNavigationProps> = props => {
  const history = useHistory();
  const goTo = React.useCallback(
    (url: string) => {
      history.push(url);
    },
    [history]
  );
  let parentPage = '';
  if (history.location.pathname.split('/').length > 2) {
    parentPage = history.location.pathname.split('/')[2];
  }

  const matchEvent = useRouteMatch('/admin/events/:eventId');
  const matchEventMode = useRouteMatch('/admin/events/:eventId/:mode');
  const matchUser = useRouteMatch('/admin/accounts/:userId');
  const matchUserEdit = useRouteMatch('/admin/accounts/:userId/:edit');
  const matchUserHistory = useRouteMatch(
    '/admin/accounts/:userId/history/:eventId'
  );

  const dispatch = useDispatch();
  const orgOnChange = React.useCallback(
    v => {
      dispatch(
        AuthActions.setSelectedOrgId({
          orgId: v.value,
        })
      );
      if (matchEvent || matchEventMode) {
        history.push('/admin/events');
      }
      if (matchUser || matchUserEdit || matchUserHistory) {
        history.push('/admin/accounts');
      }
    },
    [
      dispatch,
      history,
      matchEvent,
      matchEventMode,
      matchUser,
      matchUserEdit,
      matchUserHistory,
    ]
  );

  const user = useCurrentUser();
  const [userInfo] = useUserInfo(user?.uid);

  const currentSelectedOrgId = useSelectedOrgId();
  React.useEffect(() => {
    if (
      userInfo &&
      (userInfo.commonRoles?.length ?? 0) === 0 &&
      currentSelectedOrgId != userInfo.mainOrgId
    ) {
      dispatch(
        AuthActions.setSelectedOrgId({
          orgId: userInfo.mainOrgId,
        })
      );
    }
  }, [currentSelectedOrgId, dispatch, userInfo]);

  return (
    <NavigationWrapper>
      <NavWrap>
        <SideNavigation>
          {props.role !== 'organizationAdmin' && (
            <NavigationSectionRiddler>
              {/* リドラ */}
              {/*
              <NavigationItem
                selected={parentPage == 'preset'}
                onClick={() => goTo('/admin/preset')}
              >
                <span>
                  <FontAwesomeIcon icon={faPaste} />
                </span>
                <p>プリセット</p>
              </NavigationItem>
              */}
              <NavigationItem
                selected={parentPage == 'administrators'}
                onClick={() => goTo('/admin/administrators')}
              >
                <span>
                  <FontAwesomeIcon icon={faUserShield} />
                </span>
                <p>管理者アカウント</p>
              </NavigationItem>
              <NavigationItem
                selected={parentPage == 'organization'}
                onClick={() => goTo('/admin/organization')}
              >
                <span>
                  <FontAwesomeIcon icon={faBuilding} />
                </span>
                <p>企業</p>
              </NavigationItem>
              {/*
              <NavigationItem
                selected={parentPage == 'analyze'}
                onClick={() => goTo('/admin/analyze')}
              >
                <span>
                  <FontAwesomeIcon icon={faChartPie} />
                </span>
                <p>分析</p>
              </NavigationItem>
              */}
            </NavigationSectionRiddler>
          )}
          <NavigationSection>
            {/* リドラ・カスタマー企業 */}
            <OrganizationSelector
              onChange={orgOnChange}
              initialSelectedOrgId={currentSelectedOrgId}
              isAdmin={false}
            />
            <NavigationItem
              selected={parentPage == 'events'}
              onClick={() => goTo('/admin/events')}
            >
              <span>
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              <p>テストイベント</p>
            </NavigationItem>
            <NavigationItem
              selected={parentPage == 'accounts'}
              onClick={() => goTo('/admin/accounts')}
            >
              <span>
                <FontAwesomeIcon icon={faUser} />
              </span>
              <p>アカウント</p>
            </NavigationItem>
          </NavigationSection>
        </SideNavigation>
        <NavigationBottomSection>
          <NavigationItem onClick={() => goTo('/')}>
            <span>
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
            <p>テストに戻る</p>
          </NavigationItem>
        </NavigationBottomSection>
      </NavWrap>

      <ContentWrapper>
        <ContentHeader>
          {props.backButton && (
            <BackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </BackButton>
          )}
          <h1>{props.title}</h1>
          {props.downloadCsvButton && (
            <CsvButton onClick={props.downloadCsvButton}>
              <FontAwesomeIcon icon={faFileExport} />
              CSVのダウンロード
            </CsvButton>
          )}
          {props.headerButtons != null && (
            <>
              {props.headerButtons.map((single, i) => {
                if (single.buttonDom) {
                  return single.buttonDom;
                } else {
                  return (
                    <SubButton
                      key={`subbutton_${i}`}
                      size='medium'
                      color='positive'
                      onClick={single.event}
                      disabled={single.disabled}
                    >
                      {single.buttonText && single.buttonText}
                      {single.chevron === true && (
                        <span>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      )}
                    </SubButton>
                  );
                }
              })}
            </>
          )}
        </ContentHeader>
        <Content>{props.children}</Content>
      </ContentWrapper>
    </NavigationWrapper>
  );
};
interface NavigationItemProps {
  selected?: boolean;
}

const NavWrap = styled.div``;

const SubButton = styled(_SubButton)`
  margin-left: 1rem;

  span {
    margin-left: 1rem;
  }
`;

const NavigationWrapper = styled.div`
  display: flex;
`;
const SideNavigation = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 60px - 46px);
  background: #f0f3f5;
  width: 24rem;
`;
const ContentWrapper = styled.div`
  width: calc(100% - 24rem);
`;

const Content = styled.div`
  height: calc(100vh - 12rem);
  overflow-y: scroll;
`;
const ContentHeader = styled.div`
  display: flex;
  background: white;
  align-items: center;
  height: 6rem;
  padding: 0rem 3rem;
  border-bottom: 1px solid ${Colors.gray4};

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2rem;
    flex: 1;
  }
`;
const NavigationSectionRiddler = styled.div`
  background: #e9ecef;
  border-bottom: 1px solid ${Colors.gray4};
`;
const NavigationSection = styled.div`
  background: #f0f3f5;
`;
const NavigationBottomSection = styled.div`
  border-top: 1px solid ${Colors.gray4};
  background: #f0f3f5;
`;
const NavigationItem = styled.div`
  display: flex;
  padding: 1.2rem 3rem;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;

  span {
    margin-right: 1rem;
    svg {
      color: ${Colors.gray6};
      width: 20px !important;
      height: 20px !important;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.4rem;
  }

  ${(p: NavigationItemProps) =>
    p.selected === true
      ? `
      background: ${Colors.highlight};
      `
      : `
      &:hover {
        background: ${Colors.gray4};
      }
  `}
`;

const BackButton = styled.div`
  margin-left: -1rem;
  background: white;
  border-radius: 99px;
  padding: 1rem;
  margin-right: 0.6rem;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

const CsvButton = styled.div`
  font-size: 1.3rem;
  color: ${Colors.accent};
  cursor: pointer;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export default AdminNavigation;
