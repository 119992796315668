import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import styled from 'styled-components';

import {
  EventDocument,
  getEventData,
  useEventMembersData,
} from '../../api/event';
import Colors from '../../styles/colors';
import { EventStatus } from '../../types';
import Button from '../uiElements/deprecated/Button';

const eventTableTypes = [
  'adminEditView',
  'adminControlView',
  'eventsView',
  'resultsView',
] as const;
type EventTableType = typeof eventTableTypes[number];
const tableTopText: Record<EventTableType, string> = {
  adminControlView: 'すべてのテスト一覧',
  adminEditView: '開催予定のテスト一覧',
  eventsView: '参加予定のテスト一覧',
  resultsView: '過去に参加したテスト一覧',
};

type EventTableProps = {
  eventIds: string[];
} & (
  | {
      type: 'adminEditView' | 'adminControlView';
      onJoin?: undefined;
      onAdminEdit: (eventId: string) => void;
      onAdminControl: (eventId: string) => void;
      onAdminCreate: () => void;
      showResult?: undefined;
      activeEventId?: undefined;
    }
  | {
      type: 'eventsView';
      onJoin: (eventId: string) => void;
      onAdminEdit?: undefined;
      onAdminControl?: undefined;
      onAdminCreate?: undefined;
      showResult?: undefined;
      activeEventId?: undefined;
    }
  | {
      type: 'resultsView';
      onJoin?: undefined;
      onAdminEdit?: undefined;
      onAdminControl?: undefined;
      onAdminCreate?: undefined;
      showResult: (eventId: string) => void;
    }
);

const EventTable: React.FC<EventTableProps> = props => {
  const { eventIds } = props;
  const isAdminView =
    props.type === 'adminControlView' || props.type === 'adminEditView';

  const [eventDatas, setEventDatas] = React.useState<EventDocument[]>([]);
  const eventDatasSorted = React.useMemo(
    () =>
      eventDatas.sort(
        (a, b) =>
          b.scheduledStartTime.getTime() - a.scheduledStartTime.getTime()
      ),
    [eventDatas]
  );

  React.useEffect(() => {
    setEventDatas([]);
    eventIds.forEach(eid => {
      getEventData(eid).then(data => {
        if (data === null) return;
        setEventDatas(prev => {
          if (prev.some(e => e.eventId === data.eventId)) return prev;
          return [...prev, data];
        });
      });
    });
  }, [eventIds]);

  return (
    <EventTableWrapper>
      {isAdminView == false && (
        <TopInfo>
          <TopText>{tableTopText[props.type]}</TopText>
        </TopInfo>
      )}
      <Table>
        <THead>
          <TR>
            <TH>開催期間</TH>
            <TH>テストイベント名</TH>
            <TH>会場</TH>
            {isAdminView && <TH>参加者</TH>}
            <TH />
          </TR>
        </THead>

        <TBody>
          {eventDatasSorted.map(data => (
            <EventRow key={data.eventId} eventData={data} {...props} />
          ))}
        </TBody>
      </Table>
    </EventTableWrapper>
  );
};

type EventRowProps = EventTableProps & {
  eventData: EventDocument;
};
const EventRow: React.FC<EventRowProps> = props => {
  const ev = props.eventData;
  const eventId = ev.eventId;
  const [initEventMembers] = useEventMembersData(eventId);
  const participants = initEventMembers?.participants ?? null;
  const participantsCount =
    participants !== null ? String(participants.length) : '--';

  if (props.type === 'resultsView' && ev.status !== 'ENDED') {
    return null;
  }

  if (ev.status === EventStatus.Removed) {
    return null;
  }

  return (
    <TR>
      <TD>
        <p>
          {ev.scheduledStartTime &&
            format(ev.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
        <p>
          {' - '}
          {ev.scheduledEndTime &&
            format(ev.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
      </TD>
      <TD>
        <p>{ev.name}</p>
      </TD>
      <TD>
        <p>{ev.place}</p>
      </TD>
      {props.type === 'eventsView' && (
        <TD>
          <RowButton
            variant='dark'
            disabled={ev.status !== EventStatus.Ongoing}
            onClick={() => props.onJoin(ev.eventId)}
          >
            テストをはじめる
          </RowButton>
        </TD>
      )}
      {props.type === 'adminEditView' && (
        <>
          <TD>{participantsCount}人</TD>
          <TD>
            <RowButton
              variant='dark'
              disabled={ev.status === EventStatus.Ended}
              onClick={() => props.onAdminEdit(ev.eventId)}
            >
              編集
            </RowButton>
          </TD>
        </>
      )}
      {props.type === 'adminControlView' && (
        <>
          <TD>{participantsCount}人</TD>
          <TD>
            <RowButton
              variant='dark'
              onClick={() => props.onAdminControl(ev.eventId)}
            >
              管理
            </RowButton>
          </TD>
        </>
      )}
      {props.type === 'resultsView' && (
        <TD>
          <RowButton variant='dark' onClick={() => props.showResult(eventId)}>
            結果を表示する
          </RowButton>
        </TD>
      )}
    </TR>
  );
};

const EventTableWrapper = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2.4rem;
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 12rem;
  }
  td:nth-child(2) {
    width: 10rem;
  }

  td:last-child {
    width: 15.5rem;
    padding-right: 0;
  }
`;

const RowButton = styled(Button).attrs({
  scale: 0.5,
})`
  border-radius: 5px;
  padding: 0.6rem 1.6rem;
  height: initial;
  width: 100%;
`;

const TopInfo = styled.div`
  width: 100%;
  margin-top: 1.8rem;
  border-bottom: 2px solid ${Colors.gray8};
  padding: 1.2rem 0;
  position: relative;
`;
const TopText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
`;

export default EventTable;
