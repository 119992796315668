import React from 'react';
import styled from 'styled-components';

import { colorWithAlpha } from '../../styles/colors';

const Icons = [
  <svg
    id='Icon_-_switch_-_large'
    data-name='Icon - switch - large'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 90 90'
  >
    <g id='レイヤー_1' data-name='レイヤー 1'>
      <path
        id='パス_258'
        data-name='パス 258'
        className='cls-1'
        d='M56.91,29.28H33.09A15.13,15.13,0,0,0,18,44.37v1.26A15.13,15.13,0,0,0,33.09,60.72H56.91A15.13,15.13,0,0,0,72,45.63V44.37A15.13,15.13,0,0,0,56.91,29.28ZM33.79,51.68A6.68,6.68,0,1,1,40.46,45a6.57,6.57,0,0,1-6.67,6.68Z'
      />
      <path
        id='パス_259'
        data-name='パス 259'
        className='cls-1'
        d='M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Zm0,86.4A41.4,41.4,0,1,1,86.4,45,41.4,41.4,0,0,1,45,86.4Z'
      />
    </g>
  </svg>,
  <svg
    id='Icon_-_program_-_large'
    data-name='Icon - program - large'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 90 90'
  >
    <g id='レイヤー_1' data-name='レイヤー 1'>
      <path
        id='パス_256'
        data-name='パス 256'
        className='cls-1'
        d='M66.89,28.22H51a7,7,0,0,0,.86-3.34A6.88,6.88,0,1,0,39,28.22H23.11V44.09A6.88,6.88,0,1,1,26.44,57a6.72,6.72,0,0,1-3.33-.87V72H63a3.93,3.93,0,0,0,3.94-3.94Z'
      />
      <path
        id='パス_257'
        data-name='パス 257'
        className='cls-1'
        d='M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Zm0,86.4A41.4,41.4,0,1,1,86.4,45,41.4,41.4,0,0,1,45,86.4Z'
      />
    </g>
  </svg>,
  <svg
    id='Icon_-_create_-_large'
    data-name='Icon - create - large'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 90 90'
  >
    <g id='レイヤー_1' data-name='レイヤー 1'>
      <path
        id='パス_248'
        data-name='パス 248'
        className='cls-1'
        d='M45,31.64A14.11,14.11,0,0,0,35,55.72h0c.32.31.68.63,1.07.94l.5,5.5a1.62,1.62,0,0,0-.68.59,1.56,1.56,0,0,0-.2,1.19l.08.39a1.58,1.58,0,0,0,1.1,1.16l.09,1h-.08a1.58,1.58,0,0,0-1.19,1.88l.08.39a1.65,1.65,0,0,0,.7,1,1.6,1.6,0,0,0,.81.25l.27,3H39.7a5.65,5.65,0,0,0,10.47,0h2.14L53,66.55h.09a1.58,1.58,0,0,0,1.19-1.88l-.09-.39a1.57,1.57,0,0,0-.89-1.08l.08-1.2a1.56,1.56,0,0,0,.87-1.77l-.09-.39a1.64,1.64,0,0,0-.51-.83l.22-2.33a12.894,12.894,0,0,0,1.1-1A14.11,14.11,0,0,0,45,31.64ZM50,59l-.07.73-8.57,1.92-1.11.25L40,59Zm-9.35,7.21-.18-1.87,9.23-2.06-.18,1.94L41.64,66ZM47,69.34v1.58a2,2,0,1,1-4-.06V69.34H40.94v-.66l8.4-1.88-.23,2.54Z'
      />
      <path
        id='パス_249'
        data-name='パス 249'
        className='cls-2'
        d='M47.62,45.26a.54.54,0,0,0-.62.2,4,4,0,0,0-.28.45c-.36.59-.85,1.41-1.58,1.41s-1.57-1-2-1.6c-.08-.1-.13-.19-.19-.26a.54.54,0,0,0-.62-.2.6.6,0,0,0-.4.64l1.6,8.33.78-.13h0l-.74-3.87L43.3,48.4,43,46.85a2.9,2.9,0,0,0,2.12,1.23,2.14,2.14,0,0,0,1.61-.82,2.609,2.609,0,0,0,.17-.2A.47.47,0,0,1,47,47l-1.1,6.95v.18h0l.81.12L48,45.93h0a.6.6,0,0,0-.38-.67Z'
      />
      <path
        id='パス_250'
        data-name='パス 250'
        className='cls-1'
        d='M45,23.68a1.29,1.29,0,0,0,1.28-1.19l.51-7.62a1.28,1.28,0,0,0-1.27-1.37h-1a1.28,1.28,0,0,0-1.27,1.36l.47,7.62A1.26,1.26,0,0,0,45,23.68Z'
      />
      <path
        id='パス_251'
        data-name='パス 251'
        className='cls-1'
        d='M32.5,28.81a1.27,1.27,0,0,0,.06-1.74l-5-5.76a1.458,1.458,0,0,1-.15-.12l-.12-.08a1.19,1.19,0,0,0-.36-.17h-.1a1.28,1.28,0,0,0-1.13.32l-.7.7a1.28,1.28,0,0,0,0,1.85l5.72,5.06A1.28,1.28,0,0,0,32.5,28.81Z'
      />
      <path
        id='パス_252'
        data-name='パス 252'
        className='cls-1'
        d='M65,22.06l-.69-.7a1.26,1.26,0,0,0-1.1-.36h-.1a1.19,1.19,0,0,0-.36.17l-.12.08a1.671,1.671,0,0,0-.16.12L57.41,27.1a1.28,1.28,0,0,0,1.8,1.8l5.76-5A1.29,1.29,0,0,0,65,22.06Z'
      />
      <path
        id='パス_253'
        data-name='パス 253'
        className='cls-1'
        d='M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Zm0,86.4A41.4,41.4,0,1,1,86.4,45,41.4,41.4,0,0,1,45,86.4Z'
      />
    </g>
  </svg>,
  <svg
    id='Icon_-_energy_-_large'
    data-name='Icon - energy - large'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 90 90'
  >
    <g id='レイヤー_1' data-name='レイヤー 1'>
      <path
        id='パス_254'
        data-name='パス 254'
        className='cls-3'
        d='M41.13,13.54C44.45,22.43,42.81,27.39,39,31.2,35,35.5,28.55,38.69,24.13,45c-5.94,8.4-7,26.75,14.46,31.54-9-4.75-10.94-18.51-1.23-27.08-2.5,8.32,2.17,13.64,8,11.72s9.42,2.17,9.3,6.84c-.09,3.19-1.27,5.9-4.63,7.41A23,23,0,0,0,69.56,52.62c0-11.63-10.37-13.19-5.12-23-6.23.53-8.32,4.63-7.75,11.26.37,4.43-4.17,7.38-7.61,5.45-2.75-1.68-2.71-4.87-.25-7.29C54,34,56,22.31,41.13,13.58l-.08-.08Z'
      />
      <path
        id='パス_255'
        data-name='パス 255'
        className='cls-1'
        d='M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Zm0,86.4A41.4,41.4,0,1,1,86.4,45,41.4,41.4,0,0,1,45,86.4Z'
      />
    </g>
  </svg>,
  <svg
    id='Icon_-_communicate_-_large'
    data-name='Icon - communicate - large'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 90 90'
  >
    <g id='レイヤー_1' data-name='レイヤー 1'>
      <path
        id='パス_246'
        data-name='パス 246'
        className='cls-1'
        d='M25.38,34.22a1.2,1.2,0,0,1-.91-.19L19.8,30.8a1.215,1.215,0,1,1,1.38-2L25.85,32a1.21,1.21,0,0,1,.31,1.69,1.19,1.19,0,0,1-.78.53ZM21.6,43.45a1.21,1.21,0,0,0-1.15-1.27l-5.67-.3A1.23,1.23,0,0,0,13.5,43a1.21,1.21,0,0,0,1.15,1.27l5.67.3h.28A1.22,1.22,0,0,0,21.6,43.45ZM33.65,26a1.21,1.21,0,0,0,.63-1.6L32,19.21a1.222,1.222,0,1,0-2.23,1l2.28,5.2a1.21,1.21,0,0,0,1.33.71,1.43,1.43,0,0,0,.27-.12Zm14.66-.21a4.65,4.65,0,0,1,1.15,1.15h0L70.34,57l4.55,1.14h.09a1.83,1.83,0,0,1,.57.26c1.68,1.17,1.07,5-1.37,8.51s-5.79,5.46-7.48,4.29a1.75,1.75,0,0,1-.47-.47h0l-2.7-3.88L28.21,57.94,28,57.88a4.58,4.58,0,0,1-1.38-.64C22.44,54.37,23.94,45,29.94,36.33S44.16,22.93,48.31,25.79Zm-1.39,2c-1.15-.79-3.35-.4-5.88,1.05A30.08,30.08,0,0,0,32,37.72a30.18,30.18,0,0,0-5.08,11.63c-.46,2.88-.05,5.07,1.1,5.87s3.35.4,5.88-1a30.08,30.08,0,0,0,9.08-8.86A30.18,30.18,0,0,0,48,33.68c.48-2.88.07-5.07-1.08-5.87Z'
      />
      <path
        id='パス_247'
        data-name='パス 247'
        className='cls-1'
        d='M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0Zm0,86.4A41.4,41.4,0,1,1,86.4,45,41.4,41.4,0,0,1,45,86.4Z'
      />
    </g>
  </svg>,
];

type StageIconProps = {
  stageNum: 1 | 2 | 3 | 4 | 5;
};
const StageIcon: React.FC<StageIconProps> = ({ stageNum }) => {
  return <Wrapper>{Icons[stageNum - 1]}</Wrapper>;
};

const Wrapper = styled.div`
  height: 100%;
  svg {
    height: 100%;
  }
  .cls-1 {
    fill: ${colorWithAlpha('gray8', 1)};
  }
  .cls-2 {
    fill: #fff;
  }
  .cls-3 {
    fill: ${colorWithAlpha('gray8', 1)};
    fill-rule: evenodd;
  }
`;

export default StageIcon;
