import { faMinus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { UserRecords } from '../../../api/firestoreTypes';
import { DataArray, getRankMessage } from '../../../lib/stageResults';
import { useGetServerTime } from '../../../redux/selectors/timeSelectors';
import Colors from '../../../styles/colors';
import { GroupState } from '../../../types';
import { STAGE_TIME_LIMIT } from '../../eventProvider/stageProviders/StageManager';
import { AdminAllStage5Groups } from './ControlParticipantManager';

type RankStatusProps = {
  stageId: number;
  userId: string;
  groups?: AdminAllStage5Groups;
  userRecords?: UserRecords;
  userRanks?: DataArray;
};

const RankStatus: React.FC<RankStatusProps> = props => {
  const { stageId, userId, groups, userRecords, userRanks } = props;
  const startTimes: (
    | 'startTimes1'
    | 'startTimes2'
    | 'startTimes3'
    | 'startTimes4'
    | 'startTimes5'
  )[] = [
    'startTimes1',
    'startTimes2',
    'startTimes3',
    'startTimes4',
    'startTimes5',
  ];
  const getServerTime = useGetServerTime();
  if (stageId < 4) {
    return (
      <Rank>
        {userRecords && userRecords[startTimes[stageId]] != null ? (
          (userRecords[startTimes[stageId]]?.toMillis() ?? 0) / 1000 +
            STAGE_TIME_LIMIT <
          getServerTime() ? (
            getRankMessage(userRanks?.[stageId] ?? -1)
          ) : (
            <>
              <UserEditIcon>
                <FontAwesomeIcon icon={faUserEdit} />
              </UserEditIcon>
            </>
          )
        ) : (
          <>
            {' '}
            <UserMinusIcon>
              <FontAwesomeIcon icon={faMinus} />
            </UserMinusIcon>
          </>
        )}
      </Rank>
    );
  }
  return (
    <Rank>
      {(() => {
        const group = groups?.filter(e => e.members.includes(userId)) ?? [];
        if (group.some(e => e.state == GroupState.Finished)) {
          return getRankMessage(userRanks?.[stageId] ?? -1);
        }
        if (group.some(e => e.state != GroupState.Initializing)) {
          return (
            <>
              <UserEditIcon>
                <FontAwesomeIcon icon={faUserEdit} />
              </UserEditIcon>
            </>
          );
        }
        return (
          <>
            <UserMinusIcon>
              <FontAwesomeIcon icon={faMinus} />
            </UserMinusIcon>
          </>
        );
      })()}
    </Rank>
  );
};

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;

  &:last-child {
    border: none;
  }
`;

const UserEditIcon = styled.div`
  display: block;
  position: relative;
  right: -0.3rem;
  top: 0.2rem;
  color: ${Colors.gray4};

  svg {
    width: 28px !important;
    height: 28px;
  }
`;

const UserMinusIcon = styled.div`
  display: block;
  position: relative;
  right: -0.3rem;
  top: -0.6rem;
  color: ${Colors.gray4};

  svg {
    width: 12px !important;
    height: 12px;
  }
`;
export default RankStatus;
