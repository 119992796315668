import firebase from 'firebase/app';
import * as React from 'react';
import styled from 'styled-components';

import { Stage1Submission } from '../../../api/stage1';
import {
  PanelKey,
  panelImgs,
} from '../../eventProvider/stagePages/stage1/DraggableArea';

interface Stage1ResultProps {
  subs: Stage1Submission;
}
const Stage1Result: React.FC<Stage1ResultProps> = ({ subs }) => {
  return (
    <Stage1ResultWrapper>
      {subs.answers.map((ans, i) => (
        <ResultRow key={`${ans.answer}-${i}`} ans={ans} />
      ))}
    </Stage1ResultWrapper>
  );
};

interface ResultRowProps {
  ans: {
    achieved: number[];
    answer: string;
    createdOn: firebase.firestore.Timestamp;
  };
}
const ResultRow: React.FC<ResultRowProps> = ({ ans }) => {
  const re =
    /^(([0-6pm][0-7])(,[0-6pm][0-7])*)?\|(([0-6pm][0-7])(,[0-6pm][0-7])*)?$/;

  const ansStr = ans.answer;

  if (!re.test(ansStr)) {
    return null;
  }

  // panelImgs;

  const sides = ansStr.split('|') as [string, string];

  return (
    <ResultRowWrapper noAchieve={ans.achieved.length === 0}>
      <PanelSide>
        {sides[0].split(',').map(p => (
          <Panel
            key={p[0]}
            panelId={p[0] as PanelKey}
            rotation={Number(p[1])}
          />
        ))}
      </PanelSide>
      <Equal>=</Equal>
      <PanelSide>
        {sides[1].split(',').map(p => (
          <Panel
            key={p[0]}
            panelId={p[0] as PanelKey}
            rotation={Number(p[1])}
          />
        ))}
      </PanelSide>
    </ResultRowWrapper>
  );
};

interface Stage1ResultRowWrapperProps {
  noAchieve: boolean;
}

const Stage1ResultWrapper = styled.div`
  margin: 0 3rem;
`;

const ResultRowWrapper = styled.div`
  ${(p: Stage1ResultRowWrapperProps) =>
    p.noAchieve &&
    `
    opacity: 0.3;
  `}
`;

const PanelSide = styled.div`
  display: inline-block;
`;

interface PanelProps {
  panelId: PanelKey;
  rotation: number;
}

const Panel = styled.div`
  display: inline-block;
  background-image: url(${(p: PanelProps) => panelImgs[p.panelId]});
  width: 2rem;
  height: 2rem;
  background-size: contain;
  transform: rotate(${(p: PanelProps) => p.rotation * 45}deg);
`;

const Equal = styled.div`
  height: 2rem;
  margin: 0 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: inline-block;
  vertical-align: top;
`;

export default Stage1Result;
