import firebase from 'firebase/app';
import * as React from 'react';
import styled from 'styled-components';

import {
  useAuthTestQuery,
  useTestQuery,
} from '../../api/__generated__/test.generated';
import { usePublicDocuments } from '../../api/publicDocuments';

interface APITestProps {}
const APITest: React.FC<APITestProps> = props => {
  const [token, setToken] = React.useState<string>('');

  React.useEffect(() => {
    const auth = firebase.auth();
    auth.currentUser?.getIdToken().then(tk => {
      setToken(tk);
    });
  });

  return (
    <APITestWrapper>
      <div>
        <TokenText>Token:</TokenText>
        <TokenInput value={token} />
      </div>
      <HelloWorldChild {...props} />
      <AuthTestChild {...props} />
      <TermsChild {...props} />
    </APITestWrapper>
  );
};

const TokenText = styled.p`
  display: inline-block;
`;

const TokenInput = styled.input``;

const HelloWorldChild: React.FC<APITestProps> = () => {
  const [trying, setTrying] = React.useState<boolean>(false);
  const { loading, error, data } = useTestQuery();

  return (
    <>
      {trying ? (
        <APITestWrapper>
          <p>
            {loading ? 'loading...' : error ? 'error!' : JSON.stringify(data)}
          </p>
        </APITestWrapper>
      ) : (
        <button onClick={() => setTrying(true)}>Hello</button>
      )}
    </>
  );
};

const AuthTestChild: React.FC<APITestProps> = () => {
  const [trying, setTrying] = React.useState<boolean>(false);
  const { loading, error, data } = useAuthTestQuery();

  console.log(data);

  return (
    <>
      {trying ? (
        <APITestWrapper>
          <p>
            {loading ? 'loading...' : error ? 'error!' : JSON.stringify(data)}
          </p>
        </APITestWrapper>
      ) : (
        <button onClick={() => setTrying(true)}>Auth test</button>
      )}
    </>
  );
};

const TermsChild: React.FC<APITestProps> = () => {
  const [trying, setTrying] = React.useState<boolean>(false);
  const [data, dataLoading] = usePublicDocuments('terms');
  return (
    <>
      {trying ? (
        <APITestWrapper>
          <p>
            {dataLoading
              ? 'loading...'
              : data === undefined
              ? 'undefined'
              : data.body}
          </p>
        </APITestWrapper>
      ) : (
        <button onClick={() => setTrying(true)}>Terms</button>
      )}
    </>
  );
};

const APITestWrapper = styled.div``;

export default APITest;
