import { useGetParticipantUserDataQuery } from '../../../api/__generated__/event.generated';
import { useAdminResultParams } from '../../../redux/selectors/gameSelectors';
import ResultDetail from '../../eventProvider/eventResultPage/ResultDetail';
import ProfileBar from '../../uiElements/ProfileBar';
import AdminCommonBG from '../AdminCommonBG';

interface AdminEventResultProps {}
const AdminEventResult: React.FC<AdminEventResultProps> = () => {
  const { eventId, uid } = useAdminResultParams();

  const { data } = useGetParticipantUserDataQuery({
    variables: {
      input: {
        eventId: eventId ?? '',
        uid: uid ?? '',
      },
    },
  });

  const user = data?.getParticipantUserData;

  return (
    <AdminCommonBG title='test'>
      <ProfileBar user={user} />
      <ResultDetail uid={uid} eventId={eventId} isAdminView listHref='/' />
    </AdminCommonBG>
  );
};

export default AdminEventResult;
