import styled, { CSSObject } from 'styled-components';

import Colors, { colorWithAlpha } from '../../../styles/colors';

type Variant = 'primary' | 'light' | 'dark' | 'primary-outlined' | 'warning';
type VariantMap<T> = Readonly<Record<Variant, T>>;
interface ButtonProps {
  variant?: Variant;
  disabled?: boolean;
  scale?: number;
}

const styles = (p: ButtonProps): VariantMap<CSSObject> => {
  const scale = (n: number) => (p.scale ?? 1) * n;

  return {
    primary: {
      backgroundColor: Colors.primary,
      color: Colors.white,
      border: 'none',
    },
    light: {
      lineHeight: `calc(${scale(6)}rem - ${scale(6)}px)`,
      backgroundColor: Colors.white,
      color: Colors.gray6,
      border: `${Colors.gray4} ${scale(3)}px solid`,
    },
    dark: {
      backgroundColor: Colors.gray8,
      color: Colors.white,
      border: 'none',
    },
    'primary-outlined': {
      backgroundColor: Colors.white,
      color: Colors.primary,
      border: `${Colors.primary} ${scale(3)}px solid`,
    },
    warning: {
      backgroundColor: Colors.error,
      color: Colors.white,
      border: 'none',
    },
  };
};
const disabledStyles: VariantMap<CSSObject> = {
  primary: {
    backgroundColor: colorWithAlpha('primary', 0.5),
  },
  light: {
    backgroundColor: colorWithAlpha('gray4', 0.75),
  },
  dark: {
    backgroundColor: colorWithAlpha('gray4', 0.75),
  },
  'primary-outlined': {
    backgroundColor: colorWithAlpha('gray4', 0.75),
  },
  warning: {
    backgroundColor: colorWithAlpha('gray4', 0.75),
  },
};
const hoverStyles: VariantMap<CSSObject> = {
  primary: {},
  light: {},
  dark: {
    backgroundColor: Colors.primary,
  },
  'primary-outlined': {},
  warning: {
    backgroundColor: Colors.error,
  },
};
const activeStyles: VariantMap<CSSObject> = {
  primary: {
    backgroundColor: Colors.highlight,
  },
  light: {},
  dark: {
    backgroundColor: Colors.highlight,
  },
  'primary-outlined': {
    color: Colors.accent,
    borderColor: Colors.accent,
  },
  warning: {
    backgroundColor: colorWithAlpha('error', 0.75),
  },
};

const Button = styled.button`
  outline: 0;
  user-select: none;
  font-weight: 700;
  opacity: 1;

  ${(p: ButtonProps) => {
    const scale = (n: number) => (p.scale ?? 1) * n;

    return `
      min-width: ${scale(18)}rem;
      padding: 0 ${scale(2)}rem;
      height: ${scale(6)}rem;
      line-height: ${scale(5.7)}rem;
      font-size: ${scale(2.9)}rem;
      letter-spacing: ${scale(0.15)}rem;
    `;
  }}

  ${(p: ButtonProps) => styles(p)[p.variant || 'primary']}
  &:hover {
    ${(p: ButtonProps) => hoverStyles[p.variant || 'primary']}
  }
  &:active {
    ${(p: ButtonProps) => activeStyles[p.variant || 'primary']}
  }
  &:disabled {
    pointer-events: none;
    ${(p: ButtonProps) => disabledStyles[p.variant || 'primary']}
  }
`;

export default Button;
