import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useSubmitAnswer4Mutation } from '../../../../api/__generated__/stage4.generated';
import {
  useStage4Penalty,
  useStage4StageSubmissions,
} from '../../../../api/stage4';
import { stage4Problems } from '../../../../lib/stage4Problems';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../../redux/actions/gameActions';
import { timeActions } from '../../../../redux/actions/timeActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStageState,
} from '../../../../redux/selectors/gameSelectors';
import { useRemainingTime } from '../../../../redux/selectors/timeSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import StageManager, {
  STAGE_TIME_LIMIT,
} from '../../stageProviders/StageManager';
import Stage4Main from './Stage4Main';

interface Stage4Props {}
const Stage4: React.FC<Stage4Props> = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const eventId = useEventId();
  const [penalty, incrementPenalty] = useStage4Penalty(user, eventId);
  const remainingTime = useRemainingTime() ?? 0;

  const [previousRecords] = useStage4StageSubmissions(user, eventId);
  const [isFadingOut, setIsFadingOut] = React.useState(false);
  const [submitAnswer4] = useRetryableMutationWithUI(useSubmitAnswer4Mutation, {
    hookOptions: {},
    loading: { disabled: true },
    error: {
      options: {
        errorType: 'CommonError',
        message: 'タイムの記録中にエラーが発生しました。',
      },
    },
  });

  const problems = stage4Problems;
  const [problemId, setProblemId] = React.useState<number>(0);
  const [maxClearedId, setMaxClearedId] = React.useState<number>(-1);

  React.useEffect(() => {
    dispatch(timeActions.setTimerOffset(10 * penalty));
  }, [dispatch, penalty]);

  const onClear = React.useCallback(() => {
    if (!eventId) return;
    setIsFadingOut(true);

    submitAnswer4({
      variables: {
        input: {
          eventId,
          problemId,
          time: STAGE_TIME_LIMIT - remainingTime,
        },
      },
    });
  }, [problemId, remainingTime, submitAnswer4, eventId]);

  const [stage4Status, setStage4Status] = useLocalStorage<string | null>(
    'stage4:' + eventId + ':' + user?.uid,
    null
  );

  const onAllClear = React.useCallback(() => {
    setStage4Status('finished');
    dispatch(gameActions.setHasStageFinished({ stage: 4, finished: true }));
  }, [dispatch, setStage4Status]);

  const safelySetProblemId = React.useCallback(
    (payload: number | ((prev: number) => number)) => {
      setProblemId(prev => {
        const next = typeof payload === 'function' ? payload(prev) : payload;
        console.log(next, problems, payload);
        if (next < problems.length) {
          return next;
        } else {
          if (stage4Status !== 'finished') {
            onAllClear();
          }
          return prev;
        }
      });
    },
    [onAllClear, problems, stage4Status]
  );

  const onEndFadingOut = React.useCallback(() => {
    setIsFadingOut(false);
  }, []);

  React.useEffect(() => {
    if (isFadingOut) {
      setTimeout(() => {
        onEndFadingOut?.();
      }, 2000);
    }
  }, [isFadingOut, onEndFadingOut]);

  React.useEffect(() => {
    if (isFadingOut) {
      return;
    }

    const clearedProblemIds =
      previousRecords?.map(rec => rec.problemId).map(id => Number(id)) ?? [];

    if (clearedProblemIds.length === 0) {
      return;
    }

    const tempMaxClearedId = Math.max(...clearedProblemIds);
    if (maxClearedId !== tempMaxClearedId) {
      console.log('next', tempMaxClearedId, maxClearedId);
      setMaxClearedId(tempMaxClearedId);
      safelySetProblemId(tempMaxClearedId + 1);
    }
  }, [previousRecords, isFadingOut, safelySetProblemId, maxClearedId]);

  return (
    <StageManager stageId={4} finishOnTimeUp>
      <CommonBG>
        <TimeGauge />

        <Stage4Main
          incrementPenalty={incrementPenalty}
          problem={problems[problemId]}
          onClear={onClear}
          fadingOutProblem={isFadingOut}
          onEndFadingOut={onEndFadingOut}
        />
      </CommonBG>
    </StageManager>
  );
};

export default Stage4;
