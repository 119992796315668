import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useEventAvailable } from '../../api/event';
import { useStartTimesEffect } from '../../api/stages';
import { appActions } from '../../redux/actions/appActions';
import Events from '../mypage/Events';
import Information from '../mypage/Information';
import EventResult from './eventResultPage/EventResultPage';
import Stages from './stageProviders/Stages';

interface EventRouterProps {}

const EventRouter: React.FC<EventRouterProps> = () => {
  const [eventAvailable, eventLoading] = useEventAvailable();
  useStartTimesEffect();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (eventLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [dispatch, eventLoading]);

  if (eventLoading) {
    return null;
  }
  if (!eventAvailable) {
    return <Redirect to='/events' />;
  }
  return (
    <Switch>
      <Redirect
        exact
        from='/events/:eventId'
        to='/events/:eventId/information'
      />
      <Route path='/events/:eventId/information'>
        <Information />
      </Route>
      <Route path='/events/:eventId/stages'>
        <Stages />
      </Route>
      <Route path='/events/:eventId/result'>
        <EventResult />
      </Route>
    </Switch>
  );
};

interface EventsRouterProps {}
const EventsRouter: React.FC<EventsRouterProps> = () => {
  return (
    <Switch>
      <Route exact path='/events'>
        <Events />
      </Route>
      <Route path='/events/:eventId'>
        <EventRouter />
      </Route>
      <Route>
        <Redirect to='/events' />
      </Route>
    </Switch>
  );
};

export default EventsRouter;
