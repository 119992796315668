import * as Apollo from '@apollo/client';
import * as React from 'react';

import {
  SetLoadingStatePayload,
  useSetErrorOverlayState,
  useSetLoadingState,
} from '../redux/actions/appActions';
import { usePushErrorReport } from '../redux/actions/errorActions';
import {
  ErrorOverlayState,
  ErrorOverlayStateNoRetry,
} from '../redux/reducers/appReducer';
import { useMemorizedObject } from './useMemorizedObject';
import {
  GeneratedUseMutationType,
  MutateOptions,
  RetryableMutationOptions,
  useRetryableMutation,
} from './useRetryableMutation';

type RetryableMutationWithUITuple<T, U> = [
  // requestMutate
  (options?: Apollo.MutationFunctionOptions<T, U> | undefined) => void,
  // result
  Apollo.MutationResult<T>
];

export type RetryableMutationWithUIOptions<T, U> = RetryableMutationOptions<
  T,
  U
> & {
  loading?: {
    disabled?: boolean;
    options?: Omit<SetLoadingStatePayload, 'visible'>;
  };
  error?: {
    disabled?: boolean;
    options?: ErrorOverlayStateNoRetry;
  };
  errorReport?: {
    disabled?: boolean;
  };
};

export const useRetryableMutationWithUI = <T, U>(
  useMutation: GeneratedUseMutationType<T, U>,
  options: RetryableMutationWithUIOptions<T, U>
): RetryableMutationWithUITuple<T, U> => {
  const setLoadingState = useSetLoadingState();
  const setErrorOverlayState = useSetErrorOverlayState();
  const pushErrorReport = usePushErrorReport();

  const [shouldRetry, setShouldRetry] = React.useState(false);
  const [mutateOptions, setMutateOptions] =
    React.useState<MutateOptions<T, U>>(undefined);

  const loadingOptions = useMemorizedObject(options.loading?.options);
  const errorOptions = useMemorizedObject(options.error?.options);
  const hookOptions = useMemorizedObject(options.hookOptions);
  const inheritedOnError = options.hookOptions.onError;
  const loadingDisabled = options.loading?.disabled ?? false;
  const errorDisabled = options.error?.disabled ?? false;
  const errorReportDisabled = options.errorReport?.disabled ?? false;

  const showLoading = React.useCallback(() => {
    if (!loadingDisabled) {
      setLoadingState({
        visible: true,
        ...loadingOptions,
      });
    }
  }, [loadingDisabled, loadingOptions, setLoadingState]);

  const hideLoading = React.useCallback(() => {
    if (!loadingDisabled) {
      setLoadingState({
        visible: false,
      });
    }
  }, [loadingDisabled, setLoadingState]);

  const onRetry = React.useCallback(() => {
    setShouldRetry(true);
  }, []);

  const showErrorOverlay = React.useCallback(() => {
    if (!errorDisabled) {
      const errorOverlayOptions: ErrorOverlayState = errorOptions ?? {
        errorType: 'CommonError',
      };

      const errorOverlayOptionsWithRetry =
        errorOverlayOptions.errorType === 'CommonError'
          ? {
              ...errorOverlayOptions,
              onRetry,
            }
          : errorOverlayOptions;

      setErrorOverlayState(errorOverlayOptionsWithRetry);
    }
  }, [onRetry, errorDisabled, errorOptions, setErrorOverlayState]);

  const reportError = React.useCallback(
    (e: Apollo.ApolloError) => {
      if (!errorReportDisabled) {
        pushErrorReport({
          // TODO: errorCodeの適切な決定
          errorCode: '999',
          error: e,
          gqlPayload:
            mutateOptions !== undefined
              ? JSON.stringify(mutateOptions)
              : undefined,
        });
      }
    },
    [errorReportDisabled, mutateOptions, pushErrorReport]
  );

  const onError = React.useCallback(
    (e: Apollo.ApolloError) => {
      inheritedOnError?.(e);
      showErrorOverlay();
      reportError(e);
    },
    [inheritedOnError, reportError, showErrorOverlay]
  );

  const [requestMutate, result, retry, currentMutateOptions] =
    useRetryableMutation(useMutation, {
      ...options,
      hookOptions: {
        ...hookOptions,
        onError,
      },
    });

  React.useEffect(
    () => setMutateOptions(currentMutateOptions),
    [currentMutateOptions]
  );

  React.useEffect(() => {
    if (result.loading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [hideLoading, result.loading, showLoading]);

  React.useEffect(() => {
    if (shouldRetry) {
      setShouldRetry(false);
      retry();
    }
  }, [retry, shouldRetry]);

  return [requestMutate, result];
};
