import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../styles/colors';

interface SettingsTitleProps {}
const SettingsTitle: React.FC<SettingsTitleProps> = () => {
  return (
    <Svg
      xmlns='http://www.w3.org/2000/svg'
      width='214.496'
      height='26.56'
      viewBox='0 0 214.496 26.56'
    >
      <path
        id='Path_1201'
        data-name='Path 1201'
        d='M-81.312-7.648V-9.312c0-3.84-2.784-6.72-8.1-6.72h-7.1c-.576,0-.736-.32-.736-.7v-.128c0-.352.224-.608.736-.608H-83.04V-25.76H-98.176c-5.728,0-9.088,2.24-9.088,8.064v.928c0,5.024,2.56,7.68,9.024,7.68h6.368c.416,0,.64.16.64.608v.1c0,.416-.16.608-.64.608h-14.88V.32H-89.92C-83.68.32-81.312-3.392-81.312-7.648Zm28.224,8V-7.84H-66.432c-1.792,0-2.048-.576-2.048-1.76h14.24v-6.144H-68.448a1.731,1.731,0,0,1,2.016-1.76h13.344V-25.76h-15.04c-8.512,0-9.92,4.608-9.92,11.232v4.96c0,5.888,2.208,9.92,9.824,9.92Zm28.576-17.6V-25.76H-50.144v8.512h8.16V.352h9.76v-17.6Zm27.68,0V-25.76H-22.464v8.512h8.16V.352h9.76v-17.6Zm13.6,17.6V-25.76H6.912V.352ZM48.9-7.424V-25.7H40.032v14.432c0,.352-.128.448-.256.448-.192,0-.256-.1-.32-.448l-3.2-9.248c-.9-2.592-2.656-5.408-7.264-5.408-4.32,0-7.424,2.656-7.424,7.9V.352H30.4v-14.4c0-.288.064-.416.192-.416.1,0,.192.1.256.384L33.92-5.856C35.3-2.176,37.472.64,41.6.64,46.112.64,48.9-1.824,48.9-7.424ZM78.432-16c0-7.264-2.336-9.92-9.984-9.92h-5.6c-8.512,0-10.4,3.04-10.4,11.2v4.032c0,8.1,2.144,11.2,10.72,11.2h5.824c6.976,0,9.344-3.04,9.344-9.76v-5.728H64.288v5.408h4.7v.608c0,.608-.384,1.184-1.376,1.184h-2.88c-2.016,0-2.848-.288-2.848-2.912V-14.72c0-2.3.608-2.944,2.528-2.944H67.04c1.408,0,1.888.576,1.888,1.664Zm28.8,8.352V-9.312c0-3.84-2.784-6.72-8.1-6.72h-7.1c-.576,0-.736-.32-.736-.7v-.128c0-.352.224-.608.736-.608H105.5V-25.76H90.368c-5.728,0-9.088,2.24-9.088,8.064v.928c0,5.024,2.56,7.68,9.024,7.68h6.368c.416,0,.64.16.64.608v.1c0,.416-.16.608-.64.608H81.792V.32H98.624C104.864.32,107.232-3.392,107.232-7.648Z'
        transform='translate(107.264 25.92)'
        fill={Colors.gray8}
      />
    </Svg>
  );
};

const Svg = styled.svg`
  height: 3.2rem;
  font-size: 3.2rem;
  margin: 0 auto;
  display: block;
`;

export default SettingsTitle;
