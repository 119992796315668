import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import styled from 'styled-components';

import RotateLeftImg from '../../../../static/svg/Rotate_Left_Stage1.svg';
import RotateRightImg from '../../../../static/svg/Rotate_Right_Stage1.svg';
import Colors from '../../../../styles/colors';

export interface Position {
  x: number;
  y: number;
}

export interface PanelState {
  rotation: number;
  position: Position;
}

export const panelSize = 80;
export const panelAreaOffset: Position = { x: 800, y: 100 };

interface DraggablePanelProps {
  src: string;
  onDrag: (e: DraggableEvent, data: DraggableData) => void;
  onStop: () => void;
  rotatePanel: (diff: number) => void;
  panelState: PanelState;
  isInInput: boolean;
}

const DraggablePanel: React.FC<DraggablePanelProps> = props => {
  const { src, onDrag, onStop, rotatePanel, panelState, isInInput } = props;
  return (
    <Draggable
      key={src}
      bounds={'parent'}
      onDrag={(e, data) => onDrag(e, data)}
      onStop={() => onStop()}
      position={panelState.position || panelAreaOffset}
    >
      <Panel src={src} rotation={panelState.rotation}>
        {isInInput && (
          <RotationControl>
            <RotationControlButton
              src={RotateLeftImg}
              onClick={() => rotatePanel(-1)}
            />
            <RotationControlButton
              src={RotateRightImg}
              onClick={() => rotatePanel(1)}
            />
          </RotationControl>
        )}
      </Panel>
    </Draggable>
  );
};

export const RotationControl = styled.div`
  width: ${panelSize * 0.85}px;
  height: ${panelSize * 0.4}px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface RotationControlButtonProps {
  src: string;
}
const RotationControlButton = styled.div`
  width: ${panelSize * 0.4}px;
  height: ${panelSize * 0.4}px;
  background-color: ${Colors.gray5};
  background-image: url(${(p: RotationControlButtonProps) => p.src});
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;

  &:hover {
    background-color: ${Colors.primary};
  }
`;

interface PanelProps {
  src: string;
  rotation: number;
}

export const Panel = styled.div`
  width: ${panelSize}px;
  height: ${panelSize}px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.1s;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(${(p: PanelProps) => p.src});
    background-size: contain;
    background-repeat: no-repeat;
    transform: ${(p: PanelProps) => `rotate(${p.rotation * 45}deg)`};
    transform-origin: 50% 48.1%;
    position: absolute;
    top: 2px;
    left: 0;
    transition: 0.1s;
  }

  & > ${RotationControl} {
    display: none;
  }

  &:hover > ${RotationControl} {
    display: flex;
  }
`;

export default DraggablePanel;
