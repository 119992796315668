import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Stage5GroupDocument } from '../../api/stage5';
import { StageId } from '../../api/stages';

export const useStageState = (props: { stageId: StageId }) =>
  useSelector(state => ({
    finished: state.game.hasStageFinished[props.stageId],
  }));
export const useLocalStage4Penalty = () =>
  useSelector(state => state.game.stage4Penalty);
export const useStagesFinished = () =>
  useSelector(state => state.game.hasStageFinished);
export const useEventId = () =>
  useRouteMatch<{ eventId: string }>('/events/:eventId')?.params.eventId ??
  null;
export const useAdminEventId = () =>
  useRouteMatch<{ eventId: string }>('/admin/events/:eventId')?.params
    .eventId ?? null;
export const useAdminOrgId = () =>
  useRouteMatch<{ orgId: string }>('/admin/organization/:orgId')?.params
    .orgId ?? null;
export const useAdminResultParams = () => {
  const match = useRouteMatch<{ eventId: string; uid: string }>(
    '/admin/events/:eventId/result/:uid'
  );
  return {
    eventId: match?.params.eventId ?? null,
    uid: match?.params.uid ?? null,
  };
};
export const useStage5GroupInfo = () =>
  useSelector(state => state.game.stage5GroupInfo);
export const useStage5CurrentAnswer = () =>
  useSelector(state => state.game.stage5CurrentAnswer);
export const useStage5Flags = (groupDocData?: Stage5GroupDocument) =>
  useSelector(state => {
    const user = state.auth.user;
    return {
      isPresenter:
        user !== null &&
        groupDocData !== undefined &&
        groupDocData?.presenter === user?.uid,
      isGroupOwner:
        user !== null &&
        groupDocData !== undefined &&
        user.uid === groupDocData?.createdBy,
    };
  });
export const useRecordedStartTimes = () =>
  useSelector(state => state.game.recordedStageStartTimes);
export const useRecordedStartTime = (stageId: StageId) =>
  useSelector(state => state.game.recordedStageStartTimes[stageId]);
export const useStageManagerState = () =>
  useSelector(state => state.game.stageManagerState);
