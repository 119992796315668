import { RequiredOptions } from 'prettier';
import * as React from 'react';
import { ValueType } from 'react-select';
import styled from 'styled-components';

import Input from '../../input/Input';
import Select, { Options } from '../../select/Select';
import { FormElement } from '../formTypes';
import FormDateElementView from './FormDateElementView';

interface FormElementViewProps {
  element: FormElement;
}
const FormElementView: React.FC<FormElementViewProps> = ({ element: e }) => {
  const onSelectChange = React.useCallback(
    (v: ValueType<Options<any>, false>) => {
      if (e.type !== 'select') return;
      e.onChange((v as any).value);
    },
    []
  );

  return (
    <FormElementViewWrapper>
      <Label>
        <LabelText>{e.label}</LabelText>
        {e.required === true && <Required>*必須</Required>}
      </Label>
      <>
        {e.type === 'text' && <Input {...e} />}
        {/*e.type === 'number' && <Input {...e} />*/}
        {e.type === 'password' && <Input {...e} />}
        {e.type === 'date' && <FormDateElementView element={e} />}
        {e.type === 'select' && (
          <SelectWrapper>
            <Select
              options={e.options}
              onChange={onSelectChange}
              value={
                e.options.find(op => op.value === e.value) ??
                e.options
                  .map(o => o.options)
                  .flat()
                  .find(op => op && op.value === e.value)
              }
              overrideStyles={e.overrideStyles}
              menuPlacement={e.menuPlacement}
              placeholder={e.placeholder}
            />
          </SelectWrapper>
        )}
      </>
    </FormElementViewWrapper>
  );
};

const Label = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const LabelText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
`;

const FormElementViewWrapper = styled.div`
  margin: 0 0 1rem;
`;

const Required = styled.div`
  color: red;
  font-size: 1rem;
  margin-left 1rem;
`;

const SelectWrapper = styled.div`
  margin-bottom: 2rem;
`;

export default FormElementView;
