import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useEventData,
  useEventResult,
  useEventStats,
} from '../../../api/event';
import { appActions } from '../../../redux/actions/appActions';
import ResultDetailView from './ResultDetailView';

interface ResultProps {
  uid: string | undefined | null;
  eventId: string | null;
  listHref: string;
  nextHref?: string;
  prefHref?: string;
  isAdminView?: boolean;
}

const ResultDetail: React.FC<ResultProps> = props => {
  const history = useHistory();
  const [event, eventLoading] = useEventData(props.eventId ?? null);
  const [result, resultLoading] = useEventResult(
    props.uid ?? null,
    props.eventId ?? null
  );
  const [stats, statsLoading] = useEventStats(props.eventId ?? null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      appActions.setLoadingState({
        visible: eventLoading || resultLoading || statsLoading,
        text: 'Now Loading',
      })
    );
  }, [dispatch, eventLoading, resultLoading, statsLoading]);

  const goToList = React.useCallback(() => {
    history.push(props.listHref);
  }, [history, props.listHref]);
  const goToNext = React.useCallback(() => {
    if (!props.nextHref) return;
    history.push(props.nextHref);
  }, [history, props.nextHref]);
  const goToPrev = React.useCallback(() => {
    if (!props.prefHref) return;
    history.push(props.prefHref);
  }, [history, props.prefHref]);
  const goToEventControl = React.useCallback(() => {
    if (!props.eventId) return;
    history.push(`/admin/events/${props.eventId}/control`);
  }, [history, props.eventId]);

  return (
    <ResultDetailView
      eventStartDate={event?.scheduledStartTime}
      eventEndDate={event?.scheduledEndTime}
      eventName={event?.name}
      eventResult={result}
      eventStats={stats}
      goToList={goToList}
      goToNext={props.nextHref ? goToNext : undefined}
      goToPrev={props.prefHref ? goToPrev : undefined}
      goToEventControl={props.isAdminView ? goToEventControl : undefined}
    />
  );
};

export default ResultDetail;
