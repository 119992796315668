import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useDeleteOrganizationMutation } from '../../api/__generated__/organization.generated';
import { OrganizationDocument } from '../../api/organization';
import { useAllAdminUserInfo } from '../../api/user';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { appActions } from '../../redux/actions/appActions';
import Colors from '../../styles/colors';
import AdminPopup from '../admin/UIelements/AdminPopup';
import MenuPopup from '../admin/UIelements/MenuPopup';
import OrganizationIcon from '../admin/UIelements/OrganizationIcon';
import SortTH from '../admin/UIelements/sorter/SortTH';

type OrganizationTableProps = {
  organizationDataList: OrganizationDocument[];
} & {
  onJoin?: undefined;
  onAdminEdit: (eventId: string) => void;
  onAdminControl: (eventId: string) => void;
  showResult?: undefined;
  activeEventId?: undefined;
  sortObj: any;
  onChangeSort: (sortObj: any) => void;
};

const AdminOrganizationTable: React.FC<OrganizationTableProps> = props => {
  const [allUsers] = useAllAdminUserInfo();
  const allUserNameTable: { [key: string]: string } = {};
  if (allUsers !== undefined) {
    allUsers.forEach(doc => {
      allUserNameTable[doc.uid] = doc.fullName;
    });
  }

  const createAdminNameList = (data: OrganizationDocument) =>
    data.admin.map(e => allUserNameTable[e]);

  const listHeader = [
    { label: '企業名', key: 'name' },
    { label: '管理者', key: 'admin', disable: true },
  ];

  return (
    <OrganizationTableWrapper>
      <Table>
        <THead>
          <TR>
            {listHeader.map(single => {
              return (
                <SortTH
                  key={single.key}
                  sortKey={single.key}
                  currentSortObj={props.sortObj}
                  onClick={props.onChangeSort}
                  disable={single.disable}
                >
                  {single.label}
                </SortTH>
              );
            })}
            <TH></TH>
          </TR>
        </THead>

        <TBody>
          {props.organizationDataList.map(data => (
            <SingleRow
              key={data.orgId}
              organizationData={data}
              adminNames={createAdminNameList(data)}
              {...props}
            />
          ))}
        </TBody>
      </Table>
    </OrganizationTableWrapper>
  );
};

type SingleRowProps = OrganizationTableProps & {
  organizationData: OrganizationDocument;
  adminNames: string[];
};
const SingleRow: React.FC<SingleRowProps> = props => {
  const history = useHistory();
  const onUserDetail = (orgId?: string) => {
    history.push(`/admin/organization/${orgId}/`);
  };
  const orgId = props.organizationData.orgId;

  const [deleteOrganization] = useRetryableMutationWithUI(
    useDeleteOrganizationMutation,
    {
      hookOptions: {},
    }
  );
  const dispatch = useDispatch();

  const detailContents = {
    title: '詳細',
    onClick: () => {
      onUserDetail(orgId);
    },
  };
  const deleteContents = {
    title: '削除する',
    onClick: () => {
      // Task Delete Organization
      console.log('delete');

      dispatch(
        appActions.setErrorOverlayState({
          errorType: 'CommonError',
          message: '本当に企業を削除しますか。',
          backButtonText: 'いいえ',
          retryButtonText: 'はい',
          onRetry: () => {
            deleteOrganization({
              variables: {
                input: {
                  orgId: orgId,
                },
              },
            });
          },
          onBack: () => {
            console.log('close');
          },
        })
      );
    },
    red: true,
  };

  return (
    <TR onClick={() => onUserDetail(orgId)}>
      <TD>
        <OrgName>
          <OrganizationIcon
            size={42}
            src={props.organizationData.imgSrc ?? undefined}
          />
          <p>{props.organizationData.name}</p>
        </OrgName>
      </TD>
      <TD>
        <Admins>
          {props.adminNames.map((e, i) => {
            if (e != undefined) {
              return <p key={i}>{e}</p>;
            }
          })}
        </Admins>
      </TD>
      <TD>
        <AdminPopup
          width={100}
          key='popup'
          content={
            <MenuPopup
              contents={
                ['adminDentsu', 'adminRiddler'].includes(orgId)
                  ? [detailContents]
                  : [detailContents, deleteContents]
              }
            />
          }
        >
          <InfoButton>
            <FontAwesomeIcon icon={faEllipsisV} />
          </InfoButton>
        </AdminPopup>
      </TD>
    </TR>
  );
};

const InfoButton = styled.div`
  border-radius: 99px;
  width: 36px;
  height: 36px;
  transition: 0.2s;
  color: ${Colors.gray6};
  opacity: 0;

  svg {
    margin: 9px;
    width: 18px !important;
    height: 18px;
  }

  &:hover {
    background: white;
  }
`;

const OrganizationTableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
`;

const THead = styled.thead`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const TBody = styled.tbody`
  width: 100%;

  tr {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: ${Colors.gray2};

      & ${InfoButton} {
        opacity: 1;
      }
    }
  }
`;

const OrgName = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 1.6rem;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 0.6rem 1.4rem;
`;
const TH = styled.th`
  position: relative;
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 30%;
  }
  td:nth-child(2) {
  }
  td:last-child {
    width: 38px;
  }
`;

const CsvButton = styled.div`
  font-size: 1.3rem;
  color: ${Colors.accent};
  cursor: pointer;

  position: absolute;
  right: 1px;
  top: 14px;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Admins = styled.div`
  display: flex;

  p {
    margin-right: 1rem;
  }
`;

export default AdminOrganizationTable;
