import * as React from 'react';

import { ButtonProps } from './buttonConfigs';
import MainButton from './MainButton';
import SubButton from './SubButton';
import TextButton from './TextButton';

const Button: React.FC<ButtonProps> = props => {
  switch (props.variant) {
    case 'main':
      return <MainButton {...props} />;

    case 'sub':
      return <SubButton {...props} />;

    case 'text':
      return <TextButton {...props} />;
  }
};

export default Button;
