import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { couldStartTrivia } from 'typescript';

import { useEventDataOnce } from '../../../api/event';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import { useEventId } from '../../../redux/selectors/gameSelectors';
import { EventStatus } from '../../../types';
import CommonBG from '../../uiElements/CommonBG';
import ProfileBar from '../../uiElements/ProfileBar';
import ResultDetail from './ResultDetail';

interface EventResultProps {}
const EventResult: React.FC<EventResultProps> = () => {
  const user = useCurrentUser();
  const eventId = useEventId();
  const [event] = useEventDataOnce(eventId);

  const history = useHistory();
  React.useEffect(() => {
    if (!event) {
      return;
    }
    if (event?.status !== EventStatus.Ended) {
      history.push('/events');
    }
  }, [event, history]);

  return (
    <CommonBG withHeader withFooter headerOptions={{ buttonType: 'logout' }}>
      <ProfileBar user={user} disableEmail />
      <ResultDetail uid={user?.uid} eventId={eventId} listHref='/history' />
    </CommonBG>
  );
};
export default EventResult;
