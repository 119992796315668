import * as React from 'react';
import styled from 'styled-components';

import {
  Validators,
  pwFormatValidator,
  useValidator,
} from '../../../../lib/useValidator';
import HeaderPasswordReset from '../../../../static/png/Header_Reset.png';
import Button from '../../../uiElements/button/MainButton';
import CommonBG from '../../../uiElements/CommonBG';
import { HeaderOptions } from '../../../uiElements/Header';
import Input from '../../../uiElements/input/Input';
import TextWindow from '../../../uiElements/TextWindow';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
};
interface ConfirmPasswordResetViewProps {
  sendPw: (pw: string) => void;
}
const ConfirmPasswordResetView: React.FC<ConfirmPasswordResetViewProps> = ({
  sendPw,
}) => {
  const [pw, setPw] = React.useState('');
  const [pwRe, setPwRe] = React.useState('');

  const pwValidators = React.useMemo<Validators>(
    () => [[val => val === ''], pwFormatValidator],
    []
  );
  const [pwValidator, pwIsValid] = useValidator(pwValidators);

  const pwReValidators = React.useMemo<Validators>(
    () => [
      [val => val === ''],
      [val => val !== pw, 'パスワードが一致しません'],
      pwFormatValidator,
    ],
    [pw]
  );
  const [pwReValidator, pwReIsValid] = useValidator(pwReValidators);

  const onConfirm = React.useCallback(() => {
    sendPw(pw);
  }, [pw, sendPw]);

  const isValid = pwIsValid && pwReIsValid;

  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <Window>
          <HeaderTitle src={HeaderPasswordReset} />
          <p>パスワードを再設定できます。</p>
        </Window>
      </Wrapper>
      <Body>
        <InputItem>
          <InputTitle>
            {'新しいパスワードを8文字以上で入力してください。'}
          </InputTitle>
          <Input
            autoComplete='new-password'
            type='password'
            value={pw}
            onChange={setPw}
            validator={pwValidator}
          />
        </InputItem>
        <InputItem>
          <InputTitle>
            {'確認のため、もう一度新しいパスワードを入力してください。'}
          </InputTitle>
          <Input
            autoComplete='new-password'
            type='password'
            value={pwRe}
            onChange={setPwRe}
            validator={pwReValidator}
          />
        </InputItem>

        <PasswordResetButton
          size={'large'}
          color={'positive'}
          onClick={onConfirm}
          disabled={!isValid}
        >
          再設定する
        </PasswordResetButton>
      </Body>
    </CommonBG>
  );
};

const HeaderTitle = styled.img`
  width: 39rem;
  height: 3.2rem;
`;

const Window = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 2rem;
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 1rem;
`;

const InputItem = styled.div`
  margin: 0 0 2rem;
`;

const InputTitle = styled.div`
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const PasswordResetButton = styled(Button)`
  display: block;
  font-size: 2.5rem;
  margin: 0 auto;
`;

const Body = styled.div`
  margin: 0 auto;
  margin-top: 4rem;
  width: 32rem;
`;

export default ConfirmPasswordResetView;
