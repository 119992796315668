import firebase from 'firebase';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  HandleFirebaseErrorResult,
  handleReauthenticateWithCredentialErrorCode,
} from '../../../lib/firebase/firebaseErrorHandlers';
import { appActions } from '../../../redux/actions/appActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import PWSettingsView from './PWSettingsView';

interface PWSettingsProps {}
const PWSettings: React.FC<PWSettingsProps> = () => {
  const user = useCurrentUser();
  const dispatch = useDispatch();

  const [error, setError] =
    React.useState<HandleFirebaseErrorResult | null>(null);
  const [done, setDone] = React.useState(false);

  const onConfirmChange = React.useCallback(
    async (currentPW: string, newPW: string) => {
      if (!user?.email) return;

      dispatch(
        appActions.setLoadingState({
          visible: true,
        })
      );

      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPW
      );
      user
        .reauthenticateWithCredential(cred)
        .then(async () => {
          return await user
            .updatePassword(newPW)
            .then(() => {
              dispatch(
                appActions.setErrorOverlayState({
                  errorType: 'CommonError',
                  message: 'パスワードの変更が完了しました。',
                })
              );
              setDone(true);
            })
            .catch(() => {
              dispatch(
                appActions.setErrorOverlayState({
                  errorType: 'CommonError',
                  message: 'パスワードの変更に失敗しました。',
                })
              );
            });
        })
        .catch((error: any) => {
          console.log(error);
          setError(handleReauthenticateWithCredentialErrorCode(error.code));
        })
        .finally(() => {
          dispatch(
            appActions.setLoadingState({
              visible: false,
            })
          );
        });
    },
    [dispatch, user]
  );

  return (
    <PWSettingsView
      done={done}
      error={error}
      onConfirmChange={onConfirmChange}
    />
  );
};

export default PWSettings;
