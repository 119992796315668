import * as React from 'react';
import _Select, {
  MenuPlacement,
  OptionTypeBase,
  OptionsType,
  Styles,
  ValueType,
} from 'react-select';
import { StylesConfigFunction } from 'react-select/src/styles';

import { selectErrorStyles, selectStyles } from './selectStyles';

export type Options<T extends OptionTypeBase> = OptionsType<T>;
export type SelectOverrideStyles = Partial<
  Record<keyof Styles<any, false>, React.CSSProperties>
>;

interface SelectProps<T extends OptionTypeBase> {
  options: Options<T>;
  placeholder?: string;
  isError?: boolean;
  disabled?: boolean;
  onChange: (x: ValueType<T, false>) => void;
  value?: T;
  overrideStyles?: SelectOverrideStyles;
  defaultValue?: any;
  menuPlacement?: MenuPlacement;
}
function Select<T>(
  props: React.PropsWithChildren<SelectProps<T>>
): React.ReactElement {
  const { placeholder, isError, disabled } = props;
  const styles = React.useMemo(() => {
    const baseStyles: Styles<any, false> = isError
      ? selectErrorStyles
      : selectStyles;

    return Object.fromEntries(
      Object.entries(baseStyles).map(([k, f]) => {
        const newFunc: StylesConfigFunction = (base, prop) => ({
          ...f(base, prop),
          ...(props.overrideStyles?.[k as keyof Styles<any, false>] ?? {}),
        });

        return [k, newFunc];
      })
    );
  }, [props.overrideStyles, isError]);

  return (
    <_Select
      placeholder={placeholder || ''}
      isDisabled={disabled}
      styles={styles}
      defaultValue={props.defaultValue}
      menuPlacement={props.menuPlacement}
      {...props}
    />
  );
}

export default Select;
