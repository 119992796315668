import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game1 } from './firestoreTypes';

export const stage1Schema = gql`
  mutation SubmitAnswer1($input: Answer1!) {
    submitAnswer1(input: $input) {
      achieved {
        targetId
        name
        score
      }
    }
  }

  query getAllStage1Targets {
    getAllStage1Targets {
      targetId
      name
      score
    }
  }
`;

export type Stage1Submission = Game1;

export const useStage1Submission = (
  user: firebase.User | null,
  eventId: string | null
): LoadingHook<Stage1Submission, Error> => {
  return useDocumentData<Stage1Submission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('1')
      : undefined
  );
};

export const useStage1SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage1Submission, Error> => {
  return useDocumentData<Stage1Submission>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(uid)
          .doc('1')
      : undefined
  );
};
