import 'react-datepicker/dist/react-datepicker.css';

import { Locale } from 'date-fns';
import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import MonthArrowLeftImg from '../../static/svg/common_Datepicker_MonthArrow.svg';
import CalendarIconImg from '../../static/svg/Icon-calendar.svg';
import Colors from '../../styles/colors';

interface RenderCustomHeaderParams {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const jaLocale: Locale = {
  ...ja,
  options: {
    weekStartsOn: 0,
    /* Monday */
    firstWeekContainsDate: 1,
  },
};
registerLocale('ja', jaLocale);

const Input = styled.input`
  font-size: 1.2rem;
  padding: 0.8rem 2.7rem 0.8rem 0.7rem;
  border: ${Colors.gray4} 1px solid;
  border-radius: 0.4rem;

  &::placeholder {
    color: ${Colors.gray4};
  }
`;

const CustomDateInputWrapper = styled.div`
  position: relative;
`;

const CustomDateInputIcon = styled.div`
  width: 2rem;
  height: 1.7rem;
  background-image: url(${CalendarIconImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
`;

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
}

const CustomDateInput = React.forwardRef(
  (p: CustomInputProps, ref: React.Ref<HTMLInputElement>) => (
    <CustomDateInputWrapper>
      <Input {...p} readOnly ref={ref} />
      <CustomDateInputIcon />
    </CustomDateInputWrapper>
  )
);

const CalenderHeader = styled.div`
  position: relative;
  padding: 0.3rem 0;
  padding-bottom: 1rem;
`;
const HeaderYear = styled.span`
  font-size: 1.1rem;
  line-height: 1.1rem;
  display: inline-block;
  margin-right: 0.4rem;
`;
const HeaderMonth = styled(HeaderYear)`
  font-size: 1.1rem;
  line-height: 1.1rem;
  margin-right: 0;
`;

const MonthArrowLeft = styled.div`
  background-image: url(${MonthArrowLeftImg});
  background-repeat: no-repeat;
  background-size: 8px;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: -0.1rem;
  left: 0.8rem;
  padding: 6px;
  background-position: center;
`;

const MonthArrowRight = styled(MonthArrowLeft)`
  transform: rotate(180deg);
  left: auto;
  right: 0.8rem;
`;

const RenderCustomHeader: React.FC<RenderCustomHeaderParams> = params => {
  const { date, decreaseMonth, increaseMonth } = params;

  return (
    <CalenderHeader>
      <MonthArrowLeft onClick={decreaseMonth} />
      <MonthArrowRight onClick={increaseMonth} />
      <HeaderYear>{format(date, 'yyyy年')}</HeaderYear>
      <HeaderMonth>{format(date, 'MM月')}</HeaderMonth>
    </CalenderHeader>
  );
};

const Container = styled.div`
  border: ${Colors.gray4} 1px solid;
  border-radius: 0.4rem;

  & .react-datepicker__header {
    background-color: ${Colors.white};
    border-bottom: none;
  }

  & .react-datepicker__day--selected {
    background-color: ${Colors.primary};
    border-radius: 0;
  }

  & .react-datepicker__day-names {
    margin-top: 0.4rem;
  }

  & .react-datepicker__day-name {
    line-height: 1.2rem;
    width: calc(2.8rem + 2px);
    margin: 0.1rem;

    &:first-child {
      color: ${Colors.error};
    }
  }

  & .react-datepicker__month {
    margin: 0.2rem 0.4rem 0.4rem 0.4rem;
  }

  & .react-datepicker__day {
    border: ${Colors.gray2} 1px solid;
    width: 2.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
    margin: 0.1rem;
    outline: none;
    color: ${Colors.gray8};
    font-size: 1rem;
    font-weight: 500;
  }
`;

const DatePicker: React.FC<ReactDatePickerProps> = props => {
  return (
    <ReactDatePicker
      {...props}
      dateFormat='yyyy年MM月dd日 (eee)'
      customInput={<CustomDateInput />}
      calendarContainer={Container}
      locale={jaLocale}
      renderCustomHeader={RenderCustomHeader}
    />
  );
};

export default DatePicker;
