import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  JoinGroupMutation,
  useJoinGroupMutation,
} from '../../../../api/__generated__/stage5.generated';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { Validators, useValidator } from '../../../../lib/useValidator';
import { gameActions } from '../../../../redux/actions/gameActions';
import { Stage5GroupInfo } from '../../../../redux/reducers/gameReducer';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import _Button from '../../../uiElements/button/MainButton';
import CommonBG from '../../../uiElements/CommonBG';
import _Input from '../../../uiElements/input/Input';
import TextWindow from '../../../uiElements/TextWindow';

interface GroupIdInputViewProps {
  inputGroupId: string;
  setInputGroupId: (val: string) => void;
  onBack: () => void;
  onJoinGroup: () => void;
  hasWrongIdError: boolean;
}

export const GroupIdInputView: React.FC<GroupIdInputViewProps> = ({
  inputGroupId,
  setInputGroupId,
  onBack,
  onJoinGroup,
  hasWrongIdError,
}) => {
  const [isWrongId, setIsWrongId] = React.useState(false);
  const onChange = React.useCallback(
    (val: string) => {
      setIsWrongId(false);
      setInputGroupId(val);
    },
    [setInputGroupId]
  );
  React.useEffect(() => {
    setIsWrongId(hasWrongIdError);
  }, [hasWrongIdError]);

  const validators = React.useMemo<Validators>(
    () => [
      [val => val === ''],
      [
        val => val.length !== 5 || !isFinite(Number(val)),
        '半角数字5桁で入力してください。',
      ],
      [() => isWrongId, 'グループIDが間違っています。'],
    ],
    [isWrongId]
  );
  const [validator] = useValidator(validators);

  return (
    <CommonBG>
      <GroupIdInputWrapper>
        <TextWindow bracket={true}>グループIDを入力してください。</TextWindow>
        <Input
          value={inputGroupId}
          onChange={onChange}
          fullWidth
          validator={validator}
          inputAlign='center'
          messageAlign='left'
          placeholder='入力してください'
        />
        <Buttons>
          <Button size='large' color='negative' onClick={onBack}>
            戻る
          </Button>
          <Button
            size='large'
            color='positive'
            disabled={inputGroupId === ''}
            onClick={onJoinGroup}
          >
            参加する
          </Button>
        </Buttons>
      </GroupIdInputWrapper>
    </CommonBG>
  );
};

interface GroupIdInputProps {
  onBack: () => void;
}
const GroupIdInput: React.FC<GroupIdInputProps> = props => {
  const dispatch = useDispatch();
  const eventId = useEventId();
  const [inputGroupId, setInputGroupId] = React.useState<string>('');
  const user = useCurrentUser();
  const [persistantGroupInfo, setPersistantGroupInfo] =
    useLocalStorage<Stage5GroupInfo | null>(
      eventId + ':stage5GroupInfo:' + user?.uid,
      null
    );

  const onJoinGroupCompleted = React.useCallback(
    (res: JoinGroupMutation) => {
      const data = res.joinGroup;
      dispatch(
        gameActions.setStage5GroupInfo({
          groupId: data.groupDocId,
          searchId: data.searchId,
        })
      );
      setPersistantGroupInfo({
        groupId: data.groupDocId,
        searchId: data.searchId,
      });
    },
    [dispatch]
  );

  const [joinGroup] = useRetryableMutationWithUI(useJoinGroupMutation, {
    hookOptions: {
      onCompleted: onJoinGroupCompleted,
    },
    loading: { options: { text: '送信中...' } },
  });

  const onJoinGroup = React.useCallback(() => {
    if (eventId === null) {
      return;
    }

    joinGroup({
      variables: {
        input: {
          eventId,
          searchId: Number(inputGroupId),
        },
      },
    });
  }, [eventId, inputGroupId, joinGroup]);

  return (
    <GroupIdInputView
      inputGroupId={inputGroupId}
      setInputGroupId={setInputGroupId}
      onBack={props.onBack}
      onJoinGroup={onJoinGroup}
      hasWrongIdError={false}
    />
  );
};

const GroupIdInputWrapper = styled.div`
  width: 62rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Input = styled(_Input)`
  height: 8rem;
  line-height: 8rem;
  font-size: 3rem;
  outline: none;
  margin-top: 6rem;
`;

const Button = styled(_Button)`
  display: inline-block;
  margin-right: 8rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  display: block;
  margin-top: 9rem;
  text-align: center;
`;

export default GroupIdInput;
