const resultComments = [
  [
    '固定観念に囚われ気味のようです。どんな仕事をこなすにしても、方法が1つと言うことは絶対にありません。「何か別のアプローチはないのか？」と常に問うクセをつけてみましょう',
    '「こんなことは無理だろう…」と無意識に思考に蓋をしていないでしょうか？常識的な発想も常識を外した発想もどちらもできると選択肢が広がります。自由な思考を心がけてみましょう',
    '型にはまらない柔軟な考え方ができているようです。多角的な視点は、物事の構造を把握するときにも役立ちます。いろいろな立場や目線に立って事象を観察すれば、より良い理解が得られるでしょう。',
    '非常に多角的に物事を捉えられているようです。状況解決や企画の走り出しなどたくさんのアイディアが必要なとき、積極的に様々な視点からアイディアを生み出していきましょう。',
  ],
  [
    '情報の整理が苦手なようですね。まずは目的を明確にして、必要な情報とそうでない情報の整理を心がけましょう。',
    '「確実にわかることは何か？」「目の前の情報にはどんな意味があるのか？」と日常的に情報を整理するクセをつけてみましょう。また、何か事象が起きたときそれには必ず理由があります。結果から理由を想像してみるのも、論理的思考力を身につける練習になりますよ。',
    '情報の整理と論理的な構築が得意のようです。今回は「すべて正しい情報」をもとに思考できましたが、実際の仕事では「より正しい情報」を見抜くことも重要になります。常に情報の確かさを意識しながら仕事をすると、より正しい選択をできるようになるはずです。',
    '早さと正確さにおいて、非常に論理的な思考ができているようです。たくさんの情報の収集・分析が必要な業務の際に、論理的な正確さを意識して情報を扱うことでこの力を強く活かせるはずです。',
  ],
  [
    '「ひらめき」はたくさんの要素が頭の中にあるほど生まれやすくなります。生活や趣味を通して様々な情報や考え方をインプットし、枠に囚われない柔軟な思考を身につけていきましょう。',
    '日常的に得られる様々な情報を要素分解することで、たくさんの「アイディアのタネ」を手に入れることができます。タネをアレンジしたり組み合わせることによって、新しいアイディアを生むことができるはずです。',
    '頭の中にある要素と要素を掛け合わせるのが得意なようです。巧拙を問わず要素の掛け合わせを行うことによって、様々なアイディアを生むことができます。一発で素晴らしいアイディアを生むことはできません。まずはたくさんのアイディアを生み、その中から良い物を選びとっていきましょう。',
    `ひらめきの力が非常に優れているようです。枠に囚われず斬新なアイディアをたくさん生み出してみましょう。
  他の力も鍛えることによって、そのアイディアをさらにブラッシュアップしていくことができるはずです。`,
  ],

  [
    '途中でクリックを繰り返すだけになったりしなかったでしょうか？Energyはただ手を動かすだけでなく、「考え続ける」ための力です。面倒な仕事などでもその中身をしっかり観察・分析することできっと取り組むことができるようになるはずです。',
    '1つ1つの変化をしっかり分析することはできましたか？　ひたすら行動するだけでなく、その結果を分析すること・分析を踏まえ解決手段を考えることが"試行錯誤"の本筋です。1つの思考作業においてもPDCAサイクルをしっかりと回していきましょう。',
    '粘り強く考え続ける力が身についています。他の力が成長していくと、「楽しみながら考える力」が身についてEnergyをさらに成長させることができます。何か苦手な分野があるときは、まずそこを埋めていくと良いでしょう。',
    '考え続ける力がしっかりと身についています。この力があれば、他の苦手な力にもしっかり取り組んで成長を目指せるはずです。長期的な仕事や作業量の多い仕事も、ただ手を動かすだけでなく「考え続ける」ことによってより効率的にこなせるようになるでしょう。',
  ],

  [
    '20秒という短い時間の中で物事を伝えるのは大変だったと思います。詳細に、かつ丁寧に相手に物事を伝えることを日常や仕事の会話でも意識してみましょう。',
    'ある程度の意思疎通はできているようです。自分の話している言葉が相手に正しく伝わっているかどうか、普段から意識して会話をしてみましょう。同時に、相手の立場に立って話していることを理解する練習も意識的に行ってみましょう。',
    '相手との意思疎通が上手くいっているようです。要点をまとめて説明する力はプレゼンなどにも役立ちます。普段から簡潔に物事を伝えるクセをつけると、よりCommunicateの力を磨けるはずです。',
    '素晴らしい意思疎通の力が身についています。自分の意見を伝え、相手の意見を理解することでより思考をブラッシュアップすることができます。グループワークなどで率先してその力を発揮してみてください。',
  ],
] as const;

export const getResultCommnet = (idx: number, rank: number): string => {
  if (idx < 0 || 4 < idx) return '';
  if (rank < 2) {
    return resultComments[idx][0];
  } else if (rank < 4) {
    return resultComments[idx][1];
  } else if (rank < 6) {
    return resultComments[idx][2];
  } else {
    return resultComments[idx][3];
  }
};
