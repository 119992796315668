import { AccountParams } from '../../adminUsers/AdminUserAccounts';

export const adminUserAccountsSorter: any = {
  fullName: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userData.ruby === null) a.userData.ruby = '';
      if (b.userData.ruby === null) b.userData.ruby = '';

      return b.userData.ruby > a.userData.ruby ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userData.ruby === null) a.userData.ruby = '';
      if (b.userData.ruby === null) b.userData.ruby = '';

      return b.userData.ruby < a.userData.ruby ? -1 : 1;
    },
  },
  admin: {
    ASC: (a: AccountParams, b: AccountParams) => {
      return (
        (a.userData.orgAdmin?.length ?? 0) - (b.userData.orgAdmin?.length ?? 0)
      );
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      return -(
        (a.userData.orgAdmin?.length ?? 0) - (b.userData.orgAdmin?.length ?? 0)
      );
    },
  },
  startYear: {
    ASC: (a: AccountParams, b: AccountParams) => {
      return a.userOrg.startYear < b.userOrg.startYear ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      return a.userOrg.startYear > b.userOrg.startYear ? -1 : 1;
    },
  },
  employeeId: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.employeeId === null) a.userOrg.employeeId = '';
      if (b.userOrg.employeeId === null) b.userOrg.employeeId = '';
      return a.userOrg.employeeId < b.userOrg.employeeId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.employeeId === null) a.userOrg.employeeId = '';
      if (b.userOrg.employeeId === null) b.userOrg.employeeId = '';
      return a.userOrg.employeeId > b.userOrg.employeeId ? -1 : 1;
    },
  },
  industry: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.industryId === undefined || a.userOrg.industryId === null)
        a.userOrg.industryId = '';
      if (b.userOrg.industryId === undefined || b.userOrg.industryId === null)
        b.userOrg.industryId = '';
      return a.userOrg.industryId < b.userOrg.industryId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.industryId === undefined || a.userOrg.industryId === null)
        a.userOrg.industryId = '';
      if (b.userOrg.industryId === undefined || b.userOrg.industryId === null)
        b.userOrg.industryId = '';
      return a.userOrg.industryId > b.userOrg.industryId ? -1 : 1;
    },
  },
  occupation: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (
        a.userOrg.occupationId === undefined ||
        a.userOrg.occupationId === null
      )
        a.userOrg.occupationId = '';
      if (
        b.userOrg.occupationId === undefined ||
        b.userOrg.occupationId === null
      )
        b.userOrg.occupationId = '';
      return a.userOrg.occupationId < b.userOrg.occupationId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (
        a.userOrg.occupationId === undefined ||
        a.userOrg.occupationId === null
      )
        a.userOrg.occupationId = '';
      if (
        b.userOrg.occupationId === undefined ||
        b.userOrg.occupationId === null
      )
        b.userOrg.occupationId = '';
      return a.userOrg.occupationId > b.userOrg.occupationId ? -1 : 1;
    },
  },
  department: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.department === null) a.userOrg.department = '';
      if (b.userOrg.department === null) b.userOrg.department = '';
      return a.userOrg.department < b.userOrg.department ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.department === null) a.userOrg.department = '';
      if (b.userOrg.department === null) b.userOrg.department = '';
      return a.userOrg.department > b.userOrg.department ? -1 : 1;
    },
  },
};
