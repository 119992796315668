import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import actionCreatorFactory from 'typescript-fsa';

import { useMemorizedObject } from '../../lib/useMemorizedObject';
import { GqlError } from '../../types';
import { ErrorReport } from '../reducers/errorReducer';

const actionCreator = actionCreatorFactory();

export const errorActions = {
  pushReport: actionCreator<ErrorReport>('PUSH_REPORT'),
  dropReport: actionCreator('DROP_REPORT'),
};

export type PushErrorReportOptions = {
  error?: Error;
} & Omit<ErrorReport, 'stackTrace' | 'storeDump' | 'gqlErrors'>;

function getGqlErrors(e?: Error): ErrorReport['gqlErrors'] {
  if (!(e instanceof ApolloError)) {
    return undefined;
  }
  return e.graphQLErrors.map((ge): GqlError => {
    const locations =
      ge.locations?.map(({ line, column }) => ({ line, column })) ?? null;
    const message = ge.message;
    const code: string | undefined = ge.extensions?.code;
    const path = ge.path?.map(p => '' + p) ?? null;
    return {
      locations,
      message,
      path,
      code,
    };
  });
}

export const usePushErrorReport = (): ((
  payload: PushErrorReportOptions
) => void) => {
  const dispatch = useDispatch();
  const store: Omit<DefaultRootState, 'error'> = useSelector(state => {
    const { app, auth, game, time } = state;
    return { app, auth, game, time };
  });
  const memorizedStore = useMemorizedObject(store);
  const pushErrorReport = React.useCallback(
    (payload: PushErrorReportOptions) => {
      const { errorCode, message, error, gqlPayload } = payload;
      dispatch(
        errorActions.pushReport({
          errorCode,
          storeDump: JSON.stringify(memorizedStore),
          stackTrace: error?.stack,
          message,
          gqlErrors: getGqlErrors(error),
          gqlPayload,
        })
      );
    },
    [dispatch, memorizedStore]
  );
  return pushErrorReport;
};
