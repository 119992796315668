import styled from 'styled-components';

import Colors from '../../styles/colors';
import ProfileSettings from '../settings/settingsItems/ProfileSettings';
import CommonBG from './CommonBG';
import { HeaderOptions } from './Header';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  hideSettingButton: true,
  buttonType: 'logout',
};

interface WelcomeProps {}
const Welcome: React.FC<WelcomeProps> = props => {
  return (
    <CommonBG withHeader withFooter headerOptions={headerOptions}>
      <ProfileSettings welcome />
    </CommonBG>
  );
};

export default Welcome;
