import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game3 } from './firestoreTypes';

export const stage3Schema = gql`
  mutation SubmitAnswer3($input: Answer3!) {
    submitAnswer3(input: $input) {
      score
      correct
    }
  }
`;

export type Stage3Submission = Game3;

export const useStage3Submission = (
  user: firebase.User | null,
  eventId: string | null
): LoadingHook<Stage3Submission[], Error> => {
  return useCollectionData<Stage3Submission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('3')
          .collection('problem_id')
      : undefined
  );
};

export const useStage3SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage3Submission[], Error> => {
  return useCollectionData<Stage3Submission>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(uid)
          .doc('3')
          .collection('problem_id')
      : undefined
  );
};
