import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAdminStageResetMutation } from '../../../api/__generated__/game.generated';
import {
  GetAdminAllStage5GroupsQuery,
  useGetAdminAllStage5GroupsLazyQuery,
} from '../../../api/__generated__/stage5.generated';
import {
  EventDocument,
  useEventData,
  useEventResult,
  useEventStats,
} from '../../../api/event';
import {
  Event as EventDocumentRaw,
  UserRecords,
} from '../../../api/firestoreTypes';
import { useUserInfo } from '../../../api/user';
import { DataArray, calcRank } from '../../../lib/stageResults';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { appActions } from '../../../redux/actions/appActions';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors from '../../../styles/colors';
import { GroupState } from '../../../types';
import _Button from '../../uiElements/button/SubButton';
import AdminCommonBG from '../AdminCommonBG';
import { UserEvents } from '../adminUsers/AdminUserDetail';
import { AdminAllStage5Groups } from '../UIelements/ControlParticipantManager';
import RankStatus from '../UIelements/RankStatus';

interface ParamTypes {
  userId: string;
  eventId: string;
}

const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;
interface AdminEventUserStatusViewProps {}
const AdminEventUserStatusView: React.FC<AdminEventUserStatusViewProps> =
  props => {
    const dispatch = useDispatch();
    const params = useParams<ParamTypes>();

    const [currentUserInfo] = useUserInfo(params.userId);

    const [events] = useEventData(params.eventId);
    const [eventStats] = useEventStats(params.eventId);
    const [eventResult] = useEventResult(params.userId, params.eventId);
    const [groups, setGroups] = React.useState<AdminAllStage5Groups>();
    const onFetch = (data: GetAdminAllStage5GroupsQuery) => {
      const g = data.getAdminAllStage5Groups;
      setGroups(g);
    };
    const [time, updateTime] = React.useState(Date.now());
    const [runQuery, { called, refetch }] = useGetAdminAllStage5GroupsLazyQuery(
      {
        onCompleted: onFetch,
      }
    );

    const fetch = React.useCallback(() => {
      if (params.eventId === undefined) {
        return;
      }

      if (called) {
        refetch?.({
          input: {
            eventId: params.eventId,
          },
        });
      } else {
        runQuery({
          variables: {
            input: {
              eventId: params.eventId,
            },
          },
        });
      }
    }, [called, params.eventId, refetch, runQuery]);

    const [adminStageResetMutation] = useRetryableMutationWithUI(
      useAdminStageResetMutation,
      {
        hookOptions: {},
      }
    );

    React.useEffect(() => {
      const timeoutId = setTimeout(() => updateTime(Date.now()), 15000);

      if (refetch) {
        refetch();
      }
      return () => {
        clearTimeout(timeoutId);
      };
    }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      fetch();
    }, [fetch]);

    const onStageReset = React.useCallback(
      (stageId: number) => {
        adminStageResetMutation({
          variables: {
            input: {
              stageId: stageId.toString(),
              uid: params.userId,
              eventId: params.eventId,
            },
          },
        });
      },
      [adminStageResetMutation, params.eventId, params.userId]
    );

    const userEvents = React.useMemo(() => {
      if (!eventResult) {
        return null;
      }
      const ret = {
        id: params.eventId,
        record: eventResult,
      } as UserEvents;
      ret.stats = eventStats;
      const convert = (data: EventDocumentRaw) =>
        ({
          status: data.status,
          name: data.name,
          place: data.place,
          scheduledStartTime: data.scheduledStartTime.toDate(),
          scheduledEndTime: data.scheduledEndTime.toDate(),
          startedOn: data.startedOn?.toDate() ?? null,
          endedOn: data.endedOn?.toDate() ?? null,
          resultCalculatedOn: data.resultCalculatedOn?.toDate() ?? null,
        } as EventDocument);

      ret.info = events;

      ret.scores = stages.map(
        stage => ret.record.calculatedScores?.[stage].score ?? 0
      ) as DataArray;
      ret.deviations = stages.map(stage => {
        const score = ret.record.calculatedScores?.[stage].score ?? 0;
        const eventAvg = ret.stats?.[stage].avg ?? score;
        const eventStd = ret.stats?.[stage].std ?? 1;
        return 50 + 10 * ((score - eventAvg) / eventStd);
      }) as DataArray;
      ret.ranks = ret.scores.map(calcRank) as DataArray;

      return ret;
    }, [eventResult, eventStats, events, params.eventId]);

    const Statuses = () => {
      const _list = [];
      const _icons = [
        Icon_Switch,
        Icon_Program,
        Icon_Create,
        Icon_Energy,
        Icon_Communicate,
      ];
      const _categories = [
        'Switch',
        'Program',
        'Create',
        'Energy',
        'Communicate',
      ];
      const names = [
        'ナンバーパネル',
        'ロジカルミステリー',
        'ひらめき謎ノック',
        'ライツトライアル',
        'アドリブプレゼン',
      ];
      for (let i = 0; i < 5; i++) {
        _list.push(
          <TR key={_categories[i]}>
            <TD>
              <SVG src={_icons[i]} />
              <p>{_categories[i]}</p>
              <p>{names[i]}</p>
            </TD>
            <TD>
              <RankStatus
                stageId={i}
                key={i}
                userId={params.userId}
                groups={groups}
                userRecords={eventResult}
                userRanks={userEvents?.ranks}
              />
            </TD>
            {eventResult &&
              (eventResult[('startTimes' + (i + 1)) as keyof UserRecords] ||
                (i === 4 &&
                  (
                    groups?.filter(e => e.members.includes(params.userId)) ?? []
                  ).some(
                    e => e.state != GroupState.Initializing
                  ))) /* まだ未受験の場合、ボタンを表示しない */ && (
                <TD>
                  <Button
                    size={'medium'}
                    color={'negative'}
                    onMouseDown={() => {
                      dispatch(
                        appActions.setErrorOverlayState({
                          errorType: 'CommonError',
                          message: `${_categories[i]}の試験結果をリセットして、未受験に戻しますか？`,
                          backButtonText: 'いいえ',
                          retryButtonText: 'はい',
                          onRetry: () => {
                            onStageReset(i);
                          },
                          onBack: () => {
                            console.log('close');
                          },
                        })
                      );
                    }}
                  >
                    未受験に戻す
                  </Button>
                </TD>
              )}
          </TR>
        );
      }

      return <>{_list}</>;
    };

    return (
      <AdminCommonBG backButton title='テストの進行管理'>
        <AdminEventControllerWrapper>
          <Row>
            <DetailTable>
              <THead>
                <TR>
                  <TH>氏名</TH>
                  <TH>開催日時</TH>
                  <TH>テストイベント名</TH>
                  <TH>プリセット</TH>
                </TR>
              </THead>

              <TBody>
                <TR>
                  <TD>
                    <Link to={`/admin/accounts/${params.userId}`}>
                      {currentUserInfo?.displayName ??
                        currentUserInfo?.fullName ??
                        ''}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </TD>
                  <TD>
                    {' '}
                    {events?.scheduledStartTime &&
                      format(events?.scheduledStartTime, 'yyyy年M月d日')}{' '}
                    {events?.scheduledStartTime &&
                      format(events?.scheduledStartTime, 'HH:mm')}
                    {' - '}
                    {events?.scheduledEndTime &&
                      format(events?.scheduledEndTime, 'HH:mm')}
                  </TD>
                  <TD>
                    <Link to={`/admin/events/${params.eventId}/control`}>
                      {events?.name ?? ''}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </TD>
                  <TD>テストプリセット１</TD>
                </TR>
              </TBody>
            </DetailTable>
          </Row>
          <Row>
            <Item>
              <ResultTable>
                <THead>
                  <TR>
                    <TH>テスト項目</TH>
                    <TH>ステータス</TH>
                    <TH></TH>
                  </TR>
                </THead>

                <TBody>
                  <Statuses />
                </TBody>
              </ResultTable>
            </Item>
          </Row>
        </AdminEventControllerWrapper>
      </AdminCommonBG>
    );
  };

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Margin = styled.div`
  margin-bottom: 1rem;
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
  font-weight: bold;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  tbody {
    tr {
      border: none;
    }
  }

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 24rem;
    }
    td:nth-child(3) {
      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      padding-right: 0;
    }
  }
`;

const ResultTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  tr {
    td:nth-child(1) {
      display: flex;
      align-items: center;

      > :nth-child(2) {
        font-weight: bold;
        width: 14rem;
      }
    }
    td:nth-child(2) {
      color: ${Colors.accent};
      font-weight: bold;
      font-size: 2.8rem;
    }
    td:nth-child(3) {
      color: ${Colors.accent};
      font-weight: bold;
      font-size: 2.8rem;
    }

    td:last-child {
      padding-right: 0;
    }
  }

  td {
    padding: 1rem 0;
  }
`;
const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }

  a {
    color: ${Colors.accent};
    font-weight: bold;
    text-decoration: none;

    svg {
      margin-left: 0.6rem;
    }
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const RankWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const RankHead = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const RankItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:last-child {
    margin-right: 0;
  }
`;
const RankBody = styled.div`
  width: 100%;
  display: flex;
`;
const BodyItem = styled.div`
  flex: 1;
  font-size: 2.8rem;
  margin-right: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  margin: 1rem 0;

  &:last-child {
    border: none;
  }
`;
export default AdminEventUserStatusView;
