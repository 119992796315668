import { faLastfmSquare } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { presets } from '../../lib/preset';
import S_Icon_Communicate from '../../static/svg/admin/category_communicate_h.svg';
import Icon_Communicate from '../../static/svg/admin/category_communicate.svg';
import S_Icon_Create from '../../static/svg/admin/category_create_h.svg';
import Icon_Create from '../../static/svg/admin/category_create.svg';
import S_Icon_Energy from '../../static/svg/admin/category_energy_h.svg';
import Icon_Energy from '../../static/svg/admin/category_energy.svg';
import S_Icon_Program from '../../static/svg/admin/category_program_h.svg';
import Icon_Program from '../../static/svg/admin/category_program.svg';
import S_Icon_Switch from '../../static/svg/admin/category_switch_h.svg';
import Icon_Switch from '../../static/svg/admin/category_switch.svg';
import Colors from '../../styles/colors';
import _Button from '../uiElements/button/SubButton';
import AdminCommonBG from './AdminCommonBG';
import { HeaderButton } from './UIelements/AdminNavigation';

interface AdminPresetDetailPropsProps {}
const AdminPresetDetailProps: React.FC<AdminPresetDetailPropsProps> = props => {
  const history = useHistory();
  const [openSwitch, setOpenSwitch] = React.useState(false);
  const [openProgram, setOpenProgram] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEnergy, setOpenEnergy] = React.useState(false);
  const [openCommunicate, setOpenCommunicate] = React.useState(false);
  const dummy = {
    name: 'プリセット1',
    preset_id: 0,
    score: [
      {
        category: 'switch',
        rank: 'A+',
        average: 123,
        tests: [
          { name: '問題1', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題2', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題3', times: 1000, correct: 600, rate: 0.6 },
        ],
      },
      {
        category: 'program',
        rank: 'A+',
        average: 123,
        tests: [
          { name: '問題1', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題2', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題3', times: 1000, correct: 600, rate: 0.6 },
        ],
      },
      {
        category: 'create',
        rank: 'A+',
        average: 123,
        tests: [
          { name: '問題1', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題2', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題3', times: 1000, correct: 600, rate: 0.6 },
        ],
      },
      {
        category: 'energy',
        rank: 'A+',
        average: 123,
        tests: [
          { name: '問題1', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題2', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題3', times: 1000, correct: 600, rate: 0.6 },
        ],
      },
      {
        category: 'communicate',
        rank: 'A+',
        average: 123,
        tests: [
          { name: '問題1', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題2', times: 1000, correct: 600, rate: 0.6 },
          { name: '問題3', times: 1000, correct: 600, rate: 0.6 },
        ],
      },
    ],
  };

  return (
    <AdminCommonBG title={dummy.name} backButton>
      <Details>
        {presets[0].tests.map((single, i) => {
          return (
            <SingleDetail key={'preset_detail_' + i}>
              {i == 0 && (
                <Category>
                  <SVG src={Icon_Switch} />
                  <p>Switch</p>
                </Category>
              )}
              {i == 1 && (
                <Category>
                  <SVG src={Icon_Program} />
                  <p>Program</p>
                </Category>
              )}
              {i == 2 && (
                <Category>
                  <SVG src={Icon_Create} />
                  <p>Create</p>
                </Category>
              )}
              {i == 3 && (
                <Category>
                  <SVG src={Icon_Energy} />
                  <p>Energy</p>
                </Category>
              )}
              {i == 4 && (
                <Category>
                  <SVG src={Icon_Communicate} />
                  <p>Communicate</p>
                </Category>
              )}
              <img src={single.thumb} />
              <Title>{single.name}</Title>
              <Detail>{single.detail}</Detail>
            </SingleDetail>
          );
        })}
      </Details>
      <Info>
        {dummy.score.map(single => {
          let open = false;
          if (single.category == 'switch') open = openSwitch;
          if (single.category == 'program') open = openProgram;
          if (single.category == 'create') open = openCreate;
          if (single.category == 'energy') open = openEnergy;
          if (single.category == 'communicate') open = openCommunicate;

          return (
            <SingleInfo>
              <InfoButton
                onClick={() => {
                  if (single.category == 'switch') {
                    setOpenSwitch(!openSwitch);
                    return;
                  }
                  if (single.category == 'program') {
                    setOpenProgram(!openProgram);
                    return;
                  }
                  if (single.category == 'create') {
                    setOpenCreate(!openCreate);
                    return;
                  }
                  if (single.category == 'energy') {
                    setOpenEnergy(!openEnergy);
                    return;
                  }
                  if (single.category == 'communicate') {
                    setOpenCommunicate(!openCommunicate);
                    return;
                  }
                }}
              >
                <InfoButtonTitle>
                  {single.category == 'switch' && <SVG src={S_Icon_Switch} />}
                  {single.category == 'program' && <SVG src={S_Icon_Program} />}
                  {single.category == 'create' && <SVG src={S_Icon_Create} />}
                  {single.category == 'energy' && <SVG src={S_Icon_Energy} />}
                  {single.category == 'communicate' && (
                    <SVG src={S_Icon_Communicate} />
                  )}
                </InfoButtonTitle>
                <span>
                  <p>平均ランク</p>
                  <p>{single.rank}</p>
                  <p>平均点</p>
                  <p>123</p>
                </span>
                <Chevron open={open}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Chevron>
              </InfoButton>
              <InfoWrap open={open} bodyHeight={32 + 45 * single.tests.length}>
                <InfoTitle>
                  <SingleInfoTitle>テスト問題</SingleInfoTitle>
                  <SingleInfoTitle>受験数</SingleInfoTitle>
                  <SingleInfoTitle>正解数</SingleInfoTitle>
                  <SingleInfoTitle>正答率</SingleInfoTitle>
                </InfoTitle>
                {single.tests.map((singleTest, n) => {
                  return (
                    <InfoBody key={'info_' + n}>
                      <SingleInfoBody>{singleTest.name}</SingleInfoBody>
                      <SingleInfoBody>{singleTest.times}</SingleInfoBody>
                      <SingleInfoBody>{singleTest.correct}</SingleInfoBody>
                      <SingleInfoBody>{singleTest.rate * 100}%</SingleInfoBody>
                    </InfoBody>
                  );
                })}
              </InfoWrap>
            </SingleInfo>
          );
        })}
      </Info>
    </AdminCommonBG>
  );
};

const Details = styled.div`
  margin: 1rem 0;
  display: flex;
`;
const SingleDetail = styled.div`
  flex: 1;
  margin-right: 1.2rem;

  img {
    width: 100%;
    border: 2px solid ${Colors.gray6};
  }

  &:last-child {
    margin-right: 0;
  }
`;
const Category = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;
const Title = styled.div`
  font-size: 1.6rem;
`;
const Detail = styled.div`
  font-size: 1rem;
  color: ${Colors.gray6};
`;
const Info = styled.div`
  margin-bottom: 2rem;
`;
const SingleInfo = styled.div`
  overflow: hidden;
  border-top: 1px solid ${Colors.gray4};
  border-left: none;
  border-right: none;
  padding: 0.2rem 0;
`;
const InfoButton = styled.div`
  display: flex;
  align-items: center;
  background: ${Colors.gray2};
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${Colors.gray4};
  }

  span {
    display: flex;
    align-items: center;

    p:nth-child(2n-1) {
      font-size: 1.4rem;
    }
    p:nth-child(2n) {
      font-weight: bold;
      color: ${Colors.accent};
      font-size: 2.8rem;
      margin: 0 2rem;
    }
  }
`;
const InfoButtonTitle = styled.div`
  flex: 1;

  svg {
    margin: 0.6rem 1rem;
  }
`;
const InfoTitle = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
  font-weight: bold;
`;
const InfoBody = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};

  &:last-child {
    border-bottom: none;
  }
`;
const SingleInfoTitle = styled.div`
  flex: 1;
  font-size: 1.3rem;
  padding: 0.6rem 1rem;
`;
const SingleInfoBody = styled.div`
  flex: 1;
  font-size: 1.4rem;
  padding: 1.2rem 1rem;
`;

interface bodyProps {
  open: boolean;
  bodyHeight?: number;
}

const InfoWrap = styled.div`
  transition: 0.2s;

  ${(p: bodyProps) =>
    p.open === true
      ? `
        height: ${p.bodyHeight}px;
      `
      : `
        height: 0;
  `}
`;

const Chevron = styled.div`
  svg {
    transition: 0.2s;
    margin-right: 2rem;

    ${(p: bodyProps) =>
      p.open === true
        ? `
          transform: rotate(180deg);
        `
        : `
    `}
  }
`;

export default AdminPresetDetailProps;
