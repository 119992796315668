import * as React from 'react';
import { useDispatch } from 'react-redux';

import { appActions } from '../../redux/actions/appActions';

interface Props {}

const PanicConsole: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [dotCount, setDotCount] = React.useState(0);
  const [commaCount, setCommaCount] = React.useState(0);
  const onKeyPress = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === '.') {
        setDotCount(d => (d < 5 ? d + 1 : d));
      } else if (ev.key === ',') {
        setDotCount(d => {
          if (d === 5) {
            setCommaCount(c => (c < 5 ? c + 1 : c));
            return 5;
          } else {
            return 0;
          }
        });
      } else {
        setDotCount(0);
        setCommaCount(0);
      }
    },
    [setDotCount, setCommaCount]
  );
  React.useEffect(() => {
    window.addEventListener('keypress', onKeyPress);
    return () => window.removeEventListener('keypress', onKeyPress);
  }, [onKeyPress]);
  React.useEffect(() => {
    if (dotCount === 5 && commaCount === 5) {
      dispatch(
        appActions.setErrorOverlayState({
          errorType: 'ManualError',
        })
      );

      setDotCount(0);
      setCommaCount(0);
    }
  }, [dotCount, commaCount, dispatch]);
  return null;
};

export default PanicConsole;
