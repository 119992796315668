import { FormSelectOption } from './formTypes';

export const getNumberOptions = (
  start: number,
  end: number
): FormSelectOption<number>[] => {
  const range = end - start + 1;
  const years = new Array(range).fill(0).map((_, i) => start + i);

  return years.map(y => ({
    label: y.toString(),
    value: y,
  }));
};

export const getYearOptions = (start = 1970): FormSelectOption<number>[] => {
  const nowYear = new Date().getFullYear();
  return getNumberOptions(start, nowYear);
};

export const getDayOptions = (
  year: number,
  month: number
): FormSelectOption<number>[] => {
  const months30 = [4, 6, 9, 11];
  const months31 = [1, 3, 5, 7, 8, 10, 12];

  if (months30.includes(month)) {
    return getNumberOptions(1, 30);
  }
  if (months31.includes(month)) {
    return getNumberOptions(1, 31);
  }

  // 2月
  const isExtra = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  return getNumberOptions(1, isExtra ? 29 : 28);
};
