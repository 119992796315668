import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const timeActions = {
  updateRemainingTime: actionCreator<void>('TIME_UPDATE_REMAINING_TIME'),
  clearTimer: actionCreator<void>('TIME_CLEAR_TIMER'),
  setTimer:
    actionCreator<{ startTimeOnServer?: number; duration: number }>(
      'TIME_SET_TIMER'
    ),
  setTimerOffset: actionCreator<number>('TIME_SET_OFFSET'),
  setServerTimeOffset: actionCreator<number | undefined>(
    'TIME_SET_SERVER_TIME_OFFSET'
  ),
};
