import React from 'react';
import styled from 'styled-components';

import Colors, { colorWithAlpha } from '../../../../styles/colors';
import * as Types from '../../../../types';
import Button from '../../../uiElements/button/SubButton';
import TextWindow from '../../../uiElements/TextWindow';
import { AchievedItem } from './AchievedPanel';

interface NewlyAchievedWindowProps {
  newlyAchievedTargets: Types.Stage1Target[];
  onClose: () => void;
}

const NewlyAchievedWindow: React.FC<NewlyAchievedWindowProps> = props => {
  const { newlyAchievedTargets } = props;
  return (
    <>
      {newlyAchievedTargets.length > 0 && (
        <NewlyAchievedWindowWrapper>
          <NewlyAchievedContent>
            <NewlyAchievedText bracket dark>
              <p>ターゲットを開放しました。</p>
              <NewlyAchievedList>
                {newlyAchievedTargets.map(t => (
                  <NewlyAchievedItem key={t.targetId}>
                    {t.name}
                  </NewlyAchievedItem>
                ))}
              </NewlyAchievedList>
              <CloseButton
                size={'large'}
                color={'positive'}
                onClick={props.onClose}
              >
                確認
              </CloseButton>
            </NewlyAchievedText>
          </NewlyAchievedContent>
        </NewlyAchievedWindowWrapper>
      )}
    </>
  );
};

const NewlyAchievedWindowWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colorWithAlpha('gray8', 0.9)};
  z-index: 3;
`;

const NewlyAchievedContent = styled.div`
  width: 80rem;
  max-width: 90vw;
  min-height: 15rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 4rem 0 2.3rem;
  text-align: center;
`;

const NewlyAchievedText = styled(TextWindow).attrs({
  dark: true,
})`
  min-height: 5rem;
  font-size: 2.4rem;
  line-height: 5rem;
  text-align: center;
  letter-spacing: 0.2rem;
  min-width: 0;
  display: inline-block;
  vertical-align: middle;
`;

const NewlyAchievedList = styled.div`
  margin: 2rem 5% 0;
  width: 90%;
  text-align: center;
  color: ${Colors.gray8};
`;

const NewlyAchievedItem = styled(AchievedItem)`
  margin-right: 2rem;
  height: 2.2rem;
  line-height: 2.2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const CloseButton = styled(Button)`
  margin-top: 2rem;
`;

export default NewlyAchievedWindow;
