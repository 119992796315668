import * as React from 'react';
import styled from 'styled-components';

import Button from '../button/Button';
import { ButtonProps } from '../button/buttonConfigs';

const FormSubmitButton: React.FC<ButtonProps> = props => {
  return (
    <FormSubmitButtonWrapper>
      <Button {...props} />
    </FormSubmitButtonWrapper>
  );
};

const FormSubmitButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export default FormSubmitButton;
