import * as React from 'react';
import styled from 'styled-components';

import { colorWithAlpha } from '../../../../styles/colors';
import Window from '../../../uiElements/Window';

interface CriticalErrorOverlayProps {}

const CriticalErrorOverlay: React.FC<CriticalErrorOverlayProps> = () => {
  return (
    <ErrorOverlayWrapper>
      <TextBox>
        <Text1>{'Sorry...'}</Text1>
        <Text2>{'申し訳ありません。一時的なエラーが発生しました。'}</Text2>
        <Text2>{'このエラーは自動でログが送信されています。'}</Text2>
        <Text2>
          {'このページをリロードしてそのままテストを進行してください。'}
        </Text2>
      </TextBox>
    </ErrorOverlayWrapper>
  );
};

const Text1 = styled.div`
  margin: 5rem auto 8rem;
  font-size: 12rem;
  font-weight: 700;
`;

const Text2 = styled.div`
  font-size: 3.5rem;
  font-weight: 700;
`;

const TextBox = styled(Window)`
  text-align: center;
  position: absolute;
  width: 70%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const ErrorOverlayWrapper = styled.div`
  position: fixed;
  background-color: ${colorWithAlpha('white', 0.5)};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
`;

export default CriticalErrorOverlay;
