import * as React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useEndedEventIds } from '../../api/event';
import { useSetLoadingState } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import PlayerImg from '../../static/svg/MyPage_Player.svg';
import EventTable from '../eventProvider/EventTable';
import CommonBG from '../uiElements/CommonBG';
import ProfileBar from '../uiElements/ProfileBar';
import HistoryResult from './HistoryResult';

interface HistoryProps {}
const History: React.FC<HistoryProps> = () => {
  const user = useCurrentUser();
  const setLoadingState = useSetLoadingState();
  const history = useHistory();
  const [eventIds, loading] = useEndedEventIds(user);
  const eventId =
    useRouteMatch<{ eventId: string }>('/history/:eventId')?.params.eventId ??
    null;
  const [prevEventId, setPrevEventId] = React.useState<string | null>(null);
  const [nextEventId, setNextEventId] = React.useState<string | null>(null);

  const setEventId = React.useCallback(
    (eId: string) => {
      history.push('/history/' + eId);
    },
    [history]
  );

  React.useEffect(() => {
    setLoadingState({ visible: loading });
  }, [loading, setLoadingState]);

  React.useEffect(() => {
    if (!loading && !eventIds?.length) history.push('/');
  }, [eventIds, history, loading]);

  React.useEffect(() => {
    if (loading || !eventIds || !eventId) return;
    const idx = eventIds.indexOf(eventId);
    setPrevEventId(idx !== -1 && eventIds[idx + 1] ? eventIds[idx + 1] : null);
    setNextEventId(idx !== -1 && eventIds[idx - 1] ? eventIds[idx - 1] : null);
  }, [eventId, eventIds, loading]);

  return (
    <Switch>
      <Route path='/history' exact>
        <CommonBG
          withHeader
          withFooter
          headerOptions={{ buttonType: 'logout' }}
        >
          <EventsWrapper>
            <Content>
              <Player>
                <SVG src={PlayerImg} />
                <p>{user?.displayName ?? 'NoName'}</p>
              </Player>

              <EventTable
                eventIds={eventIds ?? []}
                type='resultsView'
                showResult={setEventId}
              />
              {loading && <Loading>Loading...</Loading>}
            </Content>
          </EventsWrapper>
        </CommonBG>
      </Route>
      <Route path='/history/:eventId'>
        <HistoryResult prevEventId={prevEventId} nextEventId={nextEventId} />
      </Route>
    </Switch>
  );
};

const EventsWrapper = styled.div``;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2.4rem 8rem 0;
`;

const Player = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  line-height: 7rem;
  font-size: 2.8rem;
  margin: 0 auto;

  p {
    font-weight: bold;
  }

  svg {
    width: 4rem;
    height: 4rem;
    margin-right: 4rem;
  }
`;

const Loading = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem 0;
`;

export default History;
