import * as React from 'react';
import styled from 'styled-components';

import { colorWithAlpha } from '../../../../styles/colors';
import _Button from '../../../uiElements/button/SubButton';
import _TextWindow from '../../../uiElements/TextWindow';

interface Stage2ResultProps {
  isCorrect: boolean;
  onComplete: () => void;
}
const Stage2Result: React.FC<Stage2ResultProps> = props => {
  const text1 = props.isCorrect ? '正解' : '不正解';
  const text2 = props.isCorrect
    ? '名推理、お見事です！'
    : '犯人は「青木」でした。';

  return (
    <Stage2ResultWrapper>
      <TextWindow bracket {...props}>
        <Text1>{text1}</Text1>
        <Text2>{text2}</Text2>
        <Button size='large' color='neutral' onClick={() => props.onComplete()}>
          確認
        </Button>
      </TextWindow>
    </Stage2ResultWrapper>
  );
};

const Stage2ResultWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
`;

const TextWindow = styled(_TextWindow)`
  background-color: ${(p: Stage2ResultProps) =>
    p.isCorrect ? 'rgba(81, 207, 102, 0.25)' : 'rgba(240, 62, 62, 0.25)'};
  min-height: 6rem;
  min-width: 54rem;
  padding: 2rem 5rem;
  line-height: 6rem;
  font-size: 2.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
  white-space: pre-line;
`;

const Text1 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 3.5rem;
  font-weight: 600;
  margin-top: 1rem;
`;
const Text2 = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
`;

const Button = styled(_Button)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: inline-block;
`;

export default Stage2Result;
