import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecordErrorMutationVariables = Types.Exact<{
  input: Types.ErrorPayload;
}>;


export type RecordErrorMutation = (
  { __typename?: 'Mutation' }
  & { recordError: (
    { __typename?: 'CreateErrorResult' }
    & Pick<Types.CreateErrorResult, 'reportId'>
  ) }
);


export const RecordErrorDocument = gql`
    mutation recordError($input: ErrorPayload!) {
  recordError(input: $input) {
    reportId
  }
}
    `;
export type RecordErrorMutationFn = Apollo.MutationFunction<RecordErrorMutation, RecordErrorMutationVariables>;

/**
 * __useRecordErrorMutation__
 *
 * To run a mutation, you first call `useRecordErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordErrorMutation, { data, loading, error }] = useRecordErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordErrorMutation(baseOptions?: Apollo.MutationHookOptions<RecordErrorMutation, RecordErrorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordErrorMutation, RecordErrorMutationVariables>(RecordErrorDocument, options);
      }
export type RecordErrorMutationHookResult = ReturnType<typeof useRecordErrorMutation>;
export type RecordErrorMutationResult = Apollo.MutationResult<RecordErrorMutation>;
export type RecordErrorMutationOptions = Apollo.BaseMutationOptions<RecordErrorMutation, RecordErrorMutationVariables>;