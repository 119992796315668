// import { equal } from '@wry/equality';
import * as React from 'react';
import isEqual from 'react-fast-compare';

export const useMemorizedObject = <T>(obj: T): T => {
  const [memorized, setMemorized] = React.useState<T>(obj);

  // TODO: Typeで対応したい
  if (typeof obj === 'function') {
    console.warn('Do not use useMemorizedObject with a function!');
  }

  React.useEffect(() => {
    setMemorized(old => {
      if (!isEqual(old, obj)) {
        return obj;
      }

      return old;
    });
  }, [obj]);

  return memorized;
};
