import { ApolloError, gql } from '@apollo/client';
import firebase from 'firebase/app';
import { useCallback, useEffect } from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';
import { useDispatch } from 'react-redux';

import { gameActions } from '../redux/actions/gameActions';
import { useLocalStage4Penalty } from '../redux/selectors/gameSelectors';
import { useIncrementPenalty4Mutation } from './__generated__/stage4.generated';
import { Game4, Game4StageRecord } from './firestoreTypes';

export const stage4Schema = gql`
  mutation incrementPenalty4($input: Penalty4!) {
    incrementPenalty4(input: $input) {
      eventId
      penalty
    }
  }

  mutation SubmitAnswer4($input: Answer4!) {
    submitAnswer4(input: $input) {
      problemId
    }
  }
`;

export type Stage4Submission = Game4;
export type Stage4StageSubmission = Game4StageRecord;

const useServerStage4Penalty = (
  user: firebase.User | null,
  eventId: string | null
) => {
  return useDocumentData<Stage4Submission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('4')
      : undefined
  );
};

export const useStage4StageSubmissions = (
  user: firebase.User | null,
  eventId: string | null
): LoadingHook<Stage4StageSubmission[], Error> => {
  return useCollectionData<Stage4StageSubmission>(
    user !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(user.uid)
          .doc('4')
          .collection('stage_id')
      : undefined
  );
};

export const useStage4StageSubmissionsByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage4StageSubmission[], Error> => {
  return useCollectionData<Stage4StageSubmission>(
    uid !== null && eventId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('submissions')
          .doc(eventId)
          .collection(uid)
          .doc('4')
          .collection('stage_id')
      : undefined
  );
};

const onError = (e: ApolloError) => console.error(e);

export const useStage4Penalty = (
  user: firebase.User | null,
  eventId: string | null
): [number, () => void] => {
  const dispatch = useDispatch();
  const penalty = useLocalStage4Penalty();
  const [submission] = useServerStage4Penalty(user, eventId);
  const [incrementMutation] = useIncrementPenalty4Mutation({
    onError,
    variables: { input: { eventId: eventId ?? '' } },
  });
  const increment = useCallback(() => {
    dispatch(gameActions.incrementStage4Penalty());
    incrementMutation();
  }, [incrementMutation, dispatch]);
  useEffect(() => {
    if (submission?.penalty == null) return;
    dispatch(gameActions.setStage4Penalty(submission.penalty));
  }, [dispatch, submission]);
  return [penalty, increment];
};
