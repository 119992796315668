import * as React from 'react';
import styled from 'styled-components';

import {
  stage3problems as problems,
  stage3problems,
} from '../../../../lib/Stage3Problems';
import Stage3Main from './Stage3Main';
import Stage3ProblemSelector from './Stage3ProblemSelector';

interface Stage3ViewProps {
  onSend: (ans: string, problemId: number) => void;
  judge: 'correct' | 'wrong' | null;
  cleared: Partial<Record<number, boolean>>;
}

const getFirstUnsolvedIdx = (cleared: Partial<Record<number, boolean>>) => {
  const idx = problems.findIndex(prb => !cleared[prb.problemId]);
  return idx !== -1 ? idx : 19;
};

const Stage3View: React.FC<Stage3ViewProps> = props => {
  const [problemIdx, setProblemIdx] = React.useState<number>(
    getFirstUnsolvedIdx(props.cleared)
  );

  const setNextProblem = React.useCallback(() => {
    if (
      problemIdx <= 19 &&
      !props.cleared[stage3problems[problemIdx].problemId + 1]
    )
      setProblemIdx(Math.min(19, problemIdx + 1));
  }, [problemIdx, props.cleared]);

  return (
    <Stage3Wrapper>
      <Stage3ProblemSelector
        problemIdx={problemIdx}
        setProblemIdx={setProblemIdx}
        problems={problems}
        cleared={props.cleared}
      />
      <Stage3Main
        onSend={props.onSend}
        problemIdx={problemIdx}
        judge={props.judge}
        setNextProblem={setNextProblem}
      />
    </Stage3Wrapper>
  );
};

const Stage3Wrapper = styled.div`
  height: 100vh;
  display: flex;
`;

export default Stage3View;
