import * as React from 'react';
import styled, { css } from 'styled-components';
import { JsxAttribute } from 'typescript';

import dummyIcon from '../../../static/png/admin/dummy_org.png';

interface OrganizationIconProps {
  size: number;
  src?: string;
}
const OrganizationIcon: React.FC<OrganizationIconProps> = props => {
  return (
    <Icon size={props.size}>
      <Img src={props.src || dummyIcon} />
    </Icon>
  );
};

interface IconProps {
  size: number;
}
const Icon = styled.div`
  ${(p: IconProps) => `
    width: ${p.size}px;
    height: ${p.size}px;

    img {
        width: ${p.size}px;
        height: ${p.size}px;}
    }
  `}
`;

const Img = styled.img``;

export default OrganizationIcon;
