import React from 'react';
import styled from 'styled-components';

import { DataArray } from '../../../lib/stageResults';
import { colorWithAlpha } from '../../../styles/colors';
import ChartFig from './RadarChartScore';

const f = (v: number) => {
  if (v <= 0.5) return Math.pow(v, 1.2);
  return 1 - Math.pow(1 - v, 1.2);
};

const normalize = (v: number) => {
  if (v < 30) return 1 / 5;
  if (70 <= v) return 1;
  return f((v - 30) / 40) * 0.8 + 0.2;
};

const cosVal = [
  -0.9510565162951536, 6.123233995736766e-17, 0.9510565162951535,
  0.5877852522924731, -0.587785252292473,
];
const sinVal = [
  -0.3090169943749473, -1, -0.3090169943749474, 0.8090169943749475,
  0.8090169943749475,
];

/**
 * p: 長さ5のnumber[] - 各方向への長さ
 * r: nubmer - アニメーションするためのパラメータ
 * 返り値: 頂点の座標の配列 [x, y, x, y, ...]
 * 420px * 400px の長方形に (220, 221) を中心とした正五角形を書いてます
 * v * r === 1.0 の時に最大の大きさ（半径221px）の五角形が書かれる感じ
 * v は最終的に書かれてほしいサイズ、rはアニメーションするためのパラメータ
 */
const g = (p: DataArray, r: number) =>
  p
    .map((v, i) => [
      cosVal[i] * v * r * 221 + 210,
      (sinVal[i] * v * r + 1) * 221,
    ])
    .flat();

interface Props {
  deviations: DataArray;
  width: number;
  loaded: boolean;
}

const RadarChart: React.FC<Props> = props => {
  const { deviations } = props;
  const normalized = React.useMemo(
    () => deviations.map(normalize) as DataArray,
    [deviations]
  );
  const [ratio, setRatio] = React.useState(0);
  const data = React.useMemo(() => g(normalized, ratio), [normalized, ratio]);
  const lines = [0, 0.2, 0.4, 0.6, 0.8, 1].map(i => g([i, i, i, i, i], 1));
  const f = React.useCallback(() => {
    if (!props.loaded) {
      setRatio(0);
      return;
    }
    setRatio(ratio => {
      if (ratio < 1) {
        const d = (0.1 + ratio) * (1.1 - ratio) * 0.05;
        setTimeout(f, 10);
        return ratio + d;
      } else {
        return 1;
      }
    });
  }, [props.loaded]);
  React.useEffect(() => {
    f();
  }, [f]);

  return props.loaded ? (
    <Wrapper>
      {deviations.map((v, i) => (
        <ChartFig
          key={i}
          stageNum={(i + 1) as 1 | 2 | 3 | 4 | 5}
          deviation={v}
        />
      ))}
      <Chart viewBox='0 0 420 400'>
        {lines.map((ln, idx) => (
          <polyline
            key={idx}
            fill='none'
            stroke='black'
            strokeWidth='1'
            points={[...ln, ln[0], ln[1]].join(' ')}
          />
        ))}
        <polyline
          stroke={colorWithAlpha('primary', 1)}
          strokeWidth='1'
          points={data.join(' ')}
          fill={colorWithAlpha('primary', 1)}
          fillOpacity='0.9'
        />
      </Chart>
    </Wrapper>
  ) : (
    <></>
  );
};

const Chart = styled.svg`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 33.7rem;
  height: 32rem;
`;

const Wrapper = styled.div`
  position: relative;
  width: 67rem;
  height: 40rem;
`;

export default RadarChart;
