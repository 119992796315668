import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  GetFinishedStage5GroupsQuery,
  useGetFinishedStage5GroupsLazyQuery,
} from '../../../api/__generated__/stage5.generated';
import { gameActions } from '../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import { useEventId } from '../../../redux/selectors/gameSelectors';

const stage5finishedAction = gameActions.setHasStageFinished({
  stage: 5,
  finished: true,
});

const stage5startAction = gameActions.setHasStageFinished({
  stage: 5,
  finished: false,
});

interface Stage5FinishedManagerProps {}
const Stage5FinishedManager: React.FC<Stage5FinishedManagerProps> = () => {
  const eventId = useEventId();
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const onFetch = (data: GetFinishedStage5GroupsQuery) => {
    const finishedGroups = data.getFinishedStage5Groups;
    if (finishedGroups.length > 0) {
      dispatch(stage5finishedAction);
    } else {
      dispatch(stage5startAction);
    }
  };

  const [runQuery, { called, refetch }] = useGetFinishedStage5GroupsLazyQuery({
    onCompleted: onFetch,
  });

  const fetch = React.useCallback(() => {
    if (eventId === null) {
      return;
    }

    if (called) {
      refetch?.({
        input: {
          eventId,
        },
      });
    } else {
      runQuery({
        variables: {
          input: {
            eventId,
          },
        },
      });
    }
  }, [called, eventId, refetch, runQuery]);

  React.useEffect(() => {
    fetch();
  }, [user, eventId, fetch]);

  return null;
};

export default Stage5FinishedManager;
